﻿#Banking-Information {


    .banking-check {
        padding-left: 5px;
    }

    .banking-message {
        font-weight:bold;
        padding-left: 7px;
    }
}

.business-signup-form-page 
{
    #Banking-Information {
        .checkbox input[type=checkbox] + label.validationMessage {
            margin-bottom: 26px;
        }

        .checkbox input[type=checkbox] + label + label {
            margin-top: 37px;
        }
    }
}
