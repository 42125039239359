#page-businessgyms-traffic {
   .comming-soon{
        display: block;
        margin: 0 auto;
        max-width: 768px;
        width: 90%;
        font-size: 35px;
        font-weight: $font-w-semibold;
        color: $color-27;
        line-height: 400px;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-proxima;

   }
}