.searchlocation {
  div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 70vw;

    @include respond-to(tablet) {
      width: 22vw;
      justify-content: flex-end;
    }
    @include respond-to(desktop) {
      width: 18vw;
    }
  }
  input {
    display: inline-block;
    height: 27px;
    line-height: 24px;
    width: 100%;
    padding: 0 10px;
    border: 2px solid $button-color;
    background: $white;
    color: $button-color;
    font-weight: bold;
    font-size: 13px;
    border-radius: 0;
  }
  .nearMe {
    background: $white url('../img/icons/icon-place-blue.png') center center no-repeat;
    cursor: pointer;
    display: block;
    border: 2px solid $button-color;
    padding: 14px;
    margin-left: 10px;
    position: relative;

    @include respond-to(tablet) {
      padding: 11.5px;
      margin-left: 5px;
      background-size: 60%;
    }

    &.in-progress {
      background: $white;
      &:before {
          content: "";
          @include preloader(18px, 18px, 2px, absolute, $color-6, $color-12);
          margin: auto;
          z-index: 5;
      }
    }
  }
  .control-label {
    position: absolute;
    top: 35px;
    left: 0;
    width: 55vw;
    font-size: 13px;
    @include respond-to(tablet) {
      width: 100%;
    }
  }
}
.map-controls {
  position: absolute;
  right: calc(1vw + 10px);
  top: 10px;
  display: flex;

  .map-filter {
    @include respond-to(tablet) {
      margin-left: 5px;
      height: 27px;
      line-height: 24px;
    }
  }
}

#map {
  width: 100%;
  height: 100%;

  .custom-iw .gm-style-iw {
    top: 1vw !important;
    left: 0 !important;

    @include respond-to(desktop-lg) {
      top: 0.5vw !important;
    }
  }
  .custom-iw>div:first-child>div:nth-child(2) {
    display: none;
  }
  .custom-iw .gm-style-iw, .custom-iw .gm-style-iw > div, .custom-iw .gm-style-iw > div > div {
    width: 100% !important;
    max-width: 100% !important;
  }
  .custom-iw, .custom-iw > div:first-child > div:last-child {
    width: 90vw !important;
    @include respond-to(tablet) {
      width: 40vw !important;
    }
    @include respond-to(desktop) {
      width: 30vw !important;
    }
    @include respond-to(desktop-lg) {
      width: 20vw !important;
    }
  }
  .custom-iw>div:first-child>div:last-child {
    left: 0 !important;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    z-index:-1 !important;
  }
  .custom-iw > div:last-child {
    top: 1vw !important;
    right: 1vw !important;
    @include respond-to(desktop-lg) {
      top: 0.5vw !important;
      right: 0.5vw !important;
    }
  }
  .popin-infoWindow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4vw 3vw 0;
    @include respond-to(tablet) {
      padding: 1vw;
    }
    @include respond-to(desktop-lg) {
      padding: 0.5vw;
    }
    .item {
      display: flex;
      justify-content: space-between;
      width: 100%;

      + .item {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $bg-color-1;
      }
    }
    .image {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 100%;
      height: 0;
      width: 30%;
      padding-bottom: 30%;
      &.empty {
        background-color: #008ece;
        background-image: url('../img/club-logo-placeholder.jpg');
      }
    }
    .tier {
      width: 65%;
    }
    .name {
      margin-bottom: 10px;
      a {
        text-decoration: none;
      }
      span {
        font-weight: bold;
        color: $black;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }

        @include respond-to(tablet) {
          font-size: 18px;
        }
      }
    }
    .rating-price {
      @include clearfix;
      margin-bottom: 10px;
      margin-right: 5vw;
      @include respond-to(tablet) {
        margin-right: 2vw;
      }
      @include respond-to(desktop) {
        margin-right: 1vw;
      }

      .rating {
        @include respond-to(tablet) {
          float: left;
          width: 110px;
        }

        span {
          @include sprite($layout-rating-star);
          float: left;
          margin-right: 4px;

          :last-child {
            margin-right: 0;
          }

          &.active {
            @include sprite($layout-rating-star-active);
          }
        }
      }

      .price {
        float: right;
        font-weight: bold;
        color: $black;
        font-size: 14px;
        line-height: 16px;
        @include respond-to(tablet) {
          font-size: 18px;
        }
      }
    }
    .contact-data {
      color: $font-color-15;
      font-size: 12px;
      line-height: 1.4;
      margin-bottom: 5px;

      @include respond-to(tablet) {
        font-size: 14px;
      }
    }
    a {
      color: $color-1;
      font-size: 12px;
      font-weight: 700;
      text-decoration: underline;

      @include respond-to(tablet) {
        font-size: 14px;
        &:hover {
          text-decoration: none;
        }
      }
      @include respond-to(desktop) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}