// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$layout-checkbox-icon-name: 'layout-checkbox-icon';
$layout-checkbox-icon-x: 18px;
$layout-checkbox-icon-y: 14px;
$layout-checkbox-icon-offset-x: -18px;
$layout-checkbox-icon-offset-y: -14px;
$layout-checkbox-icon-width: 13px;
$layout-checkbox-icon-height: 10px;
$layout-checkbox-icon-total-width: 253px;
$layout-checkbox-icon-total-height: 243px;
$layout-checkbox-icon-image: '../img/layout.png';
$layout-checkbox-icon: (18px, 14px, -18px, -14px, 13px, 10px, 253px, 243px, '../img/layout.png', 'layout-checkbox-icon', );
$layout-fb-name: 'layout-fb';
$layout-fb-x: 77px;
$layout-fb-y: 71px;
$layout-fb-offset-x: -77px;
$layout-fb-offset-y: -71px;
$layout-fb-width: 44px;
$layout-fb-height: 43px;
$layout-fb-total-width: 253px;
$layout-fb-total-height: 243px;
$layout-fb-image: '../img/layout.png';
$layout-fb: (77px, 71px, -77px, -71px, 44px, 43px, 253px, 243px, '../img/layout.png', 'layout-fb', );
$layout-in-name: 'layout-in';
$layout-in-x: 121px;
$layout-in-y: 114px;
$layout-in-offset-x: -121px;
$layout-in-offset-y: -114px;
$layout-in-width: 44px;
$layout-in-height: 43px;
$layout-in-total-width: 253px;
$layout-in-total-height: 243px;
$layout-in-image: '../img/layout.png';
$layout-in: (121px, 114px, -121px, -114px, 44px, 43px, 253px, 243px, '../img/layout.png', 'layout-in', );
$layout-rating-star-active-name: 'layout-rating-star-active';
$layout-rating-star-active-x: 45px;
$layout-rating-star-active-y: 39px;
$layout-rating-star-active-offset-x: -45px;
$layout-rating-star-active-offset-y: -39px;
$layout-rating-star-active-width: 16px;
$layout-rating-star-active-height: 16px;
$layout-rating-star-active-total-width: 253px;
$layout-rating-star-active-total-height: 243px;
$layout-rating-star-active-image: '../img/layout.png';
$layout-rating-star-active: (45px, 39px, -45px, -39px, 16px, 16px, 253px, 243px, '../img/layout.png', 'layout-rating-star-active', );
$layout-rating-star-name: 'layout-rating-star';
$layout-rating-star-x: 61px;
$layout-rating-star-y: 55px;
$layout-rating-star-offset-x: -61px;
$layout-rating-star-offset-y: -55px;
$layout-rating-star-width: 16px;
$layout-rating-star-height: 16px;
$layout-rating-star-total-width: 253px;
$layout-rating-star-total-height: 243px;
$layout-rating-star-image: '../img/layout.png';
$layout-rating-star: (61px, 55px, -61px, -55px, 16px, 16px, 253px, 243px, '../img/layout.png', 'layout-rating-star', );
$layout-remove-icon-name: 'layout-remove-icon';
$layout-remove-icon-x: 8px;
$layout-remove-icon-y: 5px;
$layout-remove-icon-offset-x: -8px;
$layout-remove-icon-offset-y: -5px;
$layout-remove-icon-width: 10px;
$layout-remove-icon-height: 9px;
$layout-remove-icon-total-width: 253px;
$layout-remove-icon-total-height: 243px;
$layout-remove-icon-image: '../img/layout.png';
$layout-remove-icon: (8px, 5px, -8px, -5px, 10px, 9px, 253px, 243px, '../img/layout.png', 'layout-remove-icon', );
$layout-search-icon-name: 'layout-search-icon';
$layout-search-icon-x: 31px;
$layout-search-icon-y: 24px;
$layout-search-icon-offset-x: -31px;
$layout-search-icon-offset-y: -24px;
$layout-search-icon-width: 14px;
$layout-search-icon-height: 15px;
$layout-search-icon-total-width: 253px;
$layout-search-icon-total-height: 243px;
$layout-search-icon-image: '../img/layout.png';
$layout-search-icon: (31px, 24px, -31px, -24px, 14px, 15px, 253px, 243px, '../img/layout.png', 'layout-search-icon', );
$layout-select-caret-icon-name: 'layout-select-caret-icon';
$layout-select-caret-icon-x: 0px;
$layout-select-caret-icon-y: 0px;
$layout-select-caret-icon-offset-x: 0px;
$layout-select-caret-icon-offset-y: 0px;
$layout-select-caret-icon-width: 8px;
$layout-select-caret-icon-height: 5px;
$layout-select-caret-icon-total-width: 253px;
$layout-select-caret-icon-total-height: 243px;
$layout-select-caret-icon-image: '../img/layout.png';
$layout-select-caret-icon: (0px, 0px, 0px, 0px, 8px, 5px, 253px, 243px, '../img/layout.png', 'layout-select-caret-icon', );
$layout-tw-name: 'layout-tw';
$layout-tw-x: 165px;
$layout-tw-y: 157px;
$layout-tw-offset-x: -165px;
$layout-tw-offset-y: -157px;
$layout-tw-width: 44px;
$layout-tw-height: 43px;
$layout-tw-total-width: 253px;
$layout-tw-total-height: 243px;
$layout-tw-image: '../img/layout.png';
$layout-tw: (165px, 157px, -165px, -157px, 44px, 43px, 253px, 243px, '../img/layout.png', 'layout-tw', );
$layout-yt-name: 'layout-yt';
$layout-yt-x: 209px;
$layout-yt-y: 200px;
$layout-yt-offset-x: -209px;
$layout-yt-offset-y: -200px;
$layout-yt-width: 44px;
$layout-yt-height: 43px;
$layout-yt-total-width: 253px;
$layout-yt-total-height: 243px;
$layout-yt-image: '../img/layout.png';
$layout-yt: (209px, 200px, -209px, -200px, 44px, 43px, 253px, 243px, '../img/layout.png', 'layout-yt', );
$layout-spritesheet-width: 253px;
$layout-spritesheet-height: 243px;
$layout-spritesheet-image: '../img/layout.png';
$layout-spritesheet-sprites: ($layout-checkbox-icon, $layout-fb, $layout-in, $layout-rating-star-active, $layout-rating-star, $layout-remove-icon, $layout-search-icon, $layout-select-caret-icon, $layout-tw, $layout-yt, );
$layout-spritesheet: (253px, 243px, '../img/layout.png', $layout-spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
