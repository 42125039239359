#business-gyms-top-menu {
    display: none;

    @include respond-to(tablet) {
        display: block;
            max-width: 1215px;
            margin:0 auto;
    }
	
    @include respond-to(tablet) {
        display: block;
            max-width: 1215px;
            margin:0 auto;
    }
    .menu-logged-list {
        position: relative;
        display: table;
        margin: 15px auto;
        top: auto;
        left: auto;
        z-index: 0;
        width: 100%;
        //max-width: 768px;
        padding: 0;
        background: $white;

        @include respond-to(desktop) {
            width: 100%;
        }


        visibility: visible;
        transition: all 0.25s ease-in-out;
        transform: none;

        li {
            display: table-cell;
            padding: 0;
            margin: 0;
            text-align: center;
            font-family: ProximaNova;
            font-weight: 700;
            position: relative;

            a {
                @extend .component-button-1;

                padding: 0 40px;
                width: 100%;
            }

            &.business-menu-list-item {
                a {
                    color: $text-logged-menu;
                    border-right-width: 1px;
                    border-left-width: 1px;
                    border-color: $text-logged-menu;
                    font-size: 18px;
                    line-height: 50px;
                    height: 50px;

                    &:hover {
                        border-color: $button-color;
                    }
                }

                &.active {
                    a {
                        border-color: $button-color;
                        background-color: $button-color;
                        color: $white;
                    }
                }

                &:first-of-type {
                    &.business-menu-list-item {
                        a {
                            border-left-width: 2px;
                        }
                    }
                }

                &:last-of-type {
                    &.business-menu-list-item {
                        a {
                            border-right-width: 2px;
                        }
                    }
                }
            }

            span {
                display: none;
                margin: 0 auto 20px;
                background-position: center center;
                background-repeat: no-repeat;
            }

            &:before {
                content: none;
                display: none;
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

            &.dashboard {
                span {
                    background-image: url("../img/icons/ico_dashboard.png");
                    width: 30px;
                    height: 27px;
                }
            }

            &.edit-profile {
                span {
                    background-image: url("../img/icons/ico_edit_profile.png");
                    width: 27px;
                    height: 26px;
                }
            }

            &.activity {
                span {
                    background-image: url("../img/icons/ico_activity.png");
                    width: 37px;
                    height: 37px;
                }
            }

            &.settings {
                span {
                    background-image: url("../img/icons/ico_settings.png");
                    width: 23px;
                    height: 23px;
                }
            }

            &.logout {
                text-align: right;
                padding-bottom: 0;

                a {
                    display: inline-block;
                }
            }
        }
    }

    #profile-side-menu {
        display: none;
        padding: 0 16px;
        width: 23%;
        max-width: 253px;
        transition: none;

        @include respond-to(desktop) {
            display: inline-block;
        }


        .menu-logged-list {
            top: auto;
            left: auto;
            position: relative;
            width: 100%;
            z-index: 0;
            visibility: visible;
            transform: none;

            li {
                padding: 0;
                text-align: center;
                font-size: 16px;

                &:before {
                    top: auto;
                    bottom: -5px;
                    height: 5px;
                }

                &.active {
                    background-color: $color-13;
                    color: $white;

                    &:before {}
                }

                a {
                    display: block;
                    padding: 30px 0 25px 30%;
                    text-align: center;
                }

                span {
                    left: 10%;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                    width: 20%;
                    display: inline-block;
                    background-size: contain;
                }

                &.active {
                    &.dashboard {
                        span {
                            background-image: url("../img/icons/ico_dashboard-white.png");
                        }
                    }

                    &.activity {
                        span {
                            background-image: url("../img/icons/ico_activity-white.png");
                        }
                    }
                }
            }
        }
    }
}

body.traffic {
    #business-gyms-top-menu {
        @include respond-to(tablet) {
            /*margin: 0 auto;
            width: 100%;
            max-width: 940px;
            padding-left: 15px;
            padding-right: 15px;
            text-align: left;*/
        }


        .menu-logged-list {
            margin: 15px auto 15px 0;
        }
    }
}