// ERRORS
.has-error {
    input, .input-label:before, textarea {
        box-shadow: 0 0 4px $error-color !important;
    }
}

.has-error,
.has-error-backend {
    &.keepsigned {
        display: flex !important;
        flex-direction: column;

        .control-label {
            order: 2;
        }
    }

    .control-label,
    + .control-label {
        font-family: ProximaNova;
        font-weight: $font-w-semibold;
        background: $error-color;
        color: $white;
        display: block;
        width: 100%;
        padding: 9px 10px;
        position: relative;
        margin-top: 10px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $error-color;
            position: absolute;
            top: -7px;
            left: 20px;
        }
    }
}

.individualerror {
    font-family: ProximaNova;
    font-weight: $font-w-semibold;
    background: $error-color;
    color: $white;
    display: block;
    width: 100%;
    padding: 9px 10px;
    position: relative;
    margin-top: 10px;

    &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $error-color;
        position: absolute;
        top: -7px;
        left: 20px;
    }
}

.others-validation-message-common {
    color: #fff;
    background-color: #d04b4b;
    padding: 9px 10px;
    position: relative;

    &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #d04b4b;
        position: absolute;
        top: -7px;
        left: 20px;
    }
}

.mt-1{
    margin-top: 1px;
}
.mt-2 {
    margin-top: 2px;
}
.mt-3 {
    margin-top: 3px;
}
.mt-4 {
    margin-top: 4px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-6 {
    margin-top: 6px;
}
.mt-7 {
    margin-top: 7px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-9 {
    margin-top: 9px;
}
.mt-10 {
    margin-top: 10px;
}