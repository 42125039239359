button.suggestAnEdit {
  cursor: pointer;
  padding-left: 42px;
  padding-right: 42px;
  &:disabled, &:hover {
    color: #fff;
    background: $button-color;
  }
  ~ .control-label {
    display: inline-block;
    width: auto;
  }
}