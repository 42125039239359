#page-buy-end {
    // overflow: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    .buy-row {
        &.buy-row-first {
            position: relative;
        }

        width: 100%;

        // margin-top: 51px;
        + .buy-row {
            margin-top: 0;
        }

        @include clearfix;

        #workout {
            display: none;

            @include respond-to(desktop) {
                margin-top: 70px;
                position: absolute;
                bottom: 20px;

                &.withimage {
                    position: relative;
                    margin-top: 20px;
                }

                header {
                    text-align: left;
                }
            }
        }

        .buy-column.grey {
            background: $bg-color-1;
            border-bottom: 1px solid #ececec;

            // border-top: 1px solid #ececec;
            position: relative;

            header {
                margin-top: 20px;
                text-align: center;

                @include fonts-vw(ProximaNova, 14px, 20px);

                @include respond-to(desktop) {
                    text-align: left;
                }
            }

            @include respond-to(desktop) {
                margin: 0;

                &:after {
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    width: 20vw;
                    left: 100%;
                    top: 0;
                    background: url("../img/club-about-bg.png") no-repeat;
                    background-size: auto 100%;
                }
            }
        }

        .buy-column.booking {
            padding-top: 27px;
            padding-bottom: 31px;
        }

        .buy-column.barcode {
            text-align: center;

            .subtitle {
                margin-bottom: 10px;
            }

            .purchase {
                margin-bottom: 15px;
            }

            .mobile-note {
                display: none;
            }

            .code {
                margin-bottom: 27px;

                img {
                    max-width: 60%;
                    margin: 5px auto 0;
                    max-height: 185px;
                }

                span {
                    /*@include fonts-vw(ProximaNova, 35px, 30px);*/
                    font-size: 35px;
                    font-weight: 700;
                    display: block;
                    text-transform: lowercase;
                }
            }
        }

        &:first-of-type {
            .buy-column:first-child {
                padding: 0 6.5%;
                width: 100%;
                float: left;

                @include respond-to(desktop) {
                    width: 52%;
                    margin-right: 3%;
                    padding: 0;
                }
            }

            .buy-column:nth-child(2n) {
                padding: 0 6.5% 20px 6.5%;
                width: 100%;
                float: left;

                @include respond-to(desktop) {
                    padding: 0 0 20px 60px;
                    width: 45%;
                }
            }

            .grey.buy-column:nth-child(2n) {
                margin: 0 6.5% 20px 6.5%;
                width: 87%;
                padding: 0 20px 20px;
                float: left;

                @include respond-to(desktop) {
                    margin: 0;
                    padding: 0 0 20px 60px;
                    width: 45%;
                }
            }
        }

        &:last-of-type {
            @include respond-to(desktop) {
                display: flex;
                justify-content: space-between;
            }


            .buy-column:first-child, .buy-column:nth-child(2n) {
                float: none;
            }

            //Change for mobile - different order
            .buy-column:first-child {
                padding: 0 6.5%;
                width: 100%;

                @include respond-to(desktop) {
                    width: 52%;
                    margin-right: 3%;
                    padding: 0;
                }
            }

            .buy-column:nth-child(2n) {
                padding: 0 6.5%;
                width: 100%;

                @include respond-to(desktop) {
                    padding: 0 0 0 60px;
                    width: 45%;
                     @media print {
                        
                        width:100%;
                     }
                }
            }
        }

        .search-list {
            padding-top: 15px;
        }

        .share, .print, .invite {
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 16px;
            color: $color-13;
            padding: 3px 15px;
            border: 2px solid $color-13;
        }

        .social-tier {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            > * {
                margin: 5px 5px 0;
            }
        }

        .share {
            .share-fb {
                width: 20px;
                height: 20px;
                display: inline-block;

                @include sprite($buyend-fb-blue);
            }

            .share-text {
                display: inline-block;
                height: 26px;
                line-height: 26px;
                padding-right: 6px;
                vertical-align: top;
            }
        }

        .invite {
            .invite-text {
                display: inline-block;
                height: 26px;
                line-height: 26px;
                padding-left: 6px;
            }

            .invite-icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                position: relative;
                top: 1px;

                @include sprite($buyend-email-blue);
            }
        }

        .toggle-box {
            > :first-child {
                @include respond-to(tablet) {
                    cursor: pointer;
                }


                @include respond-to(desktop) {
                    cursor: default;
                }
            }

            .toggle-icon {
                @include respond-to(tablet) {
                    display: inline-block;
                }


                @include respond-to(desktop) {
                    display: none;
                }
            }
        }

        .print {
            float: right;
            padding: 4px 15px;
            display: none;

            .print-text {
                display: inline-block;
                height: 22px;
                vertical-align: middle;

                //padding-top: 4px;
                padding-left: 6px;
            }

            .print-icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-top: 3px;

                @include sprite($buyend-print-blue);
            }

            @include respond-to(desktop) {
                display: inline-block;
                cursor: pointer;
            }
        }

        //@include sprite($footer-fb);

        .buy-content {
            font-family: ProximaNova;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $color-9;
            display: none;
            word-break: break-word;

            @include respond-to(desktop) {
                display: inline-block !important;
            }

            .booking-disclaimer {
                font-weight: bold;
                padding-bottom: 15px;
            }
        }

        header {
            font-weight: 700;

            @include fonts-vw(ProximaNova, 18px, 20px);

            color: $color-9;
            margin-top: 20px;
            margin-bottom: 28px;
        }

        h1 {
            font-weight: 700;

            @include fonts-vw(ProximaNova, 20px, 32px);

            color: $black;
        }

        .subtitle {
            font-weight: 600;

            @include fonts-vw(ProximaNova, 14px, 16px);

            color: $color-10;
        }

        .purchase {
            color: #1466de;
            font-weight: 700;

            @include fonts-vw(ProximaNova, 18px, 25.89px);

            span {
                display: block;

                + span {
                    margin-top: 10px;
                }
            }
        }

        .buy-image {
            margin: 0 auto 30px auto;
            width: 70%;

            img {
                display: block;
                margin: auto;
                max-width: 100%;
                max-height: 300px;              
            }

            @include respond-to(desktop) {
                width: 100%;
                margin: 0;
                margin-bottom: 30px;
            }
        }

        ul.search {
            margin-bottom: 30px;
        }

        ul li.search {
            background: $bg-color-1;
            padding: 25px;
            position: relative;
            cursor:pointer;

            + li.search {
                margin-top: 30px;
            }

            .title {
                margin-bottom: 21px;
                color: $color-9;
                font-family: ProximaNova;
                font-weight: 600;
                font-size: 18px;
                a{
                    color:$color-9;
                }
            }

            .content {
                color: $color-9;
                font-family: ProximaNova;
                font-weight: 300;
                font-size: 16px;
                display: flex;
                justify-content: space-between;

                .date {
                    font-weight: 700;
                    white-space: nowrap;
                }
            }

            & > .remove {
                position: absolute;
                right: 8px;
                top: 10px;
                width: 26px;
                height: 26px;
                border: 2px solid $color-13;
                display: block;
                background: url("../img/icons/ex2.png") no-repeat;
                background-position: center center;
                cursor: pointer;
            }

            ul {
                @include clearfix;

                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;

                li {
                    padding: 7px 8px;
                    border: 2px solid $color-13;
                    margin: 5px 20px 5px 0;

                    span {
                        color: $color-13;
                        font-family: ProximaNova;
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 18px;
                        vertical-align: top;
                    }

                    .remove {
                        display: inline-block;
                        width: 19px;
                        height: 18px;
                        background: url("../img/icons/ex.png") no-repeat;
                        background-size: cover;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .browse-more {
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .input-search {
            width: 100%;

            input {
                cursor: pointer;
            }
        }

        .input-locale {
            width: 100%;
        }

        .input-submit {
            width: 100%;

            button {
                width: 50%;
                margin: 0 auto;
            }
        }

        @include respond-to(tablet) {
            form {
                flex-wrap: nowrap;
            }

            .input-search {
                width: 26%;
            }

            .input-locale {
                width: 48%;
            }

            .input-submit {
                width: 24%;

                button {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .buy-row-second #workout {
        padding: 0 6.5%;

        .browse-more {
            form {
                > div {
                    margin-top: 10px;
                }
            }
        }
    }
}

.buy-datepicker {
    padding: 1%;
    background-color: $white;
    border: 2px solid $color-13;

    table.ui-datepicker-calendar td.ui-datepicker-today a {
        background: $bg-color-1;
    }

    table.ui-datepicker-calendar a.ui-state-default.ui-state-active {
        background: $color-13;
    }
}

@media (max-width: 1025px) {
    #page-buy-end {
        .buy-row {
            .buy-column
            {
                &.barcode {
                    .mobile-note {
                        display: block;
                        padding-bottom: 15px;
                    }
                 }
                &.desktop-note {
                    display: none;
                }
            }
        }
    }  

}
