#page-businesspayment {
    @extend .all-business;

    .main-section .bottomnav {
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .payment-logos {

        img {
            max-width: initial;
        }
    }

    .main-section {
        .left-column {
            width: 100%;
            padding: 2vw 0;

            @include respond-to(tablet) {
                width: 48%;
                float: left;
            }

            .title {
                margin: 0;
            }

            .payment {
                margin-top: 0;
            }

            .points {
                ul li {
                    margin-top: 2vw;
                    padding-left: 40px;
                    position: relative;

                    &:before {
                        content: " ";
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        background: url(../img/icons/icon-check.png) no-repeat;
                        background-size: cover;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        #payment-form .right-column .promocode {
            .button, button {
                cursor: pointer;
                margin-right: 0;
                margin-left: 5px;
                padding: 12px 18px;
            }
        }

        .right-column {
            width: 100%;
            background: #e6e6e6;
            padding: 2vw;

            @include respond-to(tablet) {
                width: 38%;
                float: right;
            }
            // .summary .position > div {
            //     width:100%;
            // }
            .confirm {
                width: 100%;
            }

            .payment {
                margin-top: 0;
            }

            .terms {
                font-size: 12px;
            }

            .promocode {
                span {
                    margin-top: 7px;
                }

                .button, button {
                    line-height: 1.2;
                    margin-right: 0;
                    margin-left: 5px;
                    padding: 12px 18px;
                    cursor: pointer;
                }

                input[type="text"] {
                    padding: 12px
                }

                input {
                    margin-right: 0
                }
            }
        }

        .payment {
            margin-top: 3vw;
        }

        .subtitle.left {
            text-align: left;
        }

        .wrapper {
            max-width: 1025px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .inner-small-wrapper {
            // margin: 0 auto;
            max-width: 380px;

            @include clearfix;

            input {
                width: 100%;
            }
        }

        .title {
            text-align: center;
        }

        .title.left {
            text-align: left;
        }

        .goback {
            border: 2px solid $color-33;
            color: $color-33;
            float: left;
            background: $white;
            padding: 12px 27px;
            font-weight: 700;
            text-transform: uppercase;
            margin-right: 10px;

            @include fonts-vw(ProximaNova, 13.5px, 13.5px);
        }
    }

    .confirm {
        cursor: pointer;
        float: right;
        background: $color-33;
        color: $white;
        padding: 12px 0;
        line-height: 1.3;
        text-align: center;
        width: 27vw;
        max-width: 380px;
        border: none;
        font-weight: 700;
        text-transform: uppercase;

        @include fonts-vw(ProximaNova, 13.5px, 13.5px);
    }

    .description {
        p {
            margin-top: 28px;
        }
    }

    .payment {
        .payment-button {
            background: #1189d5;
            padding: 10px;
            border-color: #1189d5;
            box-shadow: none;
            border-radius: 10px;
            color: $white;
            margin-bottom: 20px;
        }

        input[type="text"] {
            padding: 32px 15px 18px 15px;
            background: $white;
            border-top: 1px solid #d6dbdf;
            border-bottom: none;
            border-left: none;
            border-right: none;

            @include fonts-vw(ProximaNova, 16px, 16px);
        }

        input[type="text"]:last-of-type {
            border-bottom: 1px solid #d6dbdf;
        }
    }

    .promocode {
        span {
            margin: 33px 0 16px 0;
            width: 100%;
            display: inline-block;

            @include clearfix;
        }

        .button {
            @extend .goback;
            cursor: pointer;
            @include fonts-vw(ProximaNova, 13px, 13px);
            width: 29%;
            margin: 0;
            padding: 10px 0;
        }

        input[type="text"] {
            border: 2px solid $color-33;
            background: $white;
            padding: 10.5px;
            width: 69%;
            float: left;
            margin-right: 2%;
        }
    }

    .summary {
        .position {
            color: #2f2f2f;
            font-weight: 700;
            margin-top: 13px;
            float: left;
            width: 100%;
            clear: both;

            @include fonts-vw(ProximaNova, 18px, 18px);

            &.tax {
                color: #b0b0b0;
            }

            &.total {
                color: #1243b2;
                // margin-top: 26px;
                .summary-price {
                    width: 35%;
                    margin-right: 5%;
                    border-top: 2px solid;
                }
                // padding-top:5px;
                margin-bottom: 10px;
                // @include fonts-vw(ProximaNova, 28px, 28px);
            }

            > div {
                width: 40%;
                float: left;
            }

            > div:last-of-type() {
                width: 60%;
                float: left;
            }
        }
    }

    .terms {
        margin-top: 0;
        margin-bottom: 25px;
        float: left;
        width: 100%;
        @include clearfix();

        input[type="checkbox"] {
            width: 0;
            height: 0;
            visibility: hidden;
            float: right;
        }

        input[type="checkbox"] + label {
            cursor: pointer;
        }

        input[type="checkbox"] + label:before {
            content: '';
            display: inline-block;
            position: relative;
            top: 5px;
            width: 24px;
            height: 20px;
            background: url(../img/icons/icon-unchecked-login.png) no-repeat;
        }

        input[type="checkbox"]:checked + label:before {
            background: url(../img/icons/icon-checked-login.png) no-repeat;
        }
        //Preloading:
        input[type="checkbox"] + label:after {
            background: url(../img/icons/icon-checked-login.png) no-repeat;
        }
    }

    #payment-form {
        position: relative;

        button {
            position: absolute;
            top: 0;
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .promocode .button {
            cursor: pointer;
            position: relative;
            margin: 0;
        }
    }


    .free-trial-promo {
        color: #1243b2;
        padding-top: 15px;
    }
}

.right {
    float: right;
    padding-top: 10px;
    font-weight: 700;
    padding-right: 10px;
}

.bottomnav {
    position: relative;

    .right {
        position: absolute;
        top: -60px;

        @include respond-to(desktop) {
            position: relative;
            top: 0;
        }
    }
}