#page-faq {
    //width: 1180px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px;

    @include clearfix;

    section:not(#search).noinfo {
        display: none;
    }

    section {
        &.expanded .wrapper > div {
            display: block;
        }

         &:nth-of-type(2n){
            clear:left;
        }

        &.expanded .title {
            padding-bottom: 30px;
            background: url("../img/v-shape4.png") no-repeat;
            background-position: 90% 5px;

            @include respond-to(tablet) {
                background: url("../img/shadows-stripes/dash-2.png") repeat-x;
                background-position: bottom;
            }
        }

        .wrapper > div {
            display: none;

            @include respond-to(tablet) {
                display: block;
            }
        }

        width: 90%;
        margin: 0 5%;

        &:not(#search) {
            margin: -2px 5% 0;
            width: 90%;
            float: left;

            &:nth-of-type(2n-1) {}

            @include respond-to(tablet) {
                margin-left: 2%;
                width: 44%;
                float: left;
                margin-right: 0;
                margin-top: 0;

                &:nth-of-type(2n) {
                    margin-left: 1%;
                    margin-right: 2%;
                    float: right;
                    clear: right;
                }
            }
        }

        &#search {
            padding: 36px 0 20px 0;
            text-align: center;

            @include respond-to(tablet) {
                padding: 56px 0 60px 0;
            }


            h1 {
                font-family: ProximaNova;
                font-weight: 700;
                font-size: 32px;
                color: $black;
                margin-bottom: 32px;
            }

            input {
                width: 100%;
                padding: 15px 33px;
                font-weight: 700;
                border-width: 2px 4px 2px 4px;
                border-style: solid;
                border-color: $color-12;
                background: url("../img/icons/icon-magnifying.png") no-repeat;
                background-position: 4px center;
                background-size: 20px 20px;

                @include fonts-vw(ProximaNova, 13px, 13px);

                @include respond-to(tablet) {
                    width: 403px;
                    padding: 13px 33px;
                }
            }

            ::-webkit-input-placeholder {
                @include fonts-vw(ProximaNova, 13px, 13px);

                font-weight: 700;
                color: $color-11;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                @include fonts-vw(ProximaNova, 13px, 13px);

                font-weight: 700;
                color: $color-11;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                @include fonts-vw(ProximaNova, 13px, 13px);

                font-weight: 700;
                color: $color-11;
            }

            :-ms-input-placeholder {
                font-size: 13px;
                font-family: ProximaNova;
                font-weight: 700;
                color: $color-11;
            }
        }

        &:not(#search) .wrapper {
            padding: 20px;
            border: 2px solid $color-13;
            // border-bottom: none;

            @include respond-to(tablet) {
                border: none;
                padding: 0;
            }
        }

        &:not(#search):not(.noinfo):last-of-type .wrapper {
            // border-bottom: 2px solid $color-13;

            @include respond-to(tablet) {
                border: none !important;
            }
        }

        &.found:last-child .wrapper {
            // border-bottom: 2px solid $color-13 !important;

            @include respond-to(tablet) {
                border: none;
            }
        }

        .title {
            font-weight: 700;
            cursor: pointer;

            @include fonts-vw(ProximaNova, 13.5px, 20px);

            color: $color-13;
            background: url("../img/v-shape3.png") no-repeat;
            background-position: 90% 5px;

            @include respond-to(tablet) {
                cursor: default;
                color: $color-9;
                padding-bottom: 30px;
                margin-bottom: 35px;

                // border-bottom: 2px solid $black;
                background: url("../img/shadows-stripes/dash-2.png") repeat-x;
                background-position: bottom;
                background-size: auto 5px;
            }
        }

        ul {
            li {
                margin-bottom: unit-vw(40px, mobile);
                padding-right: 13%;
                position: relative;
                cursor: pointer;

                @include respond-to(mobile-lg) {
                    margin-bottom: unit-vw(54px, tablet);
                }


                @include respond-to(tablet) {
                    margin-bottom: unit-vw(54px, tablet);
                }


                @include respond-to(desktop) {
                    padding-right: 88px;
                    margin-bottom: 54px;
                }


                &:after {
                    content: "";
                    background: url("../img/v-shape.png") no-repeat;
                    background-position: center center;
                    display: block;
                    position: absolute;
                    border: 1px solid $color-10;
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    text-align: center;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                }

                &.empty {
                    cursor: default;
                    &:after {
                        display: none;
                    }
                }

                .question {
                    font-family: ProximaNova;
                    font-weight: 600;
                    font-size: 16px;
                    color: $color-10;
                }

                .answer {
                    display: none;
                    margin-top: 29px;
                    color: $color-12;
                    font-family: ProximaNova;
                    font-weight: 400;
                    font-size: 14px;
                }

                &.expanded {
                    .answer {
                        display: block;
                    }

                    &:after {
                        border: 1px solid $color-12;
                        background: $color-12 url("../img/v-shape2.png") no-repeat;
                        background-position: center center;
                    }
                }
            }
        }
    }
    .clearer {
    width: 100%;
    clear: both;
    display: block;
    &:last-of-type(){
        display:none;
    }
}
section:not(#search).member-logo{
    width:100%;
    margin:3% auto 0 auto;
    float:right;
    text-align:center;
    img{
        width:32%;
        max-width:130px;
        margin:0 auto;
    }
    @include respond-to(tablet){
        width:44%;
    margin-top:2%;
    margin-left: 1%;
    margin-right: 2%;

    }
}
    .noresult {
        display: none;
        text-align: center;
    }
}