#multiselects-directive {
    #page-businesswelcome &, #page-businessregistration & {
        .popup {
            position: absolute;
            background: #fff;
            padding: 15px;
            border: 2px solid $black;
            top: 0;
            z-index: 10000;

            > .ng-scope {
                width: 100%;
                clear: both;
                margin-bottom: 5px;
            }
            //      input {
            //         width: 10%;
            //         float: left;
            //         padding: 0;
            // margin: 0;
            //         &[type=text]{
            //             float:right;
            //             width:55%;
            //             padding:10px;
            //         }
            //     }
            input[type='checkbox'], input[type='radio'] {
                display: none;
            }

            label {
                display: inline-block;
                max-width: 260px;
                position: relative;
                padding-left: 15%;
                margin: 5px 0;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                @include respond-to(desktop) {
                    padding-left: 10%;

                    &:before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            input:checked + label {
                &:before {
                    background-image: url('../img/icons/icon-checked-business.png');
                }
            }

            .close {
                padding: 10px;
                margin-top: 15px;
                background: #d0d0d0;
                border-radius: 10px;
                display: inline-block;
                cursor: pointer;
            }

            .others-validation-message {
                color: #fff;
                background-color: #d04b4b;
                padding: 9px 10px;
                position: relative;

                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #d04b4b;
                    position: absolute;
                    top: -7px;
                    left: 20px;
                }
            }
        }

        .openpopup {
            display: inline-block;
            width: 100%;
            background: $color-30;
            border: 2px solid $color-29;
            color: $color-32;
        }
    }
}
