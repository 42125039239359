html {
	font-family: $font-proxima;
	font-size: 16px;
	color: #000;
	overflow-x: hidden;
	position: relative;
}

body {
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;

	// UNCOMMENT IF USING SASS-CORE FRAMEWORK AND PX FONT UNITS
	//@include font-px(md);

	// &[data-overlayactive="true"] {
	// 	height: 100vh;
	// }
}

*, *:before, *:after {
	box-sizing: border-box;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

a {
	text-decoration: none;
}

input, textarea {
	&:focus, &:active {
		border: none;
		outline: none;
	}
}

button {

	&:focus {
		outline: none;
	}

	&::-moz-focus-inner {
	    padding: 0;
	    border: 0
	}
}


.clear {
	*zoom: 1;
	&:before, &:after {
	    content: " ";
	    display: table;
	}

	&:after {
	    clear: both;
	}

}

.clearfix{
    height: 0;
    clear: both;
}
.hide{
    display: none !important;
}
//layout

#layout-main-wrap {
	width: 1200px;
	max-width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

#body-overlay{
	top:0;
	left:0;
	position: fixed;
	height: 100vh;
	width: 100%;
	background-color: $black;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all 0.4s ease-in-out;

	body[data-overlayactive="true"] & {
		// z-index:1;
		visibility: visible;
		opacity: 0.75;
		pointer-events: auto;
	}
}

.body-content {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 72px);

	@include respond-to(tablet) {
		min-height: calc(100vh - 70px);
	}

	> div[id], main[id] {
		flex-grow: 1;
	}
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}