#page-businessgyms-traffic {
    padding-bottom: 3vw;

    &.in-progress {
        @extend .in-progress-profile-component;
        z-index: 1;
    }

    .common-section-data {
        padding: 5px;
        background-color: #ecf0f1;

        @include respond-to(tablet) {
            max-width: 500px;
            margin: 0 auto;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-bottom: 15px;
        }
    }

    .nodata {
        font-weight: 700;
        text-align: center;
        padding: 10px 0;
        font-size: 13px;
        color: $color-42;
    }

    .data-info-copy {
        text-align: center;
        padding-bottom: 15px;
    }

    .wrapper {
        max-width: 940px;

        @include respond-to(tablet) {
        }
    }
    /*#region choose clubs*/
    .club-select-wrapper {
        width: 100%;
        max-width: 900px;
        padding: 10px 5px;
        text-align: right;
        color: #000;
        position: relative;
        background-color: #e6e6e6;
        border: 1px solid $color-18;
        margin-top: 15px;

        @include respond-to(tablet) {
            margin-top: 0;
        }

        span.text {
            display: inline-block;

            @include fonts-vw(ProximaNova, 12px, 18px);
            font-weight: $font-w-bold;
            text-transform: uppercase;

            @include respond-to(tablet) {
                // font-size: 16px;
            }
        }

        #clubsFilter {
            display: inline-block;
            padding-left: 2%;
            background: none;
            border: none;
            outline: none;
            float: right;

            @include respond-to(tablet) {
                float: none;
            }


            button {
                position: relative;
                background: none;
                border: none;
                outline: none;
                font-weight: $font-w-semibold;
                color: $color-18;
                cursor: pointer;
            }

            .submit {
                padding: 7px 14px;
                background-color: $color-39;
            }

            .btn {
                padding-right: 15px;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 15px;
                    height: 15px;
                    border: 1px solid $color-18;
                    background-image: url("../img/icons/ico-arrow-down.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 80%;
                }
            }

            ul {
                overflow: auto !important;
                position: absolute;
                display: block;
                bottom: 0px;
                right: 0px;
                z-index: 1;
                padding: 9px 14px 9px 20px;
                transform: translateY(100%);
                width: auto;
                max-width: none;
                min-width: 200px;
                height: auto;
                max-height: 30vh;
                background-color: $white;
                border: 1px solid $color-39;
            }

            li {
                padding: 5px 0;
                cursor: pointer;
                width: 100%;
                clear: both;

                a {
                    padding-right: 10px;

                    &:active, &:hover, &:focus {
                        outline: none;
                        border: none;
                    }
                }

                span {
                    display: inline-block;
                    position: relative;
                    width: 20px;
                    height: auto;
                    min-height: 16px;
                    //float: right;
                    position: absolute;
                    right: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        right: 0;
                        top: 50%;
                        height: 15px;
                        width: 15px;
                        transform: translateY(-50%);
                        background-image: url("../img/icons/icon-unchecked-business.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    &.glyphicon-ok {
                        &:before {
                            background-image: url("../img/icons/icon-checked-business.png");
                        }
                    }
                }
            }
        }
    }
    /*#endregion*/
    /*#region mobile buttons for practice user sections*/
    #data-select {
        &.viewaanalytics {
            display: none;
        }

        width: 100%;
        float: left;
        border: 1px solid $color-18;
        border-left: none;
        border-right: none;
        margin-top: 10px;

        .wrapper > div {
            width: 25%;
            float: left;
            padding: 0 3px;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
            font-weight: 600;
            border-right: 1px solid $color-18;

            @include fonts-vw(ProximaNova, 11px, 18px);
        }

        @include respond-to(tablet) {
            display: none !important;
        }

        .page-users {
            border: none;
        }

        &.viewpageoverview .page-overview, &.viewshares .page-shares, &.viewlocation .page-location, &.viewusers .page-users {
            background: $color-41;
            color: #fff;
        }
    }
    /*#endregion*/
    /*#region date buttons*/
    .date-buttons-wrapper {
        margin-top: 10px;
        padding-bottom: 15px;

        @include respond-to(tablet) {
        }

        .select {
            @include clearfix;

            .select-buttons {

                .button {
                    @extend .component-button-grey;
                    border: 1px solid $color-18;
                    cursor: pointer;
                    margin-left: 0.5%;
                    @include fonts-vw(ProximaNova, 10px, 12px);
                    text-align: center;
                    width: 33.333%;
                    margin: 0;
                    margin-left: 0;
                    float: left;
                    border-right: none;

                    &.active {
                        background: $color-41;
                    }

                    @include respond-to(tablet) {
                        width: 150px;
                    }
                }

                .button:last-child {
                    border-right: 1px solid #737373;
                }
            }
        }


        #calendar-inputs-wrapper {
            margin-top: 5px;

            @include respond-to(desktop) {
            }

            .input-search {
                width: 50%;
                float: left;


                @include respond-to(tablet) {
                    width: 225px;
                }

                &:before {
                    left: 5px;
                    background-image: url("../img/icons/icon-calendar-grey.png");
                    top: 3px;
                }

                input {
                    padding: 0 10px 0 35px;
                    width: 100%;
                    text-align: right;
                    border: 1px solid $color-18;
                    color: $color-18;
                    cursor: pointer;
                    height: 35px;
                    line-height: 30px;
                    font-size: 12px;
                    font-weight: 700;
                }

                &:first-of-type {
                    input {
                        border-right: none;
                    }
                }
            }

            .input-search:last-child {

                input:last-child {
                    border-left: none;
                }
            }
        }
    }
    /*#endregion*/
    /*#region google anayltics section*/
    .main-analytics {
        display: none;

        &.viewaanalytics {
            display: block;
        }

        .left-column {
            @include clearfix();
        }

        .analytics-charts {
            width: 100%;
            clear: both;
            padding-top: 3vw;

            @include clearfix;

            .chart {
                width: 200px;
                float: left;
                display: inline-block;
                position: relative;

                .legend {
                    position: absolute;
                    left: 0;
                    top: -3vw;
                    width: 90vw;

                    @include fonts-vw(ProximaNova, 12px, 18px);

                    .title {
                        padding-right: 10px;
                        font-weight: 700;
                    }

                    label:nth-of-type(1):before {
                        content: ' ';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: #058DC7;
                    }

                    label:nth-of-type(2):before {
                        content: ' ';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: #50B432;
                    }
                }
            }

            .sumup {
                width: 30%;
                float: left;
                display: inline-block;
                margin-left: 2vw;
                @include fonts-vw(ProximaNova, 16px, 34px);
                font-weight: 700;
            }
        }
    }
    /*#endregion*/
    /*#region practice user section*/
    .main-practice {
        display: none;

        &.viewmainpageoverview {
            display: block;
        }

        section {
            .header {
                margin: 0;
                padding-bottom: 15px;

                .text {
                    color: #000;
                    font-weight: 700;
                    @include fonts-vw(ProximaNova, 20px, 20px);
                    text-align: center;
                    position: relative;
                    padding-right: 15px;
                    padding-bottom: 8px;
                }

                .info-popup {
                    top: 0;
                }
            }
        }

        .left-column {
            width: 100%;
            float: left;

            @include respond-to(desktop) {
                width: 49%;
            }

            .page-overview {
                display: none;

                @include respond-to(tablet) {
                    display: block;
                }

                .pageviews-details {
                    @include clearfix;
                }
            }

            &.viewpageoverview {
                .page-overview {
                    display: block;
                }
            }

            .shares {
                display: none;
                @include clearfix;

                @include respond-to(tablet) {
                    display: block;
                    margin-top: 15px;
                }
            }

            &.viewshares {
                .shares {
                    display: block;
                }
            }
            
            .location {
                @include clearfix;
                display: none;
                position: relative;

                @include respond-to(tablet) {
                    display: block;
                    margin-top: 15px;
                }

                .ng-scope {
                    @include clearfix;
                }

                .even {
                    background: #f8f7f7;
                    padding: 14px 18px 14px 7px;
                    line-height: 0;
                }

                .odd {
                    padding: 14px 18px 14px 7px;
                    background: #e6e6e6;
                    line-height: 0;
                }

                .location-name {
                    float: left;

                    @include fonts-vw(ProximaNova, 13px, 13px);
                    color: #737373;
                    font-weight: 600;
                }

                .location-percent {
                    float: right;

                    @include fonts-vw(ProximaNova, 16px, 16px);
                    font-weight: 700;
                }
            }

            &.viewlocation {
                .location {
                    display: block;
                    margin-bottom: 15px;
                }
                .tableofinfo{
                    display: block;
                    margin-bottom: 15px;
                }
            }

            .tableofinfo {
                display: none;
                @include clearfix;

                @include respond-to(tablet) {
                    display: block;
                }

                &.viewLocation {
                    display: block;
                }

                .content {
                    width: 100%;
                }

                .content-row {
                    width: 100%;
                    @include fonts-vw(ProximaNova, 11px, 11px);
                    @include clearfix;
                    border-left: solid 1px #ccc;
                    border-right: solid 1px #ccc;
                }

                .place {
                    width: 40%;
                    color: #005c9c;
                    float: left;
                    padding-left: 20px;
                    padding: 10px 10px;
                }

                .field {
                    width: 15%;
                    padding: 10px 10px;
                    float: left;
                    border-left: solid 1px #ccc;
                    text-align: right;
                }

                h2 {
                    @include fonts-vw(ProximaNova, 16px, 18px);
                    font-weight: 700;
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .table-header {
                    @include fonts-vw(ProximaNova, 11px, 11px);
                    padding: 0px 2px;
                    border: solid 1px #ccc;
                    background: #e9e9e9;
                    font-weight: 700;
                    margin-top: 0;
                    margin-bottom: 0;
                    @include clearfix;

                    .place {
                        color: #000;
                    }

                    .field {
                        text-align: center;
                    }
                }

                .total {
                    font-weight: 700;

                    @include fonts-vw(ProximaNova, 11px, 11px);
                    border-bottom: solid 1px #ccc;

                    @include clearfix;
                    border-left: solid 1px #ccc;
                    border-right: solid 1px #ccc;
                }
            }


            .userchart {
                position: relative;
            }

            .howmanytimes .description {
                min-height: 60px;

                @include respond-to(tablet) {
                    min-height: 59px;
                }
            }
        }

        .right-column {
            display: none;
            float: right;

            &.viewusers {
                display: block;
            }

            width: 100%;

            @include respond-to(desktop) {
                display: block;
                width: 49%;
            }

            @include respond-to(tablet) {
                display: block;
            }


            .stats-part {
                padding: 14px 0;
                border-bottom: 1px solid #fff;
                @include clearfix;

                .sub-title {
                    font-weight: bold;
                    padding-bottom: 15px;
                }


                .graph-container {
                    position: relative;
                    width: 100%;

                    @include clearfix;

                    .graph-item {
                        position: relative;
                        float: left;
                        padding: 0 2.5%;

                        .result {
                            position: relative;
                            width: 100%;
                            text-align: center;
                            margin-bottom: 20px;
                        }

                        .label {
                            margin-top: 19px;
                            text-align: center;
                        }

                        .num {
                            -webkit-transition: all 1s ease-in-out;
                            -moz-transition: all 1s ease-in-out;
                            -o-transition: all 1s ease-in-out;
                            transition: all 1s ease-in-out;
                            width: 100%;
                            position: relative;
                            background: #dadada;

                            .datafilled {
                                -webkit-transition: all 1s ease-in-out;
                                -moz-transition: all 1s ease-in-out;
                                -o-transition: all 1s ease-in-out;
                                transition: all 1s ease-in-out;
                                content: '';
                                width: 100%;
                                display: block;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                    }

                    &.weight {

                        .num {
                            height: 200px;
                        }

                        .label {
                            @include fonts-vw(ProximaNova, 11px, 11px);
                            font-weight: 600;
                        }

                        .result {
                            @include fonts-vw(ProximaNova, 11px, 11px);
                            font-weight: 600;
                        }

                        .datafilled {
                            background: rgba(255, 175, 75, 1);
                            background: -moz-linear-gradient(top, rgba(255, 175, 75, 1) 0%, rgba(253, 128, 8, 1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 175, 75, 1)), color-stop(100%, rgba(253, 128, 8, 1)));
                            background: -webkit-linear-gradient(top, rgba(255, 175, 75, 1) 0%, rgba(253, 128, 8, 1) 100%);
                            background: -o-linear-gradient(top, rgba(255, 175, 75, 1) 0%, rgba(253, 128, 8, 1) 100%);
                            background: -ms-linear-gradient(top, rgba(255, 175, 75, 1) 0%, rgba(253, 128, 8, 1) 100%);
                            background: linear-gradient(to bottom, rgba(255, 175, 75, 1) 0%, rgba(253, 128, 8, 1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffaf4b', endColorstr='#fd8008', GradientType=0 );
                        }
                    }

                    &.ages {

                        .num {
                            height: 100px;
                        }

                        .label {
                            @include fonts-vw(ProximaNova, 11px, 11px);
                            font-weight: 600;
                        }

                        .result {
                            @include fonts-vw(ProximaNova, 11px, 11px);
                            font-weight: 600;
                        }

                        .datafilled {
                            background: rgba(73, 155, 234, 1);
                            background: -moz-linear-gradient(top, rgba(73, 155, 234, 1) 0%, rgba(9, 80, 166, 1) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(73, 155, 234, 1)), color-stop(100%, rgba(9, 80, 166, 1)));
                            background: -webkit-linear-gradient(top, rgba(73, 155, 234, 1) 0%, rgba(9, 80, 166, 1) 100%);
                            background: -o-linear-gradient(top, rgba(73, 155, 234, 1) 0%, rgba(9, 80, 166, 1) 100%);
                            background: -ms-linear-gradient(top, rgba(73, 155, 234, 1) 0%, rgba(9, 80, 166, 1) 100%);
                            background: linear-gradient(to bottom, rgba(73, 155, 234, 1) 0%, rgba(9, 80, 166, 1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#0950a6', GradientType=0 );
                        }
                    }

                    .gender {
                        .label {
                            font-size: 11px;
                        }
                    }
                }
            }

            .age {
                width: 100%;

                @include respond-to(tablet) {
                }
            }

            .gender {
                width: 100%;
                float: left;
                position: relative;

                .legend {
                    @include fonts-vw(ProximaNova, 16px, 16px);
                    font-weight: 700;

                    .item-wrapper {
                        margin-bottom: 20px;
                        margin-top: 20px;
                        width: 32%;
                        text-align: center;
                        float: left;

                        .color-icon {
                            text-align: center;
                            height: 20px;
                            width: 20px;
                            margin: 0 auto;

                            &.male {
                                background-color: rgba(54, 162, 235, 0.2)
                            }

                            &.female {
                                background-color: rgba(255, 99, 132, 0.2)
                            }

                            &.other {
                                background-color: rgba(255, 206, 86, 0.2)
                            }
                        }

                        .label {
                            font-size: 11px;
                            font-weight: normal;
                        }
                    }
                }
            }

            .goals {
                .chart {
                }

                .legend {
                    padding-top: 15px;

                    .item-wrapper {
                        height: 75px;
                        width: 50%;
                        float: left;
                        overflow: hidden;
                        padding: 8px 0;
                        text-align: center;
                        font-weight: 600;
                        font-size: 13px;

                        .color-icon {
                            height: 20px;
                            width: 20px;
                            margin: 0 auto;

                            &.icon1 {
                                background-color: rgba(255, 0, 0, 0.2);
                            }

                            &.icon2 {
                                background-color: rgba(0, 255, 128, 0.2);
                            }

                            &.icon3 {
                                background-color: rgba(255, 153, 51, 0.2);
                            }

                            &.icon4 {
                                background-color: rgba(255, 255, 0, 0.2);
                            }

                            &.icon5 {
                                background-color: rgba(51, 153, 255, 0.2);
                            }

                            &.icon6 {
                                background-color: rgba(255, 51, 255, 0.2);
                            }
                        }

                        .goalssummary {
                            @include fonts-vw(ProximaNova, 13px, 13px);
                            font-weight: 600;
                        }
                    }
                }
            }
            //End of right column
            .howmanytimes .description {
                min-height: 70px;

                @include respond-to(tablet) {
                    min-height: 59px;
                }
            }
        }
    }
    /*#endregion*/
    /*#region Percent/Numbers radio buttons*/
    .radios {
        clear: both;
        text-align: center;

        @include fonts-vw(ProximaNova, 14px, 14px);

        @include respond-to(tablet) {
        }

        input {
            visibility: hidden;
            width: 0;
        }

        input + label {
            position: relative;
            padding-left: 40px;
            display: inline-block;

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 10px;
                top: 2px;
                width: 14px;
                height: 14px;
                border-radius: 40px;
                border: 2px solid #dadada;
            }
        }

        input:checked + label:before {
            background: #1243b2;
        }
    }
    /*#endregion*/
    /*#region google analytics table*/
    .tableofinfo {
        display: none;
        @include clearfix;

        @include respond-to(tablet) {
            display: block;
        }

        &.viewLocation {
            display: block;
        }

        .content {
            width: 100%;
        }

        .content-row {
            width: 100%;
            @include fonts-vw(ProximaNova, 11px, 11px);
            @include clearfix;
            border-left: solid 1px #ccc;
            border-right: solid 1px #ccc;
        }

        .place {
            width: 40%;
            color: #005c9c;
            float: left;
            padding-left: 20px;
            padding: 10px 10px;
        }

        .field {
            width: 15%;
            padding: 10px 10px;
            float: left;
            border-left: solid 1px #ccc;
            text-align: right;
        }

        h2 {
            @include fonts-vw(ProximaNova, 16px, 18px);
            font-weight: 700;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .table-header {
            @include fonts-vw(ProximaNova, 11px, 11px);
            padding: 0px 2px;
            border: solid 1px #ccc;
            background: #e9e9e9;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
            @include clearfix;

            .place {
                color: #000;
            }

            .field {
                text-align: center;
            }
        }

        .total {
            font-weight: 700;

            @include fonts-vw(ProximaNova, 11px, 11px);
            border-bottom: solid 1px #ccc;

            @include clearfix;
            border-left: solid 1px #ccc;
            border-right: solid 1px #ccc;
        }
    }
    /*#endregion*/
    .pagination {
        padding-left: 20px;
        display: block;
        width: 100%;
        clear: both;

        > div {
            display: inline-block;
            width: auto;
            float: right;
            margin-top: 20px;
            margin-right: 10px;
            cursor: pointer;
            padding: 1px 0;
        }

        input {
            width: 20px;
            border: 1px solid #000;
            text-align: center;
            margin-right: 5px;
            border-radius: 0;
        }

        .arrow {
            border: 1px solid #000;
            padding: 1px 4px;
        }
    }

    .onecolumn {
        .info-icon {
            float: right;
        }

        position: relative;
        margin-top: 15px;
        width: 100%;
        float: left;

        @include clearfix;

        @include fonts-vw(ProximaNova, 13px, 13px);
        font-weight: 700;
        background: #e6e6e6;
        color: #464646;
        padding: 14px 13px 14px 7px;

        span:not(.info-icon) {
            font-weight: 600;
            margin-right: 20px;
            color: #737373;
        }
    }

    .threecolumns {
        width: 30%;
        float: left;
        background: #e6e6e6;

        &:nth-of-type(1) {
            margin-right: 5%;
        }

        &:nth-of-type(2) {
            margin-right: 5%;
        }

        .info-icon {
            float: right;
        }

        .single-icon {
            width: 100%;
            display: block;
            text-align: center;
            height: 20px;

            img {
                max-width: 25px;
            }
        }

        .description {
            position: relative;

            @include fonts-vw(ProximaNova, 11px, 13px);
            font-weight: 600;
            color: #737373;
            padding: 14px 30px 18px 14px;

            @include respond-to(tablet) {
                padding: 14px 13px 18px 14px;
            }
        }

        .amount {
            color: #464646;

            @include fonts-vw(ProximaNova, 25px, 42px);
            padding-bottom: 12px;
            font-weight: 700;
            text-align: center;
        }

        &.instagram {
            .amount {
                color: #f19900;
            }
        }

        &.facebook {
            .amount {
                color: #0d3fb6;
            }
        }
    }
}
