#order-history {
    position: relative;
    width: 100%;
    display: inline-block;
    transition: margin-left 0.5s ease-in-out;

    @include respond-to(tablet) {
        width: 50%;
        margin-left: 0 !important;

        &:before {
            content: "";
            background: $white;
            width: unit-vw(10px, tablet);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0px;
            z-index: 1;
        }
    }

    @include respond-to(desktop) {
        &:before {
            width: 10px;
        }
    }

    .title {
        position: relative;
        display: inline-block;
        padding: 12px 0 40px 29px;
        width: 100%;
        font-size: 24px;
        font-weight: $font-w-bold;

        &:before {
            content: "";
            left: 0;
            top: 32%;
            transform: translateY(-50%);
            position: absolute;
            height: 18px;
            width: 20px;
            background-image: url('../img/icons/ico_order-history.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &:after {
            content: "";
            left: 0;
            bottom: 0;
            position: absolute;
            height: 5px;
            width: 100%;
            background-image: url('../img/shadows-stripes/mobile-section-stripes-gray.png');
            background-repeat: repeat-x;
        }

        @include respond-to(tablet) {
            min-height: 75px;
            padding: 12px 0 unit-vw(40px, tablet) unit-vw(29px,tablet);
            font-size: unit-vw(24px, tablet);

            &:before {
                height: unit-vw(18px, tablet);
                width: unit-vw(20px, tablet);
            }
        }

        @include respond-to(desktop) {
            padding: 12px 0 40px 29px;
            font-size: 24px;

            &:before {
                height: 18px;
                width: 20px;
            }
        }
    }

    #history-wrapper {
        width: 200%;
        overflow: hidden;
        min-height: 334px;

        &.no-pagination {
            min-height: 389px;

            @media (max-width: 768px) {
                min-height: unset;
            }
        }

        @media (max-width: 768px) {
            min-height: unset;
        }
    }

    .order-history-previous, .order-history-upcomming, .order-history-cart {
        width: 50%;
        display: inline-block;
        padding: 0 2%;
        // border: 2px solid $bg-color-1;
        border-top: none;

        @include respond-to(desktop) {
            padding: 0 2% 0 7%;
        }

        .refunded {
            color: red;
        }

        > li {
            position: relative;
            padding-top: 39px;
            min-height: 116px;
            cursor: pointer;
            transition: all 0.20s ease-in-out;

            @include respond-to(tablet) {
                padding-top: unit-vw(45px, tablet);
                min-height: unit-vw(125px, tablet);
            }

            @include respond-to(desktop) {
                padding-top: 38px;
                min-height: 106px;
            }

            &:before {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                background-image: url('../img/shadows-stripes/mobile-section-stripes-gray.png');
                background-repeat: repeat-x;
            }

            &.expanded {
                padding-top: 10px;
                cursor: default;
            }

            > a {
                position: relative;
                display: block;
                width: 100%;
                color: $font-color-15;
                /*cursor: pointer;*/
                padding: 5px 0;
                /*&:before {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 5px;
                    width: 8px;
                    background-image: url('../img/icons/ico-arrow-down.png');
                    background-repeat: no-repeat;
                    background-size:contain;
                    transition: all 0.25s ease-in-out;
                    transform-origin: top;
                }*/
            }

            &.expanded {
                > a {
                    &:before {
                        transform: rotate(180deg) translateY(-50%);
                    }
                }
            }

            .details-wrapper {
                display: inline-block;
            }

            .date, .gym-name {
                display: inline-block;
                font-size: unit-vw(19px,mobile);

                @include respond-to(mobile-lg) {
                    font-size: unit-vw(16px, mobile);
                }

                @include respond-to(tablet) {
                    font-size: unit-vw(16px, tablet);
                }

                @include respond-to(desktop) {
                    font-size: 16px;
                }
            }

            .date {
                width: 40%;
            }

            .gym-name {
                width: 58%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                padding-left: 10px;
                -moz-binding: url('assets/xml/ellipsis.xml#ellipsis');
            }

            .item-details {
                display: block;
                position: relative;
                font-size: 13px;
                height: 68px;
                overflow: hidden;
                max-height: 0;
                padding: 0;
                transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out;
                font-size: unit-vw(19px, mobile);

                @include respond-to(mobile-lg) {
                    font-size: unit-vw(16px, mobile);
                }

                @include respond-to(tablet) {
                    font-size: unit-vw(16px, tablet);
                }

                @include respond-to(desktop) {
                    font-size: 16px;
                }
            }

            &.expanded {
                .item-details {
                    padding-top: 13px;
                    max-height: 58px;
                }
            }

            .item-details {

                .item-link {
                    //inline-block when payments and order sites will be present
                    margin-left: 25px;
                    width: 25%;
                    height: 40px;
                    background-image: url('../img/icons/ico-link-grey.png');
                    background-repeat: no-repeat;
                    background-size: 20%;
                    background-position: 50% 0;
                }

                .rating {
                    display: inline-block;
                    float: right;
                    top: -6px;
                    width: 83px;
                    height: 24px;
                    position: relative;

                    @include respond-to (mobile-lg) {
                        width: 106px;
                    }

                    @include respond-to (tablet) {
                        width: 106px;
                    }

                    .empty-stars, .filled-stars {
                        display: block;

                        li {
                            display: inline-block;
                            height: 24px;
                            width: 15px;
                            margin-left: 1px;
                            background-size: contain;
                            background-repeat: no-repeat;

                            @include respond-to(mobile-lg) {
                                width: 20px;
                            }

                            @include respond-to(tablet) {
                                width: 20px;
                            }
                        }
                    }

                    .empty-stars {
                        > ul li {
                            background-image: url('../img/icons/ico-star-empty.png');
                        }
                    }

                    .filled-stars {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        li {
                            background-image: url('../img/icons/ico-star-filled.png');
                        }
                    }
                }
            }
        }
    }

    .order-history-previous {
        transition: margin-left 0.5s ease-in-out;
    }

    .order-history-cart {
        width: 100%;

        > li {
            min-height: 33px;

            &.expanded {
                padding-bottom: 10px;
            }

            .date {
                width: 29%;
            }

            .gym-name {
                width: 29%;
                text-align: center;
            }

            .class-name {
                width: 29%;
                display: inline-block;
                text-align: center;
            }

            .more-details {
                float: right;
            }
        }
    }
}
