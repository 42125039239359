﻿.instruction-popup {
    &.small-popup {
        position: absolute;
        top: 30%;
        max-width: 700px;
        width: 95% !important;
        text-align: left;
        /*top: 50%;*/
        left: 50%;
        /*margin-top: -400px;*/
    }

    .instruction-header {
        font-size: 20px;
        text-align: center;
        padding-top: 3px;

        @media (max-width: 767px) {
            padding-top: 10px;
        }
    }

    .step {
        padding: 30px 0;
        margin-left: 30px;
        margin-right: 30px;

        .step-header {
            font-weight: bold;

            .header-number {
            }

            font-size: 18px;
            padding-bottom: 10px;
        }

        .step-image {
            max-width: 700px;

            img {
                width: 100%;
            }
        }

        .step-content {
            padding-top: 10px;
        }
    }
}
