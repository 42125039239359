﻿#Club-Information {
    label {
        font-weight: bold;
    }

    .photos {
        clear: both;
        @include clearfix;

        .duplicate-images-label {
            font-size: 17px;
        }

        .row1, .row2 {
            padding-bottom: 45px;
            border-bottom: 1px solid $color-39;
        }

        .previews {
            width: 100%;

            @include clearfix;
        }

        .upload {
            @extend .toextendwithpucture;

            .continue {
                margin-top: 15px;
            }

            .comment {
                width: 100%;
                float: none;
                margin: 0;
                padding: 0;
                padding-bottom: 5px;
                text-align: center;
            }

            @include respond-to(tablet) {
            }
        }

        .explanation {
            text-align: center;

            @include respond-to(tablet) {
            }


            span.error {
                color: red;
            }
        }

        .preview {
            width: 50%;
            float: left;
            padding: 2%;

            &:nth-child(2n-1) {
                padding-left: 0;
            }

            &:nth-child(2n) {
                padding-right: 0;
            }

            @include respond-to(tablet) {
                width: 25%;
                padding: 1.5%;

                &:nth-child(2n) {
                    padding-left: 0.5%;
                    padding-right: 1%;
                }

                &:nth-child(2n-1) {
                    padding-left: 1%;
                    padding-right: 0.5%;
                }

                &:nth-child(4n-3) {
                    padding-left: 0;
                    padding-right: 1.5%;
                }

                &:nth-child(4n) {
                    padding-left: 1.5%;
                    padding-right: 0;
                }
            }


            .image {
                display: block;
                width: 100%;
                padding-bottom: 57%;
                background: #dadada;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
                border: 2px solid #afafaf;
            }

            .makeprimary, .delete {
                font-size: 13px;

                @include respond-to(tablet) {
                    font-size: 100%;
                }
            }

            .makeprimary {
                float: left;
                cursor: pointer;
            }

            .delete {
                float: right;
                cursor: pointer;
            }

            &:first-of-type {
                clear: left;
            }
        }
    }

    .additional-club-information {
        clear: both;

        textarea {
            height: 200px;
        }
    }

    .square-foot-label {
    }

    .input-field.half.sq-ft-input {
        width: 46%;
        margin-left: 0;
    }

    .fitnesstypes {
        @include clearfix;
        width: 100%;
        margin-left: 8px;
        margin-right: 10px;
        clear: both;

        @media (max-width: 768px) {
            margin-left: unset;
            margin-right: unset;
        }

        .subtitle {
            float: none;
            margin-bottom: 0 !important;
            text-align: left;
        }

        .fitness {
            width: 100%;

            @include clearfix;

            .checkbox {
                width: 26%;
                float: left;

                @media (max-width: 768px) {
                    width: 50%;
                    float: unset;
                    display: inline-block;
                }
            }

            .input-field {
                margin-top: 13px;
                margin-left: 0;
            }


            input:disabled, select:disabled {
                /*background-color: #717171 !important;*/
                opacity: 0.4;
                color: rgb(149, 149, 149) !important;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #000;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #000;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #000;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #000;
                }
            }
        }
    }
}

//Used for both logo and barcode
.single-image {
    .logo-label {
        clear: both;
        display: block;
        padding-bottom: 10px
    }

    .upload-button {
        float: left;
    }

    &.image {
        width: 100%;
        float: left;
        padding-bottom: 7px;

        .image-canvas-wrapper {
            width: 200px;
            float: left;
            clear: both;
        }

        .imagecanvas {
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
            padding-bottom: 51.5%;
            border: 2px solid $color-29;
            background: $color-30;
            position: relative;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-position: center center !important;

            .info {
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);
            }

            @include respond-to(desktop) {
                width: 100%;
            }
        }

        .inner {
            width: 100%;
            margin: 0 auto;
            clear: both;

            @include respond-to(desktop) {
                width: 100%;
            }
        }

        .explanation {
            cursor: pointer;
            float: unset;
            clear: both;
        }
    }
}

#picture, #picturebar {
    width: 0;
    height: 0;
    visibility: hidden;
}
