.birthdaydatepicker {
    width: 100%;
    position: relative;

    #calendar-modal {
        width: 100%;
        position: absolute;
        outline: 0;
        z-index: 10000;
    }


    .top-copy-wrapper {
        background-color: #1a4fbf;
        color: #fff;
        padding: 3px;
    }

    .inner-calendar {
        background: #fff;
        color: #000;
        border: 2px solid #1a4fbf;
    }

    .inner-calendar .years {
        display: inline-block;
        margin-right: 20px;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid #fff;
        margin: 0;
        padding: 0px 4px;
        cursor: pointer;
        line-height: 29px;
        float: left;
        width: 20%;
        text-align: center;
    }

    .inner-calendar .month {
        font-size: 20px;
        font-weight: 700;
        display: inline-block;
        cursor: pointer;
    }

    .inner-calendar .month span {
        min-width: 35px;
        text-align: center;
        display: inline-block;
    }

    .inner-calendar .month .arrow {
        cursor: pointer;
    }

    .inner-calendar .yearpopup {
        position: absolute;
        height: 250px;
        width: 100px;
        overflow-y: scroll;
        z-index: 10000;
        background: #fff;
        left: 40%;
        transform: translateX(-50%);
        /* top: 100%; */
        font-size: 16px;
        cursor: pointer;
        color: #1a4fbf;
        border: 1px solid #1a4fbf;


        li {
            padding: 3px 0;
            border-bottom: 1px solid #ccc;
            text-align: center;
        }
    }

    .inner-calendar .day {
        text-align: left;
        padding: 10px;
    }

    .inner-calendar .day .single-day {
        width: 14.2%;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        padding: 3px;
    }

    .single-day.ng-binding.ng-scope.real-day {
        background-color: #eee;
        margin: 0;
        border: 1px solid #ddd;
    }
}
