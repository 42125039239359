﻿#Club-Lookup {
    .google-error {
        padding-bottom: 10px;
        font-weight: bold;
        color: #de0000;
        font-size: 16px;
        padding-left: 10px;

        &.hours-label {
            padding-left: 0;
            padding-top: 10px;
        }
    }



    .hours-wrapper {
        clear: both;
    }

    .hours-wrapper {
        padding-top: 20px;

        .override.checkbox {
            label {
                font-weight: normal;
                padding-left: 15px;
            }
        }

        .hours-error {
            padding-top: 15px;
            font-size: 18px;
            color: #d04b4b;
        }
    }

    .hours-details {
        clear: both;
        padding-top: 15px;
        font-weight: bold;
    }

    .hours-subtitle {
        margin-bottom: 0;
    }

    .open24, .override {
        padding-bottom: 25px;
    }

    .timeoption {
        width: 100%;
        padding-bottom: 5px;
        max-width: 500px;
        clear: left;
        padding-left: 7px;
        border-top: 1px solid #dadada;
        margin-bottom: 30px;
        padding-top: 25px;

        .checkbox {
            input[type=checkbox] + label:not(.validationMessage) {
                padding-left: 36px;
            }
        }

        .day-option {
            width: 100%;
            clear: both;

            @include clearfix;

            .day {
                display: inline-block;
                width: 30%;
                float: left;

                input {
                    float: right;
                    height: 0;
                    width: 0;
                }

                select {
                    -moz-appearance: none;
                }

                label {
                    width: 99%;
                    float: left;
                    // line-height: 1.5;
                }
                //line-height: 43px;
            }



            .hours {
                display: inline-block;
                width: 70%;
                float: left;
                padding-top: 6px;
            }

            .input-field.half {
                @include fonts-vw(ProximaNova, 14px, 14px);
                width: 45%;
                margin-right: 7px;
                float: left;
            }
        }
    }


    .describe-question {
        .checkbox {
            &:last-of-type {
                clear: both;
            }
        }
    }

    .question {
        width: 100%;

        @include clearfix;

        .checkbox {
            width: 33%;
            float: left;

            @media (max-width: 768px) {
                width: 100%;
                float: unset;
                display: inline-block;
            }
        }

        .input-field {
            margin-top: 13px;
            margin-left: 0;
        }


        input:disabled, select:disabled {
            /*background-color: #717171 !important;*/
            opacity: 0.4;
            color: rgb(149, 149, 149) !important;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #000;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #000;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #000;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #000;
            }
        }

        .other-input {
            margin-top: 10px;
        }

        .event-description {
            color: #1a4fbf;
        }

        .errors {
            color: #de0000;
            margin-top: 50px;
        }
    }
}

.errors {
    color: #de0000;
}
