﻿.cart-order-popup {
    .items-wrapper {
        margin-bottom: 25px;

        .items-header {
            font-weight: bold;
            font-size: 20px;
            color: #145ac0;
            padding-bottom: 20px;
        }

        .item-wrapper {
            clear: both;
            margin-bottom: 20px;
            position: relative;
            padding-bottom: 20px;

            &:before {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url(../img/shadows-stripes/mobile-section-stripes-gray.png);
                background-repeat: repeat-x;
            }
        }

        .item-details {
            float: left;
            text-align: left;
            width: 55%;

            .item-name {
                font-weight: bold;
                font-size: 18px;
                padding-bottom: 2px;
            }

            .picture-wrapper {
                width: 25%;
                display: inline-block;
            }

            .item-picture {
                vertical-align: middle;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                //100%;
                height: 0;
                margin-bottom: 11px;
                width: 100%;
                padding-bottom: 75%;

                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 120px;
                }


                &.empty {
                    background-size: auto 100%;
                    background-color: #008ece;
                    background-image: url("/Content/img/club-logo-placeholder.jpg");
                }
            }

            .item-stuff {
                width: 73%;
                display: inline-block;
                vertical-align: top;
            }
        }

        .item-pricing {
            float: right;
            width: 43%;

            .item-price-each {
                display: inline-block;
                width: 50%;


                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }

            .item-price-total {
                display: inline-block;
                width: 48%;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        .item-price-header {
            padding-bottom: 15px;
            position: relative;
            margin-bottom: 20px;

            &:before {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url(../img/shadows-stripes/mobile-section-stripes-gray.png);
                background-repeat: repeat-x;
            }

            .item-price-each, .item-price-total {
                font-weight: bold;
                font-size: 17px;
            }
        }
    }

    .shipments-wrapper {
        .shipments-header {
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 20px;
            color: #145ac0;
        }

        .shipments {
            text-align: left;

            .shipment {
                padding-bottom: 20px;
                position: relative;
                margin-bottom: 20px;

                &:before {
                    content: '';
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-image: url(../img/shadows-stripes/mobile-section-stripes-gray.png);
                    background-repeat: repeat-x;
                }


                .shipment-date {
                    font-size: 18px;
                }

                .shipment-date-label, .shipment-method, .tracking-code-label {
                    font-weight: bold;
                }
            }

            .shipment-to-name {
                font-weight: bold;
            }

            .shipment-details {
                display: inline-block;
                width: 52%;
            }

            .shipment-items {
                width:100%;
                display: inline-block;
                vertical-align: top;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                -moz-binding: url('assets/xml/ellipsis.xml#ellipsis');
                text-align:center;
            }

            .shipments-items-header {
                font-weight: bold;
                font-size: 17px;
                position: relative;
                padding-bottom: 15px;
                margin-bottom: 20px;

                &:before {
                    content: '';
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-image: url(../img/shadows-stripes/mobile-section-stripes-gray.png);
                    background-repeat: repeat-x;
                }
            }
        }
    }
}
