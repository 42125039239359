#page-register {
    @include clearfix;

    .inner-wrapper {
        padding-top: 80px;
        width: 79%;
        margin: 0 auto;

        @include respond-to(tablet) {
            width: 72%;
        }
    }

    .left-column {
        width: 100%;

        @include respond-to(tablet) {
            width: 46%;
            float: left;
        }
    }

    .middle-column {
        width: 100%;

        @include respond-to(tablet) {
            width: 8%;
        }


        float: left;
        text-align: center;
        padding-top: 10px;
    }

    .right-column {
        float: right;
        width: 100%;

        @include respond-to(tablet) {
            width: 46%;
        }


        header {
            padding-top: 10px;
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 22px;
            color: $white;
            text-align: center;

            @include respond-to(tablet) {
                text-align: left;
            }
        }
    }

    .columnwrapper {
        @include clearfix;
    }

    button.sign-up {
        float: right;
    }
}

body.body-register-page {
    background: url("../img/backgrounds/2.png") no-repeat;
    background-size: cover;
    background-position: center center;

    @include respond-to(tablet) {
        background-position: top center;
    }
}