﻿#Activities-Amenities {
    .normal-fields {
        background-color: #fff;
        padding: 19px;
        height: 300px;
        overflow-y: scroll;
        margin-bottom: 30px;

        @include clearfix;

        .separator {
            @include clearfix;
            margin-top: 35px;
        }

        button {
            margin-top: 30px;
        }

        .single-checkbox {
            width: 50%;
            float: left;
            cursor: pointer;

            @include respond-to(tablet) {
                width: 33%;
            }


            @include respond-to(desktop) {
                width: 25%;
            }

            .single-checkbox-inner {
                margin: 3px;
                padding: 5px;

                &.selected {
                    background-color: #b0b0b0;
                    color: #fff;
                    font-weight: bold;
                }
            }


            label {
                @include fonts-vw(ProximaNova, 14px, 14px);
                display: block;
                width: 110%;
                position: relative;
                color: $black;
                font-weight: 600;
                padding-left: 28px;

                @include respond-to(tablet) {
                    display: inline;
                    width: auto;
                }

                &:before {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                @include respond-to(desktop) {
                    &:before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            input[type=checkbox] {
                width: 0;
                visibility: hidden;
            }

            input:checked + label {
                &:before {
                    background-image: url('../img/icons/icon-checked-business.png');
                }
            }
        }
    }

    .custom-fields {
        @include clearfix;

        .subtitle {
            text-align: left;
            padding-bottom: 15px;
        }


        .add-another-btn {
            float: left;
            padding: 15px 20px;
            background-color: #edaa20;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            cursor: pointer;
        }

        .customfields {
            clear: left;
        }
    }
}
