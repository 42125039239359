﻿#page-business-signup-form-complete {
    .wrapper {
        padding-top: 55px;
    }

    .complete-header{
        font-weight: bold;
        text-align: center;
        font-size: 33px;
        margin-bottom: 5px;
    }

    .sub-header {
        text-align: center;
        max-width: unset;
    }

    .continue {
        float: unset;
        margin: 30px auto;
    }
}