@media print {
    html, body {
       // height: 100%;
       // overflow: hidden;
    }
    header {
        .menu-logged {
            display: none;
        }
        .menu {
            display: none;
        }
    }

    .body-content {
        .stripes {
            display: none;
        }
        footer {
            display: none;
        }
    }

    #body-overlay {
        display: none;
    }

    .pac-container {
        display: none;
    }

    #page-buy-end {
        > .mod-padding-mobile {
            display: none;
        }
        .buy-row {
            &.buy-row-first {
                margin-top: 0;
            }

            .buy-column.grey {
                border: none !important;
            }

            &:first-of-type {
                .buy-column:first-child {
                    width: auto;
                    max-width: 100%;
                    padding: 0;
                    float: left;
                }
                .subtitle {
                    display: none;
                }
                .purchase {
                    margin-top: 27px;
                    .clubName {
                        display: block;
                        font-size: 40px;
                    }
                }
                .grey.buy-column:nth-child(2n) {
                    display: none;
                }
            }
            &:last-of-type {
                .buy-column:first-child {
                    display: none;
                }
            }

            #workout {
                display: none !important;
            }
        }

        .toggle-box {
            .toggle-icon {
                display: none;
            }
            .toggle-content {
                display: block !important;
            }
        }
    }

}