// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$buyend-email-blue-name: 'buyend-email-blue';
$buyend-email-blue-x: 0px;
$buyend-email-blue-y: 0px;
$buyend-email-blue-offset-x: 0px;
$buyend-email-blue-offset-y: 0px;
$buyend-email-blue-width: 17px;
$buyend-email-blue-height: 12px;
$buyend-email-blue-total-width: 162px;
$buyend-email-blue-total-height: 150px;
$buyend-email-blue-image: '../img/buyend.png';
$buyend-email-blue: (0px, 0px, 0px, 0px, 17px, 12px, 162px, 150px, '../img/buyend.png', 'buyend-email-blue', );
$buyend-email-name: 'buyend-email';
$buyend-email-x: 17px;
$buyend-email-y: 12px;
$buyend-email-offset-x: -17px;
$buyend-email-offset-y: -12px;
$buyend-email-width: 17px;
$buyend-email-height: 12px;
$buyend-email-total-width: 162px;
$buyend-email-total-height: 150px;
$buyend-email-image: '../img/buyend.png';
$buyend-email: (17px, 12px, -17px, -12px, 17px, 12px, 162px, 150px, '../img/buyend.png', 'buyend-email', );
$buyend-fb-blue-name: 'buyend-fb-blue';
$buyend-fb-blue-x: 64px;
$buyend-fb-blue-y: 54px;
$buyend-fb-blue-offset-x: -64px;
$buyend-fb-blue-offset-y: -54px;
$buyend-fb-blue-width: 24px;
$buyend-fb-blue-height: 24px;
$buyend-fb-blue-total-width: 162px;
$buyend-fb-blue-total-height: 150px;
$buyend-fb-blue-image: '../img/buyend.png';
$buyend-fb-blue: (64px, 54px, -64px, -54px, 24px, 24px, 162px, 150px, '../img/buyend.png', 'buyend-fb-blue', );
$buyend-fb-name: 'buyend-fb';
$buyend-fb-x: 88px;
$buyend-fb-y: 78px;
$buyend-fb-offset-x: -88px;
$buyend-fb-offset-y: -78px;
$buyend-fb-width: 24px;
$buyend-fb-height: 24px;
$buyend-fb-total-width: 162px;
$buyend-fb-total-height: 150px;
$buyend-fb-image: '../img/buyend.png';
$buyend-fb: (88px, 78px, -88px, -78px, 24px, 24px, 162px, 150px, '../img/buyend.png', 'buyend-fb', );
$buyend-ins-blue-name: 'buyend-ins-blue';
$buyend-ins-blue-x: 112px;
$buyend-ins-blue-y: 102px;
$buyend-ins-blue-offset-x: -112px;
$buyend-ins-blue-offset-y: -102px;
$buyend-ins-blue-width: 25px;
$buyend-ins-blue-height: 24px;
$buyend-ins-blue-total-width: 162px;
$buyend-ins-blue-total-height: 150px;
$buyend-ins-blue-image: '../img/buyend.png';
$buyend-ins-blue: (112px, 102px, -112px, -102px, 25px, 24px, 162px, 150px, '../img/buyend.png', 'buyend-ins-blue', );
$buyend-ins-name: 'buyend-ins';
$buyend-ins-x: 137px;
$buyend-ins-y: 126px;
$buyend-ins-offset-x: -137px;
$buyend-ins-offset-y: -126px;
$buyend-ins-width: 25px;
$buyend-ins-height: 24px;
$buyend-ins-total-width: 162px;
$buyend-ins-total-height: 150px;
$buyend-ins-image: '../img/buyend.png';
$buyend-ins: (137px, 126px, -137px, -126px, 25px, 24px, 162px, 150px, '../img/buyend.png', 'buyend-ins', );
$buyend-print-blue-name: 'buyend-print-blue';
$buyend-print-blue-x: 34px;
$buyend-print-blue-y: 24px;
$buyend-print-blue-offset-x: -34px;
$buyend-print-blue-offset-y: -24px;
$buyend-print-blue-width: 15px;
$buyend-print-blue-height: 15px;
$buyend-print-blue-total-width: 162px;
$buyend-print-blue-total-height: 150px;
$buyend-print-blue-image: '../img/buyend.png';
$buyend-print-blue: (34px, 24px, -34px, -24px, 15px, 15px, 162px, 150px, '../img/buyend.png', 'buyend-print-blue', );
$buyend-print-name: 'buyend-print';
$buyend-print-x: 49px;
$buyend-print-y: 39px;
$buyend-print-offset-x: -49px;
$buyend-print-offset-y: -39px;
$buyend-print-width: 15px;
$buyend-print-height: 15px;
$buyend-print-total-width: 162px;
$buyend-print-total-height: 150px;
$buyend-print-image: '../img/buyend.png';
$buyend-print: (49px, 39px, -49px, -39px, 15px, 15px, 162px, 150px, '../img/buyend.png', 'buyend-print', );
$buyend-spritesheet-width: 162px;
$buyend-spritesheet-height: 150px;
$buyend-spritesheet-image: '../img/buyend.png';
$buyend-spritesheet-sprites: ($buyend-email-blue, $buyend-email, $buyend-fb-blue, $buyend-fb, $buyend-ins-blue, $buyend-ins, $buyend-print-blue, $buyend-print, );
$buyend-spritesheet: (162px, 150px, '../img/buyend.png', $buyend-spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
