.menu-logged {
    float: right;
    position: relative;
    margin: 0 -15px 0 60px;
    min-width: 160px;

    @include respond-to(mobile-sm) {
        margin-left: 0;
        margin-right: 0;
        min-width: 135px;
    }

    @include respond-to(mobile-lg) {
        margin-left: 0;
        margin-right: 0;
    }

    &.expanded {
        .menu-trigger span {
            transform: rotate(180deg);
        }
        .menu-logged-list {
            visibility: visible;
            transform: translateY(0);
        }
    }
}
.menu-logged-wrapper {
    background: $white;
    padding-right: 10px;
    position: relative;
    z-index: 10;

    @include respond-to(desktop) {
        padding-right: 0;
    }
}
.user-info {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    cursor: pointer;

    @include respond-to(mobile-sm) {
        padding-top: 2px;
        width: 90%;
    }

    > * {
        float: left;
    }

    span {
        max-width: 54px;
        overflow: hidden;
        // display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        // float: right;
        @include respond-to(mobile-sm) {
            margin-top: -1px;
            display: inline-block;
            max-width: 60px;
            line-height: 50px;
            vertical-align: middle;
        }
    }
}
.user-photo {
    display: flex;
    align-items: center;
    height: 50px;
    width: 50px;
    background: url('../img/user-photo-blank.png') center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 10px;
    overflow:hidden;
    position:relative;
    @include respond-to(mobile-sm){
        margin-top: 16px;
        margin-right: 4px;
        height: 17px;
        width: 20px;
    }
    &>div{
            width: 100%;
    height: 100%;
    background-size: cover;
    }
    img {
       display: block;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100%;
    margin: 0 auto;
    max-width: 200%;
    max-height: 200%;
    position: absolute;
    z-index: 2;
    left: 50%;
     top:50%;
    transform: translate(-50%, -50%);


        // @include respond-to(mobile-sm) {
        //     height: 40px;
        //     max-width: 40px;
        // }
    }

}
.user-name {
    font-size: 16px;
    font-family: ProximaNova;
    font-weight: 600;
    color: $color-2;
    line-height: 50px;
    margin-right: 10px;

    @include respond-to(mobile-sm) {
        margin-right: 5px;
        font-size: 13px;
        width: 92px;
    }

    @include respond-to(desktop) {
        margin-right: 5px;
        font-size: 16px;
        width: 136px;
    }
}
.menu-trigger {
    overflow: hidden;
    text-indent: -9999px;
    height: 16px;
    width: 16px;
    margin-top: 17px;
    border: 1px solid $border-color-checkbox;
    position: relative;
    span {
        position: absolute;
        top: 5px;
        left: 3px;

        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid $color-12;

        transition: all 0.25s ease-in-out;
    }
}
.menu-logged-list {
    position: absolute;
    top: 58px;
    left: 0;
    z-index: 5;
    width: 100%;
    padding: 10px 10px 30px;
    background: $white;
    transform: translateY(-100%);
    visibility: hidden;
    transition: all 0.25s ease-in-out;

    li {
        padding: 20px 0;
        text-align: center;
        font-size: 16px;
        font-family: ProximaNova;
        font-weight: 700;
        color: $text-logged-menu;
        position: relative;

        a {
            color: inherit;
            display: block;
        }

        span {
            display: block;
            margin: 0 auto 20px;
            background-position: center center;
            background-repeat: no-repeat;

            &.logout-link {
                padding-bottom: 0;
                margin-bottom: 0;
                cursor: pointer;
            }
        }

        &:before {
            content: "";
            background: url('../img/shadows-stripes/mobile-section-stripes-gray.png') repeat-x center 0;
            width: 100%;
            height: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }

        &.dashboard {
            span {
                background-image: url('../img/icons/ico_dashboard.png');
                width: 30px;
                height: 27px;
            }
        }

        &.edit-profile {
            span {
                background-image: url('../img/icons/ico_edit_profile.png');
                width: 27px;
                height: 26px;
            }
        }

        &.activity {
            span {
                background-image: url('../img/icons/ico_activity.png');
                width: 37px;
                height: 37px;
            }
        }

        &.settings {
            span {
                background-image: url('../img/icons/ico_settings.png');
                width: 23px;
                height: 23px;
            }
        }

        &.logout {
            text-align: right;
            padding-bottom: 0;

            a {
                display: inline-block;
            }
        }
    }
}

#profile-side-menu {
    display: none;
    padding: 0 16px;
    width: 23%;
    max-width: 253px;
    transition: none;

    @include respond-to(desktop){
        display: inline-block;
    }

    .menu-logged-list {
        top: auto;
        left: auto;
        position: relative;
        width: 100%;
        z-index: 0;
        visibility: visible;
        transform: none;

        li {
            padding: 0;
            text-align: center;
            font-size: 16px;

            &:before{
                top: auto;
                bottom:-5px;
                height:5px;
            }

            &.active {
                background-color: $color-13;
                color: $white;

                &:before{
                }
            }

            a {
                display: block;
                padding: 30px 0 25px 30%;
                text-align:center;
            }

            span {
                left: 10%;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                width: 20%;
                display: inline-block;
                background-size: contain;

            }

            &.active {
                &.dashboard {
                    span{
                        background-image: url('../img/icons/ico_dashboard-white.png');
                    }
                }

                &.activity {
                    span{
                        background-image: url('../img/icons/ico_activity-white.png');
                    }
                }
            }
        }
    }
}