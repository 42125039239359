//standard text
.component-text {
  h2 {
    margin-bottom: 25px;

    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
  }

  p {
    margin-bottom: 12px;
     word-wrap: break-word;
    line-height: 1.3;
  }

  //TODO: a, li etc.
}