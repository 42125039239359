#page-thankyou {
    section {
        padding: 30px 0;

        .block {
            padding: 54px 0 100px 150px;

            //background: $bg-color-1;
            background: url("../img/group-5.png");
            background-size: 100% 100%;
            width: 87%;
            float: left;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100vw;
                height: 100%;
                background: $bg-color-1;
                position: absolute;
                right: 99%;
                top: 0;
            }

            h1 {
                font-family: ProximaNova;
                font-weight: 400;
                font-size: 32px;
                color: $black;
                margin-top: 48px;
            }

            .subtitle {
                font-family: ProximaNova;
                font-weight: 400;
                font-size: 16px;
                color: $color-10;
                margin-top: 10px;
            }

            button {
                margin-top: 35px;
                background: $color-12;
                border-bottom: 2px solid #094496;
                border-left: 2px solid #094496;
                border-right: 2px solid #094496;
                border-top: 2px solid #bbcfed;

                a {
                    display: block;
                    font-family: ProximaNova;
                    font-weight: 700;
                    font-size: 13px;
                    padding: 10px 11px;
                    color: $white;
                }

                .arrow {
                    margin-left: 10px;
                }
            }
        }
    }
}