#page-businessregistration {
    @extend .all-business;
    //Uniqe wrapper size
    .main-section {
        @include clearfix;

        .wrapper {
            max-width: 950px;
            padding-left: 30px;
            padding-right: 30px;

            @include respond-to(tablet) {
                padding-left: 180px;
            }


            @include respond-to(desktop) {
                padding-left: 130px;
            }


            .inner-wrapper {
                width: 100%;
            }
        }

        .leftdash {
            padding: 10px;
            padding-left: 0;
            width: 17px;
            text-align: center;
            float: left;
        }
        //inside thinner column
        .thin-wrapper {
            max-width: 480px;

            @include clearfix;
            margin: 0 auto;

            .subtitle {
                text-align: left;
                margin-bottom: 15px;

                &.left {
                    margin-bottom: 0;
                }

                p {
                    display: inline-block;
                    width: 100%;
                    float: left;
                    margin-bottom: 10px;
                    //width: 40%;
                    //float: right;
                    line-height: 1;

                    @include respond-to(tablet) {
                        margin-bottom: 0;
                        display: inline;
                        width: auto;
                        float: none;
                        line-height: 46px;
                    }
                }

                label {
                    margin-top: 0;
                    margin-left: 0;
                }
            }
        }

        .thin-wrapper, .right-column {
            .input-field {
                &.full {
                    width: 90%;
                    margin-left: 10%;

                    label {
                        width: 16%;

                        @include respond-to(tablet) {
                            width: 10%;
                        }
                    }

                    &.textarea {
                        width: 100%;
                        float: right;
                        margin-left: 0;
                        margin-top: 10px;

                        label {
                            line-height: 1;
                            width: 130px;
                        }

                        @include respond-to(tablet) {
                            margin-top: 0;
                            width: 90%;
                        }
                    }
                }

                &.full100 {
                    width: 100%;
                    float: left;
                }
            }

            .continue.back {
                float: none;
                display: inline-block;
            }
        }
        //Custom title different than others
        .title {
            @include fonts-vw(ProximaNova, 36px, 36px);
            font-weight: 700;
            text-align: center;

            @include clearfix;
            margin-bottom: 20px;
        }

        .subtitle {
            float: left;
            text-align: center;
            margin-top: 10px;

            &.hours-subtitle {
                margin-top: 0;
                margin-bottom: 0 !important;
            }

            &.logo-subtitle {
                text-align: left;

                .logo-subtitle-text {
                    position: relative;
                    top: 7px;
                }
            }

            @include clearfix;

            @include respond-to(tablet) {
                margin-top: 30px;
            }


            .explanation {
                padding-left: 15px;
                float: none;
                width: auto;

                span.error {
                    color: red;
                }
            }

            &.left {
                text-align: left;
            }
        }

        .description {
            float: left;
            width: 100%;
            margin-bottom: 10px;
            text-align: center;

            @include fonts-vw(ProximaNova, 14px, 14px);

            @include clearfix;

            @include respond-to(tablet) {
                margin-bottom: 25px;
            }
        }

        .formats {
            margin-top: 0;
            // 25px;
            margin-bottom: 10px;
        }

        #SecondaryContactTitle {
            padding: 9px;
        }

        #picture {
            width: 0;
            height: 0;
            visibility: hidden;
        }

        .pctr {
            float: left;
        }

        .image {
            width: 100%;
            float: left;

            .imagecanvas {
                width: 100%;
                margin: 0 auto;
                padding-bottom: 51.5%;
                border: 2px solid $color-29;
                background: $color-30;
                position: relative;
                background-size: contain !important;
                background-repeat: no-repeat;
                background-position: center top !important;

                .info {
                    position: absolute;
                    bottom: 10%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @include respond-to(desktop) {
                    width: 100%; //width: 360px;
                }
            }

            .inner {
                width: 100%;
                margin: 0 auto;

                @include respond-to(desktop) {
                    padding-top: 19px;
                    width: 100%; // 360px;
                }
            }

            .explanation {
                cursor: pointer;
            }
        }

        .center {
            text-align: center;
        }

        .right {
            text-align: right;
        }

        .taxid {
            max-width: 120px;
            float: left;
            margin-right: 15px;
        }

        .hours-details {
            clear: both;
            padding-top: 15px;
            font-weight: bold;

            &.hours-details-error {
                color: #de0000;
            }
        }

        .hours-subtitle {
            margin-bottom: 0;
        }

        .open24, .override {
            padding-bottom: 25px;
        }

        .timeoption {
            float: left;
            width: 100%;
            padding-bottom: 5px;

            .option {
                width: 100%;
                clear: both;

                @include clearfix;

                .day {
                    display: inline-block;
                    width: 30%;
                    float: left;

                    input {
                        float: right;
                        height: 0;
                        width: 0;
                    }

                    select {
                        -moz-appearance: none;
                    }

                    label {
                        width: 99%;
                        float: left;
                        // line-height: 1.5;
                    }
                    //line-height: 43px;
                }

                .hours {
                    display: inline-block;
                    width: 70%;
                    float: left;
                    padding-top: 6px;
                    // .input-field.half:nth-of-type(2n):after {
                    //     display: inline-block;
                    //     content: "Am";
                    //     position: absolute;
                    //     top: 12px;
                    //     left: 35%;
                    //     font-weight: 400;
                    // }
                    // .input-field.half:nth-of-type(3n):after {
                    //     display: inline-block;
                    //     content: "PM";
                    //     position: absolute;
                    //     top: 12px;
                    //     left: 35%;
                    //     font-weight: 400;
                    // }
                }

                .half {
                    @include fonts-vw(ProximaNova, 14px, 14px);
                    width: 46%;
                }
            }

            &.error {
                padding: 10px;
                box-shadow: 0 0 4px #d04b4b !important;
                margin-bottom: 10px;
            }
        }

        .fitnesstypes {
            @include clearfix;
            float: left;
            width: 100%;

            .subtitle {
                float: none;
                margin-bottom: 0 !important;
            }

            .fitness {
                width: 100%;
                margin: 0 auto;

                @include respond-to(tablet) {
                    width: 66%;
                    /*padding-top: 15px;*/
                }


                @include clearfix;

                .checkbox {
                    width: 26%;
                    float: left;
                    margin-left: 6%;
                }

                .input-field {
                    margin-top: 13px;
                }
            }
        }

        .clubinfo {
            padding-top: 15px;

            .inside {
                /*width: 80vw;
                margin: 0 auto;
                text-align: center;*/
            }

            @include respond-to(desktop) {
                padding-top: 29px;

                .inside {
                    /*width: 45vw;*/
                }
            }


            .subtitle {
                margin-top: 0;
                width: auto;
                float: none;
                display: inline-block;
                //line-height: 1;
                padding-right: 10px;
            }

            label {
                width: auto !important;

                &:before {
                    margin-top: -5px;
                }
            }
        }

        .api-page-directive {
            padding-top: 35px;
            position: relative;

            .disabled-wrapper {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.7);
                top: -15px;
                bottom: -15px;
                left: -158px;
                right: 0;
                z-index: 10000;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
        }


        .enable-button {
            margin-top: 29px;
            margin-bottom: 25px;
            background-color: #fff;
            color: #000;
            border: 2px solid #a7a7a7;
            font-weight: normal;
            cursor: pointer;
            padding: 13px 27px;
            font-family: ProximaNova;
            margin-left: 15px;
            display: block;
            float: right;
        }

        .service-provider-directive {
            .software {
                .inner-field {
                    label {
                        padding-bottom: 15px;
                        text-align: center !important;
                    }

                    select {
                        width: 100% !important;
                    }
                }
            }

            .provider-selected {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 5px;
                padding-top: 15px;
            }

            .service-fields {
                border-top: 2px solid #b0b0b0;
                clear: both;
                padding-top: 35px;
                border-bottom: 2px solid #b0b0b0;
                padding-bottom: 5px;

                .service-instructions {
                    padding-bottom: 15px;

                    .mail-link {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    &.already-know {
                        margin: 0 auto;
                        max-width: 391px;
                        float: unset;
                    }
                }

                .unknown-external {
                    font-size: 14px;
                    padding-top: 5px;
                }

                .provider-selected-disclaimer {
                    clear: both;
                    font-size: 14px;
                    padding-top: 15px;
                }

                .no-edit-external {
                    position: relative;
                    top: 12px;


                    @media (max-width: 768px) {
                        top: 0;
                    }
                }

                .input-field {
                    label {
                        width: 200px;
                    }

                    &.other-service-provider {
                        margin-left: 0;
                        width: 100%;
                    }

                    &.package-checkbox {
                        label {
                            font-size: 16px;
                            padding-left: 25px;

                            @media (max-width: 1025px) {
                                padding-left: 50px;
                            }
                        }
                    }
                }

                .help-text {
                    font-size: 15px;
                    padding-top: 15px;
                    clear: both;
                    padding-bottom: 10px;

                    .help-text-label {
                        font-weight: bold;
                        font-size: 19px;
                        padding-bottom: 5px;
                    }

                    .help-list {
                        list-style: decimal;
                        padding-left: 20px;
                        line-height: 1.2;
                    }

                    .help-text-note {
                        font-size: 14px;
                        padding-top: 5px;

                        .help-text-note-label {
                            font-weight: bold;
                        }
                    }
                }
            }

            .showcase-agreement {
                font-size: 13px;
                font-weight: bold;
                padding-top: 10px;
            }

            .service-fields:after {
                content: "";
                clear: both;
                display: block;
            }

            .clubready-email-button {
                float: unset;
                max-width: 325px;
                display: block;
                margin: 0 auto;
                text-align: center;
                margin-top: 10px;
                cleaR: both;
            }

            .continue.email-sent, .continue.email-sending {
                background-color: #727272;
                cursor: default;
            }

            .clubready-error {
                color: #de0000;
                font-weight: bold;
                margin-top: 5px;
            }
        }
        //List of services checkboxes
        .checkbox {
            input[type="checkbox"] {
                width: 0;
                visibility: hidden;
                float: left;
            }

            input[type=checkbox] + label:not(.validationMessage), input[type=checkbox] + label + label {
                position: relative;
                right: 0;
                display: inline-block;
                width: 100%;
                line-height: 1;
                text-align: left;
                padding-left: 36px;

                @include fonts-vw(ProximaNova, 14px, 14px);

                &.clubinfo-checkbox:before {
                    top: 10px;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    width: 32px;
                    height: 34px;
                    background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                    background-size: cover;
                    margin-right: 10px;
                    position: absolute;
                    left: 2px;
                    top: 36%;
                    transform: translateY(-50%);
                }

                @include respond-to(desktop) {
                    padding-left: 20px;

                    &:before {
                        width: 16px;
                        height: 17px;
                    }
                }
            }

            input[type=checkbox] + label.validationMessage {
                margin-bottom: 15px;

                &:before {
                    content: none;
                    display: none;
                }

                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid #d04b4b;
                    position: absolute;
                    bottom: -6px;
                    left: 20px;
                }
            }

            input[type=checkbox]:checked + label:before {
                background: url("../img/icons/icon-checked-business.png") no-repeat;
                background-size: cover;
            }

            input[type=checkbox]:disabled + label:before {
                background: url("../img/icons/icon-unchecked-business-disabled.png") no-repeat;
                background-size: cover;
                opacity: 0.4;
            }
        }

        .about {
            margin-top: 10px;

            @include respond-to(tablet) {
                margin-top: 10px;
            }
        }

        .question {
            .checkbox {
                width: 50%;
                float: left;

                &:last-of-type {
                    clear: both;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .input-field {
            input.other-input {
                margin-top: 15px;
            }
        }

        .event-description {
            font-weight: bold;
            color: #1243b2;
        }

        .using-eventbrite {
            font-weight: bold;
            color: #1243b2;
        }

        .event-date {

            .input-field.half {
                width: 35%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .input-field.half:not(.halftax):nth-of-type(2n-1) {
                float: left;
                clear: unset;
            }

            .input-field.half:not(.halftax):nth-of-type(2n) {
                clear: unset;
                margin-left: 93px;

                @media (max-width: 767px) {
                    margin-left: unset;
                }
            }
        }

        .credentials {
            .subtitle {
                margin-bottom: 10px;
            }
        }

        .logo.input-field {
            .logo-label {
                @media (max-width: 767px) {
                    display: block !important;
                    padding-bottom: 0 !important;
                }
            }

            label.upload-button {
                position: relative;
                right: unset;
                line-height: unset;
                text-align: unset;
                float: left;
                width: unset;
                padding: 14px 27px;

                @media (max-width: 767px) {
                    clear: both;
                    display: block;
                    margin-left: 0px;
                }
            }

            .inner {
                padding-top: 0;
            }

            .single-image.image .explanation {
                cursor: pointer;
                float: unset;
                clear: both;
                padding-right: 10px;
            }
        }

        #eventPicture {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        .input-field {
            @include fonts-vw(ProximaNova, 14px, 16px);
            position: relative;
            margin-bottom: 15px;

            &.no-mgb {
                margin-bottom: 0;
            }

            textarea, input[type=text], input, select {
                width: 100%;
                padding: 10px;
                // background: #dadada;
                // border: 2px solid #b0b0b0;
                // color: #414141;
                // font-family: ProximaNova;
                // font-size: 2.70833vw;
                // font-weight: 600;
                // font-weight: 600;
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-family: ProximaNova;
                @include fonts-vw(ProximaNova, 13px, 13.3px);
                font-weight: 600;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                font-family: ProximaNova;
                @include fonts-vw(ProximaNova, 13px, 13.3px);
                font-weight: 600;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                font-family: ProximaNova;
                @include fonts-vw(ProximaNova, 13px, 13.3px);
                font-weight: 600;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                font-family: ProximaNova;
                @include fonts-vw(ProximaNova, 13px, 13.3px);
                font-weight: 600;
            }

            input:disabled, select:disabled, textarea:disabled {
                /*background-color: #717171 !important;*/
                opacity: 0.4;
                color: rgb(149, 149, 149) !important;       

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #000;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #000;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #000;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #000;
                }
            }

            select.disabled, input.disabled {
                background: #717171 !important;
            }

            textarea {
                min-height: 100px;
                resize: none;
            }

            label {
                line-height: 40px;
                padding-right: 10px;
                font-weight: 700;
                position: relative;
                width: 150px;

                @include respond-to(mobile-sm) {
                    width: 100%;
                    text-align: left;
                }

                @include respond-to(tablet) {
                    width: 180px;
                    position: absolute;
                    right: 100%;
                }


                @include respond-to(desktop) {
                    width: 135px;
                }

                text-align: right;

                a {
                    color: $black;
                    text-decoration: underline;
                }
            }

            .size-ranges-label {
                font-size: 15px;
            }

            &.half {
                width: 100%;

                @include respond-to(desktop) {
                    width: 40%;
                }

                &.halftax {
                    width: 48%;
                    padding-top: 40px;

                    .taxid:nth-of-type(2n-1) {
                        max-width: 100px;
                    }

                    @include respond-to(tablet) {
                        padding-top: 0px;
                    }

                    @include respond-to(desktop) {
                        width: 215px;
                    }
                }

                float: left;
                clear: none;

                &:not(.halftax):nth-of-type(2n-1) {
                    float: right;
                    clear: right;

                    label:not(.has-error) {
                        width: 150px;

                        @include respond-to(desktop) {
                            width: 50%;
                        }
                    }
                }

                &:nth-of-type(2n-1) {
                    label:not(.has-error) {
                        width: 150px;

                        @include respond-to(desktop) {
                            width: 50%;
                        }
                    }
                }


                &:not(.halftax):nth-of-type(2n) {
                    clear: left;
                }
            }

            &.full {
                width: 100%;
                float: left;

                @include clearfix;
            }

            &.third {
                width: 100%;
                margin-right: 80px;
                float: left;

                label {
                    width: 170px;
                }

                @include respond-to(desktop) {
                    width: 30%;

                    label {
                        width: 80px;
                    }
                }
            }

            &.middle {
                width: 100%;
                float: left;
                margin-top: 20px;

                @include respond-to(tablet) {
                    margin-top: 40px;
                }


                @include clearfix;

                .inner-field {
                    // max-width: 600px;
                    margin: 0 auto;
                    text-align: center;

                    label {
                        text-align: left;
                        right: auto;
                        position: static;
                        width: auto;
                        display: inline-block;
                        line-height: 1;

                        @include respond-to(tablet) {
                            text-align: right;
                            line-height: 14px;
                        }
                    }

                    select {
                        width: 51%;
                        display: inline-block;
                    }
                }
            }

            &.checkbox {
                width: 100%;
                float: left;

                @include clearfix;
            }

            &.facebook, &.twitter, &.instagram {
                label {
                    width: 20px;
                    height: 37px;
                    background-size: auto 80%;
                    background-position: 10% center;
                    position: absolute;
                    right: 100%;
                }

                input {
                    line-height: 0.7;
                }
            }

            &.facebook label {
                background: url("/content/img/icons/icon-black-fb.png") no-repeat;
            }

            &.twitter label {
                background: url("/content/img/icons/icon-black-tw.png") no-repeat;
            }

            &.instagram label {
                background: url("/content/img/icons/icon-black-ins.png") no-repeat;
            }
        }
    }

    .main-section .input-field {
        .openpopup {
            width: 50% !important;
            padding: 11px;
        }

        .popup {
            label {
                line-height: 1.2;
                text-align: left !important;
                float: left;
            }

            .title {
                @include fonts-vw(ProximaNova, 18px, 36px);
            }

            input {
                margin-top: 3px;
            }
        }
    }

    .taxid-wrapper {
        width: 100%;
        margin-bottom: 5px !important;

        label {
            width: 150px !important;
            margin-top: unset !important;
            float: unset !important;
            text-align: right !important;

            @media (max-width: 767px) {
                text-align: left !important;
            }
        }

        .two-digit-num {
            width: 100px;
            float: unset;

            @include respond-to(mobile-sm) {
                width: 64px;
                margin-top: 0px;
            }

            .text-box {
                width: 100%;
            }
        }

        span {
            width: 20px;
            text-align: center;
            margin: 0px 20px;
            line-height: 40px;
            float: left;
        }

        .seven-digit-num {
            width: 150px;
            float: left;

            @include respond-to(mobile-sm) {
                width: 136px;
                margin-top: 0px;
            }

            .text-box {
                width: 100%;
            }
        }
    }

    .sq-ft-input {
        width: 60% !important;

        label {
            position: relative !important;
            right: unset !important
        }

        select {
            width: 60% !important;
        }
    }

    .number-sq-ft-input {
        width: 35% !important;

        @include respond-to(mobile-sm) {
            margin-top: 40px;
        }
    }
}
