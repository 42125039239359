﻿
#eventPicture {
    width: 0;
    height: 0;
    visibility: hidden;
}

#Event-Information {
    .image {
        padding-left: 8px;

        @media (max-width: 767px) {
            padding-left: 0;
        }
    }

    .single-image {
        font-weight: bold;

        @media (max-width: 767px) {
            .upload-button {
                margin-left: 0;
                clear: both;
            }
        }
    }

    .password-warning {
        color: #18ae1d;
        margin-top: 8px;
    }

    input:disabled, select:disabled, textarea:disabled {
        /*background-color: #717171 !important;*/
        opacity: 0.4;
        /*color: rgb(149, 149, 149) !important;*/

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #000;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #000;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #000;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: #000;
        }
    }
}

#Eventbrite-Information {
    .errors {
        font-weight: bold;
        padding-left: 8px;
    }

    .success {
        padding-left: 8px;
        font-weight: bold;
        color: #18ae1d;
    }


}
.instructions-link {
    margin-top: -13px;
    font-size: 13px;
    padding-left: 8px;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 5px;

    &.no-mt {
        margin-top: 0;
        padding-top: 6px;
        padding-left: 0;
        clear: both;
        padding-bottom: 10px;
    }
}

.no-mb{
    margin-bottom:0 !important;
}