.event-details-page {
    padding-top: 30px;
    flex-grow: 1;

    @include respond-to(tablet) {
        padding: 35px 0 0 0;
    }

    .sub-title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 16px;
    }


    .left-side {
        position: relative;

        @include respond-to(tablet) {
            float: left;
            width: 75%;
        }

        .event-title {
            margin-bottom: 35px;
            font-weight: bold;
            line-height: 1.3;
            font-size: 32px;
            max-width: 85%;

            @include respond-to(tablet) {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 20px;
                float: left;
                width: 50%;
            }



            @include respond-to(tablet) {
                padding-right: 143px;
                margin-bottom: 20px;
                font-weight: bold;
                line-height: 1.3;
                font-size: 32px;
            }
        }

        .send-invite {
            position: absolute;
            right: 0;
            top: 0;

            @include respond-to(tablet) {
                position: relative;
            }

            a {
                margin-top: 2px;
                background-image: url("/Content/img/club.png");
                background-position: -204px -209px;
                width: 50px;
                height: 39px;
                display: block;

                .invite-text {
                    display: none;
                }
            }
        }



        .misc-content {
            clear: both;

            @include respond-to(tablet) {
                width: 33%;
                float: left;
                clear: none;
            }

            .send-invite {
                @include respond-to(tablet) {
                    float: left;
                }
            }

            .default-image {
                padding-bottom: 15px;

                @include respond-to(tablet) {
                    float: left;
                    padding-left: 15px;
                    padding-bottom: 0;
                }

                img {
                    max-width: 120px;

                    @include respond-to(tablet) {
                        max-width: 100%;
                        width: 170px;
                        height: 127.5px;
                    }
                }
            }

            .logo-directions {
                margin-top: 20px;

                @include respond-to(tablet) {
                    margin-top: 0;
                }
            }

            .directions-link {
                cursor: pointer;
                position: relative;
                display: inline-block;
                height: 35px;
                line-height: 35px;
                padding: 0 16px 0 36px;
                margin-top: 4px;
                border: 2px solid #145ac0;
                transition: all 0.3s;
                color: #145ac0;
                font-weight: bold;
                font-size: 13px;
                text-transform: uppercase;
                white-space: nowrap;
                width: 170px;

                &:before {
                    position: absolute;
                    content: "";
                    left: 12px;
                    top: 7px;
                    background-image: url(../img/club.png);
                    background-position: -31px -46px;
                    width: 14px;
                    height: 18px;
                }
            }
        }
    }


    .image-wrapper {


        @include respond-to(tablet) {
            float: left;
            width: 25%;
        }

        img {
            max-width: 100%;
            max-height: 275px;
        }
    }


    .button-wrapper {
        background: #e8e8e8;
        padding: 5px;
        text-align: left;

        @include respond-to(tablet) {
            width: 50%
        }

        span {
            max-width: 50%;
            font-weight: bold;
            font-size: 18px;
            display: inline-block;
            padding-top: 25px;
            padding-left: 15px;

            @include respond-to(tablet) {
                max-width: initial;
            }
        }

        .button {
            background-color: #145ac0;
            color: #fff;
            width: 150px;
            font-weight: bold;
            text-align: center;
            margin: 20px 0;
            display: inline-block;
            float: right;

            a {
                color: #fff;
                display: block;
                padding: 10px 20px;
            }
        }
    }






    .location-details-wrapper {
        line-height: 1.3;


        @include respond-to(tablet) {
            float: left;
            width: 35%;
        }

        .time-wrapper {
            padding-bottom: 15px;
        }

        .address-wrapper {
            padding-bottom: 15px;
            padding: 15px 0;
        }

        .website-link {
            padding-bottom: 15px;

            a {
                color: #1b5dbd;
                text-decoration: underline;
                font-weight: 300;
                word-wrap: break-word;
            }
        }
    }

    .description-wrapper {
        line-height: 20px;
        padding: 15px 0;
    }

    .direction-map {
        background: $white;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 0.25s ease-in-out, z-index 0s ease-in-out 0.4s;

        body[data-overlayactive="true"] & {
            z-index: 1;
            transition: z-index 0s 0s;
        }

        &.in-progress {
            &:before {
                content: "";
                background: rgba(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 4;
            }

            &:after {
                content: "";

                @include preloader(15vw, 15vw, 2px, absolute, $color-6, $color-12);
                margin: auto;
                z-index: 5;

                @include respond-to(tablet) {
                    width: 5vw;
                    height: 5vw;
                }
            }
        }

        &.active {
            transform: translateX(0);

            &.fixed {
                // position: fixed;
                top: 0;
            }
        }

        @include respond-to(desktop) {
            // position: absolute;
            top: 74px;
            left: 50%;
        }


        .map-controls {
            position: relative;
            top: 0;
            right: inherit;
            padding: 10px;
            flex-direction: column;
        }

        #direction-close {
            width: 27px;
            height: 27px;
            background: $color-12 url("../img/icons/ex.png") center center no-repeat;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
        }

        .tier {
            order: 2;
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
        }

        #direction-mode {
            border: 2px solid $button-color;
            background: $white;
            color: $button-color;
            height: 27px;
            position: relative;
            width: 90px;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $button-color;
                position: absolute;
                top: 7.5px;
                right: 6px;
            }

            @include respond-to(tablet) {
                width: calc(50% - 5px);
            }


            @include respond-to(desktop) {
                width: calc(35% - 5px);
            }


            select {
                font-family: Arial;
                font-size: 13px;
                color: $button-color;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                outline: 0;
                padding-left: 8px;
                padding-right: 20px;
                border-radius: 0;
                background: transparent;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';

                option {
                    font-weight: bold;
                }

                &:focus {
                    outline: 0;
                }
            }

            select::-ms-expand {
                display: none;
            }
        }

        .direction-info {
            background: $white;
            border: 2px solid $button-color;
            padding: 0 8px;
            font-size: 13px;
            font-weight: bold;
            font-family: Arial;
            line-height: 23px;
            display: flex;
            width: calc(100% - 90px - 5px);
            justify-content: space-between;
            white-space: nowrap;
            overflow: hidden;

            span {
                color: $button-color;
            }

            @include respond-to(tablet) {
                width: 50%;
            }


            @include respond-to(desktop) {
                width: 65%;
            }
        }

        #direction-input {
            order: 1;

            > div {
                position: relative;

                + div {
                    margin-top: 5px;
                }
            }

            label {
                position: absolute;
                height: 27px;
                line-height: 27px;
                z-index: 1;
                padding-left: 24px;
                font-size: 13px;
                font-weight: bold;
                font-family: Arial;
                background-size: auto 70% !important;
                background-position: 8px center;
                background-repeat: no-repeat;

                &[for="start"] {
                    background-image: url("../img/icons/map-marker-yellow.png");
                }

                &[for="end"] {
                    background-image: url("../img/icons/map-marker.png");
                }
            }

            .searchlocation {
                div {
                    width: calc(100% - 27px - 5px);
                }

                input {
                    padding-left: 60px;
                }

                .control-label {
                    top: 27px;
                    z-index: 2;
                    // width: calc(100% - 27px - 5px);
                    width: auto;
                }
            }

            .nearMe {
                @include respond-to(mobile) {
                    padding: 11.5px;
                    margin-left: 5px;
                    background-size: 60%;
                }
            }
        }

        #map {
            height: calc(70% - 111px - 10px);
            width: calc(100% - 20px);
            margin: 0 10px 10px;
            border: 2px solid $button-color;

            @media screen and (max-width: 1023px) and (orientation: landscape) {
                width: calc(60% - 10px);
                height: calc(100% - 111px - 10px);
                margin-right: 0;
                float: left;
            }

            .custom-iw, .custom-iw > div:first-child > div:last-child {
                width: auto;
                max-width: 200px !important;
            }

            .popin-infoWindow {
                padding: 10px 10px 0;

                .tier {
                    width: 100%;
                    flex-wrap: wrap;
                }

                .name {
                    span {
                        font-size: 18px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .contact-data {
                    font-size: 14px;
                }
            }
        }

        #directions {
            // height: calc(30% - 10px);
            width: calc(100% - 20px);
            margin: 0 10px 10px;
            overflow-y: auto;
            background: $white;
            position: absolute;
            top: calc(70% + 20px);
            bottom: 0;
            transition: top 0.25s ease-in-out;

            #showMore {
                display: none;
                margin-top: -20px;
                cursor: pointer;
                position: fixed;
                width: calc(100% - 20px);
                background: $white;
                border-bottom: 2px solid $color-12;

                span {
                    display: block;
                    margin: 0 auto;
                    width: 20px;
                    height: 20px;
                    background: url("../img/v-shape6.png") center center no-repeat;
                    transition: transform 0.25s ease-in-out;
                }
            }

            &.active {
                #showMore {
                    display: block;
                }
            }

            &.is-expanded {
                top: 131px;

                #showMore span {
                    transform: rotate(180deg);
                }
            }

            .adp-warnbox {
                display: none;
            }

            img.adp-marker {
                width: 26px;
            }

            @media screen and (max-width: 1023px) and (orientation: landscape) {
                height: calc(100% - 111px - 10px);
                width: calc(40% - 20px);
                float: right;
            }
        }
    }
}
