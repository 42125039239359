.menu-mobile{
    right: 0;
    top: 56px;
    z-index: 1;
    position: absolute;
    width: 271px;
    display: block;
    background-color: $white;
    box-shadow: inset 0 1px $bg-color-1;
    overflow: hidden;
    text-align: center;
    transition: all 0.25s ease-in-out;

    @include respond-to(mobile-lg){
        display: none;
    }

    @include respond-to(tablet){
        display: none;
    }

    &.collapsed{
        transform: translateY(-100%);
        visibility: hidden;
    }

    &.expanded {
        transform: translateY(0);
        visibility: visible;
    }

    .menu-mobile-item{
        width:90%;
        max-width: 360px;
        display: block;
        margin: 26px auto 0;
        text-align: center;
    }

    .title{
        font-size: 43px;
        color: $font-color-16;
        font-weight: $font-w-bold;
    }

    .fb-login{
        margin-bottom:-10px;
        width: 90%;
        padding: 15px 25px 15px 45px;
        position: relative;
        background-color: $button-color-fb;
        outline: none;
        border: none;
        box-shadow: 0 2px 0 $color-16;
        border-radius: 0;
        vertical-align: baseline;
        font-weight: $font-w-bold;
        color: $white;
        cursor: pointer;

        &:before{
            content: '';
            top:50%;
            left: 15px;
            position: absolute;
            height: 20px;
            width: 20px;
            transform: translateY(-50%);
            background: url('../img/icons/fb_logo_blue.svg') $white 3px 5px no-repeat;
            background-size: 90% 75%;
        }
    }

    p{
        &.menu-mobile-item {
            color: $font-color-16;
        }
    }


    input[type="email"], input[type="password"]{
        padding: 12px 0;
        border: 2px solid $font-color-16;
        letter-spacing: 1px;
        color: $font-color-16;
        font-weight: $font-w-bold;
        border-radius: 0;

        &:active, &:focus{
            border: 2px solid $font-color-16;
        }

        //EDIT ONLY MIXIN
        @mixin mobile-menu-input-placeholder(){
            color: $font-color-16;
            letter-spacing: 0;
            font-weight: $font-w-normal;
        }

        &::-webkit-input-placeholder {
          @include mobile-menu-input-placeholder;
        }

        &:-moz-placeholder { /* Firefox 18- */
          @include mobile-menu-input-placeholder;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
          @include mobile-menu-input-placeholder;
        }

        &:-ms-input-placeholder {
          @include mobile-menu-input-placeholder;
        }
    }

    .forgot{
        text-align: right;
        text-decoration: underline;
        color: $color-default-blue-link;
    }

    input[type="checkbox"]{
        display: none;
    }

    input[type="checkbox"]+label{
        position: relative;
        padding-left: 30px;
        text-align: left;
        cursor: pointer;

        &:before{
            content: '';
            left: 0;
            top: 30%;
            transform: translateY(-50%);
            position: absolute;
            height: 15px;
            width: 15px;
            background-color: $font-color-16;
        }
    }
    input[type="checkbox"]:checked+label{

        &:after{
            content:'';
            left: -3px;
            top: -7px;
            position: absolute;
            height: 25px;
            width: 25px;
            background-image: url('../img/icons/icon-green-tick.png');
            background-size: contain;
        }
    }

    button[type="submit"]{
        padding: 8px 0;
        border: none;
        outline: none;
        background-color: $font-color-16;
        color: $white;
        font-size: 30px;
        cursor: pointer;
    }

    .create-account{
        margin-bottom: 35px;

        p,a {

            width: 50%;
            display: inline-block;
        }
        p{
            text-align: left;
            vertical-align: middle;
            font-size: 13px;
        }
        a{
            margin-left: 5%;
            width: 45%;
            padding: 15px 5px;
            background-color: $color-16;
            color: $white;
            font-size: 13px;
        }
    }

    .control-label {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}