#page-club {
    padding-top: 30px;

    .club-wrap {
        //breakpoint for 2 columns
        $bp-2-col: 1024px;

        .event-date {
            font-size: 20px;
            font-weight: bold;
        }
        //768px
        .about-wrap {
            overflow: hidden;
            margin-bottom: 40px;

            @include respond-to(tablet) {
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    left: -2000px;
                    right: 50%;
                    top: 0;
                    bottom: 0;
                    background: $bg-color-1;
                }
            }
        }

        #table-tab-pricing {
            position: relative;
        }

        .showpopup {
            position: absolute;
            background: #e8e8e8;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 1;
            line-height: 20px;
            text-align: center;
            display: none;
            /*height: 100%;*/ //74px;
            padding: 10px;

            &.show {
                display: block;
            }

            .left {
                float: left;
                font-weight: 700;
                padding-left: 10px;
                cursor: pointer;
                border: 1px solid;
                padding: 10px;
                line-height: 10px;
                margin-top: 20px;
                width: 20%;
                clear: left;

                @include respond-to(tablet) {
                    width: auto;
                }
            }

            .right {
                cursor: pointer;
                border: 1px solid;
                padding: 10px;
                line-height: 10px;
                margin-top: 20px;
                width: 20%;
                clear: right;

                @include respond-to(tablet) {
                    width: auto;
                }
            }
        }

        .club-row {
            @include clearfix;

            &.club-row--basic-gallery {
                .club-col {
                    @include club-col($bp-2-col);
                }
            }

            &.club-row--table-features {
                margin-bottom: 40px;
            }

            &.club-row--about {
                padding-top: 25px;
                padding-bottom: 25px;
                background: $bg-color-1;
                //overflow: auto;
                @include respond-to(tablet) {
                    position: relative;

                    &:before {
                        position: absolute;
                        z-index: 1;
                        content: "";
                        left: 100%;
                        right: -316px;
                        transform: translateX(-100px);
                        top: 0;
                        bottom: 0;
                        background: url("../img/club-about-bg.jpg") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            .club-col {
                &.club-col--fixed-2 {
                    @include club-col($sc-tablet-min);
                }

                &.club-col--basic-contact {
                    margin-bottom: 22px;

                    @include breakpoint($bp-2-col) {
                        margin-bottom: 50px;
                    }


                    header {
                        position: relative;

                        @include respond-to(tablet) {
                            padding-right: 180px;
                        }


                        .basic-data {
                            $actionsWidth: 50px + 45px + 28px;
                            $actionsHeight: 41px;

                            position: relative;
                            margin-bottom: 20px;

                            @include respond-to(tablet) {
                                margin-bottom: 28px;
                            }


                            h1 {
                                padding-right: $actionsWidth + 20px;
                                margin-bottom: 18px;
                                font-weight: bold;
                                line-height: 1.3;
                                font-size: 32px;
                                // @include respond-to(mobile-sm) {
                                //   padding-right: 0;
                                // }
                                @include respond-to(tablet) {
                                    margin-bottom: 30px;
                                }
                            }

                            .rating {
                                @include clearfix;

                                @include respond-to(mobile-sm) {
                                    min-height: $actionsHeight;
                                }


                                span {
                                    @include sprite($layout-rating-star);
                                    float: left;
                                    margin-right: 4px;

                                    :last-child {
                                        margin-right: 0;
                                    }

                                    &.active {
                                        @include sprite($layout-rating-star-active);
                                    }
                                }
                            }

                            .actions {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: $actionsWidth;
                                // @include respond-to(mobile-sm) {
                                //   top: auto;
                                //   bottom: 0;
                                // }
                                a {
                                    float: right;
                                    margin-left: 27px;

                                    &:last-child {
                                        margin-left: 0;
                                    }

                                    &.action-like {
                                        @include sprite($club-action-like);

                                        &.active {
                                            @include sprite($club-action-like-hover);

                                            &:hover {
                                                @include sprite($club-action-like);

                                                @media screen and (max-width: 545px) {
                                                    @include sprite($club-action-like-hover);
                                                }
                                            }
                                        }

                                        &:hover {
                                            @include sprite($club-action-like-hover);

                                            @media screen and (max-width: 545px) {
                                                @include sprite($club-action-like);
                                            }
                                        }
                                    }

                                    &.action-email {
                                        margin-top: 2px;

                                        @include sprite($club-action-email);

                                        &:hover {
                                            @include sprite($club-action-email-hover);
                                        }

                                        > * {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .logo-directions {
                            @include clearfix;
                            position: relative;
                            margin-bottom: 25px;

                            @include respond-to(tablet) {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 180px;
                                padding-left: 10px;
                                margin-bottom: 0;
                                text-align: center;
                            }


                            .logo {
                                @include respond-to(mobile-ex) {
                                    display: flex;
                                    width: 100%;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                }


                                .logo-img {
                                    vertical-align: middle;

                                    @include respond-to(tablet) {
                                        margin-bottom: 11px;
                                    }


                                    + .logo-img {
                                        @include respond-to(mobile-ex) {
                                            text-align: right;
                                        }


                                        @include respond-to(tablet) {
                                            margin-bottom: 15px;
                                        }


                                        @include respond-to(desktop) {
                                            margin-bottom: 25px;
                                        }
                                    }

                                    img {
                                        width: auto;
                                        max-width: 100%;
                                        height: auto;
                                        max-height: 120px;
                                    }

                                    &.logo-img--club {
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                        background-size: contain;
                                        //100%;
                                        height: 0;
                                        width: 120px;
                                        padding-bottom: 120px;

                                        @include respond-to(tablet) {
                                            width: 100%;
                                            padding-bottom: 75%;
                                        }


                                        &.empty {
                                            background-size: auto 100%;
                                            background-color: #008ece;
                                            background-image: url("../img/club-logo-placeholder.jpg");
                                        }
                                    }

                                    &.logo-img--badge {
                                        @include respond-to(mobile-ex) {
                                            width: 100px;
                                        }


                                        img {
                                            cursor: help;

                                            @include respond-to(tablet) {
                                                max-width: 50%;
                                            }
                                        }

                                        .disclaimer {
                                            border: 2px solid $color-12;
                                            padding: 10px;
                                            text-align: center;
                                            background: $white;
                                            position: absolute;
                                            left: 0;
                                            right: 0;
                                            z-index: 1;
                                            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
                                            visibility: hidden;
                                            opacity: 0;
                                            transition: all 0.25s ease-in-out;

                                            @include respond-to(tablet) {
                                                left: -150px;
                                                right: 0;
                                            }


                                            @include respond-to(desktop) {
                                                left: -75px;
                                                right: -75px;
                                            }


                                            strong {
                                                display: block;
                                                margin-bottom: 5px;
                                            }
                                        }

                                        &.active {
                                            .disclaimer {
                                                visibility: visible;
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }

                            .directions-link {
                                cursor: pointer;
                                position: relative;
                                display: inline-block;
                                height: 35px;
                                line-height: 35px;
                                padding: 0 16px 0 36px;
                                margin-top: 4px;
                                border: 2px solid $button-color;
                                transition: all $trans-duration;
                                color: $button-color;
                                font-weight: bold;
                                font-size: 13px;
                                text-transform: uppercase;
                                white-space: nowrap;

                                @include respond-to(mobile-sm) {
                                    margin-top: 20px;
                                }


                                @include respond-to(mobile-lg) {
                                    position: absolute;
                                    bottom: -61px;
                                    right: 0;
                                }


                                @include respond-to(tablet) {
                                    width: 100%;
                                }


                                &:hover {
                                    color: #fff;
                                    background: $button-color;

                                    &:before {
                                        @include sprite($club-directions-link-hover);
                                    }
                                }

                                &:before {
                                    position: absolute;
                                    content: "";
                                    left: 12px;
                                    top: 7px;

                                    @include sprite($club-directions-link);
                                }
                            }


                            .club-view-social-icons {
                                width: 100%;
                                justify-content: space-between;
                                margin-top: 15px;

                                & .icons {
                                    width: 29px;
                                    height: 29px;
                                    /*margin-right: 30px;*/
                                }
                            }

                            .friends-favourites {
                                padding-top: 10px;
                                position: relative;
                                left: unset;

                                @include respond-to(tablet) {
                                    position: absolute;
                                    left: -20px;
                                }

                                img {
                                    font-size: 13px;
                                    position: relative;
                                    width: 22px;
                                    top: unset;
                                    float: left;
                                }

                                span {
                                    position: relative;
                                    top: 0;
                                    left: 7px;
                                    text-align: left;
                                    display: inline-block;
                                    float: left;
                                    max-width: 175px;
                                }
                            }
                        }
                    }

                    .contact-data {
                        @include respond-to(mobile-lg) {
                            padding-right: 190px;
                        }


                        @include respond-to(tablet) {
                            padding-right: 180px;
                        }


                        .separator {
                            margin-left: -3px;
                        }

                        p {
                            margin-bottom: 15px;
                            line-height: 1.3;

                            a:not([href^=tel]) {
                                color: #1b5dbd;
                                text-decoration: underline;
                                font-weight: $font-w-light;
                                word-wrap: break-word;
                            }

                            a[href^=tel] {
                                color: inherit;
                            }

                            strong {
                                font-weight: $font-w-semibold;
                            }
                        }

                        .open-hours {
                            line-height: 1.3;
                            padding: 0;

                            .open-hours-header {
                                font-weight: bold;
                                font-size: 18px;
                            }

                            .open-hours-day {
                                font-weight: bold;
                                width: 95px;
                                display: inline-block;
                            }

                            .open-hours-date {
                                padding-top: 5px;

                                &.today {
                                    font-weight: bold;
                                    color: #08469d;
                                }
                            }
                        }
                    }
                }

                &.club-col--gallery {
                    max-width: 767px;
                    margin: 0 auto 40px;

                    @include breakpoint($bp-2-col) {
                        margin-bottom: 50px;
                    }


                    .gallery-wrap {
                        position: relative;

                        &:before {
                            position: relative;
                            display: block;
                            content: "";
                            padding-top: unit-pc(297, 526);
                        }

                        .gallery-slider {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            overflow: hidden;

                            .gallery-item, .slick-list, .slick-track {
                                width: 100%;
                                height: 100%;
                            }

                            .gallery-item {
                                background-position: center center;
                                background-size: contain;
                                background-repeat: no-repeat;

                                &.gallery-item--default {
                                    background-size: 100% 100%;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                        .nav {
                            position: absolute;
                            top: 50%;
                            margin-top: -11px;
                            width: 22px;
                            height: 22px;
                            border: 2px solid rgba(255, 255, 255, 0.6);
                            box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
                            background: #e8e8e8;

                            &.nav-prev {
                                left: 22px;

                                @include respond-to(tablet) {
                                    left: 18px;
                                }


                                &:before {
                                    left: 50%;
                                    margin-left: -4px;
                                }
                            }

                            &.nav-next {
                                right: 22px;

                                @include respond-to(tablet) {
                                    right: 18px;
                                }


                                &:before {
                                    left: 50%;
                                    margin-left: -3px;
                                    transform: rotateZ(180deg);
                                }
                            }

                            &:before {
                                @include sprite($club-gallery-nav);
                                position: absolute;
                                content: "";
                                top: 50%;
                                margin-top: -6px;
                            }
                        }
                    }
                }

                &.club-col--table {
                    @include respond-to(mobile-ex) {
                        margin-bottom: 40px;
                    }


                    .table-wrap {
                        header {
                            @include clearfix;
                            overflow: hidden;
                            position: relative;

                            ul {
                                /*min-width: 500px;

                                @media (max-width: 767px){
                                    min-width: unset;

                                }*/
                                li {
                                    float: unset;
                                    /*&:nth-child(2) {
                                        display: none;
                                    }*/
                                    /*&:nth-child(2) {
                                        display: none;
                                    }*/
                                    a {
                                        display: inline-block;
                                        width: auto;
                                        height: 42px;
                                        line-height: 43px;
                                        padding: 0 15px;
                                        background: url("../img/club-table-button.png") no-repeat;
                                        background-size: 100% 42px;
                                        color: #fff;
                                        font-weight: bold;
                                        text-transform: uppercase;
                                        white-space: nowrap;

                                        @include respond-to(tablet) {
                                            padding: 0 25px;
                                        }


                                        &.active {
                                            background: url("../img/club-table-button-active.png") no-repeat;
                                            background-size: 100% 42px;
                                            width: 100%;
                                            text-align: center;

                                            @media (max-width: 545px) {
                                                font-size: 13px;
                                            }
                                        }

                                        &.schedule-tab {
                                            background: unset;
                                            background-color: #f2b728;
                                            border-left: 2px solid #e9a500;
                                            border-bottom: 2px solid #e9a500;
                                            border-right: 2px solid #e9a500;
                                            border-top: 2px solid #f4c44f;
                                        }

                                        &.schedule-tab.active {
                                            background: unset;
                                            background-color: #e9a500;
                                            border-right: 2px solid #d39706;
                                            border-top: 2px solid #d39706;
                                            border-left: 2px solid #d39706;
                                            border-bottom: 2px solid #f4c44f;
                                        }
                                    }
                                }
                            }
                        }

                        .schedule-header {
                            background-color: #f2b728;
                            border-left: 2px solid #e9a500;
                            border-bottom: 2px solid #e9a500;
                            border-right: 2px solid #e9a500;
                            border-top: 2px solid #f4c44f;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            text-align: center;
                            color: #fff;
                            font-weight: bold;
                            font-size: 16px;
                            text-transform: uppercase;
                            /*margin-top: 20px;*/
                        }

                        .no-classes {
                            background-color: #e8e8e8;
                            text-align: center;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            font-weight: bold;
                        }

                        .date-tab-selector {
                            /*margin-top: 45px;*/
                            padding-top: 15px;
                            text-align: center;
                            padding-bottom: 15px;
                            font-weight: bold;
                            background-color: #8d8d8d;
                            color: #fff;
                            position: relative;

                            .date-arrow {
                                padding-left: 12px;
                                padding-right: 12px;
                                font-size: 30px;
                                vertical-align: sub;
                                cursor: pointer;
                            }

                            .date-arrow:hover {
                                color: #f2b728;
                            }

                            .today-button {
                                position: absolute;
                                right: 25px;
                                top: 10px;
                                border: 2px solid;
                                padding: 10px 15px;
                                cursor: pointer;

                                @media (max-width: 1023px) {
                                    position: relative;
                                    top: unset;
                                    right: unset;
                                    display: block;
                                    margin-left: 15px;
                                    margin-right: 15px;
                                }
                            }

                            .today-button:hover {
                                background-color: #fff;
                                color: #8d8d8d;
                            }
                        }

                        .table-content {

                            .date-tab {
                                display: none;

                                &.active {
                                    display: block;
                                }
                            }

                            .table-tab {
                                display: none;

                                &.active {
                                    display: block;

                                    &#table-tab-schedule {
                                        margin-top: -45px;

                                        @media (min-width: 365px) and (max-width: 1023px) {
                                            margin-top: -41px;
                                        }
                                    }
                                }

                                .classes-show-more, .class-schedule-link a, .back-to-top {
                                    height: 35px;
                                    line-height: 35px;
                                    padding: 0 28px;
                                    border: 2px solid #145ac0;
                                    transition: all 0.3s;
                                    color: #145ac0;
                                    font-weight: bold;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                    white-space: nowrap;
                                    max-width: 132px;
                                    margin: 0 auto;
                                    text-align: center;
                                    width: 100%;
                                    cursor: pointer;
                                }

                                .classes-show-more:hover, .class-schedule-link a:hover, .back-to-top:hover {
                                    color: #fff;
                                    background: #145ac0;
                                }

                                .back-to-top {
                                    margin: unset;
                                    max-width: 141px;
                                    margin-top: 10px;
                                }

                                .class-schedule-link a {
                                    max-width: 275px;
                                    margin-top: 15px;
                                    display: block;
                                }

                                .class-schedule-link.only-link {
                                    margin-top: 45px;
                                    background-color: #e8e8e8;
                                    padding-top: 5px;
                                    padding-bottom: 20px;
                                }

                                .date-filter {
                                    margin-bottom: 0px;
                                    text-align: right;
                                    white-space: nowrap;
                                    float: right;
                                    margin-top: 7px;

                                    @media (min-width: 320px) and (max-width: 1023px) {
                                        margin-top: 3px;
                                        margin-top: 50px;
                                        float: left;
                                    }

                                    @include respond-to(mobile-ex) {
                                        margin-top: 3px;
                                        margin-top: 50px;
                                        float: left;
                                    }


                                    a {
                                        position: relative;
                                        display: inline-block;
                                        height: 35px;
                                        line-height: 35px;
                                        padding: 0 10px;
                                        border: 2px solid #427acc;
                                        transition: all $trans-duration;
                                        background: $button-color;
                                        vertical-align: top;
                                        color: #fff;
                                        font-weight: bold;
                                        font-size: 13px;
                                        text-transform: uppercase;
                                        white-space: nowrap;

                                        @include respond-to(tablet) {
                                            padding: 0 20px;
                                        }


                                        &:hover {
                                            opacity: 0.9;
                                        }

                                        &.today {
                                            margin-right: 5px;
                                        }

                                        &.datepicker {
                                            width: 128px;
                                            text-align: center;
                                            /*padding-left: 0;*/
                                            /*padding-right: 11px;*/
                                            /*&:before {
                                                position: absolute;
                                                content: "";

                                                @include sprite($club-table-tab-date);

                                                background-position: -45px -64px;
                                                width: 57px;

                                                top: 50%;
                                                margin-top: -10px;
                                                left: 50%;
                                                margin-left: -51px;

                                            }*/
                                            @include respond-to(tablet) {
                                                /*padding: 0 20px;*/
                                            }

                                            .date-text {
                                            }
                                        }

                                        &.active {
                                            background: #0a459d;
                                        }
                                    }
                                }

                                table {
                                    $layoutWidth: 580px;

                                    width: 100%;
                                    //table-layout: fixed;
                                    tr {
                                        &:nth-child(odd) {
                                            td {
                                                background: $bg-color-1;
                                            }
                                        }

                                        td {
                                            padding: 20px 10px 19px 10px;
                                            vertical-align: middle;
                                            font-size: 18px;
                                            font-weight: $font-w-semibold;
                                            line-height: 1.3;

                                            &.option-name {
                                                // width: 57%;
                                                width: 99%;
                                                padding-left: 20px;

                                                time {
                                                    display: block;
                                                    font-size: 13px;
                                                    font-weight: bold;

                                                    .date {
                                                        @include respond-to(mobile-sm) {
                                                            display: block;
                                                        }
                                                    }

                                                    .time-label {
                                                        display: inline-block;
                                                        margin-left: 5px;
                                                        font-weight: $font-w-semibold;
                                                        text-transform: uppercase;

                                                        @include respond-to(mobile-sm) {
                                                            margin-left: 0;
                                                        }
                                                    }
                                                }

                                                .option-label {
                                                    padding-top: 2px;
                                                    padding-bottom: 2px;
                                                }

                                                .option-instructor {
                                                    font-size: 15px;
                                                    font-weight: normal;
                                                    padding-bottom: 2px;
                                                }

                                                .option-date {
                                                    font-size: 12px;
                                                    font-weight: normal;
                                                }
                                            }

                                            &.price {
                                                // width: 20%;
                                                font-weight: bold;
                                                white-space: nowrap;

                                                @include respond-to(tablet) {
                                                    min-width: 120px;
                                                }
                                            }

                                            &.book {
                                                // width: 23%;
                                                text-align: center;
                                                cursor: pointer;

                                                span, a {
                                                    display: inline-block;
                                                    height: 35px;
                                                    line-height: 35px;
                                                    padding: 0 28px;
                                                    border: 2px solid $button-color;
                                                    transition: all $trans-duration;
                                                    color: $button-color;
                                                    font-weight: bold;
                                                    font-size: 13px;
                                                    text-transform: uppercase;
                                                    white-space: nowrap;

                                                    &:hover {
                                                        color: #fff;
                                                        background: $button-color;
                                                    }
                                                }
                                            }

                                            &.book-column {
                                                text-align: center;
                                                min-width: 117px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.club-col--features {
                    @mixin media-3-col-helper {
                        @media (min-width: $sc-mobile-lg-min) and (max-width: $sc-tablet-min - 1), (min-width: $sc-tablet-lg-min) {
                            @content;
                        }
                    }
                    // @include respond-to(mobile-ex) {
                    //   padding-left: unit-pc(7px);
                    //   padding-right: unit-pc(7px);
                    // }
                    @include media-3-col-helper {
                        overflow: hidden;
                    }


                    h2 {
                        margin-bottom: 25px;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.3;
                    }

                    .list-wrap {
                        @include respond-to(tablet) {
                            margin-left: -14px;
                            margin-right: -14px;
                        }


                        ul {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            width: 100%;

                            @include respond-to(tablet) {
                                padding: 0 14px;
                            }


                            li {
                                position: relative;
                                padding-left: 12px;
                                margin-bottom: 15px;
                                width: 50%;

                                @include respond-to(tablet) {
                                    width: 33.33%;
                                }

                                font-size: 16px;

                                &:before {
                                    position: absolute;
                                    content: "";
                                    left: 0px;
                                    top: 5px;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 2px;
                                    background: #c5c5c5;
                                }
                            }
                        }
                    }
                }

                &.club-col--about {
                    &.club-col--about-1 {
                        @include respond-to(mobile-ex) {
                            position: relative;
                            margin-bottom: 28px;
                            padding-bottom: 18px;

                            &:before {
                                position: absolute;
                                content: "";
                                height: 5px;
                                bottom: -2px;
                                left: unit-pc(22px);
                                right: unit-pc(22px);
                                background: url("../img/club-about-separator.png") repeat-x;
                            }
                        }
                    }

                    &.club-col--about-2 {
                        position: relative;
                        z-index: 21;
                    }
                }
            }
        }
    }

    .direction-map {
        background: $white;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 0.25s ease-in-out, z-index 0s ease-in-out 0.4s;

        body[data-overlayactive="true"] & {
            z-index: 1;
            transition: z-index 0s 0s;
        }

        &.in-progress {
            &:before {
                content: "";
                background: rgba(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 4;
            }

            &:after {
                content: "";

                @include preloader(15vw, 15vw, 2px, absolute, $color-6, $color-12);
                margin: auto;
                z-index: 5;

                @include respond-to(tablet) {
                    width: 5vw;
                    height: 5vw;
                }
            }
        }

        &.active {
            transform: translateX(0);

            &.fixed {
                // position: fixed;
                top: 0;
            }
        }

        @include respond-to(desktop) {
            // position: absolute;
            top: 74px;
            left: 50%;
        }


        .map-controls {
            position: relative;
            top: 0;
            right: inherit;
            padding: 10px;
            flex-direction: column;
        }

        #direction-close {
            width: 27px;
            height: 27px;
            background: $color-12 url("../img/icons/ex.png") center center no-repeat;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
        }

        .tier {
            order: 2;
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
        }

        #direction-mode {
            border: 2px solid $button-color;
            background: $white;
            color: $button-color;
            height: 27px;
            position: relative;
            width: 90px;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $button-color;
                position: absolute;
                top: 7.5px;
                right: 6px;
            }

            @include respond-to(tablet) {
                width: calc(50% - 5px);
            }


            @include respond-to(desktop) {
                width: calc(35% - 5px);
            }


            select {
                font-family: Arial;
                font-size: 13px;
                color: $button-color;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                outline: 0;
                padding-left: 8px;
                padding-right: 20px;
                border-radius: 0;
                background: transparent;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';

                option {
                    font-weight: bold;
                }

                &:focus {
                    outline: 0;
                }
            }

            select::-ms-expand {
                display: none;
            }
        }

        .direction-info {
            background: $white;
            border: 2px solid $button-color;
            padding: 0 8px;
            font-size: 13px;
            font-weight: bold;
            font-family: Arial;
            line-height: 23px;
            display: flex;
            width: calc(100% - 90px - 5px);
            justify-content: space-between;
            white-space: nowrap;
            overflow: hidden;

            span {
                color: $button-color;
            }

            @include respond-to(tablet) {
                width: 50%;
            }


            @include respond-to(desktop) {
                width: 65%;
            }
        }

        #direction-input {
            order: 1;

            > div {
                position: relative;

                + div {
                    margin-top: 5px;
                }
            }

            label {
                position: absolute;
                height: 27px;
                line-height: 27px;
                z-index: 1;
                padding-left: 24px;
                font-size: 13px;
                font-weight: bold;
                font-family: Arial;
                background-size: auto 70% !important;
                background-position: 8px center;
                background-repeat: no-repeat;

                &[for="start"] {
                    background-image: url("../img/icons/map-marker-yellow.png");
                }

                &[for="end"] {
                    background-image: url("../img/icons/map-marker.png");
                }
            }

            .searchlocation {
                div {
                    width: calc(100% - 27px - 5px);
                }

                input {
                    padding-left: 60px;
                }

                .control-label {
                    top: 27px;
                    z-index: 2;
                    // width: calc(100% - 27px - 5px);
                    width: auto;
                }
            }

            .nearMe {
                @include respond-to(mobile) {
                    padding: 11.5px;
                    margin-left: 5px;
                    background-size: 60%;
                }
            }
        }

        #map {
            height: calc(70% - 111px - 10px);
            width: calc(100% - 20px);
            margin: 0 10px 10px;
            border: 2px solid $button-color;

            @media screen and (max-width: 1023px) and (orientation: landscape) {
                width: calc(60% - 10px);
                height: calc(100% - 111px - 10px);
                margin-right: 0;
                float: left;
            }

            .custom-iw, .custom-iw > div:first-child > div:last-child {
                width: auto;
                max-width: 200px !important;
            }

            .popin-infoWindow {
                padding: 10px 10px 0;

                .tier {
                    width: 100%;
                    flex-wrap: wrap;
                }

                .name {
                    span {
                        font-size: 18px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .contact-data {
                    font-size: 14px;
                }
            }
        }

        #directions {
            // height: calc(30% - 10px);
            width: calc(100% - 20px);
            margin: 0 10px 10px;
            overflow-y: auto;
            background: $white;
            position: absolute;
            top: calc(70% + 20px);
            bottom: 0;
            transition: top 0.25s ease-in-out;

            #showMore {
                display: none;
                margin-top: -20px;
                cursor: pointer;
                position: fixed;
                width: calc(100% - 20px);
                background: $white;
                border-bottom: 2px solid $color-12;

                span {
                    display: block;
                    margin: 0 auto;
                    width: 20px;
                    height: 20px;
                    background: url("../img/v-shape6.png") center center no-repeat;
                    transition: transform 0.25s ease-in-out;
                }
            }

            &.active {
                #showMore {
                    display: block;
                }
            }

            &.is-expanded {
                top: 131px;

                #showMore span {
                    transform: rotate(180deg);
                }
            }

            .adp-warnbox {
                display: none;
            }

            img.adp-marker {
                width: 26px;
            }

            @media screen and (max-width: 1023px) and (orientation: landscape) {
                height: calc(100% - 111px - 10px);
                width: calc(40% - 20px);
                float: right;
            }
        }
    }
}
