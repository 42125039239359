.toggle-box {
    > :first-child {
        cursor: pointer;

        @include respond-to(tablet) {
            cursor: default;
        }
    }

    .toggle-icon {
        display: inline-block;
        float: right;
        transition: all  0.4s;

        &.rotated {
            transform: rotate(90deg);
            transition: all  0.4s;
        }

        @include respond-to(tablet) {
            display: none;
        }
    }
}