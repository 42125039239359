// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$club-action-email-hover-name: 'club-action-email-hover';
$club-action-email-hover-x: 154px;
$club-action-email-hover-y: 170px;
$club-action-email-hover-offset-x: -154px;
$club-action-email-hover-offset-y: -170px;
$club-action-email-hover-width: 50px;
$club-action-email-hover-height: 39px;
$club-action-email-hover-total-width: 254px;
$club-action-email-hover-total-height: 248px;
$club-action-email-hover-image: '../img/club.png';
$club-action-email-hover: (154px, 170px, -154px, -170px, 50px, 39px, 254px, 248px, '../img/club.png', 'club-action-email-hover', );
$club-action-email-name: 'club-action-email';
$club-action-email-x: 204px;
$club-action-email-y: 209px;
$club-action-email-offset-x: -204px;
$club-action-email-offset-y: -209px;
$club-action-email-width: 50px;
$club-action-email-height: 39px;
$club-action-email-total-width: 254px;
$club-action-email-total-height: 248px;
$club-action-email-image: '../img/club.png';
$club-action-email: (204px, 209px, -204px, -209px, 50px, 39px, 254px, 248px, '../img/club.png', 'club-action-email', );
$club-action-like-hover-name: 'club-action-like-hover';
$club-action-like-hover-x: 62px;
$club-action-like-hover-y: 84px;
$club-action-like-hover-offset-x: -62px;
$club-action-like-hover-offset-y: -84px;
$club-action-like-hover-width: 46px;
$club-action-like-hover-height: 43px;
$club-action-like-hover-total-width: 254px;
$club-action-like-hover-total-height: 248px;
$club-action-like-hover-image: '../img/club.png';
$club-action-like-hover: (62px, 84px, -62px, -84px, 46px, 43px, 254px, 248px, '../img/club.png', 'club-action-like-hover', );
$club-action-like-name: 'club-action-like';
$club-action-like-x: 108px;
$club-action-like-y: 127px;
$club-action-like-offset-x: -108px;
$club-action-like-offset-y: -127px;
$club-action-like-width: 46px;
$club-action-like-height: 43px;
$club-action-like-total-width: 254px;
$club-action-like-total-height: 248px;
$club-action-like-image: '../img/club.png';
$club-action-like: (108px, 127px, -108px, -127px, 46px, 43px, 254px, 248px, '../img/club.png', 'club-action-like', );
$club-directions-link-hover-name: 'club-directions-link-hover';
$club-directions-link-hover-x: 17px;
$club-directions-link-hover-y: 28px;
$club-directions-link-hover-offset-x: -17px;
$club-directions-link-hover-offset-y: -28px;
$club-directions-link-hover-width: 14px;
$club-directions-link-hover-height: 18px;
$club-directions-link-hover-total-width: 254px;
$club-directions-link-hover-total-height: 248px;
$club-directions-link-hover-image: '../img/club.png';
$club-directions-link-hover: (17px, 28px, -17px, -28px, 14px, 18px, 254px, 248px, '../img/club.png', 'club-directions-link-hover', );
$club-directions-link-name: 'club-directions-link';
$club-directions-link-x: 31px;
$club-directions-link-y: 46px;
$club-directions-link-offset-x: -31px;
$club-directions-link-offset-y: -46px;
$club-directions-link-width: 14px;
$club-directions-link-height: 18px;
$club-directions-link-total-width: 254px;
$club-directions-link-total-height: 248px;
$club-directions-link-image: '../img/club.png';
$club-directions-link: (31px, 46px, -31px, -46px, 14px, 18px, 254px, 248px, '../img/club.png', 'club-directions-link', );
$club-gallery-nav-name: 'club-gallery-nav';
$club-gallery-nav-x: 10px;
$club-gallery-nav-y: 16px;
$club-gallery-nav-offset-x: -10px;
$club-gallery-nav-offset-y: -16px;
$club-gallery-nav-width: 7px;
$club-gallery-nav-height: 12px;
$club-gallery-nav-total-width: 254px;
$club-gallery-nav-total-height: 248px;
$club-gallery-nav-image: '../img/club.png';
$club-gallery-nav: (10px, 16px, -10px, -16px, 7px, 12px, 254px, 248px, '../img/club.png', 'club-gallery-nav', );
$club-link-back-hover-name: 'club-link-back-hover';
$club-link-back-hover-x: 0px;
$club-link-back-hover-y: 0px;
$club-link-back-hover-offset-x: 0px;
$club-link-back-hover-offset-y: 0px;
$club-link-back-hover-width: 5px;
$club-link-back-hover-height: 8px;
$club-link-back-hover-total-width: 254px;
$club-link-back-hover-total-height: 248px;
$club-link-back-hover-image: '../img/club.png';
$club-link-back-hover: (0px, 0px, 0px, 0px, 5px, 8px, 254px, 248px, '../img/club.png', 'club-link-back-hover', );
$club-link-back-name: 'club-link-back';
$club-link-back-x: 5px;
$club-link-back-y: 8px;
$club-link-back-offset-x: -5px;
$club-link-back-offset-y: -8px;
$club-link-back-width: 5px;
$club-link-back-height: 8px;
$club-link-back-total-width: 254px;
$club-link-back-total-height: 248px;
$club-link-back-image: '../img/club.png';
$club-link-back: (5px, 8px, -5px, -8px, 5px, 8px, 254px, 248px, '../img/club.png', 'club-link-back', );
$club-table-tab-date-name: 'club-table-tab-date';
$club-table-tab-date-x: 45px;
$club-table-tab-date-y: 64px;
$club-table-tab-date-offset-x: -45px;
$club-table-tab-date-offset-y: -64px;
$club-table-tab-date-width: 17px;
$club-table-tab-date-height: 20px;
$club-table-tab-date-total-width: 254px;
$club-table-tab-date-total-height: 248px;
$club-table-tab-date-image: '../img/club.png';
$club-table-tab-date: (45px, 64px, -45px, -64px, 17px, 20px, 254px, 248px, '../img/club.png', 'club-table-tab-date', );
$club-spritesheet-width: 254px;
$club-spritesheet-height: 248px;
$club-spritesheet-image: '../img/club.png';
$club-spritesheet-sprites: ($club-action-email-hover, $club-action-email, $club-action-like-hover, $club-action-like, $club-directions-link-hover, $club-directions-link, $club-gallery-nav, $club-link-back-hover, $club-link-back, $club-table-tab-date, );
$club-spritesheet: (254px, 248px, '../img/club.png', $club-spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
