#page-login {
    padding-bottom: 60px;
    
    .inner-wrapper {
        padding-top: 80px;
        width: 80%;
        margin: 0 auto;

        @include respond-to(tablet) {
            width: 32%;
        }
        label.control-label.has-error.validationMessage:before{
        display:none;
     }
    }
}

body.body-login-page {
    background: url("../img/backgrounds/1.png") no-repeat;
    background-size: cover;
    background-position: center center;
}