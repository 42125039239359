.all-business {
    h1 {
        @include fonts-vw(ProximaNova, 20px, 24px);
        color: $black;
        font-weight: 700;
        margin-top: 40px;
        text-align: center;

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    .title {
        @include fonts-vw(ProximaNova, 24px, 24px);
        color: $black;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .subtitle {
        @include fonts-vw(ProximaNova, 16px, 16px);
        width: 100%;
        text-align: center;
        color: $black;
        font-weight: 700;
        margin-top: 25px;
    }

    .bottomnav {
        @include clearfix;
        margin-top: 30px;
        margin-bottom: 70px;
        position: relative;

        .continue {
            width: 120px;
            padding: 14px 12px;
            text-align: center;

            @include respond-to(mobile-sm) {
                margin-left: 4px;
                padding: 14px 14px;
            }

            @include respond-to(tablet) {
                width: 160px;
            }

            @include respond-to(desktop) {
                width: 140px;
            }
        }

        .continue.next {
            position: absolute;
            top: 100%;
            right: 0;

            @include respond-to(tablet) {
                position: relative;
                top: 0;
            }
        }
    }

    .mobile-info {
        padding: 0 10%;
        padding-bottom: 20%;

        @include clearfix;

        @include respond-to(tablet) {
            display: none;
        }


        &.mobile-shown {
            display: none;
        }

        h1 {
            text-align: justify;
        }

        h2 {
            margin: 2vw 0;
            text-align: justify;
        }

        .continue {
            margin: 0 auto;
            float: none;
            display: inline-block;
            /* width: auto; */
            left: 50%;
            position: relative;
            transform: translateX(-50%);
            margin-top: 4vw;
        }
    }

    section.mobile-hidden {
        &:not(.mobile-shown) {
            display: none;

            @include respond-to(tablet) {
                display: block;
            }
        }
    }

    .continue {
        cursor: pointer;
        padding: 14px 27px;
        border: none;
        background: $color-23;
        color: $white;
        text-transform: uppercase;

        @include fonts-vw(ProximaNova, 13.5px, 13.5px);
        font-weight: 700;
        margin-left: 15px;
        margin-top: 10px;
        display: block;
        float: right;

        @include respond-to(tablet) {
            margin-top: 0;
        }
        // @include respond-to(tablet) {
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        // }
        &.back {
            margin-left: 0;
            margin-right: 15px;
            line-height: 1.2;
            float: left;
        }
    }

    textarea {
        width: 100%;
        padding: 12px;
        color: $color-32;
        background: $color-30;
        border: 2px solid $color-29;

        @include fonts-vw(ProximaNova, 13px, 13.3px);
        font-weight: 600;
    }

    input[type="email"], input[type="password"], input[type=text], input, select {
        background: $color-30;
        border: 2px solid $color-29;
        color: $color-32;

        @include fonts-vw(ProximaNova, 13px, 13.3px);
        font-weight: 600;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    select {
        appearance: none;
        -moz-appearance: none;
        border-radius: 0;
        /* Firefox */
        -webkit-appearance: none;
        position: relative;
        background: url("../img/icons/ico-arrow-down.png") no-repeat $color-30;
        background-position: 93%;
    }

    select::-ms-expand {
        display: none;
    }

    .comment {
        margin-top: 20px;

        @include fonts-vw(ProximaNova, 13.5px, 13.5px);
        font-weight: 400;
        float: right;
    }

    .explanation {
        color: $color-32;
        font-weight: 400;
        float: left;
        width: 100%;
        font-style: italic;
        padding-top: 2px;

        @include fonts-vw(ProximaNova, 12px, 12px);

        &.middle {
            text-align: center;
            padding-bottom: 5px;
        }
    }



    ::-webkit-input-placeholder {
        font-family: ProximaNova;
        font-weight: 400;
        font-size: 16px;
        color: $color-32;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        font-family: ProximaNova;
        font-weight: 400;
        font-size: 16px;
        color: $color-32;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: ProximaNova;
        font-weight: 400;
        font-size: 16px;
        color: $color-32;
    }

    :-ms-input-placeholder {
        font-family: ProximaNova;
        font-weight: 400;
        font-size: 16px;
        color: $color-32;
    }

    &.in-progress {
        @extend .in-progress-profile-component;
    }

    label.control-label.has-error.validationMessage,
    label.control-label.has-error-backend {
        position: relative;
        right: 0;
        width: 100%;
        text-align: left;
        float: left;
    }

    .terms {
        position: relative;
    }

    .terms label.control-label.has-error.validationMessage {
        position: absolute;
        bottom: 0;

        &:before {
            display: none;
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
