﻿.rally-header {
    background-color: #012351;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    position: relative;
    z-index: 5;
    border-bottom: 1px solid #fff;
}

.rally-welcome {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 49px;
    width: 80%;
    text-align: center;
    /*min-width: 695px;*/
    /*max-width: 55%;*/
    background: url(../img/shadows-stripes/block-background-180.png) no-repeat;
    background-size: auto 100%;
    background-position: top right;
    padding-bottom: 6.45833vw;
    padding-top: 10.83333vw;
    margin: 0;

    @media (max-width: 1360px) {
        right: -100px;
        font-size: 40px
    }

    @media (max-width: 1210px) {
        right: -200px;
        font-size: 35px;
    }

    @media (max-width: 1024px) {
        width: 100%;
        padding: unset;
        background: unset;
        position: relative;
        font-size: 32px;
        padding-top:60px;
        padding-bottom: 85px;
        right: 0;
    }

    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        left: 100%;
        position: absolute;
        background: rgba(23, 99, 204, 0.4);
        top: 0;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    .welcome-rally-text {
        width: 50%;
        float: right;

        @media (max-width: 1360px) {
            margin-right: 75px;
        }

        @media (max-width: 1210px) {
            margin-right: 175px;
        }

        @media (max-width: 1024px) {
            margin-right: unset;
            float: unset;
            width: 100%;
        }
    }
}

.rally-promo-message {
    color: #de0000;
}
