#page-businessactivities {
    @extend .all-business;

    .main-section {
        .wrapper {
            max-width: 920px;
            padding-left: 15px;
            padding-right: 15px;
        }
        .title{
        @include fonts-vw(ProximaNova, 18px, 24px);
         }
    }

    .description {
        @include fonts-vw(ProximaNova, 14px, 14px);

        color: $black;
        font-weight: 400;
        margin-bottom: 25px;
    }

    .columns {
        @include clearfix;

        margin-bottom: 42px;

        .column {
            width: 24%;
            float: left;
            text-align: center;
            color: $black;
            background: #d3d3d3;
            margin-right: 1%;
            padding: 0 2%;
            font-size: 14px;
            font-weight: 600;

            &.describe {
                text-align: right;
                padding-right: 1%;
                background: none;
                margin-right: 2%;
            }

            &.basic {
                background: $color-30;
                border-color: $color-36;
            }

            &.standard {
                background: $color-33;
                color: $white;
                border-color: $color-34;
            }

            &.premier {
                background: $color-23;
                color: $white;
                border-color: $color-35;
            }

            &:last-of-type {
                float: right;
                margin-right: 0;
            }

            .option {
                padding: 5px 0 5px 0;
                min-height: 33px;
                line-height: 33px;
                border-bottom: 1px solid $color-24;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                width: 100%;

                &:last-of-type {
                    border: none;
                }

                &.big {
                    font-weight: 700;
                    border: none;
                    font-size: 16px;
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                }

                .icon-grey {
                    @extend .icon;

                    background: url("../img/icons/icon-check.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-white {
                    @extend .icon;

                    background: url("../img/icons/icon-check-white.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-dash-white {
                    @extend .icon;

                    background: url("../img/icons/icon-dash-white.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-dash {
                    @extend .icon;

                    background: url("../img/icons/icon-dash.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }
            }
        }
    }

    .terms {
        @include clearfix;

        margin-bottom: 21px;

        > div:first-of-type {
            width: 24%;
            padding-right: 1%;
            margin-right: 1%;
            text-align: right;
            float: left;
            font-weight: 600;

            @include fonts-vw(ProximaNova, 14px, 14px);
        }

        > div:last-of-type {
            float: left;
            width: 70%;
            padding: 0 7%;
            text-align: center;

            @include fonts-vw(ProximaNova, 13px, 13px);

            font-weight: 400;
        }
    }

    .questions {
        @include clearfix;

        position: relative;
        margin-bottom: 36px;

        .question {
            width: 100%;
            padding-right: 4%;
            float: left;

            .text {
                @include fonts-vw(ProximaNova, 16px, 16px);

                font-weight: 600;
            }

            @include respond-to(tablet) {
                width: 40%;
            }
        }
    }

    .normal-fields {
        @include clearfix;

        .separator {
            @include clearfix;

            margin-top: 35px;
        }

        button {
            margin-top: 30px;
        }

        .single-checkbox {
            margin-bottom: 17px;
            width: 50%;
            float: left;

            @include respond-to(tablet) {
                width: 33%;
            }


            @include respond-to(desktop) {
                width: 25%;
            }


            label {
                @include fonts-vw(ProximaNova, 14px, 14px);

                display:block;
                width: 110%;
                position: relative;
                color: $black;
                font-weight: 600;
                padding-left: 28px;

                @include respond-to(tablet){
                    display: inline;
                    width: auto;
                }
                // &:before {
                //     content: '';
                //     display: inline-block;
                //     width: 16px;
                //     height: 16px;
                //     background: $color-30;
                //     border: 2px solid $color-29;
                //     margin-right: 12px;
                //     transform: translateY(5px);
                // }
                &:before {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                @include respond-to(desktop){
                    &:before {
                        width: 15px;
                    height: 15px;
                    }
                }
            }

            input[type=checkbox] {
                width: 0;
                visibility: hidden;

                // &:checked + label:before {
                //     background: $color-23;
                //     border-color: $color-23;
                // }
            }
            input:checked + label {
                &:before {
                    background-image: url('../img/icons/icon-checked-business.png');
                }
            }
        }
    }

    .custom-fields {
        @include clearfix;

        .customfields {
            width: 100%;
            float: left;

            &:nth-of-type(2n) {
                float: right;
            }

            @include respond-to(tablet) {
                width: 20%;
                margin-right: 6.60%;

                &:nth-of-type(2n) {
                    float: left;
                }

                &:nth-of-type(4n+1) {
                    margin-right: 0;
                }

                &:nth-of-type(4n+1) {
                    float: right;
                }
            }
        }

        input[type="text"] {
            margin-top: 13px;
            background: $color-37;
            border: 2px solid $color-38;
            float: left;
            padding: 8px;
            width: 100%;
            @include respond-to(tablet){
                margin-top: 23px;
            }

            @include fonts-vw(ProximaNova, 13.5px, 13.5px);

            &:first-of-type {
                &.filled {
                    background: url("../img/icons/icon-check.png") no-repeat $color-30;
                    border: 2px solid $color-29;
                    background-size: auto 80%;
                    background-position: 96%;
                }
            }
            &:first-of-type{
                &.filled {
                    background: url("../img/icons/icon-check.png") no-repeat;
                    background-size: auto 80%;
                    background-position: 96%;
                }
            }
            &.ng-invalid:nth-of-type(2){
                border-color:red;
            }
            &:nth-of-type(2) {
                border: none;
                background: none;
                border-bottom: 2px solid $color-38;

                

                &::-webkit-input-placeholder {
                    font-size: 11px;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    font-size: 11px;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    font-size: 11px;
                }

                &:-ms-input-placeholder {
                    font-size: 11px;
                }
            }
        }
    }

    .text-field {
        @include clearfix;

        .title {
            margin: 25px 0 46px 0;
        }

        textarea {
            margin-top: 10px;
            min-height: 180px;

            @include fonts-vw(ProximaNova, 13.5px, 13.5px);
        }
    }
    textarea{
    resize: none;

    }
}