#page-business {
    section {
        @include clearfix;
        overflow: hidden;
    }

    #heading {
        padding: 10vw 0 15vw;
        position: relative;
        background: url(../img/backgrounds/business_m.jpg) no-repeat;
        background-size: auto 100%;
        background-position: center center;

        &:after {
            content: "";
            width: 100%;
            background: url(../img/shadows-stripes/horizontal-stripes.png) no-repeat;
            background-size: 100% 100%;
            padding-top: 90px;
            display: block;
            // position: relative;
            position: absolute;
            bottom: 0;
        }

        .wrapper {
            padding: 0;
        }

        @include respond-to(desktop) {
            .wrapper {
                padding-left: 15px;
                padding-right: 15px;
            }
        }


        @include respond-to(tablet) {
            background: url(../img/backgrounds/business.jpg) no-repeat;
            background-size: 100% auto;
            background-position: center center;
            padding: 0 0 7vw;
        }

        @include respond-to(desktop) {
            background-size: auto 112%;
            background-position: center bottom;
            padding: 10vw 0 7vw;
        }


        .block {
            width: 100%;
            // margin: 0 auto;
            background: url("../img/shadows-stripes/block-background2.png") no-repeat;
            background-size: auto 100%;
            background-position: 85% center;
            padding: 6vw 0;

            @include respond-to(tablet) {
                background-position: 93% center;
            }

            @include respond-to(desktop) {
                width: 100%;
                background-position: left center;
                padding: 28px 0 31px 0;
                margin: 0;

                &:before {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 100%;
                    right: 100%;
                    position: absolute;
                    background: $color-7;
                    top: 0;
                }
            }


            .title {
                font-family: ProximaNova;
                font-weight: 700;
                color: $white;

                @include fonts-vw(ProximaNova, 24px, 32px);
                padding: 0 16% 0 6vw;

                @include respond-to(tablet) {
                    padding: 0 0 0 15px;
                    width: 80%;
                }
            }

            .subtitle {
                font-weight: 600;

                @include fonts-vw(ProximaNova, 19.5px, 28px);
                color: $white;
                margin: 20px 0 18px 0;
                padding: 0 30% 0 6vw;
                width: 95%;

                br {
                    display: none;
                }

                @include respond-to(tablet) {
                    width: 68%;
                    padding: 0 0 0 15px;
                }

                @include respond-to(desktop) {
                    width: 70%;

                    br {
                        display: block;
                    }
                }
            }

            .button {
                @include fonts-vw(ProximaNova, 16px, 16px);
                color: $white;
                font-weight: 700;
                background: $color-23;
                padding: 17px 16px;
                display: none;
                text-transform: uppercase;

                @include respond-to(tablet) {
                    display: inline-block;
                    margin-left: 15px;
                }


                a {
                    color: $white;
                }
            }
        }

        .inner {
            width: 100%;
            position: relative;
        }
    }

    #compete {
        padding: 6vw 6vw;
        background: url(../img/shadows-stripes/shadow-with-dash.png) repeat-x;
        background-position: bottom;

        @include respond-to(tablet) {
            padding: 3.7vw 0;
        }


        .wrapper {
            max-width: 980px;
        }

        .title {
            text-align: center;

            @include fonts-vw(ProximaNova, 32px, 32px);
            font-weight: 700;
            margin-bottom: 47px;
        }

        .maintext {
            text-align: justify;

            @include fonts-vw(ProximaNova, 16px, 20px);
            font-weight: 600;
            color: $color-27;
            line-height: 135%;
            margin-bottom: 20px;
        }

        ul {
            li {
                //Text
                min-height: 35px;
                margin-bottom: 25px;

                @include fonts-vw(ProximaNova, 16px, 18px);
                letter-spacing: 1.5px;
                line-height: 200%;
                font-weight: 400;
                // color: $color-27;
                //font-weight: 600;
                //color: $color-27;
                //Icons:
                padding-left: 53px;
                background: url("../img/icons/icon-check.png") no-repeat;
                background-size: auto 35px;
                background-position: left center;
                //Allign text middle
                span {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 100%;
                }
            }
        }
    }

    #advantages {
        background: $color-22;
        padding: 10vw;

        @include respond-to(tablet) {
            padding: 60px 0;
        }


        .elements {
            ul {
                li {
                    color: $white;
                    text-align: center;
                    width: 100%;
                    float: left;

                    @include respond-to(tablet) {
                        width: 50%;
                    }


                    @include respond-to(desktop) {
                        width: 25%;
                    }
                }
            }
        }

        .image {
            width: 25%;
            margin: 0 auto;
            margin-bottom: 16px;

            @include respond-to(desktop) {
                margin-bottom: 25px;
            }


            img {
                width: 100%;
            }

            @include respond-to(desktop) {
                width: 50%;
            }
        }

        .title {
            @include fonts-vw(ProximaNova, 21px, 21px);
            font-weight: 600px;
            margin-bottom: 30px;
            padding: 0 10%;

            @include respond-to(tablet) {
                margin-bottom: 18px;
                min-height: 42px;
            }
        }

        .subtitle {
            @include fonts-vw(ProximaNova, 16px, 16px);
            font-weight: 400;
            padding: 0 10%;
            margin-bottom: 30px;

            @include respond-to(tablet) {
                margin: 0;
            }
        }
    }

    #membershipdetails {
        text-align: center;
        background: url(../img/shadows-stripes/shadow-with-dash.png) #eaeaea repeat-x;
        background-position: bottom;
        padding: 10vw;

        @include respond-to(tablet) {
            padding: 60px 0;
        }


        .wrapper > .subtitle {
            padding-bottom: 25px;
        }

        header {
            color: $color-28;

            @include fonts-vw(ProximaNova, 36px, 36px);
            font-weight: 700;
            margin-bottom: 25px;
        }

        .table {
            display: table;
            height: 100%;
        }

        ul {
            display: table-row;
            height: 100%;

            li {
                // width: 33%;
                // float: left;
                // padding:35px;
                float: left;
                width: 100%;
                position: relative;
                margin-bottom: 10vw;

                @include respond-to(tablet) {
                    margin-bottom: 0;
                    position: relative;
                    display: table-cell;
                    float: none;
                    width: 33%;
                    height: 100%;
                }

                z-index: 2;

                .inner-container {
                    position: absolute;
                    width: 90%;
                    left: 5%;
                    height: 100%;
                    border-radius: 10px;
                    z-index: -1;

                    @include clearfix;
                }

                .image {
                    img {
                        width: 95px;
                        border-radius: 150px;
                        overflow: hidden;
                        min-height: 95px;
                    }

                    min-height: 95px;
                    z-index: 2;
                    padding-top: 35px;
                    margin-bottom: 30px;
                }

                .title {
                    @include fonts-vw(ProximaNova, 24px, 24px);
                    padding: 0 35px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    z-index: 2;
                }

                .subtitle {
                    padding: 0 45px 20px 45px;
                    z-index: 2;

                    @include fonts-vw(ProximaNova, 16px, 16px);
                    font-weight: 600;
                }
            }

            li:nth-of-type(1) {
                padding-right: 10px;

                & > .inner-container {
                    background: $color-24;
                }
            }

            li:nth-of-type(2) {
                padding: 0 5px;
                color: $white;

                & > .inner-container {
                    background: $color-25;
                }
            }

            li:nth-of-type(3) {
                padding-left: 10px;
                color: $white;

                & > .inner-container {
                    background: $color-26;
                }
            }
        }
    }

    #contactform {
        background: $color-3;
        background: $color-3 url("../img/shadows-stripes/stripes_vertical.png") no-repeat;
        color: $white;
        background-position: 135%;
        background-size: auto 100%;
        padding: 12vw 6vw;

        @include respond-to(tablet) {
            background: $color-3 url("../img/shadows-stripes/stripes.png") no-repeat;
            padding: 57px 0;
            background-position: 102%;
            background-size: contain;
        }


        .wrapper {
            max-width: 480px;
        }

        header.title {
            @include fonts-vw(ProximaNova, 36px, 36px);
            font-weight: 700;
            text-align: center;
        }

        .subtitle {
            @include fonts-vw(ProximaNova, 18.5px, 18.5px);
            color: $font-color-16;
            font-weight: 600;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        input {
            border-radius: 0;
        }

        button[type="submit"] {
            width: 100%;
            text-align: center;
            color: $white;
            background: $colot-31;
            padding: 8px 12px;
            border-left: 1px solid #00199f;
            border-right: 1px solid #8ca4dc;
            border-bottom: 1px solid #00199f;
            border-top: 1px solid #1647b9;
            cursor: pointer;
        }

        .input-field {
            display: inline-block;
            margin-bottom: 17px;

            @include fonts-vw(ProximaNova, 16px, 16px);
            font-weight: 700;

            textarea, input[type=text], input {
                width: 100%;

                @include fonts-vw(ProximaNova, 16px, 16px);
                border: 2px solid $color-29;
                background: $color-30;
                padding: 10px;
                font-weight: 700;
            }


            input.birthday-input {
                float: left;
                width: 33%;
            }

            label {
                line-height: 25px;

                &.control-label {
                    line-height: 1;
                    font-size: 16px;
                }
            }

            &.half {
                width: 48%;
                float: left;
                clear: left;

                & + .half {
                    float: right;
                    clear: right;
                }
            }

            &.full {
                width: 100%;

                @include clearfix;
            }

            &.hradio {
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .title {
                    text-align: left;
                }

                label.control-label {
                    font-size: 16px;
                    order: 6;
                }

                label:not(.validationMessage) {
                    display: inline-block;
                    width: 70px;

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        //border-radius: 40px;
                        //border: 2px solid $color-23;
                        background-image: url('../img/icons/icon-unchecked-business.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-bottom: -7px;
                        margin-right: 13px;
                    }
                }

                input[type=radio]:checked + label:not(.validationMessage):before,
                input[type=radio]:checked + label.validationMessage + label:before {
                    // border-color: $color-23;
                    // background: $color-23;
                    background-image: url('../img/icons/icon-checked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &.checkbox {
                display: flex;
                flex-wrap: wrap;
                position: relative;

                input[type=checkbox] {
                    position: absolute;
                }

                label.control-label {
                    font-size: 16px;
                    order: 3;
                }

                label:not(.validationMessage) {
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        // border: 2px solid $color-23;
                        background-image: url('../img/icons/icon-unchecked-business.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-bottom: -7px;
                        margin-right: 13px;
                    }
                }

                input[type=checkbox]:checked + label:before {
                    // border-color: $color-23;
                    // background: $color-23;
                    background-image: url('../img/icons/icon-checked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &.hradio, &.checkbox {
                width: 100%;

                @include clearfix;

                label {
                    @include fonts-vw(ProximaNova, 13px, 13px);
                    font-weight: 600;
                }

                input {
                    width: 0px;
                    visibility: hidden;
                }
            }
        }

        .input-field.birthday-field {
            .control-label{
                clear: both;
            }
        }
    }

    #casestudies {
        padding: 12vw 6vw;
        background: url(../img/shadows-stripes/shadow-with-dash.png) repeat-x;
        background-position: bottom;

        @include respond-to(tablet) {
            padding: 50px 0;
        }


        header {
            @include fonts-vw(ProximaNova, 36px, 36px);
            font-weight: 700;
            color: $black;
            text-align: center;
            margin-bottom: 45px;
        }

        .slide {
            width: 100%;

            @include clearfix;
        }

        .description {
            width: 100%;
            padding: 20px;
            background: url(../img/backgrounds/quote.png) no-repeat;
            padding: 25px 30px 50px 30px;
            background-size: 100% 100%;
            position: relative;

            @include clearfix;

            &:before {
                content: '';
                width: 40px;
                height: 36px;
                display: block;
                top: 15px;
                left: 13px;
                position: absolute;
                background: url(../img/icons/icon-quote.png) no-repeat;
                background-size: 100% auto;
            }

            span.textend {
                width: 15px;
                height: 15px;
                display: inline-block;
                background: url(../img/icons/icon-quotend.png) no-repeat;
                background-size: 100% 100%;
            }
        }

        .person {
            float: right;
            clear: both;
            width: 200px;
            margin: 10px 0 30px 0;

            img {
                width: 70px;
                display: inline-block;
                float: right;
                transform: translateY(-30%);
            }
        }
    }

    #ourpartners {
        padding: 10vw 8vw;

        @include respond-to(tablet) {
            padding: 40px 0;
        }


        .wrapper {
            max-width: 1366px;
        }

        header {
            @include fonts-vw(ProximaNova, 36px, 36px);
            font-weight: 700;
            color: $black;
            text-align: center;
            margin-bottom: 45px;
        }

        .carousel {
            > ul {
                display: block;
                width: 100%;
                clear: both;
                margin-bottom: 30px;
                height: 100px;

                @include clearfix;

                > li {
                    float: left;
                    width: 100%;

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        text-align: center;
                        margin: 0 auto;
                    }
                }
            }

            .slick-track {
                height: 100px;
            }
        }
    }

    .slick-dots {
        margin-top: 30px;
        text-align: center;
        width: 100%;
        clear: both;

        li {
            display: inline-block;

            button {
                width: 20px;
                height: 20px;
                background: #b0b0b0;
                border-radius: 50px;
                border: none;
                font-size: 0;
                margin: 0 10px;
            }

            &.slick-active {
                button {
                    background: #0d3fb6;
                }
            }
        }
    }

    .slick-slide img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        text-align: center;
        margin: 0 auto;
    }

    .nav {
        text-align: center;
        width: 100%;
        clear: both;

        @include clearfix;

        .dot {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #b0b0b0;
            border-radius: 50px;

            &.active {
                background: #0d3fb6;
            }
        }
    }

    textarea {
        resize: none;
    }
}

//Datepicker styling:
.home-datepicker {
    background: #fff;
    padding: 1%;

    table.ui-datepicker-calendar td.ui-datepicker-today a {
        background: $bg-color-1;
    }

    table.ui-datepicker-calendar a.ui-state-default.ui-state-active {
        background: $color-13;
    }
}