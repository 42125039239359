#page-businesslogin {
     @extend .all-business;
     
    &.access-page {
        padding-bottom: 60px;

        .inner-wrapper { 
            padding-top: 80px;
            width: 90%;
            margin: 0 auto;
            max-width: 480px;

            @include respond-to(tablet) {
                // width: 32%;
            }
        }



        label{
            color: $color-32;
            margin-bottom:10px;
            @include fonts-vw(ProximaNova, 16px, 16px);
            margin-bottom:10px;
            font-weight: 700;
            display: inline-block;
            &.control-label.has-error.validationMessage:before{
        display:none;
     }

        }
        .helper a{
                color: $color-32;
            @include fonts-vw(ProximaNova, 14px, 14px);

            font-weight: 400;
        }

        .form-group {
            div + div {
                margin-top: 24px;
            }
        }

        input[type="email"], input[type="password"] {
            margin-top: 0;
        }
        input[type="checkbox"] ~ .input-label:before{
                top: -8.5px;
                width: 24px;
                height: 20px;
                background: url(../img/icons/icon-unchecked-login.png) no-repeat;
        }
         input[type="checkbox"]:checked ~ .input-label:before{

                background: url(../img/icons/icon-checked-login.png) no-repeat;
        }
        //Preloading:
           input[type="checkbox"] ~ .input-label:after{
             background: url(../img/icons/icon-checked-login.png) no-repeat;
        }


        //End

        .has-error,
        .has-error-backend {
            input[type="email"], input[type="password"] {
                margin-bottom: 0;
            }
            .control-label,
            + .control-label {
                color: $white;
            }
        }

    }
}

body.body-login-page {
    background: url("../img/backgrounds/1.png") no-repeat;
    background-size: cover;
    background-position: center center;
}