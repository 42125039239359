body > header {
    background-color: $white;
    position: relative;
    z-index: 5;

    .disclaimer-header {
        background-color: #012351;
        color: #fff;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 14px;
        position: relative;
        z-index: 5;
        padding-left: 10px;
        padding-right: 10px;

        .inner-disclaimer {
            max-width: 750px;
            margin: 0 auto;
        }

        .clubs-disclaimer {
            font-size: 18px;
        }

    }

    .wrapper {
        background: $white;
        position: relative;
        z-index: 2;
        padding-bottom: 8px;

        &:before {
            content: "";
            position: absolute;
            left: -100%;
            right: -100%;
            bottom: 0;
            height: 8px;
            background-image: url("../img/shadows-stripes/mobile-section-stripes-blue.png");
            background-repeat: repeat-x;
            background-position: left bottom;
        }

        @include respond-to(tablet) {
            padding-bottom: 10px;

            &:before {
                display: none;
            }
        }


        &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: -100%;
            right: -100%;
            bottom: 0;
            background: $white;
            z-index: -1;
            display: block;
        }
    }

    #logo {
        margin-left: 20px;
        display: inline-block;
        color: $color-1;
        text-transform: uppercase;
        font-size: 20px;
        font-family: ProximaNova;
        font-weight: 800;
        padding: 25px 0;
        cursor: pointer;
        position: relative;
        width: 170px;
        /*@include respond-to(mobile-xl) {*/
        padding: 8px 0 18px 16px;
        /*}*/
        @include respond-to(tablet) {
            width: 200px;
            padding: 14px 0 18px 0
        }

        a {
            color: inherit;
            display: block;
            height: 32px;
            margin-bottom: 0px;
            min-height: 5px;

            @include respond-to(tablet) {
                height: 46px;
            }
        }

        img {
            display: block;
            height: 32px;
        }
        /*@include respond-to(mobile-sl) {*/
        height: 60px;
        margin-left: 0;
        /*}*/
        /*@include respond-to(mobile-xl) {*/
        /*height: 60px;
            margin-left: 0;
        }*/
        @include respond-to(tablet) {
            margin-left: 0;
            padding: 13px 0 2px;

            img {
                height: 38px;
            }
        }


        .forbusiness {
            color: #3f3f3f;
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 8px;
            width: 100%;
            display: inline-block;
            position: absolute;
            top: 37px;
            text-align: right;
            padding-right: 5px;
            height: 10px;


            @include respond-to(tablet) {
                top: 48px;
            }
        }
    }

    .menu {
        float: right;
        display: none;

        ul {
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                float: left;
                list-style: none;
                padding: 0;
                font-size: 14px;
                font-family: ProximaNova;
                font-weight: 600;

                a {
                    color: $color-4;
                    text-decoration: none;
                    cursor: pointer;
                }

                &:nth-of-type(n-1):after {
                    content: ":";
                    display: inline-block;
                    padding: 0 27px;
                }

                &:last-of-type:after {
                    display: none;
                }
            }
        }

        @include respond-to(desktop) {
            display: inline-block;
            height: 50px;
            margin-top: 8px;

            li {
                line-height: 50px;
            }
        }
    }

    .menu-mobile-button {
        right: 20px;
        top: 50%;
        display: none;
        position: absolute;
        transform: translateY(-50%);
        height: 33px;
        width: 33px;
        cursor: pointer;
        margin-top: -4px;

        @include respond-to(mobile-sm) {
            display: inline-block;
        }


        @include respond-to(tablet) {
            margin-top: 0;
        }


        rect {
            height: 7px;
            width: 33px;
            fill: $color-2;

            &:nth-of-type(2) {
                y: 13px;
            }

            &:last-of-type {
                y: 26px;
            }
        }
    }

    .login {
        float: right;
        margin-left: 30px;
        /*display: inline-block;*/
        padding-bottom: 10px;
        margin-top: 12px;
        text-transform: uppercase;
        @include fonts-vw(ProximaNova, 10px, 13.5px);
        font-weight: 700;

        @include respond-to(mobile-sm) {
            display: none;
        }
    }

    a {
        &.login-button {
            display: inline-block;
            padding: 11px 5px;
            border: solid 2px $color-2;
            background: none;
            color: $color-2;
            margin-right: 20px;

            @include respond-to(tablet) {
                padding: 11px 25px;
            }
        }

        &.create-button {
            display: inline-block;
            padding: 11px 23px;
            color: $white;
            border: solid 2px $color-2;
            background: $color-2;
            border-left: 2px solid #e9a500;
            border-bottom: 2px solid #e9a500;
            border-right: 2px solid #e9a500;
            border-top: 2px solid #f4c44f;
        }
    }
}

    body.business {
        & > header {
            background-image: url("../img/shadows-stripes/mobile-section-stripes-blue.png");
            background-repeat: repeat-x;
            background-position: left bottom;

            .wrapper {
                background: none;
            }
        }
    }

body header {
    .menu-logged:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #ececec;
        position: absolute;
        z-index: 1;
        bottom: 8px;

        @include respond-to(tablet) {
            bottom: 0px;
        }
    }

    .menu-logged-wrapper {
        background: none;
    }

    .menu-logged {
        position: static;
        padding: 0 0 0 60px;
        margin: 0;

        @include respond-to(mobile-sm) {
            padding: 0;
        }

        @include respond-to(tablet) {
            margin: 0 -15px 0 0;
        }

        .alert {
            position: absolute;
            font-size: 9px;
            color: #ec1b1b;
            z-index: 2;
            padding-left: 4px;
            padding-top: 1px;
            text-align: center;
        }
    }

    .menu-logged-wrapper {
        padding: 0 10px 0 60px;
        z-index: 3;

        @include respond-to(mobile-sm) {
            padding: 0;
        }

        @include respond-to(desktop) {
            width: 236px;
            padding: 0 10px 0 0px;
        }
    }

    .menu-logged-list {
        top: 59px;

        @include respond-to(tablet) {
            top: 74px;
        }

        @include respond-to(mobile-sm) {
            top: 57px;
        }

        @include respond-to(desktop) {
            top: 57px;
        }
    }
}


    body.business {
        .menu-logged-list {
            top: 65 px;
        }

        .menu-logged:before {
            bottom: 8px;
        }
    }
