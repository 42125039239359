﻿#Membership-Details {
    .memberships {
        width: 96%;
        margin-left: 7px;
        margin-right: 7px;

        @media (max-width: 768px) {
            width: 100%;
            margin-left: unset;
            margin-right: unset;
        }

        .membership {
            padding: 15px;
            background-color: #edaa20;
            border-radius: 3px;
            position: relative;
            color: #fff;

            &.premier {
                background-color: #edaa20;
            }

            &.basicplus {
                background-color: #1a4fbf;
            }

            &.basic {
                background-color: #d0d0d0;
                color: #000;
            }

            .membership-header {
                font-weight: bold;
                padding-bottom: 3px;
                font-size: 18px;
            }

            .membership-price {
                position: absolute;
                right: 15px;
                top: 25px;
                font-weight: bold;

                @media (max-width: 768px) {
                    top: 15px;
                }
            }
        }

        .membership-extended-description {
            padding-top: 25px;
            padding-left: 7px;
            padding-right: 7px;

            .membership-extended-bullets {
                padding-top: 25px;

                .option {
                    width: 32.3%;
                    float: left;
                    list-style: none;
                    padding-bottom: 10px;

                    @media (max-width: 1024px) {
                        width: 50%;
                    }

                    @media (max-width: 768px) {
                        width: 100%;
                        float: unset;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        height: 20px;
                        width: 40px;
                        background-position: bottom;
                        background-size: 20px;
                        vertical-align: text-top;
                        background-image: url(/Content/img/icons/icon-check-blue.png);
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}
