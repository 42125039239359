#page-businesswelcome {
    @extend .all-business;
    //Unique wrapper size
    .main-section {
        .wrapper {
            max-width: 920px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .subtitle {
            text-align: left;
        }
    }

    .description {
        @include fonts-vw(ProximaNova, 14px, 14px);
        color: $black;
        font-weight: 400;
        margin-bottom: 25px;
        margin-top: 25px;
    }
    //Section columns
    .columns {
        @include clearfix;
        margin-bottom: 42px;

        .column {
            width: 24%;
            float: left;
            text-align: center;
            color: $black;
            background: #d3d3d3;
            margin-right: 1%;
            padding: 0 2%;
            @include fonts-vw(ProximaNova, 10px, 14px);
            font-weight: 600;

            &.describe {
                text-align: right;
                padding-right: 1%;
                background: none;
                margin-right: 2%;
            }

            &.basic {
                background: $color-30;
                border-color: $color-36;

                .plan span {
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    border: 2px solid;
                    border-color: $color-36;
                    color: $color-36;

                    &.current {
                        background: $color-30;
                        color: $white;
                    }
                }
            }

            &.standard {
                background: $color-33;
                color: $white;
                border-color: $color-34;

                .plan span {
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    border: 2px solid;
                    border-color: $color-33;
                    color: $color-33;

                    &.current {
                        background: $color-33;
                        color: $white;
                    }
                }
            }

            &.premier {
                background: $color-23;
                color: $white;
                border-color: $color-35;

                .plan span {
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    border: 2px solid;
                    border-color: $color-23;
                    color: $color-23;

                    &.current {
                        background: $color-23;
                        color: $white;
                    }
                }
            }

            &:last-of-type {
                float: right;
                margin-right: 0;
            }

            .option {
                padding: 5px 0 5px 0;
                min-height: 77px;
                line-height: 1;
                border-bottom: 1px solid $color-24;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                width: 100%;

                &:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(8), &:nth-of-type(10), &:nth-of-type(12), &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16) {
                    min-height: 75px;
                }

                @include respond-to(tablet) {
                    line-height: 33px;
                    white-space: nowrap;
                    min-height: 33px;

                    &:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(8), &:nth-of-type(10), &:nth-of-type(12), &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16) {
                        min-height: 33px;
                    }
                }



                a {
                    color: inherit;
                    text-decoration: underline;
                }

                &:last-of-type {
                    border: none;
                }

                &.plan {
                    padding-top: 20px;
                    position: relative;
                    background: #fff;
                    width: 120%;
                    left: -10%;
                    border-bottom: none;
                    bottom: -3px;

                    &.additional {
                        margin: 0;
                        padding: 0;
                        margin-top: -3px;
                    }
                    // &:before {
                    //     content: '';
                    //     display: block;
                    //     height: 10px;
                    //     width: 102%;
                    //     background: #fff;
                    //     position: absolute;
                    //     // top: -10px;
                    //     // left: -2%;
                    // }
                }

                &.big {
                    font-weight: 700;
                    border: none;
                    font-size: 16px;
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                }

                .icon-grey {
                    @extend .icon;
                    background: url("../img/icons/icon-check.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-white {
                    @extend .icon;
                    background: url("../img/icons/icon-check-white.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-dash-white {
                    @extend .icon;
                    background: url("../img/icons/icon-dash-white.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }

                .icon-dash {
                    @extend .icon;
                    background: url("../img/icons/icon-dash.png") no-repeat;
                    background-size: contain;
                    background-position: center center;
                }
            }
        }
    }
    //Section terms
    .terms {
        @include clearfix;
        margin-bottom: 21px;

        > div:first-of-type {
            width: 24%;
            padding-right: 1%;
            margin-right: 1%;
            text-align: right;
            float: left;
            font-weight: 600;

            @include fonts-vw(ProximaNova, 14px, 14px);
        }

        > div:last-of-type {
            float: left;
            width: 70%;
            padding: 0 7%;
            text-align: center;

            @include fonts-vw(ProximaNova, 13px, 13px);
            font-weight: 400;

            a {
                color: $black;
            }
        }
    }
    //Section questions
    .questions {
        @include clearfix;
        margin-top: 25px;
        position: relative;
        margin-bottom: 36px;

        .question {
            width: 100%;
            float: left;
            margin-bottom: 20px;

            .text {
                @include fonts-vw(ProximaNova, 14px, 14px);
                font-weight: 600;
            }

            input, .openpopup {
                margin-top: 10px;
                width: 100%;
                padding: 12px;
                background: $color-30;
                border: 2px solid $color-29;

                @include fonts-vw(ProximaNova, 13.5px, 13.5px);
            }

            .openpopup {
                line-height: 1.2;
            }

            @include respond-to(tablet) {
                width: 40%;
                margin-bottom: 0px;
                padding-right: 4%;
            }
        }
        //Bottom alignment of continue button
        .continue {
            @include respond-to(tablet) {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }


    .current-payment.payment-changing {

        h2 {
            font-size: 22px;
            border-bottom: 1px solid #ddd;
        }

        .current-payment-row {
            font-family: "Helvetica Neue",Helvetica,Arial,Sans-serif;
            color: #6E787F;
            padding-lefT: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #ddd;
            padding-top: 25px;
            padding-bottom: 25px;

            .card-type {
                color: #283036;
                font-weight: 800;
            }

            .expiration-date {
                float: right;

                .expiration-date-label {
                    font-weight: 800;
                    color: #283036;
                }
            }
        }
    }

    .payment-changing {
        margin-top: 20px;
        padding: 31px 34px;
        width: 100%;
        clear: both;

        h2 {
            font-weight: 700;
        }

        .continue {
            margin: 0;
            float: none;
        }

        .image {
            margin-top: 25px;
            display: inline-block;
            width: 200px;
            height: 30px;
            background: url(../img/paypal.png) no-repeat;
            /* background-position: left center; */
            background-size: auto 100%;
        }
    }
}
