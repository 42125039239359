.wrapper {
    @include clearfix;

    width: 100%;
    max-width: $wrapper-size;
    margin: 0 auto;

    @include respond-to(tablet) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.component-footer-decoration {
    height: 52px;
    width: 100%;
    display: block;
    background: url("../img/shadows-stripes/stripes2.png") repeat-x;
    background-size: auto 52px;

    &.footer-decoration--padded {
      margin-top: 10px;
      margin-bottom: 10px;

      @include respond-to(tablet) {
        margin-top: 20px;
      }
    }
}

.stripes-top {
    //height: 5px;
    //width: 100%;
    //display: block;
    background: url("../img/shadows-stripes/dash-3.png") #fff repeat-x;
    background-size: auto 5px;
    background-position: bottom;
}
body{
  min-height:100vh;
}

.in-progress-profile-component {
    position: relative;
    overflow: hidden;

    &:before{
        content: '';
        position: absolute;
        left: 0;
        width:100%;
        height: 100%;
        background-color: $white;
        opacity: 0.80;
        z-index: 3;
    }

    &:after {
        @include preloader(10vw, 10vw, 2px, absolute, $color-6, $color-12);
        content: '';
        display: inline-block;
        top:50%;
        left: 50%;
        animation: preloader-centered 0.8s linear infinite;
        transform-origin:0% 0%;
        z-index: 4;
    }
}

.table-component {
    table {
            width: 100%;
        }

        th {
            position: relative;
            height: 40px;
            color: $color-18;
            font-size: 12px;
            font-weight: $font-w-semibold;
            background-color: $color-39;
            cursor: pointer;

            &:after{
                content:'';
                display: inline-block;
                position: relative;
                margin-left:3px;
                width:8px;
                height:12px;
                vertical-align: middle;
                background-image: url('../img/icons/ico-double-arrow.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.filter-active {
                &:after{
                    background-image: url('../img/icons/ico-double-arrow-selected.png');
                }

                &.reverse {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        th, td{
            vertical-align: middle;
            text-align: center;
        }

        tr {

            &:nth-child(2n+1){
                background-color: $color-40;
            }
        }

        td {
            height: 40px;
            border-bottom: 1px solid $color-39;
            font-size: 13px;
        }
}

.link-back {
    $color: #b7b7b7;

    position: relative;
    display: inline-block;
    padding-left: 24px;
    margin-bottom: 15px;
    transition: all $trans-duration;

    line-height: 18px;
    vertical-align: middle;
    font-weight: $font-w-semibold;
    font-size: 12px;
    color: $color;

    @include respond-to(tablet) {
      margin-bottom: 25px;
    }

    &:hover {
      color: #858585;

      &:before {
        @include sprite($club-link-back-hover);
        z-index: 1;
      }

      &:after {
        background: $color;
      }
    }

    &:before {
      position: absolute;
      left: 6px;
      top: 5px;
      content: "";
      @include sprite($club-link-back);
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 17px;
      height: 17px;
      border: 1px solid $color;
      transition: all $trans-duration;
    }
}