$businessgyms-section-space: 15px;
$businessgyms-filters-height: 90px;
$businessgyms-mobile-filters-height: 223px;
$filter-input-width: 230px;
$mobile-filter-input-width: 60%;
$filter-first-label-width: 35px;

#page-businessgyms-allgyms {
    display: block;
    margin: 0 auto;
    max-width: 768px;
    width: 90%;

    @include respond-to(tablet) {
    }


    @include respond-to(desktop) {
        width: 75%;
    }


    * {
        font-family: $font-proxima;
    }

    .section {
        display: block;
        margin: 0 auto $businessgyms-section-space;
        width: 100%;
        height: auto;
        position: relative;
    }

    #stats {
        background-color: $color-39;
        margin-bottom: 0;
        z-index: 2;
    }

    .stats-item {
        display: inline-block;
        padding: 20px 0 18px;
        width: 50%;
        text-transform: none;
        vertical-align: baseline;
        font-weight: $font-w-semibold;
        font-size: unit-vw(15px, mobile);
        text-align: center;

        @include respond-to(mobile-lg) {
            font-size: 16px;
        }


        @include respond-to(tablet) {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: $font-w-bold;
        }


        .number {
            display: inline-block;
            margin-top: 5px;
            width: 100%;
            font-size: 13px;
            text-align: center;

            @include respond-to(mobile-lg) {
                font-size: 16px;
            }


            @include respond-to(tablet) {
                display: inline;
                margin-top: 0;
                width: auto;
                font-size: 16px;
            }
        }
    }

    #allgyms-links {
        //padding+ height instead of margin to create mask, same for top (for mobile)
        margin-bottom: 0;
        z-index: 2;
        padding-top: $businessgyms-section-space;
        padding-bottom: $businessgyms-section-space;
        background-color: $white;

        .links-item {
            @extend .component-button-1;
            display: inline-block;
            width: 48%;
            height: 50px;
            line-height: 50px;
            margin-right: 4%;
            font-size: 13px;
            text-align: center;

            @include respond-to(tablet) {
                font-size: 18px;
            }


            &:last-child {
                margin-right: 0;
            }
        }
    }

    #control-buttons {
        //padding+ height instead of margin to create mask
        margin-bottom: 0;
        padding-bottom: $businessgyms-section-space;
        height: 35px;
        z-index: 2;
        background-color: $white;

        .left-side, .right-side {
            display: inline-block;
            height: 100%;

            * {
                color: $color-27;
                text-transform: uppercase;
                font-weight: $font-w-semibold;
                font-size: 13px;
                line-height: 20px;
                vertical-align: middle;
            }
        }

        .left-side {
            .view {
                margin-right: 15px;
            }

            .tiles, .list {
                display: inline-block;
                margin-right: 5px;
                height: 85%;
                width: 20px;
                background: none;
                border: none;
                outline: none;
                background-size: contain;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            .tiles {
                background-image: url("../img/icons/icon-tiles.png");
            }

            .list {
                background-image: url("../img/icons/ico_order-history.png");
            }
        }

        .right-side {
            float: right;

            .view {
                padding-right: 35px;
                background: none;
                border: none;
                outline: none;
                line-height: 25px;
                cursor: pointer;

                &:after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    right: 0;
                    top: 2px;
                    height: 20px;
                    width: 20px;
                    border: 1px solid $color-27;
                    transform: rotate(0deg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 60%;
                    background-image: url("../img/icons/ico-arrow-down.png");
                    transition: transform 0.3s ease-in-out;
                }

                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    #filters {
        position: relative;
        margin-bottom: 0;
        padding-bottom: $businessgyms-section-space;
        height: $businessgyms-mobile-filters-height;
        transform: translateY(-100%);
        z-index: 1;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;

        @include respond-to(tablet) {
            height: $businessgyms-filters-height;
        }


        &.active {
            overflow: visible;
            transform: translateY(0%);
        }

        .state-wrap, .type-wrap {
            display: inline-block;
            width: 100%;

            @include respond-to(tablet) {
                width: 50%;
            }
        }

        .type-wrap {
            margin-top: 15px;
            text-align: left;

            @include respond-to(tablet) {
                margin-top: 0;
                text-align: right;
            }
        }

        .filter-label {
            display: inline-block;
            font-size: 13px;
            font-weight: $font-w-semibold;
            width: 38%;

            @include respond-to(tablet) {
                width: auto;
            }
        }

        .state-wrap {
            @include respond-to(tablet) {
                .filter-label {
                    width: $filter-first-label-width;
                }
            }
        }

        .filter-input {
            display: inline-block;
            width: $mobile-filter-input-width;
            height: 30px;
            border: $color-27 solid 1px;
            background: none;
            background-color: $color-30;
            outline: none;
            box-shadow: 0 0 1px $color-27;

            @include respond-to(tablet) {
                width: $filter-input-width;
            }


            * {
                cursor: pointer;
            }
        }

        .dropdown-toggle {
            width: 100%;
            height: 30px;
            border: none;
            background: none;
            outline: none;
            text-align: left;
        }

        .type-select, .dropdown-toggle {
            padding-left: 10px;
            font-weight: $font-w-semibold;
        }

        .dropdown-menu.dropdown-menu-form {
            position: absolute;
            display: block;
            width: $mobile-filter-input-width;
            max-height: 400px;
            z-index: 1;
            background-color: $white;
            overflow-x: hidden !important;
            overflow-y: auto !important;
            border: $color-27 solid 1px;
            box-shadow: 0 0 1px $color-27;
            text-align: left;

            @include respond-to(tablet) {
                width: $filter-input-width;
            }


            a {
                display: block;
                padding: 5px;
                outline: none;
                background-color: $white;
                cursor: pointer;
            }

            input {
                display: block;
                margin: 5px;
                padding: 5px;
                height: 30px;
                width: 95% !important;
                border: $color-27 solid 1px;

                &:active, &:focus {
                    border: $color-27 solid 1px;
                }
            }

            li:nth-child(n+3) {
                span {
                    display: inline-block;
                    position: relative;
                    width: 20px;
                    height: auto;
                    min-height: 10px;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 50%;
                        height: 15px;
                        width: 15px;
                        transform: translateY(-50%);
                        background-image: url("../img/icons/icon-unchecked-business.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    &.glyphicon-ok {
                        &:before {
                            background-image: url("../img/icons/icon-checked-business.png");
                        }
                    }
                }
            }
        }

        .state-button-container {
            position: relative;
            display: inline-block;
            margin-top: 15px;
            width: 100%;

            @include respond-to(tablet) {
                width: 70%;
            }


            li {
                display: inline-block;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;

                &:last-of-type {
                    margin-right: 0;
                }

                @include respond-to(tablet) {
                    margin-right: 15px;
                    margin-bottom: 0;

                    &:first-child {
                        margin-left: $filter-first-label-width;
                        padding-left: 3px;
                    }
                }


                .state-button {
                    padding: 3px;
                    border: 2px solid $button-color;
                    color: $button-color;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: $font-w-bold;
                    vertical-align: middle;

                    @include respond-to(tablet) {
                        padding: 5px;
                        font-size: 13px;
                    }


                    * {
                        vertical-align: middle;
                    }

                    .close-icon {
                        height: 14px;
                        width: 14px;
                        display: inline-block;
                        background: none;
                        border: none;
                        outline: none;
                        background-image: url("../img/icons/ex.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        cursor: pointer;

                        &:active, &:hover, &:focus {
                            outline: none;
                        }
                    }

                    &.dots {
                        border: none;
                        padding: 0;
                        font-size: 13px;

                        @include respond-to(tablet) {
                            font-size: 25px;
                        }
                    }
                }
            }
        }

        .state-button {
            display: inline-block;
        }
    }

    .clear {
        float: left;

        @extend .component-button-yellow;
        margin-right: 10px;
        background-color: $color-23;
        color: $white;
    }

    .apply {
        @extend .component-button-1;
        background-color: $button-color;
        color: $white;
    }

    .filters-controls-container {
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        margin-right: 0;
        text-align: right;

        @include respond-to(tablet) {
            width: 30%;
        }


        button {
            padding: 5px;
            height: auto;
            line-height: 1.1em;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: $font-w-bold;
            vertical-align: middle;

            * {
                vertical-align: middle;
            }
        }
    }

    #gym-list {
        margin-top: -$businessgyms-mobile-filters-height;
        transition: margin 0.3s ease-in-out;

        @include respond-to(tablet) {
            margin-top: -$businessgyms-filters-height;
        }


        * {
            font-family: $font-proxima;
        }

        &.filters-opened {
            margin-top: 0;
        }

        &.tiles {
            @include clearfix;

            .table-row {
                display: none;
            }

            .table-head {
                display: none;
            }

            .gym-element {
                display: inline-block;
                margin-right: 0%;
                margin-bottom: 20px;
                padding: 15px;
                width: 100%;
                height: 220px;
                background-color: $color-30;
                float: left;
                cursor: pointer;
                position: relative;

                &.not-clickable {
                    cursor: initial;
                }

                &.disabled:before {
                    content: "disabled";
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: 65px;
                    text-align: center;
                    padding-top: 20%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.15);
                }

                & .gym-item-delete-icon {
                    width: 100%;
                    /*background-image: url(../img/icons/ico-bin-red.png);*/
                    background-image: url(../img/icons/ico-bin.png);
                    background-size: 14px;
                    background-repeat: no-repeat;
                    background-position: 5px 5px;
                    height: 28px;
                    width: 24px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    cursor: pointer;
                    z-index: 2;

                    &:hover {
                        /*background-image: url(../img/icons/ico-bin.png);*/
                    }
                }

                @include respond-to(mobile-lg) {
                    margin-right: 4%;
                    width: 48%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }


                @include respond-to(tablet) {
                    margin-right: 10%;
                    width: 45%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }


                @include respond-to(desktop) {
                    margin-right: 2%;
                    width: 32%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }


                .tile-label {
                    display: inline-block;
                    margin-bottom: 3px;
                    width: auto;
                    font-size: 12px;
                    color: $font-color-15;
                    font-weight: $font-w-semibold;
                    vertical-align: top;

                    &.half {
                        width: 50%;
                    }
                }

                .gym-item {
                    display: inline-block;
                    margin-bottom: 10px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: $font-w-bold;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &.full {
                        width: 100%;
                    }

                    &.half {
                        width: 47%;
                        text-align: left;

                        &:nth-of-type(2n-1) {
                            float: right;
                        }
                    }

                    &.Notfinished, &.Incomplete, &.Event-Past, &.Event-Incomplete {
                        color: red;
                    }

                    &.Inmoderation, &.pending, &.Pending, &.Event-Pending {
                        color: #edaa20;
                    }

                    &.Completed, &.live, &.Live, &.Event-Live {
                        color: #52994f;
                    }

                    &.PaymentError {
                        color: red;
                        width: 53%
                    }
                }

                .half-column-wrap {
                    display: inline-block;
                    width: 47%;

                    &:nth-of-type(2n-1) {
                        float: right;
                    }
                }

                .table-only {
                    display: none;
                }

                .replicate-button {
                    cursor: pointer;
                    border: none;
                    background: #edaa20;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-family: ProximaNova;
                    font-weight: 700;
                    margin-left: 15px;
                    margin-top: 10px;
                    display: block;
                    width: 118px;
                    text-align: center;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    margin: 0 auto;
                    font-size: 14px;
                }
            }

            .field-wrap {
                display: block;
                width: 100%;

                @include clearfix;

                &.cash {
                    margin: 5px 0 5px 0;

                    .gym-item {
                        color: #666666;

                        &.blue {
                            color: #145ac0;
                        }
                    }
                }

                &.dates {
                    .gym-item {
                        &.half {
                            float: left;
                        }
                    }

                    .tile-label {
                        float: left;
                        width: 35%;
                    }
                }

                .tile-label {
                    display: inline-block;
                    width: 45%;
                }
            }
        }

        &.list {
            overflow: auto;

            .gym-listwrapper {
                position: relative;
                display: table;
                width: 100%;
            }

            .table-row {
                position: relative;
                display: table-row;
                width: 100%;
            }

            .table-head {
                position: relative;
                display: table-cell;
                height: 40px;
                min-width: 175px;
                color: $color-18;
                font-size: 12px;
                font-weight: $font-w-semibold;
                background-color: $color-39;
                cursor: pointer;

                @include respond-to(mobile-sm) {
                    min-width: 42vw;
                }


                @include respond-to(desktop) {
                    min-width: 0;
                }
                // &:after{
                //     content:'';
                //     display: inline-block;
                //     position: relative;
                //     margin-left:3px;
                //     width:8px;
                //     height:12px;
                //     vertical-align: middle;
                //     background-image: url('../img/icons/ico-double-arrow.png');
                //     background-position: center;
                //     background-repeat: no-repeat;
                //     background-size: contain;
                // }
                // &.filter-active {
                //     &:after{
                //         background-image: url('../img/icons/ico-double-arrow-selected.png');
                //     }
                //     &.reverse {
                //         &:after {
                //             transform: rotate(180deg);
                //         }
                //     }
                // }
            }

            .table-head, .gym-element {
                vertical-align: middle;
                text-align: center;
            }

            .gym-element {
                position: relative;
                display: table-row;
                width: 100%;

                & .gym-item-delete-icon {
                    width: 100%;
                    background-image: url(../img/icons/ico-bin-red.png);
                    background-size: 14px;
                    background-repeat: no-repeat;
                    background-position: 8px 10px;
                    height: 40px;
                    width: 30px;
                    position: unset;
                    top: 5px;
                    right: 0px;
                    cursor: pointer;
                    border-bottom: 1px solid #e6e6e6;
                    text-align: center;

                    &:hover {
                        background-image: url(../img/icons/ico-bin.png);
                    }
                }



                &:nth-of-type(2n+2) {
                    background-color: $color-40;
                }

                .tile-label {
                    display: none;
                }

                .gym-item {
                    display: table-cell;
                    height: 40px;
                    border-bottom: 1px solid $color-39;
                    font-size: 13px;
                    vertical-align: middle;
                }

                .field-wrap {
                    display: table-cell;
                    height: 40px;
                    border-bottom: 1px solid $color-39;
                    font-size: 13px;
                    vertical-align: middle;

                    .gym-item {
                        display: inline-block;
                        height: auto;
                    }
                }

                .half-column-wrap {
                    display: none;
                }
            }
        }
    }

    &.in-progress {
        @extend .in-progress-profile-component;
    }
}