﻿#page-press {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 35px;
    padding-top: 35px;

    @include clearfix;

    #heading {
        .wrapper {
            background: url(../img/shadows-stripes/dash-2.png) repeat-x;
            background-position: bottom;
            background-size: auto 5px;
            margin-bottom: 35px;
        }
    }

    .carousel {
        div.slick-track {
            height: 170px;

            .carousel-link {
                display: block;
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                margin-left: 10px;
                margin-right: 10px;

                &.authority-link {
                    background-color: #b62a2a;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }

            > li img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                text-align: center;
            }
        }
    }

    .top-press-wrapper {
        background: url(../img/shadows-stripes/dash-2.png) repeat-x;
        background-position: bottom;
        background-size: auto 5px;
        padding-bottom: 35px;
    }

    #press-video {
        max-width: 1000px;
        margin: 0 auto;

        .video-outer-wrapper {
            width: 49%;
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;

            @media (max-width: 768px) {
                width: 100%;
                display: block;
                padding-bottom: 10px;
            }
        }

        .wrapper {
            text-align: center;
        }

        .video-wrapper {
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;
            height: 0;

            #main-video, #second-main-video {
                width: 100%;
                height: 100%;
                border: 0;
                left: 0;
                position: absolute;
                top: 0;
                max-width: 1000px;
            }
        }
    }

    h1 {
        font-family: ProximaNova;
        font-weight: 700;
        font-size: 32px;
        color: $black;
        text-align: center;
        margin-bottom: 15px;
    }

    iframe {
        margin: 0 auto;
    }

    #press-articles {
        .article {
            padding-top: 25px;
            background: url(../img/shadows-stripes/dash-2.png) repeat-x;
            background-position: bottom;
            background-size: auto 5px;
            padding-bottom: 25px;

            .article-image {
                width: 30%;
                float: left;
                height: 130px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                @media (max-width: 768px) {
                    width: unset;
                    float: unset;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-bottom: 30px;
                }
            }

            .article-details {
                width: 70%;
                float: left;
                padding-left: 25px;

                @media (max-width: 768px) {
                    width: 100%;
                    float: unset;
                    padding-left: 0;
                }

                .article-title {
                    font-weight: bold;
                    font-size: 20px;
                    padding-bottom: 5px;

                    a {
                        color: #115ac0;
                    }
                }

                .article-byline {
                    font-weight: bold;
                    color: #9b9b9b;
                    padding-bottom: 7px;
                }

                .article-video-wrapper {
                    max-width: 560px;

                    .article-video {
                        padding-top: 15px;
                        width: 100%;
                        padding-bottom: 56.25%;
                        position: relative;
                        height: 0;

                        .article-video-iframe {
                            width: 100%;
                            height: 100%;
                            border: 0;
                            left: 0;
                            position: absolute;
                            top: 0;
                            max-width: 560px;
                        }
                    }
                }

                .article-summary {
                    color: #000;
                }

                .article-more {
                    text-align: right;
                    padding-right: 39px;
                    font-weight: bold;
                    padding-top: 15px;

                    a {
                        color: #115ac0;
                    }
                }
            }

            &.article-0 {
                .article-image {
                    background-image: url("/Content/img/press/cheddar.png");
                    height: 80px;
                }
            }

            &.article-1 {
                .article-image {
                    background-image: url("/Content/img/press/authority-magazing.png");
                    background-color: rgba(182, 42, 42, 1);
                }
            }

            &.article-2 {
                .article-image {
                    background-image: url("/Content/img/press/The-Active-Times-logo.png");
                }
            }

            &.article-3 {
                .article-image {
                    background-image: url("/Content/img/press/healthy-ceo.png");
                }
            }

            &.article-4 {
                .article-image {
                    background-image: url("/Content/img/press/insider.png");
                }
            }

            &.article-5 {
                .article-image {
                    background-image: url("/Content/img/press/readers-digest.png");
                }
            }

            &.article-6 {
                .article-image {
                    background-image: url("/Content/img/press/thrive-global.png");
                }
            }

            &.article-7 {
                .article-image {
                    background-image: url("/Content/img/press/ask-men.png");
                }
            }

            &.article-8 {
                .article-image {
                    background-image: url("/Content/img/press/we-rule.png");
                }
            }

            &.article-9 {
                .article-image {
                    background-image: url("/Content/img/press/thrive-global.png");
                }
            }
        }
    }
}
