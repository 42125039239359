.info-popup {
    overflow: visible;
    display: inline-block;
    height: 14px;
    width: 14px;
    float: right;
    position: absolute;
    right: 6px; 
    top:12px;
    cursor: pointer;
    .icon {
        display: inline-block;
        height: 14px;
        width: 14px;
        background-image: url('../img/icons/info-icon.png');
    }

    .info-popup-container {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0 5px;
        max-height: 0;
        overflow: hidden;
        background-color: $white;
        font-family: $font-proxima;
        font-weight: $font-w-normal;
        text-align: center;
        z-index: 1025;
        transition: max-height 0.25s ease-in-out;
        box-shadow: inset 2px 2px 0px grey,
                    inset -2px -2px 0px grey,
                    1px 1px 2px grey;

        &.visible {
            max-height: 100px;
        }

        p {
            position: absolute;
            top: 50%;
            display: block;
            width: 90%;
            transform: translateY(-50%);
            font-size: 13px;
        }

        .close-icon{
            position: absolute;
            margin-right: 0 !important;
            width: 14px;
            height: 14px;
            right: 5px;
            top: 5px;
            background-color : grey;
            background-image: url('../img/icons/ex_white.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            
        }
    }
}