#page-reset_password {
    padding-bottom: 60px;
    .inner-wrapper {
        padding-top: 80px;
        width: 80%;
        margin: 0 auto;

        @include respond-to(tablet) {
            width: 32%;
        }
    }
}

body.body-reset_password-page {
    background: url("../img/backgrounds/1.png") no-repeat;
    background-size: cover;
    background-position: center center;
}