﻿
.partners-wrapper {
    width: 90%;
    margin: 0px auto;

    .partners {
        width: 100%;
        margin: 0px auto;

        h1 {
            font-family: ProximaNova;
            font-weight: 700;
            font-size: 32px;
            color: #000000;
            margin-bottom: 32px;
            text-align: center;
            padding-top: 25px;                          

            @include respond-to(mobile-sm) {
                font-size: 18px;
            }

            @include respond-to(mobile-lg) {
                font-size: 22px;
            }

            @include respond-to(tablet) {
                font-size: 32px;
            }

            @include respond-to(desktop) {
                font-size: 32px;
            }
        }

        .partner-block {
            width: 100%;
            background-color: #c4c4c4;
            margin-bottom: 20px;

            .partner-header {
                background-color: #3470bf;
                color: #fff;
                padding-left: 10px;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 28px;
            }

            .partner-content-block {
                padding: 10px;
                display: flex;


                @include respond-to(mobile-sm) {
                    flex-direction: column;
                }

                @include respond-to(mobile-lg) {
                    flex-direction: column;
                }

                @include respond-to(tablet) {
                    flex-direction: row;
                }

                @include respond-to(desktop) {
                    flex-direction: row;
                }

                .partner-logo-wrapper {
                    width: 300px;
                    min-height: 100px;
                    height: 100px;
                    background-color: #fff;
                    border: 1px solid #3470bf;
                    text-align: center;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 20px;

                    @include respond-to(mobile-sm) {
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    @include respond-to(mobile-lg) {
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    img {
                        max-width: 290px;
                    }
                }

                .partner-content-wrapper {
                    width: calc(100% - 300px);
                    padding: 0px 10px;
                    font-size: 14px;

                    @include respond-to(mobile-sm) {
                        width: 100%;
                    }

                    @include respond-to(mobile-lg) {
                        width: 100%;
                    }

                    @include respond-to(tablet) {
                        width: calc(100% - 300px);
                    }

                    @include respond-to(desktop) {
                        width: calc(100% - 300px);
                    }

                    h2 {
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        color: #3470bf;
                    }

                    p {
                        margin-bottom: 18px;
                        line-height: 20px;
                    }

                    .content-link {
                        color: #3470bf;
                        text-decoration: none;
                        background-color: none;
                        font-weight: bold;

                        &:hover {
                            color: #000;
                            text-decoration: underline;
                            background-color: none;
                            font-weight: bold;
                        }
                    }

                    a.know-more {
                        background-color: #3470bf;
                        padding: 3px 24px 3px 5px;
                        border-radius: 4px;
                        color: #fff;
                        font-size: 14px;
                        text-decoration: none;
                        margin-top: 5px;
                        position: relative;

                        .arrow-01 {
                            position: absolute;
                            right: 5px;
                            top: 7px;
                            width: 0px;
                            height: 0px;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 6px solid #f2b728;
                        }

                        .arrow-02 {
                            position: absolute;
                            right: 10px;
                            top: 7px;
                            width: 0px;
                            height: 0px;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 6px solid #f2b728;
                        }

                        &:hover {
                            background-color: #f2b728;

                            .arrow-01 {
                                position: absolute;
                                right: 5px;
                                top: 7px;
                                width: 0px;
                                height: 0px;
                                border-top: 5px solid transparent;
                                border-bottom: 5px solid transparent;
                                border-left: 6px solid #3470bf;
                            }

                            .arrow-02 {
                                position: absolute;
                                right: 10px;
                                top: 7px;
                                width: 0px;
                                height: 0px;
                                border-top: 5px solid transparent;
                                border-bottom: 5px solid transparent;
                                border-left: 6px solid #3470bf;
                            }
                        }
                    }
                }
            }
        }
    }
}


#casestudies {
    padding: 12vw 6vw;
    background: url(../img/shadows-stripes/shadow-with-dash.png) repeat-x;
    background-position: bottom;

    @include respond-to(tablet) {
        padding: 50px 0;
    }
}

#ourpartners {
    padding: 10vw 8vw;

    @include respond-to(tablet) {
        padding: 40px 0;
    }


    .wrapper {
        max-width: 1366px;
    }

    header {
        @include fonts-vw(ProximaNova, 36px, 36px);
        font-weight: 700;
        color: $black;
        text-align: center;
        margin-bottom: 45px;
    }

    .carousel {
         div.slick-track {
            /*display: flex !important;
            flex-wrap: nowrap;
            width: 100%;
            clear: both;
            padding-bottom: 30px;
            height: 100px;*/

            @include clearfix;

            > li {
                /*min-width: 150px !important;
                border: 0px solid red !important;
                margin: 10px;
                display: flex;
                justify-content: center;
                align-items: center;*/

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    text-align: center;
                }
            }
        }

        .slick-track {
            height: 100px;
        }
    }
}

.partners-case-studies {
    padding: 50px 0 0 0 !important;
}

.responsive-test {
    background-color: red;



    @include respond-to(mobile-lg) {
        background-color: yellow;
    }

    @include respond-to(mobile-sm) {
        background-color: greenyellow;
    }

    @include respond-to(tablet) {
        background-color: blue;
    }

    @include respond-to(desktop) {
        background-color: darkgoldenrod;
    }
}