#page-dashboard {
    * {
        vertical-align: top;
    }

    .empty-message {
        text-align: center;
        padding: 10px;
        font-weight: 700;
        min-height: 389px;
    }

    margin: 30px auto 0 auto;
    position: relative;
    max-width: 1270px;
    width: 95%;
    overflow: hidden;

    @include respond-to(tablet) {
        width: 100%;

        // overflow: visible;
    }


    &:before {
        content: "";
        top: -30px;
        left: -100%;
        right: -100%;
        position: absolute;
        height: 5px;
        z-index: 0;
        background-image: url("../img/shadows-stripes/mobile-section-stripes-blue.png");
    }

    #profile-view {
        vertical-align: top;
        display: inline-block;
        position: relative;
        padding-top: 10px;
        width: 100%;

        @include respond-to(tablet) {
            width: 100%;
        }


        @include respond-to(desktop) {
            width: 77%;
        }
    }

    #dashboard-wrapper {
        width: 300%;

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    #order-history, #favourites {
        height: auto;
        width: 33.333333%;

        @include respond-to(tablet) {
            width: 50%;
            display: inline-block;
            padding: 0 unit-vw(10px, tablet);
            height: unit-vw(760px, tablet);
        }


        @include respond-to(desktop) {
            height: 700px;
            padding: 0 10px;
        }
    }

    #profile-search, #profile-last-search {
        height: auto;
        width: 33.333333%;

        @include respond-to(tablet) {
            width: 50%;
            margin-top: unit-vw(20px, tablet);
            display: inline-block;
            padding: 0 unit-vw(10px, tablet);
            min-height: unit-vw(320px, tablet);
        }


        @include respond-to(desktop) {
            margin-top: 45px;
            min-height: 250px;
            padding: 0 10px;
        }

        .title {
            padding: 0 0 12px 0;
            min-height: unset;
        }
    }

    #order-history, #favourites, #profile-search, #profile-last-search {
        .title {
            display: none;

            @include respond-to(tablet) {
                display: inline-block;
            }
        }
    }

    #Order-Cart {
        .title {
            display:block;
        }
    }

    .blue-button {
        @extend .component-button-1;
        &.active {
                    border: 2px solid $button-color;
                    background-color: $button-color;
                    color: $white;
                }
    }

    .button-container {
        position: absolute;
        top: 5px;
        right: 18px;
        display: none;
        text-align: right;

        @include respond-to(tablet) {
            display: inline-block;
            margin-top: unit-vw(5px, tablet);
        }


        @include respond-to(desktop) {
            margin-top: 5px;
        }


        .blue-button {
            
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s ease-out, visibility 0 linear 0.3s;
            &:last-child {
                margin-left: 10px;
            }

            @include respond-to(tablet) {
                &:last-child {
                    margin-left: 0;
                }
            }


            @include respond-to(desktop) {
                &:last-child {
                    margin-left: 5px;
                }
            }

            &.hidden {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .button-container-bottom {
        @extend .button-container;

        position: relative;
        top: auto;
        bottom: 10px;
        margin-top: 20px;
        text-align: right;
        display: block;

        @include respond-to(tablet) {
            /*position: absolute;*/
        }
    }

    .in-progress {
        @extend .in-progress-profile-component;
    }
}

.mobile-menu {
    display: block;
    width: 33.3333333%;
    margin-bottom: 20px;

    li {
        @extend .component-button-1;

        display: inline-block;
        position: relative;
        padding: 0;
        text-align: center;
        min-height: 60px;
        margin-left: 1.333333%;
        width: 24%;

        &:before {
            content: "";
            position: absolute;
            display: none;
            left: 5%;
            top: 50%;
            transform: translateY(-50%);
            height: 3.5vw;
            width: 3.5vw;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        @include respond-to(mobile-lg) {
            padding-left: 7.5%;

            &:before {
                display: inline-block;
            }
        }


        &:first-child {
            margin-left: 0;
        }

        &.active {
            background-color: $button-color;
        }

        &.upcomming {
            &:before {
                background-image: url("../img/icons/icon-calendar-grey.png");
            }
        }

        &.order-history {
            &:before {
                background-image: url("../img/icons/ico_order-history.png");
            }
        }

        &.favourites {
            &:before {
                background-image: url("../img/icons/ico-favourites.png");
            }
        }

        &.last-search {
            &:before {
                background-image: url("../img/icons/icon-magnifying.png");
            }
        }

        &.active {
            &.upcomming {
                &:before {
                    background-image: url("../img/icons/icon-calendar.png");
                }
            }

            &.order-history {
                &:before {
                    background-image: url("../img/icons/ico_order-history-white.png");
                }
            }

            &.favourites {
                &:before {
                    background-image: url("../img/icons/ico-favourites-white.png");
                }
            }

            &.last-search {
                &:before {
                    background-image: url("../img/icons/icon-magnifying-white.png");
                }
            }
        }

        a {
            position: relative;
            margin: 0 auto;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
            width: auto;
            height: auto;
            text-align: center;
            line-height: 1.2em;
            font-size: unit-vw(12px);
            white-space: pre-line;
            color: $button-color;

            @include respond-to(mobile-lg) {
                position: absolute;
                width: auto;
                padding: 10px 0;
                font-size: unit-vw(10px);
                text-align: left;
            }
        }

        &.active {
            a {
                color: $white;
            }
        }
    }

    @include respond-to(tablet) {
        display: none;
    }
}

#profile-edit {
    span.fwb {
    padding: 7px;
    display: inline-block;
    color: #fff;
    background: #4267b2 ;
    border-radius: 5px;
    font-weight: 700;
    }
    .fb-like {
        margin-left: 20px;
        top: -5px;
    }

    .editprofile {
        width: 100%;

        input {
            border-radius: 0;
        }

        @include respond-to(desktop) {
            display: table;
            height: 100%;
        }


        @include clearfix;

        .editfield {
            margin-bottom: 2.2vw;

            &.half {
                width: 45%;
                float: left;

                input {
                    width: 100%;
                }
            }

            &.half + .half {
                float: right;
            }

            &.full {
                label {
                    width: 100%;
                }

                input {
                    width: 100%;
                }
            }

            &.height, &.weight, &.birthday {
                margin-bottom: 30px;

                label {
                    width: auto;
                    vertical-align: middle;
                    line-height: 42px;
                }

                input {
                    width: auto;

                    &.ng-invalid {
                        box-shadow: 0 0 4px $error-color;
                    }
                }
            }

            &.height {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .control-label {
                    order: 4;
                }

                @include respond-to(desktop) {
                    width: 60%;
                    float: left;
                    justify-content: flex-start;
                }


                label {
                    float: left;
                    padding-right: 5%;
                    width: 100%;

                    @include respond-to(desktop) {
                        width: auto;
                    }
                }

                input {
                    width: 45%;
                    height: 100%;

                    &:nth-of-type(2n) {
                        float: right;
                    }

                    @include respond-to(desktop) {
                        float: left;
                        width: 30%;
                        margin-left: 5%;
                    }
                }
            }

            &.weight {
                width: 50%;
                float: left;

                @include respond-to(desktop) {
                    width: 34%;
                    float: right;
                }


                input {
                    width: 55%;
                    float: right;
                }
            }

            &.birthday {
                width: 100%;
                clear: both;
                display: flex;
                flex-wrap: wrap;

                .control-label {
                    order: 5;
                }

                input {
                    width: 16%;
                    margin-left: 3%;
                    height: 100%;

                    @include respond-to(desktop) {
                        width: 14%;
                    }

                    &:last-of-type {
                        width: 24%;
                    }

                    &.ng-invalid {
                        box-shadow: 0 0 4px $error-color;
                    }
                }

                //contact icon removal for safari
                input::-webkit-contacts-auto-fill-button {
                    visibility: hidden;
                    display: none !important;
                    pointer-events: none;
                    position: absolute;
                    opacity: 0;
                    right: 0;
                }
            }

            &.select {
                margin-bottom: 27px;

                @include clearfix;

                label {
                    width: 100%;
                    float: left;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 35px;

                    @include respond-to(desktop) {
                        width: 41%;
                    }
                }

                select {
                    width: 100%;
                    float: right;
                    height: 50px;
                    &:focus, &:active{
                        outline:none;
                    }

                    @include respond-to(desktop) {
                        width: 55%;
                        height: 35px;
                    }
                }
            }
            &.gender .title{
                width:16%;
                float:left;

            }
            &.radio.gender{
                label {
                    width: 25% !important;
                }
            }
            &.radio {
                margin-bottom: 25px;

                @include clearfix;

                label {
                    width: 40%;
                    float: left;

                    @include respond-to(desktop) {
                        width: auto;
                        min-width:100px;
                    }


                    &:first-of-type {
                        width: 100%;

                        @include respond-to(desktop) {
                            width: 40%;
                        }
                    }
                }

                input[type=radio] + label:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 40px;
                    border: 2px solid $color-21;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }

                input[type=radio]:checked + label:before {
                    border-color: $color-13;
                    background: $color-13;
                }

                input[type=radio] {
                    visibility: hidden;
                        width: 0;
                        display: none;
                }

                input {
                    float: left;
                    + label {
                        text-align: center;
                    }
                }
            }
        }

        .save {
            width: 150px;
            text-align: center;
            background: $color-13;
            color: $white;
            padding: 10px;
            font-weight: 700;
            float: right;
            border: none;
            cursor: pointer;
        }

        label, .title {
            @include fonts-vw(ProximaNova, 16px, 16px);

            font-weight: 700;
            color: $color-9;
            padding-bottom: 13px;
            width: 100%;
            display: inline-block;

            &.has-error-backend, &.has-error {
                color: $white;
                padding: 10px;
                line-height: 1 !important;
            }
        }

        input[type=text], input[type=email], input[type=number], input[type=phone] {
            padding: 11px;

            @include fonts-vw(ProximaNova, 14px, 14px);

            border: 2px solid $color-21;
        }

        ::-webkit-input-placeholder {
            @include fonts-vw(ProximaNova, 14px, 14px);

            color: $color-11;
        }

        :-moz-placeholder {
            /* Firefox 18- */
            @include fonts-vw(ProximaNova, 14px, 14px);

            color: $color-11;
        }

        ::-moz-placeholder {
            /* Firefox 19+ */
            @include fonts-vw(ProximaNova, 14px, 14px);

            color: $color-11;
        }

        :-ms-input-placeholder {
            font-size: 9px;
            color: $color-11;
        }

        select {
            width: 100%;
            height: 50px;
            border: 2px solid $color-21;

            @include respond-to(desktop) {
                height: 35px;
            }
        }

        .picturechange {
            display: none;

            @include respond-to(desktop) {
                display: block;
            }


            margin-bottom: 20px;
            text-align: center;

            .picture {
                width: 120px;
                float: left;
                position: relative;

                img {
                    width: 100%;
                }

                .remove {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: -17px;
                    width: 12px;
                    height: 16px;
                    cursor: pointer;
                    background: url('../img/icons/icon-trash.png') no-repeat 0 0;
                }
            }

            label {
                margin-left: 20%;
                margin-top: 10%;
                width: auto;
                display: inline-block;
                padding: 10px 30px;
                background: $color-13;
                color: $white;
                font-weight: $font-w-semibold;
                float: left;
                cursor: pointer;

                &.control-label {
                    font-family: ProximaNova;
                    font-weight: $font-w-semibold;
                    background: $error-color;
                    color: $white;
                    display: block;
                    width: 100%;
                    padding: 9px 10px;
                    position: relative;
                    margin-top: 10px;
                    text-align: left;
                    margin-left: 0;
                }
            }

            input {
                visibility: hidden;
            }

            @include clearfix;
        }
    }

    .form-row {
        display: table-row;
    }

    .left-column {
        display: block;
        height: 100%;
        width: 100%;

        @include respond-to(desktop) {
            padding-right: 26px;
            display: table-cell;
            width: 49%;
        }


        @include clearfix;
    }

    .right-column {
        display: block;
        height: 100%;
        width: 100%;

        @include respond-to(desktop) {
            padding: 14px;
            background: $bg-color-1;
            display: table-cell;
            width: 51%;
        }


        @include clearfix;

        .half {
            margin-bottom: 25px;

            @media screen and (max-width: 361px) {
                label {
                    min-height: 45px;
                }
            }
        }
    }

    .friends {
        margin-top: 30px;
        width: 100%;

        @include clearfix;

        h1 {
            @include fonts-vw(ProximaNova, 18px, 18px);

            font-weight: 700;
            line-height: 45px;
            margin-bottom: 50px;
        }

        .facebook {
            width: 230px;

            @include fonts-vw(ProximaNova, 12px, 18px);

            font-weight: 600;
            display: inline-block;
        }

        .friend {
            width: 50%;
            float: left;
            padding-right: 2%;
            margin-bottom: 50px;

            &:nth-of-type(2n+2) {
                display: none;
            }

            @include respond-to(desktop) {
                width: 25%;
                margin-bottom: 10px;

                &:nth-of-type(2n+2) {
                    display: block;
                }
            }


            .description {
                width: calc(100%-90px);
                float: left;

                title {
                    width: 100%;
                    display: inline-block;

                    @include fonts-vw(ProximaNova, 14px, 14px);

                    font-weight: 700;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                .status {
                    background: $color-13;
                    color: $white;
                    padding: 8px 11px 8px 20px;
                    display: inline-block;
                    font-size: 11px;
                    font-weight: 700;
                }
            }

            .image {
                width: 75px;
                background: url(../img/homepage-feature5.png) no-repeat;
                background-size: cover;
                height: 75px;
                display: inline-block;
                float: left;
                margin-right: 15px;
            }
        }
    }

    .in-progress {
        @extend .in-progress-profile-component;
    }
}

#profile-settings {
    header {
        @include fonts-vw(ProximaNova, 20px, 20px);

        display: block;
        padding-bottom: 10px;
        height: 100%;
        font-weight: 700;
        color: $color-9;
        padding-right: 65px;

        @include respond-to(tablet) {
            padding-right: 0;
        }
    }
    #payment-form{
        .save{
            float:right;
            clear:none;
            margin-top:0;
        }
    }
    #payment-braintree{
        width:70%;
        display:inline-block;
        float:left;
    }
    .left-column {
        width: 100%;
        float: left;

        @include respond-to(tablet) {
            width: 40%;
            margin-left: 2%;
        }


        @include respond-to(desktop) {
            margin-left: 0;
        }


        header {
            position: relative;

            // height: 35px;

        }

        header:after {
            content: "";
            background: url(../img/shadows-stripes/mobile-section-stripes-gray.png) repeat-x center 0;
            width: 100%;
            height: 5px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        input[type=checkbox] {
            visibility: hidden;
        }

        label {
            width: 100%;
            display: inline-block;
            font-weight: 700;
            line-height: 1.3;
            position: relative;
            padding-left: 40px;

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                // border: 2px solid $color-16;
                margin-right: 20px;
                line-height: 1;
                position: absolute;
                top: 0px;
                left: 0;
                background-image: url('../img/icons/icon-unchecked-business.png');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        input:checked + label:before {
            background-image: url('../img/icons/icon-checked-business.png');
            // content: 'x';
            // color: $white;
            // background: $color-13;
            // border-color: $color-13;
            // text-align: center;
            // font-weight: 400;
        }

        .email {
            padding-bottom: 25px;

            header {
                background: url("../img/icons/icon-newlsetter.png") no-repeat;
                background-position: 95% 10%;
                background-size: 30px auto;

                @include respond-to(tablet) {
                    padding-left: 45px;
                    background-position: 0 10%;
                }
            }
        }

        .notification {
            padding-bottom: 25px;

            header {
                background: url("../img/icons/icon-notification.jpg") no-repeat;
                background-position: 95% 75%;
                padding-top: 20px;

                @include respond-to(tablet) {
                    padding-left: 45px;
                    background-position: 0 40%;
                }
            }

            .save {
                margin-right: 34px;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }

        .save {
            float: right;

            @include respond-to(tablet) {
                float: left;
            }
        }
    }

    .right-column {
        width: 100%;
        float: right;

        @include respond-to(tablet) {
            width: 55%;
        }


        .password-changing {
            background: $bg-color-1;
            padding: 31px 34px;

            @include clearfix;

            .password-facebook-icon {
                img {
                    max-width: 30px;
                }
            }
            .password-facebook-text {
                position:relative;
                top: 8px;
                padding-left: 10px;
                font-weight: bold;
            }
        }

        .payment-changing {
            margin-top: 20px;
            padding: 31px 34px;
            width:100%;
            clear:both;

            .image {
                margin-top: 25px;
                display: inline-block;
                width: 200px;
                height: 30px;
                background: url(../img/paypal.png) no-repeat;

                /* background-position: left center; */
                background-size: auto 100%;
            }
        }

        .form-group {
            + .has-error-backend {
                float: right;
                width: 100%;
                @include respond-to(desktop) {
                    width: 50%;
                }

                @include fonts-vw(ProximaNova, 14px, 14px);

                padding: 6px;
                text-align: center;
                line-height: 1;
            }
        }

        .save {
            float: right;
            clear: both;
            &.left{
                float:left;
                clear:none;

            }
            &.right{
                float:right;
                clear:none;
            }
        }

        .field {
            width: 100%;

            @include clearfix;

            margin-top: 18px;

            label {
                width: 100%;
                text-align: left;
                font-weight: 700;

                @include fonts-vw(ProximaNova, 16px, 16px);

                vertical-align: middle;
                line-height: 40px;
                display: inline-block;

                @include respond-to(desktop) {
                    text-align: right;
                    width: 40%;
                }


                &.has-error-backend, &.has-error {
                    float: right;
                    width: 50%;
                    width: 100%;
                    @include respond-to(desktop) {
                        width: 50%;
                    }

                    @include fonts-vw(ProximaNova, 14px, 14px);

                    padding: 6px;
                    text-align: center;
                    line-height: 1;
                }
            }

            input[type=password] {
                width: 100%;
                float: right;
                background: $bg-color-1;
                border: 2px solid $color-21;
                padding: 10px;
                border-radius: 0;

                @include respond-to(desktop) {
                    width: 50%;
                }
            }
        }
    }

    .save {
        margin-top: 25px;
        color: $white;
        background: $color-13;
        padding: 8px 12px;
        border-left: 1px solid #104793;
        border-right: 1px solid #104793;
        border-bottom: 1px solid #104793;
        border-top: 1px solid #bdd0ec;
        min-width: 109px;
        cursor: pointer;
    }

    .in-progress {
        @extend .in-progress-profile-component;
    }
}

#profile-activities {

    h2{
        @include fonts-vw(ProximaNova, 24px, 24px);
        font-weight:700;
        padding:20px 0 0px 0;
    }

    #recent-excersises, #recent-places, #devices {
        padding: 15px;
        min-height: unit-vw(400px, mobile);
        border: 2px solid $border-color-activity;
        font-family: $font-proxima;

        @include respond-to(mobile-lg) {
            min-height: 400px;
        }


        @include respond-to(tablet) {
            padding: unit-vw(15px, tablet);
            min-height: unit-vw(400px, tablet);
        }


        @include respond-to(desktop) {
            min-height: 400px;
        }
    }

    #recent-excersises, #recent-places {
        margin-right: 3%;
        display: inline-block;
        width: 22%;
        text-align: center;
        width: 48%;
        margin-right: 0;

        &:first-child {
            margin-right: 2%;
        }

        @include respond-to(tablet) {
            margin-right: 3%;
            display: inline-block;
            width: 22%;
            text-align: center;

            &:first-child {
                margin-right: 3%;
            }
        }


        @include respond-to(desktop) {
            margin-right: 3%;
            display: inline-block;
            width: 22%;
            text-align: center;
        }


        .recent-number {
            position: relative;
            display: block;
            margin: 0 auto 15px;
            width: unit-vw(80px, mobile);
            height: unit-vw(80px, mobile);
            border-radius: unit-vw(40px, mobile);

            @include respond-to(mobile-lg) {
                width: 80px;
                height: 80px;
                border-radius: 40px;
            }


            @include respond-to(tablet) {
                width: unit-vw(80px, tablet);
                height: unit-vw(80px, tablet);
                border-radius: unit-vw(40px, tablet);
            }


            @include respond-to(desktop) {
                width: 80px;
                height: 80px;
                border-radius: 40px;
            }


            span {
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                font-weight: $font-w-normal;
                line-height: 1em;
                font-size: unit-vw(60px, mobile);
                color: $white;

                @include respond-to(mobile-lg) {
                    font-size: 60px;
                }


                @include respond-to(tablet) {
                    font-size: unit-vw(60px, tablet);
                }


                @include respond-to(desktop) {
                    font-size: 60px;
                }
            }

            &.excersises {
                background-color: $button-color;
            }

            &.places {
                background-color: $button-color;
            }
        }

        .recent-title {
            display: block;
            margin-bottom: 15px;
            line-height: 1.2em;
            font-size: unit-vw(22px, mobile);
            font-weight: $font-w-semibold;
            color: $text-logged-menu;

            @include respond-to(mobile-lg) {
                font-size: 22px;
            }


            @include respond-to(tablet) {
                font-size: unit-vw(22px, tablet);
            }


            @include respond-to(desktop) {
                font-size: 22px;
            }
        }
    }

    #recent-excersises {
        .excersise-item {
            position: relative;
            height: 50px;

            .image {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: unit-vw(35px, mobile);
                height: unit-vw(35px, mobile);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                @include respond-to(mobile-lg) {
                    width: unit-vw(25px, mobile);
                    height: unit-vw(25px, mobile);
                }


                @include respond-to(tablet) {
                    width: unit-vw(35px, tablet);
                    height: unit-vw(35px, tablet);
                }


                @include respond-to(desktop) {
                    width: 35px;
                    height: 35px;
                }
            }

            .name {
                position: relative;
                left: 10px;
                display: inline-block;
                padding: 15px 0;
                font-weight: $font-w-bold;
                font-size: unit-vw(22px, mobile);

                @include respond-to(mobile-lg) {
                    font-size: 18px;
                }


                @include respond-to(tablet) {
                    font-size: unit-vw(15px, tablet);
                }


                @include respond-to(desktop) {
                    font-size: 17px;
                }
            }
        }
    }

    #recent-places {
        li {
            height: 30px;
            font-size: unit-vw(18px, mobile);

            @include respond-to(mobile-lg) {
                font-size: 18px;
            }


            @include respond-to(tablet) {
                font-size: unit-vw(15px, tablet);
            }


            @include respond-to(desktop) {
                font-size: 15px;
            }
        }
    }

    #devices {
        margin-top: 30px;
        position: relative;
        display: inline-block;
        width: 100%;
        min-height: auto;

        @include respond-to(tablet) {
            margin-top: 0;
            min-height: unit-vw(400px, tablet);
            width: 50%;
        }


        @include respond-to(desktop) {
            min-height: 400px;
        }


        .title {
            display: inline-block;
            margin-bottom: 30px;
            width: 100%;
            font-weight: $font-w-bold;
            font-size: 35px;
            text-align: center;
        }

        ul {
            display: inline-block;
            width: 100%;
        }

        li {
            display: inline-block;
            width: 33.33333%;
            float: left;
            height: 100px;

            a {
                display: block;
                position: relative;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                background: none;
                border: none;
                outline: none;
                cursor: pointer;

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .other-device-wrapper {
            display: block;
            position: relative;
            width: 97%;
            margin: 0 auto;
            bottom: 0;

            @include respond-to(tablet) {
                position: absolute;
                bottom: 20px;
            }


            button {
                display: inline-block;
                margin: 0 auto;
                bottom: 0;
                padding: 6px;
                width: 70%;
                background: none;
                outline: none;
                border: 3px solid $border-color-activity;
                color: $text-logged-menu;
                font-size: 18px;
                font-weight: $font-w-semibold;
                font-family: $font-proxima;

                @include respond-to(tablet) {
                    font-size: unit-vw(18px, tablet);
                }


                @include respond-to(desktop) {
                    font-size: 18px;
                }
            }

            .coming-soon {
                display: inline-block;
                vertical-align: middle;
                line-height: 45px;
                font-size: 14px;
                color: $text-logged-menu;
            }
        }
    }

    #activities {
        margin-top: 30px;
        width: 100%;

        .activity {
            display: inline-block;
            position: relative;
            margin-bottom: 20px;
            padding: 20px 10px;
            height: auto;
            background-color: $border-color-activity;
            text-align: left;
            width: 100%;

            @include respond-to(tablet) {
                margin-left: 3%;
                margin-bottom: 0;
                width: 22%;
                height: unit-vw(300px, tablet);
                text-align: center;

                &:first-child {
                    margin-left: 0;
                }

                &:nth-child(3), &:nth-child(4) {
                    width: 23.5%;
                }
            }


            @include respond-to(desktop) {
                height: 280px;
            }


            .title {
                display: block;
                margin-bottom: 20px;
                font-weight: $font-w-bold;
                color: $text-logged-menu;

                @include respond-to(tablet) {
                    margin-bottom: unit-vw(20px, tablet);
                }


                @include respond-to(desktop) {
                    margin-bottom: 20px;
                }
            }

            .image-wrap {
                display: inline-block;
                position: relative;
                margin-bottom: 20px;
                width: 25%;
                height: 50px;
                text-align: center;

                @include respond-to(tablet) {
                    margin-bottom: unit-vw(20px, tablet);
                    width: 80%;
                    height: 120px;
                }


                @include respond-to(desktop) {
                    margin-bottom: 20px;
                }
            }

            img {
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                max-width: 80%;
                max-height: 120px;
            }

            .line {
                display: block;
                width: 100%;
                text-align: center;

                @include respond-to(mobile-sm) {
                    width: 33.3333%;
                    display: inline-block;
                }


                @include respond-to(mobile-lg) {
                    width: 33.3333%;
                    display: inline-block;
                }


                .bold-line, .regular-line {
                    display: inline-block;
                    margin: 0 auto;
                    vertical-align: baseline;
                }

                .bold-line {
                    font-size: unit-vw(25px, mobile);
                    font-weight: $font-w-bold;

                    @include respond-to(mobile-lg) {
                        font-size: 25px;
                    }


                    @include respond-to(tablet) {
                        font-size: unit-vw(25px, tablet);
                    }


                    @include respond-to(desktop) {
                        font-size: 25px;
                    }
                }

                .regular-line {
                    font-size: 18px;
                    font-weight: $font-w-semibold;
                    color: $text-logged-menu;
                }
            }
        }
    }

    .in-progress {
        @extend .in-progress-profile-component;
    }
}