$first-column-translate: 64px;
$second-column-translate: 64px;

#page-businessgymsettings {
    @extend .all-business;
    text-align: center;

    .table-wrapper {
        display: inline-block;
        width: 90%;
        max-width: 676px;
        margin: 0 auto;
        overflow: visible;

        @include respond-to(tablet) {
            display: table;
            width: 66%;
            max-width: 676px;
        }
    }

    .table-row-wrapper {
        display: block;
        width: 100%;
        overflow: visible;

        @include respond-to(tablet) {
            display: table-row;
        }
    }

    .form-column {
        display: inline-block;
        position: relative;
        width: 100%;
        overflow: visible;
        text-align: left;
        font-family: $font-proxima;

        &:last-child {
            text-align: right;
        }

        @include respond-to(tablet) {
            display: table-cell;
            position: relative;
            width: 50%;
        }


        label:not(.checkbox-label):not(.validationMessage) {
            position: relative;
            display: inline-block;
            top: 50%;
            width: 25%;
            transform: translateY(-50%);
            text-align: right;
            font-size: 14px;
            line-height: 1em;
            font-weight: $font-w-bold;

            @include respond-to(tablet) {
                width: auto;
                position: absolute;
                left: -10px;
                transform: translate(-110%, -50%);
                text-align: left;
            }
        }

        &:last-of-type {
            @include respond-to(tablet) {
                label:not(.validationMessage) {
                    left: 30%;
                    max-width: 80px;
                    text-align: right;
                    transform: translate(-110%, -50%);
                }
            }
        }

        .business-form-title {
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 15px;
            font-size: 20px;
            font-weight: $font-w-semibold;

            &:first-of-type {
                margin-top: 0;
                margin-bottom: 30px;
            }

            &.checkbox-title {
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }

        &:last-of-type {
            .business-form-title {
                width: 100%;
                text-align: left;

                @include respond-to(tablet) {
                    width: 70%;
                    text-align: left;
                }


                &:first-child {
                    margin-top: 15px;
                }
            }
        }

        input.checkbox {
            display: none;
        }

        .checkbox-label {
            position: relative;
            padding-left: 30px;

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0px;
                left: 0;
                background-image: url("../img/icons/icon-unchecked-business.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        input:checked + .checkbox-label {
            &:before {
                background-image: url("../img/icons/icon-checked-business.png");
            }
        }

        .business-form-subtitle {
            display: inline-block;
            position: absolute;
            top: 40px;
            right: 0;
            width: 100%;
            text-align: left;
            font-size: 12px;
            font-weight: $font-w-bold;

            @include respond-to(tablet) {
                top: 35px;
                width: 70%;
            }
        }

        .input-businessSettings {
            padding: 0 1px;
            width: 73%;
            height: 100%;
            background-color: $color-30;
            border: 1px solid $color-29;
            box-shadow: 0 0 1px $color-29;
            vertical-align: top;

            @include respond-to(tablet) {
                padding: 0 10px;
                width: 70%;
            }
        }

        .input-wrapper {
            position: relative;
            margin-bottom: 10px;
            height: 28px;

            @include respond-to(tablet) {
                transform: translateX($first-column-translate);
            }


            &.two-columns-wrapper {
                height: 66px;

                @include respond-to(tablet) {
                    height: 28px;
                    // width: 70%;
                }
            }

            &.has-error {
                height: 56px;

                input {
                    height: 50%;
                }

                label:not(.validationMessage) {
                    top: 25%;
                }

                .validationMessage {
                    margin-left: auto;
                    margin-right: 11.3%;
                    float: right;
                    width: 73%;

                    @include respond-to(tablet) {
                        margin-right: 0;
                        width: 70%;
                        float: none;
                    }
                }
            }

            #zipCode, #state {
                height: 28px;

                @include respond-to(tablet) {
                    display: inline-block;
                    position: relative;
                }
            }

            #zipCode {
                margin-bottom: 10px;

                @include respond-to(tablet) {
                    margin-right: 0.9%;
                    width: 28%;
                }


                label {
                    @include respond-to(tablet) {
                        width: auto;
                    }
                }

                .input-businessSettings {
                    @include respond-to(tablet) {
                        width: 75%;
                    }
                }
            }

            #state {
                // width: 51%;
                label {
                    left: auto;
                }

                @include respond-to(tablet) {
                    text-align: right;
                    width: 39.5%;

                    label {
                        left: 15%;
                    }
                }


                .input-businessSettings {
                    // width: 70%;
                    @include respond-to(tablet) {
                        width: 81.1%;
                    }
                }
            }
        }

        &:last-of-type {
            .input-wrapper {
                transform: none;
                text-align: left;

                @include respond-to(tablet) {
                    text-align: right;
                }
            }
        }

        .checkboxes-wrapper {
            @include respond-to(tablet) {
                transform: translateX($first-column-translate);
            }


            label, input {
                cursor: pointer;
            }
        }

        .sms-checkboxes-wrapper, .email-checkboxes-wrapper {
            display: inline-block;
            width: 47%;
            vertical-align: top;

            @include respond-to(tablet) {
                display: block;
                width: auto;
            }
        }

        .business-form-aditional-info {
            display: inline-block;
            margin: 15px auto;
            margin-right: 8%;
            padding: 0 2%;
            width: 68%;
            font-size: 10px;
            text-align: left;

            @include respond-to(tablet) {
                margin-right: auto;
                width: 70%;
            }
        }

        .font-11 {
            font-size: 11px;
        }

        .font-12 {
            font-size: 12px;
        }

        .mt-0{
            margin-top: 0px;
        }

        #starsRating {
            display: inline-block;
            width: 100%;
            text-align: left;

            @include respond-to(tablet) {
                width: 70%;
            }
        }

        .rating {
            display: inline-block;
            top: -6px;
            width: 145px;
            height: 24px;
            position: relative;

            @include respond-to(mobile-lg) {
                width: 145px;
            }


            @include respond-to(tablet) {
                width: 145px;
            }


            .empty-stars, .filled-stars {
                display: block;

                li {
                    display: inline-block;
                    height: 24px;
                    width: 15px;
                    margin-left: 1px;
                    background-size: contain;
                    background-repeat: no-repeat;

                    @include respond-to(mobile-lg) {
                        width: 20px;
                    }


                    @include respond-to(tablet) {
                        width: 20px;
                    }
                }
            }

            .empty-stars {
                > ul li {
                    background-image: url("../img/icons/ico-star-empty.png");
                }
            }

            .filled-stars {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;

                li {
                    background-image: url("../img/icons/ico-star-filled.png");
                }
            }
        }

        #comment {
            height: 180px;
        }

        textarea.input-businessSettings {
            height: 180px;
            width: 100%;
            resize: none;
            font-family: $font-proxima;

            @include respond-to(tablet) {
                width: 75%;
                margin-left: auto;
            }
        }
    }

    .submit-button-wrapper {
        display: inline-block;
        margin: 20px auto;
        width: 90%;
        max-width: 676px;
        text-align: right;

        @include respond-to(tablet) {
            width: 66%;
        }
    }

    button[type="submit"] {
        @extend .component-button-1;
        border: 2px solid $color-23;
        background-color: $color-23;
        color: $white;

        &.in-progress {
            padding-right: 40px;
            color: $white;
            background: $color-23;
        }

        @include respond-to(tablet) {
            &:not(.component-button-1--nohover):hover {
                color: $white;
                background: $color-23;
            }
        }
    }

    &.in-progress {
        @extend .in-progress-profile-component;
    }

    #Birthdate {
        z-index: 1;
    }
}