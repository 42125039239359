@keyframes preloader {
   from {transform: rotate(0deg);}
   to {transform: rotate(360deg);}
}

@keyframes preloader-centered {
   from {transform: rotate(0deg) translateX(-50%) translateY(-50%);}
   to {transform: rotate(360deg) translateX(-50%) translateY(-50%);}
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}