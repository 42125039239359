$color-1: #074073;

//Buttons
$color-2: #f2b728;

//Backgrounds
$color-3: #565656;

//Fonts menu
$color-4: #505050;

//Button border
$color-5: #e49b00;

//Blue transparent
$color-6: rgba(23, 99, 204, 0.4);
$color-7: rgba(23, 99, 204, 0.74);
$color-8: #012351;
$color-9: #3e3e3e;
$color-10: #9b9b9b;
$color-11: #acacac;
$color-12: #145ac0;
$color-13: #1b5dbd;
$color-14: #525252;
$color-15: #4479c3;
$color-16: #cccccc;

//how it works header title
$color-17: #4073c6;
$color-18: #737373;
$color-19: #0d4187;
$color-20: #b3c9e9;
$color-21: #b7b7b7;
$color-22: #144bc0;
$color-23: #edaa20;

//Business page backgrounds
$color-25: #1a4fbf;
$color-24: #d0d0d0;
$color-26: #de9808;
$color-27: #848484;
$color-28: #3c3d3f;
$color-29: #b0b0b0;
$color-30: #dadada;
$colot-31: #0d3fb6;

//Business login
$color-32: #414141;
$color-33: #1243b2;
$color-34: #4269c2;
$color-35: #f2bf58;
$color-36: #b8b8b8;
$color-37: #f0f0f0;
$color-38: #dfdfdf;

//Business all gyms
$color-39: #e6e6e6;
$color-40: #f5f5f5;
//End

$facebook-color: #5c79ad;
$white: #ffffff;

$color-default-blue-link: #0f54e4;

$font-color-15: #666666;
$font-color-16: #8e8e8e;

//grey background
$bg-color-1: #e8e8e8;

//blue button borders
$button-color: #145ac0;

//blue facebook button
$button-color-fb: #3b5998;

//border colors
$border-color-checkbox: #acacac;
$border-color-activity: #ebebeb;

//grey checkbox border

$black: #000000;

// logged menu text
$text-logged-menu: #929292;

// error
$error-color: #d04b4b;

// modal
$modal-error: #d04b4b;
$modal-warning: #f2b728;
$modal-success: #52994f;

// social popin
$selected-item: #d04b4b;
$hovered-item: #f2b728;


//traffic
$color-41: #f19900;
$color-42: #e74c3c;