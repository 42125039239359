#details-confirmation {
    overflow: hidden;
    padding-bottom: 30px;

    .detail-row {
        width: 100%;
        margin-top: 51px;

        @include clearfix;

        .detail-column.grey {
            // border-top: 1px solid #ececec;
            position: relative;

            @include respond-to(tablet) {
                background: $bg-color-1;
                border-bottom: 1px solid #ececec;
            }


            header {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 15px;
                padding-left: 25px;

                @include fonts-vw(ProximaNova, 16px, 16px);

                @include respond-to(tablet) {
                    padding-left: 0;
                }
            }

            &:after {
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 20vw;
                left: 100%;
                top: 0;
                background: url("../img/club-about-bg.png") no-repeat;
                background-size: auto 100%;
            }
        }

        .detail-column.promocode {
            padding-top: 27px;
            padding-bottom: 31px;

            .form-group {
                width: 65%;
                display: inline-block;
            }

            .has-error-backend .control-label {
                width: 65%;
            }

            input[type="text"] {
                width: 100%;
                padding: 8px 9px;
                border: 2px solid $color-12;
            }

            a {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    color: $color-13;
                }
            }

            input[type="checkbox"] {
                width: 0;
                visibility: hidden;
                margin: 0;
                padding: 0;

                & + label {
                    display: inline-block;
                    width: auto;
                    padding-right: 5px;
                    margin-bottom: 0; //19px;
                    font-family: ProximaNova;
                    font-weight: 600;
                    color: $color-14;
                    padding-left: 30px;
                    font-size: 13px;
                    line-height: 3;

                    @include respond-to(tablet) {
                        padding-left: 0px;
                        font-size: 15px;
                        line-height: 1;
                    }


                    &:before {
                        content: '';
                        display: inline-block;
                        position: relative;
                        top: 5px;
                        width: 24px;
                        height: 20px;
                        background: url(../img/icons/icon-unchecked-login.png) no-repeat;
                        margin-left: -30px;

                        @include respond-to(tablet) {
                            margin-left: 0px;
                        }
                    }
                }

                &:checked + label {
                    &:before {
                        background: url(../img/icons/icon-checked-login.png) no-repeat;
                        //background: #f1b63b;
                    }
                }
            }

            #payment-form button {
                width: 0;
                height: 0;
                visibility: hidden;
            }

            button {
                float: right;

                &.in-progress {
                    &:after {
                        @include preloader(22px, 22px, 2px, absolute, $color-6, $color-12);
                    }

                    &:hover {
                        &:after {
                            @include preloader(22px, 22px, 2px, absolute, "", "");
                        }
                    }
                }

                &.apply {
                    width: 30%;
                }

                &.purchase {
                    width: 100%;
                }
            }
        }

        #payment-braintree button {
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .detail-column:first-child {
            width: 100%;

            @include respond-to(tablet) {
                width: 52%;
                float: left;
                margin-right: 3%;
            }
        }

        .detail-column:nth-child(2n) {
            width: 100%;

            @include respond-to(tablet) {
                padding-left: 60px;
                width: 45%;
                float: right;
            }
        }

        .detail-column {
            padding: 0 6.5%;

            @include respond-to(tablet) {
                padding: 0;
            }


            section {
                width: 100%;

                @include clearfix;

                .left-section {
                    float: left;
                    width: 30%;
                    padding: 0 1%;

                    img {
                        max-width: 100%;
                    }
                }

                .right-section {
                    width: 70%;
                    padding: 0 1%;
                    float: left;
                }

                &:nth-of-type(-n+2) {
                    @include respond-to(tablet) {
                        margin-bottom: 50px;
                    }
                }
            }
        }



        .payment {
            margin-bottom: 34px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            @include respond-to(mobile-lg) {
                flex-wrap: nowrap;
            }

            @include respond-to(tablet) {
                flex-wrap: wrap;
            }

            @include respond-to(desktop) {
                flex-wrap: nowrap;
            }

            .text {
                margin-top: 14px;
            }

            .method {
                margin-right: 0;
                position: relative;
                z-index: 1;

                @include respond-to(mobile-lg) {
                    margin-right: 10px;
                }

                @include respond-to(tablet) {
                    margin-right: 0;
                }

                @include respond-to(desktop) {
                    margin-right: 10px;
                }

                img {
                    height: 20px;
                }
            }

            button {
                margin-top: 10px;
                width: 100%;

                @include respond-to(mobile-lg) {
                    margin-top: 0;
                    width: auto;
                }

                @include respond-to(tablet) {
                    margin-top: 10px;
                    width: 100%;
                }

                @include respond-to(desktop) {
                    margin-top: 0;
                    width: auto;
                }
            }
        }

        .termsandconditions {
            // padding-left: 25px;
            @include respond-to(tablet) {
                padding-left: 0;
            }

            a {
                line-height: 3;
                display: inline-block;
            }

            input[type=checkbox] + label {
                margin-bottom: 10px;
            }
        }

        .detail-heading {
            text-align: center;
        }

        header {
            font-family: ProximaNova;
            font-weight: 700;
            color: $color-9;

            @include fonts-vw(ProximaNova, 14px, 20px);
        }

        h1 {
            font-family: ProximaNova;
            font-weight: 700;
            color: $black;
            text-align: center;
            display: inline-block;

            @include fonts-vw(ProximaNova, 20px, 36px);

            @include respond-to(tablet) {
                display: block;
            }
        }

        .subtitle {
            text-align: center;
            font-family: ProximaNova;
            font-weight: 700;
            color: $black;
            display: inline-block;

            @include fonts-vw(ProximaNova, 20px, 16px);

            @include respond-to(tablet) {
                display: block;
                font-weight: 400;
                font-size: 16px;
            }
        }

        .detail-gym {
            padding-left: 25px;

            @include respond-to(tablet) {
                padding-left: 0;
                padding-top: 27px;
            }


            header {
                margin-bottom: 5px;

                @include respond-to(tablet) {
                    margin-bottom: 15px;
                }
            }

            .image {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: top center;

                &.empty {
                    background-size: auto 100%;
                    background-color: #008ece;
                    background-image: url('../img/club-logo-placeholder.jpg');
                }
            }

            .details {
                font-family: ProximaNova;
                font-weight: 400;
                line-height: 22px;
                color: $color-9;

                @include respond-to(tablet) {
                    font-size: 16px;
                }

                @include fonts-vw(ProximaNova, 14px, 16px);
            }
        }

        .detail-class {
            background: $bg-color-1;
            border-bottom: 1px solid #ececec;
            padding: 20px 0 20px 20px;
            margin: 20px 0;

            @include respond-to(tablet) {
                background: none;
                border: none;
                padding: 0;
                margin: 0;
            }


            .left-section {
                font-family: ProximaNova;
                font-weight: 600;
                font-size: 14px;
                line-height: 25px;
                padding-left: 25px;

                @include respond-to(tablet) {
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .details {
                font-family: ProximaNova;
                font-weight: 300;
                font-size: 15px;
                line-height: 25px;
                color: $color-9;

                @include respond-to(tablet) {
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }

        .detail-passport {

            @include respond-to(tablet) {
            }


            header {
                margin-bottom: 5px;
                font-size: 18px;
            }

            .details {
                font-family: ProximaNova;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $color-9;
            }
        }

        ul.prices {
            margin-bottom: 42px;
            margin-top: 30px;

            li {
                font-family: ProximaNova;
                font-weight: 300;
                font-size: 16px;
                line-height: 30px;
                color: $color-9;

                @include respond-to(tablet) {
                    font-weight: 700;
                    font-size: 18px;
                }


                .price {
                    width: 30%;
                    display: inline-block;
                }
            }

            li.total {
                font-weight: 700;
                font-size: 20px;
                background: url("../img/shadows-stripes/dash-3.png") repeat-x;
                background-size: auto 5px;
                background-position: top;
                padding-top: 10px;
                margin-top: 10px;
                text-transform: uppercase;

                @include respond-to(tablet) {
                    font-size: 18px;
                }
            }
        }
    }
}

#mobile-workout{
    @include respond-to(desktop) {
        display:none;
    }
}
#desktop-workout{
    display:none;
    @include respond-to(desktop) {
        display:block;
    }
}