/*
 * Font-face
 */
@include sc-font-face(ProximaNova, proxima_nova_regular-webfont, normal);
@include sc-font-face(ProximaNova, proxima_nova_bold-webfont, bold);
@include sc-font-face(ProximaNova, proxima_nova_extrabold-webfont, $font-w-xbold);
@include sc-font-face(ProximaNova, proxima_nova_semibold-webfont, $font-w-semibold);
@include sc-font-face(ProximaNova, proxima_nova_light-webfont, $font-w-light);
@include sc-font-face(ProximaNovaCondensed, proxima_nova_condensed_bold-webfont, bold);
@include sc-font-face(ProximaNovaCondensed, proxima_nova_condensed_regular-webfont, normal);
@include sc-font-face(ProximaNovaCondensed, proxima_nova_condensed_semibold-webfont, $font-w-semibold);
@include sc-font-face(ProximaNovaCondensed, proxima_nova_condensed_extrabold-webfont, $font-w-xbold);
@include sc-font-face(Roboto, roboto-light-webfont, $font-w-light);
@include sc-font-face(Roboto, roboto-medium-webfont, $font-w-medium);
@include sc-font-face(Roboto, roboto-regular-webfont, normal);
@include sc-font-face(Roboto, roboto-bold-webfont, bold);
@include sc-font-face(RobotoBlack, roboto-black-webfont , normal);


/*
 * Font variables
 */

$font-proxima: ProximaNova, Arial, Tahoma, sans-serif;
$font-proxima-cond: ProximaNovaCondensed, Arial, Tahoma, sans-serif;
$font-roboto: Roboto, Arial, Tahoma, sans-serif;
