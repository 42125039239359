﻿#Payment-Method {
    position: relative;
    padding-bottom: 85px;
    overflow: unset;

    .payment-reminders {
        padding-top: 25px;

        @media (max-width: 768px) {
            padding-top: 15px;
            padding-bottom: 10px;
        }

        label {
            font-weight: bold;
        }
    }

    .reminders-list {
        padding-top: 10px;
        list-style-type: initial;
        padding-left: 25px;

        .reminder-label {
            font-weight: bold;
        }

        .option {
            padding-bottom: 5px;
        }
    }

    .accepted-header {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .payment-logos {
        padding-bottom: 20px;
    }

    .terms {
        margin-top: 0;
        margin-bottom: 25px;
        float: left;
        width: 100%;
        padding-top: 25px;
        @include clearfix();

        @media (max-width: 768px) {
            font-size: 15px;
        }

        &.checkbox input[type=checkbox] + label.validationMessage {
            margin-bottom: 25px;
        }
    }

    .payment-disclaimer {
        font-weight: bold;
        padding-top: 15px;
        clear: both;
    }


    #payment-form {
        position: relative;


        button {
            position: absolute;
            top: 0;
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .promocode {
            .promocode-sub-label {
                font-weight: normal;
                padding-top: 10px;
                padding-bottom: 15px;
            }

            span {
                margin: 0px 0 16px 0;
                width: 100%;
                display: inline-block;

                @include clearfix;
            }

            .has-error {
                box-shadow: 0 0 4px #d04b4b !important;
            }

            .promocode-error {
                clear: both;
                padding-top: 10px;
                color: #d04b4b;
            }

            .button {
                border: 2px solid $color-33;
                color: $color-33;
                float: left;
                background: $white;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;
                @include fonts-vw(ProximaNova, 13px, 13px);
                width: 29%;
                margin: 0;
                padding: 11px 0;
                text-align: center;
                cursor: pointer;
                position: relative;
                line-height: 1.2;
                clear: left;
                margin-top: 10px;
            }

            input[type="text"] {
                border: 2px solid $color-33;
                background: $white;
                padding: 10.5px;
                width: 69%;
                float: left;
                margin-right: 2%;
            }
        }

        .summary {
            background-color: #fff;
            padding: 15px 30px;

            @media (max-width: 768px) {
                padding-bottom: 15px;
            }

            .position {
                color: #2f2f2f;
                font-weight: 700;
                margin-top: 13px;
                float: left;
                width: 100%;
                clear: both;

                @include fonts-vw(ProximaNova, 18px, 18px);

                &.tax {
                    color: #b0b0b0;
                }

                &.total {
                    color: #1243b2;

                    .summary-price {
                        width: 35%;
                        margin-right: 5%;
                        border-top: 2px solid;
                    }

                    margin-bottom: 10px;
                }

                > div {
                    width: 40%;
                    float: left;
                }

                > div:last-of-type() {
                    width: 60%;
                    float: left;
                }
            }
        }

        .free-trial-promo {
            color: #1243b2;
            padding-top: 15px;
        }

        .payment {
            .payment-button {
                background: #1189d5;
                padding: 10px;
                border-color: #1189d5;
                box-shadow: none;
                border-radius: 10px;
                color: $white;
                margin-bottom: 20px;
            }

            input[type="text"] {
                padding: 32px 15px 18px 15px;
                background: $white;
                border-top: 1px solid #d6dbdf;
                border-bottom: none;
                border-left: none;
                border-right: none;

                @include fonts-vw(ProximaNova, 16px, 16px);
            }

            input[type="text"]:last-of-type {
                border-bottom: 1px solid #d6dbdf;
            }
        }
    }


}
#payment-braintree {
    margin-right: 27px;
}
