#profile-last-search {
    &#profile-last-search {
        padding: 0;
        padding-bottom: 23px;
    }
    position: relative;
    display: inline-block;
    overflow:hidden;

    .table-wrap {
        display: block;
        width: 100%;

        @include respond-to(tablet){
            display: table;
        }

    }

    .table-row-wrap {
        display: block;
        width: 100%;

        @include respond-to(tablet){
            display: table-row;
        }
    }

    .last-search-wrap {
        display: block;
        position: relative;
        margin-bottom: 10px;
        padding: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border: none;  
        cursor:pointer;

        > *:last-child {
            margin-bottom: 23px;
        }

        @include respond-to(tablet){
            margin: 0;
            padding: 0 3% 0 0;
            width: 50%;
            display: table-cell;
        }

        .background {
            position: absolute;
            float:left;
            width: 100%;
            height: 300px;
            background-color: $bg-color-1;
            z-index: -1;

            @include respond-to(tablet){
                width: 97%;
            }
            &:last-child {
                .background {
                    width: 100%;
                }
            }
        }

    }


    .title {
        position: relative;
        display: inline-block;
        padding: 12px 0 40px 7.5%;
        width:105%;
        font-size: 24px;
        font-weight: $font-w-bold;
        background-color: $white;

        @include respond-to(tablet){
            min-height: 75px;
            padding: unit-vw(40px, tablet) 0 unit-vw(12px, tablet);
            font-size: unit-vw(24px, tablet);

        }

        @include respond-to(desktop){
            padding: 40px 0 12px;
            font-size: 24px;
        }
    }

     .name, .address-line-wrpaper, .activity-filter-wrpaper {
        padding: 23px 0 0 25px;
        display: block;
     }

     .name {
        color: $color-9;
        font-weight: $font-w-bold;
     }

     .address-line {
        display:block;
        color: $color-9;
     }

    .activity-filter {
        @extend .component-button-1;
        margin-right: 20px;
        margin-bottom: 10px;
        background: none;
        cursor: default;
        font-size: 13px;


        @include respond-to(tablet){
            margin-right: unit-vw(20px, tablet);
            margin-top: unit-vw(25px, tablet);
            margin-bottom: 0;
            height: unit-vw(35px, tablet);
            font-size: unit-vw(14px, tablet);
            line-height: unit-vw(32px, tablet);

            &:first-child {
                margin-top: 0;
            }
        }

        @include respond-to(desktop) {
            margin-top: 25px;
            margin-right: 20px;
            height: 35px;
            font-size: 14px;
            line-height: 32px;
        }

        &.activity-filter {
            &:hover {
                background: none;
                color: $button-color;
            }
        }
    }

}