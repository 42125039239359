$header-height: 44px;

.main-popup {
  position: absolute;    
  margin: 0 auto;
  padding: 30px 0;
  width: 66%;
  min-width: 320px;
  max-width: 920px;
  text-align: center;
  z-index: 3;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white;

  &.disable {
    display: none;
  }

  .close-icon{
      position: absolute;
      width: 14px;
      height: 14px;
      right: 5px;
      top: 5px;
      background-color : grey;
      background-image: url('../img/icons/ex_white.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
  }

  #headWrap {
    display: inline-block;
    margin: 20px auto 0;
    padding: 9px 6px;
    border: 1px solid $color-18;
    border-radius: 10px;
    background-color: $color-37;
  }

  .header-image {
    display: inline-block;
    height: $header-height;
  }

  .header-text {
    display: inline-block;
    height: $header-height;
    vertical-align: top;
    font-family: $font-proxima;
    color: $color-18;
  }
  .first-line {
    display: block;
    font-size:28px;
    .bolded {
      font-weight: $font-w-bold;
    }
  }
  .second-line {
    font-size: 17px;
    color: $color-10;
    font-weight: $font-w-bold;
  }

  .main-content {
    margin: 20px auto 0;
    padding: 20px;
    width: 90%;
    min-width: 300px;
    display: block;
    text-align: center;
    background-color: $color-16;

    p {
      display: block;
      margin: 0 auto 10px;
      text-align: center;
      font-size: 14px;
      line-height: 1.2em;
    }
  }

  .form-wrapper {
    margin: 20px auto 0;
    width: 90%;
    min-width: 300px;
    text-align: left;
  }

  .input-wrapper{
    position: relative;
    margin-bottom: 10px;
    margin-right: 5px;
    min-width: 155px;
    display:inline-block;    
    height: 28px;
    width: 40%;
    vertical-align: top;
    overflow: visible;
    
    input {
      padding: 5px;
      width: 100%;
      outline: none;
      border: 1px solid $color-16;
      outline: none;
      border-radius: 0;
      appearance: none; 
    }

    &.has-error {
        height:60px;

        // input {
        //     height: 50%;
        // }

        label:not(.validationMessage) {
            top:25%;
        }

        .validationMessage{
            width: 100%;
            font-size: 13px;
            height: 30px;
        }
    }
  }

  input[type="submit"] {
    @extend .component-button-1;
    display: block;
    clear: both;
    border-radius: 0;
    appearance: none;
    height:auto;
    line-height:35px;
  }

  &.in-progress {
      @extend .in-progress-profile-component;
       position:absolute;
  }
  .continue {
     &.left{
      float:left;
     }
        cursor: pointer;
        padding: 14px 27px;
        border: none;
        background: $color-23;
        color: $white;
        text-transform: uppercase;

        @include fonts-vw(ProximaNova, 13.5px, 13.5px);

        font-weight: 700;
        margin:50px 50px 0 50px;
        display: block;
        float: right;

        

        // @include respond-to(tablet) {
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        // }
        &.back {
            margin-left: 0;
            margin-right: 15px;
            line-height: 1.2;
            float: left;
        }
    }
}
