#page-search {
    .searchlocation {
        input {
            height: 32px;
            line-height: 28px;
            width: 55vw;

            @include respond-to(tablet) {
                height: 27px;
                line-height: 24px;
                width: 100%;
            }
        }
    }

    .map-controls .map-filter.apply-submit.component-button-1 {
        display: none;

        @include respond-to(tablet) {
            display: inline-block;
        }
    }

    .other {
        position: relative;

        &:before {
            content: 'Coming soon';
            font-size: 3vw;
            font-weight: 700;
            color: #fff;
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.48);
            text-align: center;
            padding-top: 20%;
        }
    }

    + .component-footer-decoration {
        position: relative;

        &:before {
            content: "";
            background: $bg-color-1;
            position: absolute;
            top: -10px;
            bottom: -10px;
            left: 0;
            right: 0;
            z-index: -1;
        }

        @include respond-to(tablet) {
            &:before {
                display: none;
            }
        }
    }

    .club-list {
        &.in-progress {
            position: relative;

            &:before {
                content: "";
                background: rgba(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 4;
            }

            &:after {
                content: "";

                @include preloader(15vw, 15vw, 2px, absolute, $color-6, $color-12);
                margin: auto;
                z-index: 5;

                @include respond-to(tablet) {
                    width: 5vw;
                    height: 5vw;
                }
            }
        }

        $colRightPadding: 5%;

        .club-list-filter {
            margin: 15px 0;
            padding: 0 10px;
            display: flex;
            flex-direction: column;

            @include respond-to(tablet) {
                @include clearfix;
                margin: 30px 0 45px;
                padding: 0;
                flex-direction: row;
                justify-content: space-between;
            }


            .club-list-filter-label {
                color: #9b9b9b;
            }

            .filter-locale {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                padding-right: 10px;
                position: relative;
                z-index: 3;
                transition-property: z-index;
                transition-delay: 0.4s;

                body[data-overlayactive="true"] & {
                    z-index: 1;
                    transition-delay: 0s;
                }

                @include respond-to(tablet) {
                    display: none;
                    padding-right: 0;
                }


                .mapTrigger {
                    @include sprite($map-ico-map);
                    cursor: pointer;
                    transition: all 0.25s ease-in-out;
                    font-family: ProximaNova;
                    font-weight: 600;
                    font-size: 12px;
                    color: $color-12;
                    background-repeat: no-repeat;
                    position: relative;

                    span {
                        text-align: center;
                        position: absolute;
                        bottom: -15px;
                        left: -10px;
                        right: -10px;
                    }

                    body.show-map & {
                        @include sprite($map-ico-list);
                    }
                }
            }

            .filter-basic {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                order: 2;
                margin-top: 10px;

                @include respond-to(mobile-lg) {
                    flex-wrap: nowrap;
                }


                @include respond-to(tablet) {
                    order: 1;
                    width: 50%;
                    padding-right: 2.5%;
                    flex-wrap: wrap;
                    margin-top: 0;
                }


                .update {
                    order: 4;
                    margin-top: 10px;

                    @include respond-to(mobile-lg) {
                        order: 4;
                        margin-top: 2px;
                        margin-left: 5px;
                    }


                    @include respond-to(tablet) {
                        display: none;
                    }
                }

                .extra-deals {
                    order: 3;

                    @include respond-to(mobile-lg) {
                        order: 1;
                    }


                    @include respond-to(tablet) {
                        order: 2;
                    }


                    @include respond-to(desktop) {
                        order: 1;
                    }


                    label {
                        position: relative;
                        line-height: 34px;
                        border-color: $color-2;
                        background: transparent;

                        @include respond-to(tablet) {
                            color: $color-12;
                            border-color: $color-12;
                            padding-left: 36px;
                        }


                        input {
                            display: none;

                            &:checked {
                                & + span {
                                    color: $white;

                                    @include respond-to(tablet) {
                                        color: $color-12;
                                    }


                                    &:before {
                                        background: $color-2;
                                        z-index: -1;
                                    }
                                }
                            }

                            @include respond-to(tablet) {
                                &:checked {
                                    & + span {
                                        &:before {
                                            background: $button-color;
                                            border-color: $button-color;
                                        }

                                        &:after {
                                            position: absolute;
                                            content: "";
                                            top: 12px;
                                            left: 12px;

                                            @include sprite($layout-checkbox-icon);
                                        }
                                    }
                                }
                            }
                        }

                        span {
                            color: $color-2;

                            &:before {
                                content: "";
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                z-index: -1;
                            }

                            @include respond-to(tablet) {
                                color: $color-12;

                                &:before {
                                    left: 10px;
                                    top: 8px;
                                    width: 16px;
                                    height: 16px;
                                    z-index: 1;
                                    border: 1px solid $border-color-checkbox;
                                }
                            }
                        }
                    }
                }

                .result-number {
                    line-height: 36px;
                    order: 1;

                    @include respond-to(mobile-lg) {
                        order: 2;
                    }


                    @include respond-to(tablet) {
                        order: 1;
                        width: 100%;
                        text-align: right;
                    }


                    @include respond-to(desktop) {
                        order: 2;
                        width: auto;
                    }


                    span, strong {
                        vertical-align: middle;
                    }

                    strong {
                        color: #3e3e3e;
                    }
                }

                .sort {
                    white-space: nowrap;
                    line-height: 36px;
                    margin-left: 0;
                    order: 2;

                    @include respond-to(mobile-lg) {
                        order: 3;
                    }


                    @include respond-to(tablet) {
                        margin-left: auto;
                        display: block;
                        order: 3;
                    }


                    @include respond-to(desktop) {
                        margin-left: 0;
                    }


                    .label, .select {
                        vertical-align: middle;
                    }

                    .label {
                        display: inline-block;
                        margin-right: 7px;
                    }

                    .select {
                        position: relative;
                        display: inline-block;
                        width: 120px;
                        height: 35px;
                        line-height: 32px;
                        padding: 0 0 0 10px;
                        border: 2px solid $button-color;
                        transition: all $trans-duration;
                        color: $button-color;
                        font-weight: bold;
                        font-size: 13px;
                        text-transform: uppercase;
                        white-space: nowrap;
                        cursor: pointer;

                        &:before {
                            position: absolute;
                            z-index: 1;
                            content: "";

                            @include sprite($layout-select-caret-icon);
                            top: 13px;
                            right: 8px;
                            background-color: #fff;
                        }

                        .current {
                            position: relative;
                            z-index: 1;
                        }

                        .list {
                            position: absolute;
                            z-index: 1;
                            display: none;
                            background: #fff;
                            top: 29px;
                            left: -2px;
                            right: -2px;
                            border: 2px solid $button-color;
                            border-top: none;
                            line-height: 25px;

                            &.visible {
                                display: block;
                            }

                            li {
                                padding: 2px 10px 0;

                                &:hover {
                                    background: $button-color;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .filter-more {
                position: relative;
                order: 1;

                @include respond-to(tablet) {
                    order: 2;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 50%;
                    padding-left: 2.5%;
                }


                @include respond-to(desktop) {
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                }


                .filter-more-label {
                    display: none;

                    @include respond-to(tablet) {
                        display: flex;
                        width: 100%;
                        line-height: 38px;
                        text-transform: uppercase;
                        text-align: right;
                    }


                    @include respond-to(desktop) {
                        width: 30%;
                        text-align: left;
                        white-space: nowrap;
                    }
                }

                .option-wrap {
                    display: flex;
                    justify-content: space-between;
                    line-height: 35px;
                    text-align: right;
                    white-space: nowrap;
                    position: relative;
                    z-index: 2;
                    transition-property: z-index;
                    transition-delay: 0.4s;

                    body[data-overlayactive="true"] & {
                        z-index: 1;
                        transition-delay: 0s;
                    }

                    @include respond-to(tablet) {
                        width: 100%;
                    }


                    @include respond-to(desktop) {
                        width: 70%;
                    }


                    .option {
                        vertical-align: middle;
                        padding: 0 5px;
                        font-size: 12px;

                        @include respond-to(tablet) {
                            padding: 0 10px;
                            font-size: 13px;
                        }


                        &.active {
                            color: #fff;
                            background: $button-color;
                        }
                    }
                }

                .option-content-wrap {
                    .option-content {
                        display: none;
                        position: absolute;
                        left: -10px;
                        right: -10px;
                        z-index: 1;
                        background: $bg-color-1;
                        padding: 15px 10px;
                        color: $font-color-15;
                        font-size: 14px;

                        &:before {
                            content: "";
                            background: $bg-color-1;
                            position: absolute;
                            top: -100%;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            z-index: -1;
                        }

                        @include respond-to(tablet) {
                            background: $bg-color-1;
                            padding: 25px;
                            top: 109px;
                            left: 0;
                            right: 0;
                            min-height: inherit;

                            &:before {
                                display: none;
                            }
                        }


                        @include respond-to(desktop) {
                            top: 69px;
                        }


                        .apply-buttons {
                            text-align: right;

                            .apply-reset {
                                float: left;
                            }
                        }

                        &.option-content--checkbox-list {
                            .heading {
                                @include clearfix;
                                margin-bottom: 25px;

                                p {
                                    font-size: 13px;
                                    line-height: 1.4;

                                    @include respond-to(tablet) {
                                        float: left;
                                        width: 72%;
                                        padding-right: 30px;
                                    }
                                }

                                .search-input {
                                    position: relative;
                                    padding: 8px 10px 7px 35px;
                                    display: block;
                                    margin: 0 auto;
                                    width: 80%;
                                    border-bottom: 1px solid #817979;

                                    @include respond-to(tablet) {
                                        display: inline-block;
                                        border: 2px solid $button-color;
                                        float: left;
                                        width: 28%;
                                    }


                                    &:before {
                                        position: absolute;
                                        content: "";
                                        left: 8px;
                                        top: 8px;

                                        @include sprite($layout-search-icon);
                                    }

                                    input {
                                        width: 100%;
                                        background: transparent;
                                        border: none;
                                        font-size: 13px;
                                        font-weight: bold;
                                        color: $font-color-15;

                                        @include input-placeholder {
                                            opacity: 0.7;
                                            color: $font-color-15;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }

                            .current-filter {
                                @include clearfix;
                                margin-bottom: 15px;
                                display: flex;
                                justify-content: center;

                                @include respond-to(tablet) {
                                    display: block;
                                }


                                .label {
                                    white-space: nowrap;
                                    margin-right: 10px;
                                    line-height: 35px;

                                    @include respond-to(tablet) {
                                        line-height: 1;
                                        margin-right: 0;
                                        position: relative;
                                        z-index: 21;
                                        float: left;
                                        width: 90px;
                                        margin-right: -90px;
                                        margin-top: 11px;
                                    }
                                }

                                .current-filter-items {
                                    position: relative;
                                    z-index: 20;

                                    @include respond-to(tablet) {
                                        float: left;
                                        width: 100%;
                                        padding-left: 90px;
                                    }


                                    li {
                                        position: relative;
                                        float: left;
                                        height: 35px;
                                        line-height: 32px;
                                        padding: 0 38px 0 10px;
                                        margin-right: 10px;
                                        margin-bottom: 5px;
                                        border: 2px solid $button-color;
                                        transition: all $trans-duration;
                                        color: $button-color;
                                        font-weight: bold;
                                        font-size: 13px;
                                        text-transform: uppercase;
                                        white-space: nowrap;

                                        @include respond-to(tablet) {
                                            margin-right: 30px;
                                            margin-bottom: 12px;
                                        }


                                        .current-filter-remove {
                                            position: absolute;
                                            right: 11px;
                                            top: 7px;
                                            width: 18px;
                                            height: 18px;
                                            background: $button-color;
                                            cursor: pointer;

                                            &:before {
                                                position: absolute;
                                                content: "";
                                                top: 50%;
                                                margin-top: -5px;
                                                left: 50%;
                                                margin-left: -6px;

                                                @include sprite($layout-remove-icon);
                                            }
                                        }
                                    }
                                }
                            }

                            .filter-fields {
                                @include clearfix;
                                margin-bottom: 25px;

                                @include respond-to(mobile-sm) {
                                    margin-bottom: 0px;
                                }

                                .filter-fields-col {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;

                                    @include respond-to(tablet) {
                                        padding: 0 15px;
                                    }


                                    @include respond-to(desktop) {
                                        justify-content: flex-start;
                                    }


                                    li {
                                        width: 50%;

                                        @include respond-to(desktop) {
                                            width: 33%;
                                        }


                                        label {
                                            position: relative;
                                            display: block;
                                            padding: 8px 0 6px 36px;
                                            line-height: 1.3;
                                            cursor: pointer;

                                            input {
                                                display: none;

                                                &:checked {
                                                    & + span {
                                                        &:before {
                                                            background: $button-color;
                                                            border-color: $button-color;
                                                        }

                                                        &:after {
                                                            position: absolute;
                                                            content: "";
                                                            top: 12px;
                                                            left: 12px;

                                                            @include sprite($layout-checkbox-icon);
                                                        }
                                                    }
                                                }
                                            }

                                            span {
                                                &:before {
                                                    position: absolute;
                                                    content: "";
                                                    left: 10px;
                                                    top: 8px;
                                                    width: 16px;
                                                    height: 16px;
                                                    border: 1px solid $border-color-checkbox;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.option-content--date-time {
                            .button-today {
                                width: 100%;
                                margin-bottom: 20px;
                                text-align: center;
                            }

                            .date-time-slider-wrap {
                                padding-left: 40px;
                                padding-right: 40px;
                                margin-top: 20px;

                                #filter-date-time-slider {
                                    height: 1px;
                                    border: none;
                                    margin-bottom: 20px;

                                    .noUi-base {
                                        background: #b6b6b6;

                                        &:before, &:after {
                                            position: absolute;
                                            content: "";
                                            top: 0;
                                            width: 20px;
                                            height: 1px;
                                            background: #b6b6b6;

                                            @include respond-to(desktop) {
                                                width: 40px;
                                            }
                                        }

                                        &:before {
                                            left: -20px;

                                            @include respond-to(desktop) {
                                                left: -40px;
                                            }
                                        }

                                        &:after {
                                            right: -20px;

                                            @include respond-to(desktop) {
                                                right: -40px;
                                            }
                                        }
                                    }

                                    .noUi-handle {
                                        width: 40px;
                                        height: 14px;
                                        top: -6px;
                                        left: -20px;
                                        background: $button-color;
                                        border-radius: 5px;
                                        border: none;
                                        box-shadow: none;
                                        cursor: pointer;

                                        &:before, &:after {
                                            display: none;
                                        }

                                        @include respond-to(desktop) {
                                            width: 80px;
                                            left: -40px;
                                        }
                                    }
                                }

                                .legend {
                                    @include clearfix;
                                    margin: 0 -40px 30px;

                                    .legend-item {
                                        font-size: 14px;
                                        font-weight: bold;

                                        &.min {
                                            float: left;
                                        }

                                        &.max {
                                            float: right;
                                        }
                                    }
                                }
                            }

                            .selected {
                                margin-bottom: 15px;
                                font-size: 16px;
                                color: $color-13;

                                .label {
                                    margin-bottom: 8px;
                                }

                                .values {
                                    .value {
                                        display: inline-block;
                                        font-weight: bold;

                                        &.value-date {
                                            margin-right: 60px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .club-list-items-wrap {
            overflow: hidden;
            height: 0;
            position: relative;
            // @media screen and (max-width: 767px) and (orientation: landscape) {
            //   overflow: visible;
            //   body.show-map & {
            //     overflow: hidden;
            //   }
            // }
            @include respond-to(tablet) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin: -1vw -1vw 0;
            }


            .club-list-items {
                overflow: auto;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: left 0.25s ease-in-out;
                background: $bg-color-1;

                @media screen and (max-width: 767px) and (orientation: landscape) {
                    position: relative;
                }

                @include respond-to(tablet) {
                    background: none;
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 50%;
                    padding: 1vw 2vw 1vw 1vw;
                }


                .club-list-item {
                    width: 100%;
                    padding: 15px 10px 20px;
                    background: url("../img/shadows-stripes/mobile-section-stripes-gray.png") repeat-x left bottom;
                    display: flex;
                    justify-content: space-between;

                    &.event {
                        /*border: 4px solid #145ac0;*/
                        /*padding: 5px;*/
                        .event-description-wrapper {
                            color: #000;
                            margin-bottom: 10px;
                            height: 125px;
                            overflow: hidden;
                        }

                        .event-button {
                            background-color: #145ac0;
                            color: #fff;
                            width: 100px;
                            margin: 0 auto;
                            text-align: center;
                            padding: 10px 5px;
                            margin-top: 10px;
                        }

                        .event-time-wrapper {
                            color: #000;
                            padding-bottom: 25px;
                        }
                    }


                    &:last-child {
                        background: none;
                    }

                    @include respond-to(tablet) {
                        display: block;
                        background: none;
                        padding: 1vw 0 0 0;
                        margin-bottom: 3vw;
                        margin-top: -1vw;
                        width: 47.5%;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -13px;
                            right: -13px;
                            bottom: -20px;
                            background: $white;
                            z-index: -1;
                            transition: background 0.25s ease-in-out;
                        }

                        &:nth-last-child(2):nth-child(odd), &:last-child {
                            margin-bottom: 0;
                        }

                        &:hover, &.active {
                            &:before {
                                background: $bg-color-1;
                            }
                        }

                        &.active {
                            outline: 0;
                            animation: pulse 0.5s ease-in-out;
                        }
                    }


                    .image {
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain; //auto 100%;
                        height: 0;
                        width: 30%;
                        padding-bottom: 30%;

                        &.empty {
                            background-color: #008ece;
                            background-image: url("../img/club-logo-placeholder.jpg");

                            @include respond-to(tablet) {
                                background-size: auto 100%;
                            }
                        }

                        @media screen and (max-width: 767px) and (orientation: landscape) {
                            width: 20%;
                            padding-bottom: 20%;
                        }

                        @include respond-to(tablet) {
                            width: 100%;
                            padding-bottom: 61.8%;
                            margin-bottom: 10px;
                            background-size: contain; //100%
                        }


                        .heart {
                            display: none;

                            @include respond-to(tablet) {
                                display: block;
                                fill: #bcc9dc;
                                width: 3vw;
                                float: right;
                                margin: 1vw;
                            }


                            @include respond-to(desktop) {
                                width: 30px;
                                margin: 15px;
                            }
                        }

                        .fbthumb {
                            display: none;

                            @include respond-to(tablet) {
                                display: block;
                                float: right;
                            }
                        }

                        .friends-list {
                            background-color: #fff;
                            border: 2px solid #000;
                            padding: 5px;
                            position: absolute;
                            top: 55px;
                            color: #000;
                        }

                        .badge {
                            display: none;

                            @include respond-to(tablet) {
                                display: none;
                                background: url("../img/icons/ihrsa.png") center center no-repeat;
                                width: 6vw;
                                height: 3vw;
                                background-size: 100%;
                                margin: 1vw 1vw 1vw 0;
                                float: right;
                            }


                            @include respond-to(desktop) {
                                width: 54px;
                                height: 27px;
                                margin: 15px 15px 15px 0;
                            }
                        }
                    }

                    .tier {
                        width: 65%;

                        @media screen and (max-width: 767px) and (orientation: landscape) {
                            width: 75%;
                        }

                        @include respond-to(tablet) {
                            width: 100%;
                        }
                    }

                    .name {
                        margin-bottom: 10px;

                        span {
                            font-weight: bold;
                            color: $black;
                            font-size: 18px;
                            line-height: 1.3;
                            margin-right: 10px;

                            @include respond-to(tablet) {
                                margin-right: 0;
                            }


                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .heart {
                            fill: $black;
                            width: 15px;
                            position: relative;
                            top: 2px;

                            @include respond-to(tablet) {
                                display: none;
                            }
                        }

                        .fbthumb {
                            width: 15px;
                            position: relative;
                            top: 2px;

                            @include respond-to(tablet) {
                                display: none;
                            }
                        }
                    }

                    .rating-price {
                        @include clearfix;
                        margin-bottom: 12px;

                        .rating {
                            @include respond-to(tablet) {
                                float: left;
                                width: 110px;
                            }


                            span {
                                @include sprite($layout-rating-star);
                                float: left;
                                margin-right: 4px;

                                :last-child {
                                    margin-right: 0;
                                }

                                &.active {
                                    @include sprite($layout-rating-star-active);
                                }
                            }
                        }

                        .price {
                            float: right;
                            font-weight: bold;
                            color: $black;
                            font-size: 18px;
                        }
                    }

                    .contact-data {
                        color: #666666;
                        font-size: 14px;
                        line-height: 1.4;

                        .separator {
                            margin-left: -3px;
                        }

                        .badge {
                            display: none;
                            background: url("../img/icons/ihrsa.png") center center no-repeat;
                            width: 80px;
                            height: 40px;
                            background-size: 100%;
                            margin-top: 10px;

                            @include respond-to(tablet) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .club-list-items-inner {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                height: 100%;

                &.few-items {
                    align-items: flex-start;
                }
            }

            .zero-results {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-direction: column;
                flex-wrap: wrap;

                strong {
                    font-size: 20px;
                }
            }

            .club-list-map {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 100%;
                transition: left 0.25s ease-in-out;

                @include respond-to(tablet) {
                    position: relative;
                    left: inherit;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 50%;
                    padding: 0 1vw 0 2vw;
                }
            }
        }
    }
}

@include respond-to(mobile-sm) {
    .show-map {
        .club-list-items {
            left: -100% !important;
        }

        .club-list-map {
            left: 0 !important;
        }
    }
}


@include respond-to(mobile-lg) {
    .show-map {
        .club-list-items {
            left: -100% !important;
        }

        .club-list-map {
            left: 0 !important;
        }
    }
}