.detail-steps {
    width: 100%;
    padding: 0 6.5%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
    display: flex;

    @include clearfix;

    @include respond-to(tablet) {
        width: 50%;
        padding: 0;
        transform: translateX(12.5%);
        margin-bottom: 40px;
    }


    .detail-onestep {
        font-family: ProximaNova;
        font-weight: 500;
        font-size: 12px;
        color: $color-10;
        position: relative;
        display: inline-block;
        width: 32%;
        float: left;
        margin: 0;
        padding: 0;
        text-align: left;
        border-top: 2px dotted $bg-color-1;
        padding-top: 20px;
        vertical-align: top;
        cursor:pointer;

        a {
            color: inherit;
            text-decoration: underline;
            cursor: pointer;
            display: inline-block;
            &:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
        }

        @include respond-to(tablet) {
            width: 24%;
        }


        .status {
            position: absolute;
            top: -25px;
            // left: 30px;
            left: 52%;
            font-size: 10px;

            @include respond-to(tablet) {
                top: -15px;
                left: 30px;
                font-size: 12px;
            }
        }

        &:nth-of-type(5) {
            .status {
                left:37%;

                @include respond-to(tablet){
                    left: 30px;
                }
            }
        }
        &:nth-of-type(6) {
            .status {
                left: 43%;

                @include respond-to(tablet){
                    left: 30px;
                }
            }
        }


        .title {
            font-family: ProximaNova;
            font-weight: 500;
            font-size: 15px;
            display: none;

            @include respond-to(tablet) {
                display: block;
            }
        }
        .clicker {
            display: block;
    position: absolute;
    top: -70%;
    left: -11%;
    width: 64%;
    height: 140%;
    // display: block;
    // position: absolute;
    // top: 10%;
    // left: -11%;
    // width: 64%;
    // height: 100%;
}

        .onestep-description {
            display: none;

            @include respond-to(tablet) {
                display: block;
            }
        }

        &.current, &.link:hover {
            .title {
                color: $color-13;
                font-weight: 700;
            }

            &:after {
                background: $color-13;
                border-color: $color-13;
                color: $white;
            }
        }

        &.done {
            &:after {
                background: $bg-color-1;
                color: $white;
            }
        }

        &:last-of-type {
                border-top: 2px solid #fff;
            width: 3%;

            @include respond-to(tablet) {
                // float: none;
                width: 24%;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            border: 2px solid $bg-color-1;
            border-radius: 20px;
            background: $white;
            position: absolute;
            left: 0;
            top: -10px;
            text-align: center;
            color: $bg-color-1;
            line-height: 19px;

            @include respond-to(tablet) {
                width: 20px;
                height: 20px;
            }
        }

        &:nth-of-type(1):after {
            content: '1';

            @include respond-to(tablet) {
                content: '';
            }
        }

        &:nth-of-type(2):after {
            content: '2';

            @include respond-to(tablet) {
                content: '';
            }
        }

        &:nth-of-type(3):after {
            content: '3';

            @include respond-to(tablet) {
                content: '';
            }
        }

        &:nth-of-type(4):after {
            content: '4';

            @include respond-to(tablet) {
                content: '';
            }
        }
         &:nth-of-type(5):after {
            content: '5';

            @include respond-to(tablet) {
                content: '';
            }
        }
         &:nth-of-type(6):after {
            content: '6';

            @include respond-to(tablet) {
                content: '';
            }
        }
         &:nth-of-type(7):after {
            content: '7';

            @include respond-to(tablet) {
                content: '';
            }
        }
    }

    //Customization for business pages
    .business & {
        width: 100%;
        display: block;
        margin-top: 40px;
        transform: translateX(0%);
          @include respond-to(tablet) {
            width: 100%;
            transform: translateX(2%);
        }
        @include respond-to(desktop) {
            width: 100%;
            transform: translateX(4%);
        }


        .detail-onestep {
            width: 14%;
        }
    }
}