.access-page {
    padding-bottom: 80px;
    h1 {
        font-family: ProximaNova;
        font-weight: 700;
        font-size: 48px;
        color: $white;
        text-align: center;
        margin-bottom: 29px;

        @include respond-to(tablet) {
            font-size: 36px;
        }

        + p {
            color: $white;
            text-align: center;
        }
    }

    .text {
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 24px;
        color: $white;
        text-align: center;

        @include respond-to(tablet) {
            font-size: 22px;
        }
    }

    input[type="text"] {
        width: 100%;
        padding: 7px;
        margin-top: 24px;
        border: none;
        padding-left: 30px;
        font-family: ProximaNova;
        color: $color-15;
        font-weight: 600;
        font-size: 16px;
    }

    input[type="email"], input[type="password"] {
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 16px;
        color: $color-15;
        width: 100%;
        padding: 7px;
        margin-top: 24px;
        border: none;
    }

    ::-webkit-input-placeholder {
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 16px;
        color: $color-15;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 16px;
        color: $color-15;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 16px;
        color: $color-15;
    }

    :-ms-input-placeholder {
        font-family: ProximaNova;
        font-weight: 600;
        font-size: 16px;
        color: $color-15;
    }

    input[type="email"] {
        background-image: url("../img/icons/icon-email-grey.png");
        background-color: $white ;
        background-repeat: no-repeat;
        background-position: 10px 50%;
        padding-left: 30px;
    }

    input[type="password"] {
        background-image: url("../img/icons/icon-lock.png");
        background-color: $white ;
        background-repeat: no-repeat;
        background-position: 10px 50%;
        padding-left: 30px;
    }

    // input[type="password"]:last-of-type {
    //     margin-left: 6%;
    // }

    div.keepsigned {
        display: inline-block;
        width: 100%;
        position: relative;
        margin-top: 30px;

        + .keepsigned {
            margin-top: 25px;
        }

        @include clearfix;
    }

    .helper {
        text-align: right;
        margin-top: 16px;
        a {
            font-family: ProximaNova;
            font-weight: 600;
            text-decoration: none;
            border-bottom: 1px solid $white;
            font-size: 14px;
            color: $white;

            @include respond-to(tablet) {
                font-size: 16px;
            }
        }
    }

    .createaccount {
        text-align: center;
        font-family: ProximaNova;
        font-weight: 300;
        font-size: 13px;
        color: $white;
        margin-top: 35px;

        @include respond-to(tablet) {
            font-size: 16px;
        }

        a {
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 13px;
            color: $white;
            text-decoration: underline;
        }
    }

    button.sign-up,
    button.log-in {
        font-family: Roboto;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        margin: 35px auto 0;
        display: block;
        text-transform: uppercase;
        padding: 11px 65px;
        color: $white;
        border: solid 2px $color-2;
        background: $color-2;
        border-left: 2px solid #e9a500;
        border-bottom: 2px solid #e9a500;
        border-right: 2px solid #e9a500;
        border-top: 2px solid #f4c44f;
        cursor: pointer;

        @include respond-to(tablet) {
            width: auto;
            font-size: 13px;
        }
    }

    input[type="checkbox"] {
        width: 100%;
        height: 100%;
        visibility: hidden;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;

        & ~ .input-label {
            display: block;
            width: 100%;
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 14px;
            color: $white;
            padding-left: 25px;

            @include respond-to(tablet) {
                font-size: 16px;
            }

            @include clearfix;

            &:before {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                background: url("../img/icons/checkbox-unchecked.png") no-repeat;
                background-size: cover;
                transform: translateY(20%);
                position: absolute;
                top: -3.5px;
                left: 0;
            }
        }
    }

    input[type="checkbox"]:checked ~ .input-label {
        &:before {
            background: url("../img/icons/checkbox-checked.png") no-repeat;
            background-size: cover;
        }
    }
}