// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$map-ico-list-name: 'map-ico-list';
$map-ico-list-x: 0px;
$map-ico-list-y: 0px;
$map-ico-list-offset-x: 0px;
$map-ico-list-offset-y: 0px;
$map-ico-list-width: 33px;
$map-ico-list-height: 18px;
$map-ico-list-total-width: 66px;
$map-ico-list-total-height: 36px;
$map-ico-list-image: '../img/map.png';
$map-ico-list: (0px, 0px, 0px, 0px, 33px, 18px, 66px, 36px, '../img/map.png', 'map-ico-list', );
$map-ico-map-name: 'map-ico-map';
$map-ico-map-x: 33px;
$map-ico-map-y: 18px;
$map-ico-map-offset-x: -33px;
$map-ico-map-offset-y: -18px;
$map-ico-map-width: 33px;
$map-ico-map-height: 18px;
$map-ico-map-total-width: 66px;
$map-ico-map-total-height: 36px;
$map-ico-map-image: '../img/map.png';
$map-ico-map: (33px, 18px, -33px, -18px, 33px, 18px, 66px, 36px, '../img/map.png', 'map-ico-map', );
$map-spritesheet-width: 66px;
$map-spritesheet-height: 36px;
$map-spritesheet-image: '../img/map.png';
$map-spritesheet-sprites: ($map-ico-list, $map-ico-map, );
$map-spritesheet: (66px, 36px, '../img/map.png', $map-spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
