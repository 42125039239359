﻿.small-popup {
    position: absolute;
    margin: 0 auto;
    padding: 0;
    width: 500px !important;
    min-width: 320px;
    max-width: 920px;
    text-align: center;
    z-index: 3;
    top: 3vh;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #ffffff;

    @include respond-to(mobile-sm) {
        width: 290px !important;
        min-width: 290px;
    }

    /*@include respond-to(mobile-lg) {
        width: 290px !important;
        min-width: 290px;
    }*/

}

.small-popup.disable {
    display: none;
}

.small-popup .header {
    display: inline-block;
    height: 44px;
    vertical-align: top;
    font-family: ProximaNova, Arial, Tahoma, sans-serif;
    color: #737373;
    position: relative;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    line-height: 40px;
    border-bottom: 1px solid #145ac0;
    font-weight: bold;
    color: #145ac0;
    font-size: 16px;

    @include respond-to(mobile-sm) {
        height: unset;
        min-height: 44px;
        line-height: 24px;
        padding-right: 15px;
    }
}

.small-popup .close-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    right: 5px;
    top: 5px;
    background-color: grey;
    background-image: url("../img/icons/ex_white.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}



.small-popup .main-content {
    margin: 20px auto 0;
    padding: 20px;
    width: 90%;
    min-width: 290px;
    display: block;
    text-align: center;
    background-color: #fff;
}

.small-popup .main-content p {
    display: block;
    margin: 0 auto 10px;
    text-align: center;
    font-size: 14px;
    line-height: 1.2em;
}
.small-popup .main-content textarea {
    border: 1px solid #999;
}
.small-popup .main-content textarea:focus {
    border: 1px solid #999;
}
.text-red {
    color: #f00;
}

.small-popup .form-wrapper {
    margin: 20px auto 0;
    width: 90%;
    min-width: 300px;
    text-align: left;
}

.small-popup .input-wrapper {
    position: relative;
    margin-bottom: 10px;
    margin-right: 5px;
    min-width: 155px;
    display: inline-block;
    height: 28px;
    width: 40%;
    vertical-align: top;
    overflow: visible;
}

.small-popup .input-wrapper input {
    padding: 5px;
    width: 100%;
    outline: none;
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.small-popup .input-wrapper.has-error {
    height: 60px;
}

.small-popup .input-wrapper.has-error label:not(.validationMessage) {
    top: 25%;
}

.small-popup .input-wrapper.has-error .validationMessage {
    width: 100%;
    font-size: 13px;
    height: 30px;
}

.small-popup input[type="submit"] {
    display: block;
    clear: both;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: auto;
    line-height: 35px;
}

.small-popup.in-progress {
    position: absolute;
}

.small-popup .continue {
    cursor: pointer;
    padding: 14px 27px;
    border: none;
    background: #edaa20;
    color: #ffffff;
    text-transform: uppercase;
    font-family: ProximaNova;
    /*font-size: unit-vw($fontsize-1, $sc-mobile-lg-min);*/
    font-weight: 700;
    margin: 18px 26px 10px 26px;
    display: block;
    float: right;
}

.small-popup .continue.left {
    float: left;
}

@media (max-width: 479px) {
    .small-popup .continue {
        font-size: 13.5px;
    }
}

@media (min-width: 1024px) {
    .small-popup .continue {
        font-size: 13.5px;
    }
}

.small-popup .continue.back {
    margin-left: 0;
    margin-right: 15px;
    line-height: 1.2;
    float: left;
}
