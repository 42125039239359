#page-businessgymrevenue {
    margin: 0 auto;
    width: 100%;
    max-width: 768px;

    * {
        font-family: $font-proxima;
    }

    .section {
        margin-bottom: 10px;
    }

    #club-select {
        margin-top: 15px;
        width: 100%;
        text-align: left;

        @include respond-to(tablet) {
            margin-top: 0;
            width: auto;
            text-align: right;
        }
    }

    .club-select-wrapper {
        position: relative;
        display: inline-block;
        padding: 10px 15px;
        width: 100%;
        background-color: $color-39;
        white-space: nowrap;

        @include respond-to(tablet) {
            width: auto;
        }


        span.text {
            display: inline-block;
            font-size: 11px;
            font-weight: $font-w-bold;
            text-transform: uppercase;

            @include respond-to(tablet) {
                font-size: 16px;
            }
        }

        #clubsFilter {
            display: inline-block;
            padding-left: 2%;
            background: none;
            border: none;
            outline: none;
            float: right;

            @include respond-to(tablet) {
                float: none;
            }


            button {
                position: relative;
                background: none;
                border: none;
                outline: none;
                font-weight: $font-w-semibold;
                color: $color-18;
                cursor: pointer;
            }

            .submit {
                padding: 7px 14px;
                background-color: $color-39;
            }

            .btn {
                padding-right: 15px;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 15px;
                    height: 15px;
                    border: 1px solid $color-18;
                    background-image: url("../img/icons/ico-arrow-down.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 80%;
                }
            }

            ul {
                overflow: auto !important;
                position: absolute;
                display: block;
                bottom: 0px;
                right: 0px;
                z-index: 1;
                padding: 9px 14px 9px 20px;
                transform: translateY(100%);
                width: auto;
                max-width: 60%;
                height: auto;
                max-height: 30vh;
                background-color: $white;
                border: 1px solid $color-39;
            }

            li {
                padding: 5px 0;
                cursor: pointer;
                clear: both;

                a {
                    &:active, &:hover, &:focus {
                        outline: none;
                        border: none;
                    }
                }

                span {
                    display: inline-block;
                    position: relative;
                    width: 20px;
                    height: auto;
                    min-height: 16px;
                    float: right;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        right: 0;
                        top: 50%;
                        height: 15px;
                        width: 15px;
                        transform: translateY(-50%);
                        background-image: url("../img/icons/icon-unchecked-business.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    &.glyphicon-ok {
                        &:before {
                            background-image: url("../img/icons/icon-checked-business.png");
                        }
                    }
                }
            }
        }
    }

    .transaction-wrapper {
        width: 100%;
        float: none;
        background: $color-39;
        padding: 10px;
        margin-bottom: 10px;

        @include respond-to(tablet) {
            width: 50%;
            margin-bottom: 0;
            float: left;
            height: 150px;
        }

        .icon-wrapper {
            float: left;
            width: 25%;

            @include respond-to(tablet) {
                margin-top: 10%;
            }

            .icon {
                display: block;
                height: 55px;
                width: 100%;
                max-width: auto;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        .text-container {
            width: 75%;
            float: left;
            padding-left: 10px;

            .text-wrap {
                padding-bottom: 8px;

                @include respond-to(tablet) {
                }

                .title {
                    font-weight: $font-w-bold;
                    font-size: 13px;
                    text-transform: uppercase;
                    color: $color-18;
                }

                .value {
                    font-size: 27px;
                    font-weight: $font-w-bold;
                }

                .value.summary-date-range {
                    font-size: 16px;
                }
            }
        }
    }

    .transaction-wrapper.all-transactions {

        @include respond-to(tablet) {
            border-right: 5px solid #fff;
        }

        .icon-wrapper {
            .icon {
                background-image: url("../img/icons/ico-total-revenue.png");
            }
        }

        .second-text-wrap {
            margin-top: 15px;
        }
    }

    .transaction-wrapper.custom-transactions {
        .icon-wrapper {
            .icon {
                background-image: url("../img/icons/ico-total-cost.png");
            }
        }

        .calendar-summary-date {
        }
    }

    .custom-search-wrapper {
        @include respond-to(mobile-sm) {
        }

        .buttons-wrapper {
            display: block;
            width: 98%;
            text-align: center;
            margin: 0 auto;
            padding-bottom: 8px;

            @include respond-to(tablet) {
                width: 50%;
                margin: initial;
                float: left;
            }

            button {
                @extend .component-button-grey;
                width: 32%;
                height: 60px;
                padding: 3px;
                font-size: 12px;
                line-height: 1.2;
                float: left;
                white-space: initial;
                margin: 0 2px;

                @include respond-to(tablet) {
                    height: 50px;
                }

                @include respond-to(mobile-sm) {
                }

                &:first-of-type {
                    @include respond-to(tablet) {
                    }
                }

                &.active {
                    border: 2px solid $button-color;
                    background-color: $button-color;
                    color: $white;
                }
            }
        }

        .calendar-inputs-wrapper {
            display: block;
            width: 98%;
            text-align: center;
            margin: 0 auto;
            padding-bottom: 8px;

            @include respond-to(tablet) {
                float: left;
                width: 50%;
                margin: initial;
                padding: 0;
            }

            .input-search {
                width: 48%;
                font-size: 12px;
                line-height: 1.2;
                float: left;
                white-space: initial;
                margin: 0 2px;

                input {
                    height: 50px;
                    cursor: pointer;
                }
            }
        }
    }


    #tableFields {
        margin-bottom: 10px;
        position: relative;
        text-align: right;

        .dropdown-toggle {
            position: relative;
            padding-right: 15px;
            font-weight: $font-w-semibold;
            color: $color-18;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 15px;
                height: 15px;
                border: 1px solid $color-18;
                background-image: url("../img/icons/ico-arrow-down.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 80%;
            }
        }

        ul {
            overflow: auto !important;
            position: absolute;
            display: block;
            bottom: 0px;
            right: 0px;
            z-index: 1;
            padding: 9px 14px 9px 20px;
            transform: translateY(100%);
            width: auto;
            max-width: 60%;
            height: auto;
            max-height: 30vh;
            background-color: $white;
            border: 1px solid $color-39;
        }

        li {
            padding: 5px 0;
            cursor: pointer;

            a {
                &:active, &:hover, &:focus {
                    outline: none;
                    border: none;
                }
            }

            span {
                display: inline-block;
                position: relative;
                width: 20px;
                height: auto;
                min-height: 16px;
                float: right;

                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    right: 0;
                    top: 50%;
                    height: 15px;
                    width: 15px;
                    transform: translateY(-50%);
                    background-image: url("../img/icons/icon-unchecked-business.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &.glyphicon-ok {
                    &:before {
                        background-image: url("../img/icons/icon-checked-business.png");
                    }
                }
            }
        }
    }

    #table-content {
        @extend .table-component;
        overflow-x: auto;

        span {
            img {
                width: 28px;
                height: 28px;
                margin-right: 10px;
                margin-top: -14px;
                top: 8px;
                position: relative;
                float: left;
                margin-left: 10px;
                border-radius: 50%;
            }
        }

        th, td {
            position: relative;
            height: 40px;
            min-width: 175px;
            // cursor: pointer;
            text-align: left !important;

            @include respond-to(mobile-sm) {
                min-width: 42vw;
            }


            @include respond-to(desktop) {
                min-width: 0;
            }
        }

        td.refunded {
            color: red;
        }

        @include respond-to(tablet) {
            th, td {
                &:first-child {
                    width: 30%;
                }

                &:nth-child(2) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 12%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 20%;
                }

                &:nth-child(6) {
                    min-width: 140px;
                    width: 140px;
                }

                &:last-child {
                    width: 10%;
                }
            }
        }


        td {
            &:first-child {
                text-transform: uppercase;
            }

            &:last-child {
                text-transform: uppercase;
            }
        }
    }

    &.in-progress {
        @extend .in-progress-profile-component;
    }
}
