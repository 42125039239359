.modal {
    width: 100%;
    padding: 12px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 3;
    transform: translateY(-100%);
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-family: ProximaNova;
        font-weight: $font-w-semibold;
        color: $white;
        display: inline-block;
    }
    span {
        display: none;

        &.closeModal {
            display: block;
            position: absolute;
            top: 12px;
            right: 12px;
            width: 20px;
            height: 20px;
            border: 2px solid $white;
            cursor: pointer;
            background: url('../img/icons/ex_white.png') center center no-repeat;
        }
    }

    &.active {
        transform: translateY(0);
        visibility: visible;
    }
    &.modal-success {
        // background-color: $modal-success;
        background-color: $color-13;
    }
    &.modal-error {
        background-color: $modal-error;
    }
    &.modal-warning {
        background-color: $modal-warning
    }

    &.in-progress {
        padding: 9px 10px;
        p {
            line-height: 22px;
        }
        span {
            display: block;
            @include preloader(22px, 22px, 2px, relative, "", "");
            margin: 0 auto;
        }
    }
}

body {
    &.modal-active {
       header {
            z-index: 1;
            transition: z-index 0s 0s;
       }
    }
}