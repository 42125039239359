@mixin buttonsmall {
    //12pt
    font-size: 0.87vw;
}

@mixin buttoncolor {
    border: 2px solid $secondary-color;
    padding: 10px 24px;
}

@mixin topbuttons {
    //13,42pt
    font-size: 0.98vw;
}

@mixin club-col($bp-2-col) {
    @include breakpoint($bp-2-col) {
        float: left;

        &:last-child {
            width: 45%;
        }

        &:first-child {
            width: 55% ;
            padding-right: unit-pc(50px, $bp-2-col);
        }
    }
}

@mixin fonts-vw($fontname, $fontsize-1, $fontsize-2) {
    font-family: $fontname;
    /*font-size: unit-vw($fontsize-1, $sc-mobile-lg-min);*/

    @include respond-to(mobile-sm) {
        font-size: $fontsize-1;
    }


    @include respond-to(desktop) {
        font-size: $fontsize-2;
    }
}

@mixin attr-px-vw-opt($attr, $size-1, $size-2) {
    #{$attr}: unit-vw($size-1, $sc-mobile-lg-min);

    @include respond-to(mobile-sm) {
        #{$attr}: $size-1;
    }


    @include respond-to(desktop) {
        #{$attr}: unit-vw($size-2, $desktop-size);

        //unit-vw();
    }
}

@mixin attr-px-vw($attr, $size-1, $size-2) {
    #{$attr}: unit-vw($size-1, $sc-mobile-lg-min);

    @include respond-to(mobile-sm) {
        #{$attr}: unit-vw($size-1);
    }


    @include respond-to(desktop) {
        #{$attr}: unit-vw($size-2);
    }
}

@mixin fonts-vw-opt($fontname, $fontsize-1, $fontsize-2) {
    font-family: $fontname;
    font-size: unit-vw($fontsize-1, $sc-mobile-lg-min);

    @include respond-to(mobile-sm) {
        font-size: $fontsize-1;
    }


    @include respond-to(desktop) {
        font-size: unit-vw($fontsize-2, $desktop-size);

        //unit-vw($fontsize-2, $desktop-size);
    }
}

@mixin preloader($width, $height, $borderWidth, $position, $color1, $color2) {
    width: $width;
    height: $height;
    border: $borderWidth solid;

    @if $color1 != "" {
        border-bottom-color: $color1;
        border-right-color: $color1;
    }
    @else {
        border-bottom-color: rgba(255, 255, 255, 0.5);
        border-right-color: rgba(255, 255, 255, 0.5);
    }

    @if $color2 != "" {
        border-top-color: $color2;
        border-left-color: $color2;
    }
    @else {
        border-top-color: rgba(255, 255, 255, 1);
        border-left-color: rgba(255, 255, 255, 1);
    }

    border-radius: 50%;
    animation: preloader 0.8s linear infinite;
    position: $position;

    @if $position == absolute {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}