#page-businesspremier {
    @extend .all-business;


    .bottomnav {
        margin-top: 60px !important;
    }

    .main-section, .chooseoption {
        .wrapper {
            max-width: 1125px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    /*.title.premier-title {
        padding-top: 45px;
    }*/
    .chooseoption.upgradev {
        background: #e6e6e6;
        padding-top: 40px;
        padding-bottom: 0;

        .subtitle {
            margin-bottom: 10px;
        }

        .options .option {
            margin-bottom: 0;

            .survey-popup {
                @include fonts-vw(ProximaNova, 12.5px, 12.5px);
                padding: 1vw;

                .input {
                    @include respond-to(desktop) {
                        width: 100%;
                    }
                }

                .input-wrapper {
                    @include fonts-vw(ProximaNova, 12.5px, 12.5px);
                }
            }
        }
    }

    div.continue {
        margin-top: 1vw;
        float: left;
        margin-left: 0;
    }

    .description {
        a {
            color: #000;
            text-decoration: underline;
        }
    }

    .display-section {
        display: block;
        width: 100%;
        //background: url(../img/preview.jpg) no-repeat;
        //macandphone
        background: url(../img/macandphone.png) no-repeat;
        //padding-bottom: 41vw;
        padding-bottom: 16vw;
        background-size: auto 100%;
        /* background-attachment: fixed; */
        background-position: center center;
    }

    .disabled {
        position: relative;

        &:before {
            content: 'Not availlable in this membership option';
            font-size: 30px;
            padding-top: 20%;
            color: #fff;
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // background: rgba(19, 6, 6, 0.11);
            background: rgba(0, 0, 0, 0.88);
            z-index: 10;
        }
    }

    .chooseoption {
        background: url(/content/img/shadows-stripes/left-grey-stripes.png) no-repeat;
        background-size: 92%;

        .wrapper {
            background-color: #ebebeb;

            @include respond-to(tablet) {
                background-color: unset;
            }
        }

        .options {
            /*background: url("/content/img/shadows-stripes/left-grey-stripes.png") no-repeat;
            background-position: -250px;*/
            position: relative;
            margin-top: 50px;
            padding: 35px 0px 0 0px;
            background-size: auto 100%;

            @include respond-to(tablet) {
                /*background-position: -200px;*/
            }


            @include clearfix;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100vw;
                height: 100%;
                top: 0;
                right: 100%;
                background: #ebebeb;
            }


            .subtitle {
                width: 100%;
                /*margin-top: 45px;*/
                margin-top: 25px;
                text-align: left;
                padding-right: 30px;

                @include respond-to(tablet) {
                    float: left;
                    width: 50%;
                    padding-right: 30px;
                    /*line-height: 2;*/
                }
            }

            .option {
                width: 100%;
                margin-bottom: 25px;


                @include respond-to(tablet) {
                    float: left;
                    width: 50%;
                    min-height: 305px;
                }
            }
        }
    }

    .presentation {
        .wrapper {
            max-width: 1125px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 30px;
            padding-bottom: 30px;

            &.wider-wrapper {
                max-width: 1215px;
            }
        }
    }

    .textwithimage {
        /*background: url("/content/img/shadows-stripes/left-grey-stripes.png") no-repeat;
        background-position: -250px;
        position: relative;
        margin-top: 50px;
        margin-bottom: 32px;
        padding: 0 15px;
        background-size: auto 100%;

        @include respond-to(tablet) {
            background-position: -200px;
        }


        @include clearfix;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            right: 100%;
            background: #ebebeb;
        }*/
        .image {
            display: block;
            /*background: url(../img/macandphone.png) no-repeat;
            background-position: right center;*/
            /*padding-bottom: 50%;*/
            background-size: 100% auto;

            @include respond-to(tablet) {
                margin: 0 auto;
                width: 80%;
            }

            img {
                max-width: 620px;

                @media (max-width: 1200px) {
                    width: 100%;
                }
            }
        }

        .text {
            padding-top: 15px;
            margin-bottom: 0px;
            padding-bottom: 15px;
            font-weight: bold;

            @include respond-to(tablet) {
                width: 80%;
            }
        }

        .title {
            padding-top: 2vw;
        }

        @include respond-to(tablet) {
            padding: 0;

            .image {
                display: inline-block;
                width: 50%;
                float: right;
                /*padding-bottom: 35%;*/
                img {
                    max-width: 620px;

                    @media (max-width: 1200px) {
                        width: 100%;
                    }
                }
            }

            .text {
                /*padding-top: 10%;*/
                width: 50%;
                float: left;
                margin: 0 auto;
                margin-bottom: 0;
                padding-right: 30px;
            }

            .title {
                width: 50%;
                text-align: center;
                padding-bottom: 0;
                margin-bottom: -2vw;
            }
        }
    }

    .options {
        @include clearfix;

        &.upgradev {
            @include respond-to(desktop) {
                .option {
                    width: 20%;
                }
            }
        }

        &:not(.upgradev) {
            .option {
                @include respond-to(desktop) {
                    /*width: 33%;*/
                    /*margin: 0 auto;*/
                    &:nth-of-type(5) {
                        clear: left;
                    }

                    &:nth-of-type(4) {
                        float: right !important;
                    }
                    /*&:last-of-type {
                        float: right !important;
                    }*/
                    &:nth-of-type(5) {
                        margin-left: 15%;
                    }

                    &:nth-of-type(6) {
                        margin-right: 15%;
                    }
                }
            }
        }

        .option {
            width: 100%;
            /*float: left;
            display: inline-block;*/
            text-align: center;
            padding: 0 1%;
            margin-bottom: 2%;
            position: relative;
            min-height: 350px;

            @include respond-to(tablet) {
                width: 50%;
                padding-top: 20px;
                margin-bottom: 10px;
                min-height: 370px;
            }


            label {
                float: left;
                display: block;
                width: 100%;
            }

            input[type=radio] {
                width: 0;
                visibility: hidden;
                float: left;
            }

            .option-image {
                display: inline-block;
                width: 40%;
                padding-bottom: 24%;
                background: url("../img/bell.png") no-repeat;
                background-size: auto 100%;
                background-position: center center;
                float: left;
                margin-top: 2%;

                @include respond-to(tablet) {
                    margin-top: 0;
                    background-position: left;
                    width: 60%;
                    float: none;
                    padding-bottom: 45%;
                }
            }

            .survey-popup {
                position: absolute;
                top: 0;
                height: 94%;
                width: 97%;
                background: #fff;
                padding: 25px;
                border: 2px solid;
                border-radius: 10px;
                min-height: 214px;
                text-align: left;
                z-index: 100;
                font-size: 14px !important;

                @include respond-to(tablet) {
                    /*min-height: 370px;*/
                    font-size: 15px !important;
                }


                @include fonts-vw(ProximaNova, 12.5px, 14px);

                .input-wrapper {
                    position: absolute;
                    bottom: 10px;
                    padding: 0 25px 10px 25px;
                    left: 0;
                }

                .input {

                    @include respond-to(tablet) {
                        width: 72%;
                        float: left;
                    }
                }

                input {
                    @include fonts-vw(ProximaNova, 12.5px, 14px);
                    margin: 15px 0;
                    width: 100%;
                    padding: 10px;

                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        @include fonts-vw(ProximaNova, 12.5px, 14px);
                    }

                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        @include fonts-vw(ProximaNova, 12.5px, 14px);
                    }

                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        @include fonts-vw(ProximaNova, 12.5px, 14px);
                    }

                    &:-moz-placeholder {
                        /* Firefox 18- */
                        @include fonts-vw(ProximaNova, 12.5px, 14px);
                    }
                }

                button {
                    margin-top: 1vw;
                    float: left;

                    &.continue {
                        padding: 12px 27px;
                        float: unset;
                        margin-right: 0;
                        text-align: center;
                        margin: 5px auto;

                        @include respond-to(tablet) {
                            margin: 15px auto;
                            text-align: unset;
                            float: right;
                        }
                    }
                }

                button:not(.continue) {
                    cursor: pointer;
                    padding: 14px 12px;
                    line-height: 12px;
                    background: #fff;
                    border: none;
                }
            }

            .tick {
                display: block;
                width: 30px;
                height: 34px;
                float: left;
                background: url("../img/icons/icon-checked-business.png") no-repeat;
                margin: 15px auto 15px auto;

                @include respond-to(tablet) {
                    float: none;
                }


                &:after {
                    //Preloader
                    content: '';
                    width: 0;
                    visibility: hidden;
                    background: url("../img/icons/icon-checked-business.png") no-repeat;
                }
            }

            input[type=radio]:checked + label .tick {
                background: url("../img/icons/icon-checked-business.png") no-repeat;
            }

            .description {
                text-align: center;
                font-weight: 600;

                @include fonts-vw(ProximaNova, 14px, 14px);
                width: 48%;
                float: left;
                padding: 0 4%;

                @include respond-to(tablet) {
                    width: 100%;
                    float: none;
                    padding: 0 4%;
                }
            }
        }

        .subtitle {
            margin-top: 0;
            margin-bottom: 20px;
            font-weight: 700;
            text-align: left;

            @include respond-to(tablet) {
                text-align: center;
            }
        }

        .description, .textwithimage {
            @extend .subtitle;
            font-weight: 400;
        }
    }

    .bottomnav {
        clear: both;
    }
}
