﻿#Promotional-Calendar {
    .calendar {
        position: relative;


        .fc-center {
            padding-top: 8px;
        }

        .fc-day-header {
            padding: 9px 0 9px 0;
            background: #1243b2;
            color: $white;

            @include fonts-vw(ProximaNova, 10px, 19px);

            &:last-of-type, &:first-of-type {
                background: #757575;
            }
        }
        //Popup
        .addhours, .changehours {
            display: block;
            position: absolute;
            background: #fff;
            z-index: 100;
            width: 128px;
            border: 2px solid #e6e6e6;
            padding: 10px;
            text-align: center;

            input {
                margin-top: 10px;
                width: 100%;
            }

            .submit {
                margin-top: 10px;
            }

            .calendar-btns-wrapper {
                width: 100%;
                height: 30px;
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                margin-top: 6px;

                .cancel-btn {
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    font-weight: bold;
                    background-image: url(../img/icons/close-icon.png);
                    background-repeat: no-repeat;
                    background-size: 16px;
                    cursor: pointer;
                }

                .add-btn {
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    font-weight: bold;
                    background-image: url(../img/icons/submit-icon.png);
                    background-repeat: no-repeat;
                    background-size: 18px;
                    cursor: pointer;
                }
            }
        }

        .hero-unit {
            position: relative;
            padding: 40px 0;
            color: #fff;
            text-align: center;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.075);
            background: #020031;
            background: -moz-linear-gradient(45deg, #020031 0%, #6d3353 100%);
            background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #020031), color-stop(100%, #6d3353));
            background: -webkit-linear-gradient(45deg, #020031 0%, #6d3353 100%);
            background: -o-linear-gradient(45deg, #020031 0%, #6d3353 100%);
            background: -ms-linear-gradient(45deg, #020031 0%, #6d3353 100%);
            background: linear-gradient(45deg, #020031 0%, #6d3353 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020031', endColorstr='#6d3353',GradientType=1 );
            -webkit-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            -moz-border-radius: 0;
            -webkit-border-radius: 0;
            -o-border-radius: 0;
        }

        .hero-unit .btn, .pagination-centered .btn {
            float: none;
            font-weight: normal;
        }

        .hero-unit p {
            margin: 1em 0;
        }

        .bs-docs-social {
            margin-top: 1em;
            padding: 15px 0;
            text-align: center;
            background-color: rgba(245, 245, 245, 0.3);
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            border-bottom: 1px solid rgba(221, 221, 221, 0.3);
        }

        .bs-docs-social-buttons {
            margin-left: 0;
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
        }

        .bs-docs-social-buttons li {
            display: inline-block;
            padding: 5px 8px;
            line-height: 1;
        }
        /* Allows .modal-backdrop to remain on the page without blocking */
        .modal-backdrop {
            overflow: hidden;
            height: 0;
            -webkit-transition: opacity 0.15s linear, height 0 0.2s linear;
            -moz-transition: opacity 0.15s linear, height 0 0.2s linear;
            -ms-transition: opacity 0.15s linear, height 0 0.2s linear;
            -o-transition: opacity 0.15s linear, height 0 0.2s linear;
            transition: opacity 0.15s linear, height 0 0.2s linear;
        }

        .modal-backdrop.in {
            height: 100%;
            -webkit-transition: opacity 0.15s linear;
            -moz-transition: opacity 0.15s linear;
            -ms-transition: opacity 0.15s linear;
            -o-transition: opacity 0.15s linear;
            transition: opacity 0.15s linear;
        }
        /*** Calendar ***/
        .calAlert {
            width: 595px;
            float: right;
            margin-bottom: 5px;
        }

        .calXBtn {
            float: right;
            margin-top: -5px;
            margin-right: -5px;
        }

        .calWell {
            float: left;
            margin-bottom: 40px;
        }

        .fc-event.openSesame .fc-event-skin {
            background-color: rgb(229, 229, 11);
            color: black;
        }

        .fc-event.customFeed .fc-event-skin {
            background-color: rgb(132, 222, 175);
            color: black;
        }

        .calTools {
            margin-bottom: 10px;
        }
        //My changes
        .fc-event {
            background: none;
            border: none;
            color: #000;
            text-align: center;

            @include fonts-vw(ProximaNova, 16px, 40px);

            .fc-title:after {
                content: '%';
                display: inline-block;
            }
        }
    }
}
