//universal clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

//styles an input placeholder
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; } 
}
