//blue border
.component-button-1 {
  display: inline-block;
  height: 35px;
  line-height: 32px;
  padding: 0 10px;
  border: 2px solid $button-color;
  background: $white;
  transition: all $trans-duration;

  color: $button-color;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  @include respond-to(tablet) {
    &:not(.component-button-1--nohover):hover {
      color: #fff;
      background: $button-color;
    }
  }
}

.component-button-yellow {
  @extend .component-button-1;
  border: 2px solid $color-23;
  background: $white;
  color: $color-23;

  @include respond-to(tablet) {
    &:not(.component-button-yellow--nohover):hover {
      color: $white;
      background: $color-23;
    }
  }
}

.component-button-grey {
  @extend .component-button-1;
  border: 2px solid $color-18;
  background: $white;
  color: $color-18;

  @include respond-to(tablet) {
    &:not(.component-button-yellow--nohover):hover {
      color: $white;
      background: $color-18;
      border: 2px solid $color-18;
    }
  }

  &.active {
    color: $white;
    background: $color-18;
  }
}

button.facebook {
    font-family: ProximaNova;
    font-weight: 600;
    width: 100%;
    font-size: 14px;
    color: $white;
    background: $facebook-color;
    border: none;
    padding: 10px 25px 10px 53px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    &:before {
      content: "";
      background: url("../img/icons/fb_logo_white.svg") 0 0 no-repeat;
      position: absolute;
      top: 50%;
      left: 15px;
      background-size: contain;
      padding: 10px 10px 10px 20px;
      margin-top: -11px;
      border-right: 1px solid #4567a6;
    }

    @include respond-to(tablet) {
        font-size: 20px;
    }
}

button {
  &:disabled, &.in-progress {
    cursor: default !important;
  }
  &.in-progress {
    position: relative;
    &:after {
      content: "";
      @include preloader(22px, 22px, 2px, absolute, "", "");
      margin: auto 10px auto auto;
    }
  }
}