#page-businessthankyou {
    .wrapper{
        position: relative;
    }
    section {
        padding: 30px 0;
        .second-block{
            padding: 0 21% 0 34px;//54px 15% 100px 50px;
            @include respond-to(desktop){
                position:absolute;
                bottom:20px;
                left:21%;
            }

        }
        .block {
            padding: 0 21% 0 34px;//54px 15% 100px 50px;

            //background: $bg-color-1;
            background: url("../img/group-5.png");
            background-size: 100% 100%;
            width: 100%;
            float: left;
            position: relative;

            @include respond-to(desktop) {
                padding: 54px 0 100px 260px;
            }


            &:before {
                content: "";
                display: block;
                width: 100vw;
                height: 100%;
                background: $bg-color-1;
                position: absolute;
                right: 99%;
                top: 0;
            }

            h1 {
                @include fonts-vw(ProximaNova, 32px, 32px);
                text-align:left;
                font-weight: 700;
                color: $color-9;
                margin-top: 48px;
                margin-bottom: 17px;
            }

            h2 {
                @include fonts-vw(ProximaNova, 20px, 20px);

                font-weight: 700;
                color: $color-9;
            }

            .subtitle {
                font-family: ProximaNova;
                font-weight: 400;
                font-size: 16px;
                color: $color-10;
                margin-top: 32px;

                p {
                    margin-bottom: 25px;

                    @include respond-to(desktop) {
                        width: 55%;
                    }
                }
            }
            }
            button {
                margin-top: 25px;
                border: none;
                margin-right: 25px;
                cursor:pointer;
                min-width:300px;

                &.dashboard {
                    background: $color-23;

                    // border-bottom: 2px solid #094496;
                    // border-left: 2px solid #094496;
                    // border-right: 2px solid #094496;
                    // border-top: 2px solid #bbcfed;
               
                }

                &.create {
                    background: $color-12;
                }

                
                    display: inline-block;
                    font-family: ProximaNova;
                    font-weight: 700;
                    @include fonts-vw(ProximaNova, 13.5px, 13.5px);
                    //padding: 10px 11px;
                    padding: 14px 27px;
                    color: $white;
                

                .arrow {
                    margin-left: 10px;
                }
            }
        
    }
}