﻿#Software-Provider {
    .api-provider {
        .input-field {

            label {
                font-size: 16px;
            }

            select {
                margin: 0 auto;
                position: relative;
                max-width: 1000px;
                display: block;
            }
        }

        .api-provider-wrapper {
        }

        .service-provider-directive {
            .service-provider-instructions {
                padding-left: 7px;
                padding-bottom: 15px;
            }

            .software {
                .inner-field {
                    label {
                    }

                    select {
                        width: 100% !important;
                    }
                }
            }

            .provider-selected {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 5px;
                padding-top: 15px;
            }

            .service-fields {
                clear: both;
                padding-bottom: 15px;

                .service-instructions {
                    padding-bottom: 15px;

                    .mail-link {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    &.already-know {
                        margin: 0 auto;
                        max-width: 475px;
                        float: unset;
                    }
                }

                .unknown-external {
                    font-size: 14px;
                    padding-top: 5px;
                }

                .provider-selected-disclaimer {
                    clear: both;
                    font-size: 14px;
                    padding-top: 15px;
                }

                .no-edit-external {
                    position: relative;
                    top: 12px;


                    @media (max-width: 768px) {
                        top: 0;
                    }
                }

                .input-field {

                    &.other-service-provider {
                        margin-left: 0;
                        width: 100%;
                    }

                    &.package-checkbox {
                        label {
                            font-size: 16px;
                            padding-left: 25px;

                            @media (max-width: 1025px) {
                                padding-left: 50px;
                            }
                        }
                    }
                }

                .help-text {
                    font-size: 15px;
                    padding-top: 15px;
                    clear: both;
                    padding-bottom: 10px;

                    .help-text-label {
                        font-weight: bold;
                        font-size: 16px;
                        padding-bottom: 5px;
                    }

                    .help-list {
                        list-style: decimal;
                        padding-left: 20px;
                        line-height: 1.2;
                    }

                    .help-text-note {
                        font-size: 14px;
                        padding-top: 5px;

                        .help-text-note-label {
                            font-weight: bold;
                        }
                    }
                }


                .package-checkbox {
                    margin-top: 5px;
                }

                &.additional-class-fields {
                    border-top: 1px solid #afafaf;
                    padding-top: 20px;
                }
            }

            .showcase-agreement {
                font-size: 13px;
                font-weight: bold;
                padding-top: 10px;
            }

            .service-fields:after {
                content: "";
                clear: both;
                display: block;
            }

            .clubready-email-button {
                float: unset;
                max-width: 325px;
                display: block;
                margin: 0 auto;
                text-align: center;
                margin-top: 10px;
                cleaR: both;
            }

            .continue.email-sent, .continue.email-sending {
                background-color: #727272;
                cursor: default;
            }

            .clubready-error {
                color: #de0000;
                font-weight: bold;
                margin-top: 5px;
            }
        }
    }
}