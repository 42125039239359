#page-standard {
    @extend .all-business;


    .subscription-error {
        color: red;
        font-weight: bold;
    }

    .subscription-error-link {
        border: 2px solid #a8a8a8;
        max-width: 200px;
        padding: 12px;
        text-align: center;
        cursor: pointer;
        margin-top: 10px;
        float: right;
    }

    button.continue.premium {
        width: 300px;
        margin: 0 auto;
        float: none;
    }

    button.continue.last {
        margin-right: 0;
    }

    span.percent {
        position: absolute;
        /* top: 10px; */
        margin-left: 50px;
        margin-top: 12px;
        /*@include respond-to(tablet) {
            margin-top: 21px;
        }*/
    }
    //Uniqe wrapper size
    .main-section {
        .wrapper {
            max-width: 920px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .paymentoptions {
            background: #e6e6e6;
            max-width: 100%;

            @include respond-to(tablet) {
                padding: 50px 0 42px 0;
            }


            .inner-wrapper {
                max-width: 1040px;
                margin: 0 auto;
            }

            .option {
                width: 70%;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 1vw;

                &:after {
                    content: " ";
                    display: block;
                    background: #fff;
                    height: 3px;
                    width: 100vw;
                    position: absolute;
                    left: 0;
                    transform: translateY(2vw);
                }

                @include respond-to(tablet) {
                    &:after {
                        display: none;
                    }

                    padding-bottom: 0vw;
                    width: 33%;
                    float: left;

                    &:nth-of-type(1) {
                        padding-right: 2%;
                    }

                    &:nth-of-type(2) {
                        padding: 0 1%;
                    }

                    &:nth-of-type(3) {
                        padding-left: 2%;
                    }
                }


                input {
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }

                input[type=radio] + label {
                    display: inline-block;
                    width: 100%;
                    line-height: 1;
                    margin-bottom: 30px;

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 33px;
                        height: 33px;
                        background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                        background-size: cover;
                        margin-right: 10px;
                    }
                }

                input[type=radio]:checked + label:before {
                    background: url("../img/icons/icon-checked-business.png") no-repeat;
                    background-size: cover;
                }

                .option-name {
                    @include fonts-vw(ProximaNova, 24px, 24px);
                    font-weight: 700;
                    color: $black;
                    margin-bottom: 15px;
                }

                .option-describtion {
                    @include fonts-vw(ProximaNova, 16px, 16px);
                    font-weight: 400;
                    color: $black;
                }
            }
        }

        header.subtitle {
            margin-top: 0;
            padding-top: 25px;
        }

        .pricing {
            @include clearfix;

            > .description {
                @include respond-to(tablet) {
                    margin-bottom: 25px;
                }
            }

            .pricing-options {
                width: 100%;
                float: left;
                padding: 45px 0 0 0;

                @include respond-to(tablet) {
                    width: 48%;
                }


                .input-field {
                    @include clearfix;
                    margin-bottom: 25px;

                    .subtitle {
                        margin: 0;
                    }
                }



                label {
                    width: 73%;
                    float: left;

                    @include respond-to(tablet) {
                        width: 49%;
                    }
                }

                input {
                    width: 25%;
                    float: right;
                    padding: 10px;

                    @include respond-to(tablet) {
                        width: 49%;
                    }
                }
            }

            .pricing-preview {
                width: 80%;
                margin: 0 auto;
                float: none;
                margin-top: 45px;
                border: 1px solid #000;
                display: none;

                @include respond-to(tablet) {
                    width: 50%;
                    float: right;
                    display: block;
                }


                img {
                    width: 100%;
                }
            }

            .pricing-description {
                width: 100%;
                float: left;
                clear: both;

                a {
                    color: inherit;
                    text-decoration: underline;
                }

                @extend .description;
                margin: 25px 0;
            }
        }

        .banking {
            @include clearfix;

            .table {
                display: block;
                height: 100%;
                width: 100%;

                @include respond-to(tablet) {
                    display: table;
                }


                @include clearfix;

                .table-row {
                    @include respond-to(tablet) {
                        display: table-row;
                    }
                }
            }

            .splitter {
                width: 2%;
                // height: 100%;
                display: table-cell;
            }

            .banking-info {
                width: 100%;
                // float: left;
                display: block;

                @include respond-to(tablet) {
                    width: 58%;
                    display: table-cell;
                    height: 100%;
                }


                .input-field {
                    @include clearfix;
                    margin-bottom: 25px;

                    .subtitle {
                        margin: 0;
                    }

                    &:last-of-type {
                        margin: 0;
                    }
                }

                label {
                    display: block;
                    padding: 15px 0 0;
                    width: 100%;
                    float: none;
                    text-align: left;

                    @include respond-to(tablet) {
                        padding: 0;
                        text-align: left;
                        width: 49%;
                        float: left;
                    }
                }

                input {
                    width: 100%;
                    float: none;
                    padding: 10px;

                    @include respond-to(tablet) {
                        width: 49%;
                        float: right;
                        padding: 10px;
                    }


                    &.error {
                        border-color: red;
                    }
                }

                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0;
                }
            }

            .banking-upload {
                background: #e6e6e6;
                display: block;
                width: 100%;
                margin-top: 25px;
                padding: 30px 24px 40px 24px;
                background-size: 46% !important;
                background-position: 82% center !important;

                @include clearfix;

                @extend .toextendwithpucture;

                @include respond-to(tablet) {
                    margin-top: 0;
                    width: 40%;
                    display: table-cell;
                    height: 100%;
                }

                .banking-info-importnat-copy {
                    color: #008000;
                }
            }
        }
        //End Paymentoptions
        .title {
            margin-bottom: 24px;
        }

        .subtitle {
            text-align: left;
        }

        .description {
            @include fonts-vw(ProximaNova, 16px, 16px);
            font-weight: 400;
            color: $black;

            p {
                margin-bottom: 22px;
            }
        }

        .terms, .tax-terms, .no-day-pass {
            margin: 35px 0;

            &.left {
                width: 50%;
                float: left;
                margin-bottom: 0;
            }

            a {
                color: $black;
                text-decoration: underline;
            }

            input {
                width: 0;
                height: 0;
                visibility: hidden;
                position: absolute;
            }

            label:not(.validationMessage) {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                    background-size: cover;
                    margin-right: 10px;
                    top: 4px;
                    position: relative;
                }
            }

            label.validationMessage {
                top: 0;
                position: relative !important;
                margin: 0 !important;

                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid #d04b4b;
                    position: absolute;
                    bottom: -7px;
                    left: 20px;
                }
            }

            input[type=checkbox] + label {
                display: inline-block;
                width: 100%;
                line-height: 1;
                margin-bottom: 0px;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                    background-size: cover;
                    margin-right: 10px;
                    top: 4px;
                    position: relative;
                }
            }

            input[type=checkbox]:checked + label:before {
                background: url("../img/icons/icon-checked-business.png") no-repeat;
                background-size: cover;
            }
        }

        .no-day-pass {
            margin-bottom: 0;
        }

        .tax-terms, .tax-terms.has-error {


            .has-error::before {
                background-image: none;
            }

            &.left {
                width: 100%;
                float: none;
                margin: 0;
            }

            input {
                width: initial;
            }

            label {
                width: 100% !important;
            }

            .control-label {
                padding-top: 0px;
                padding-bottom: 5px;
            }
        }




        .barcode {
            display: block;
            height: 100%;
            border: 2px solid #a8a8a8;

            @include respond-to(tablet) {
                display: table;
            }


            @include clearfix;

            .barcode-row {
                display: block;

                @include respond-to(tablet) {
                    display: table-row;
                }
            }

            .image {
                width: 100%;
                display: block;
                height: 100%;
                min-height: 290px;
                position: relative;
                background-repeat: no-repeat;
                background-size: 50%;
                background-position: center center;

                @include respond-to(tablet) {
                    width: 50%;
                    display: table-cell;
                    min-height: 0;
                }


                .delete {
                    position: absolute;
                    bottom: 10%;
                    right: 10%;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &.show-tablet {
                    display: none;

                    @include respond-to(tablet) {
                        display: table-cell;
                    }
                }

                &.show-mobile {
                    display: block;

                    @include respond-to(tablet) {
                        display: none;
                    }
                }
            }

            .barcode-inner {
                width: 100%;
                background: #e6e6e6;
                overflow: hidden;
                display: block;
                height: 100%;
                padding: 30px 24px 40px 24px;

                @include respond-to(tablet) {
                    width: 50%;
                    display: table-cell;
                }


                @extend .toextendwithpucture;
            }
        }
        //End of barcode
        .additional {
            margin-bottom: 3vw;
        }

        .calendar-description {
            margin: 3vw 0;

            p {
                padding-bottom: 1vw;

                &:first-of-type() {
                    font-weight: 700;
                }
            }
        }

        .comment {
            width: 70%;
            float: left;
            margin-left: 5%;
            padding-top: 0.7vw;
            font-weight: 600;
            font-size: 16px;
        }

        .photos {
            @include clearfix;

            .row1, .row2 {
                padding-bottom: 15px;
                border-bottom: 1px solid $color-39;
            }

            .previews {
                width: 100%;

                @include clearfix;
            }

            .upload {
                @extend .toextendwithpucture;

                .comment {
                    width: 100%;
                    float: none;
                    margin: 0;
                    padding: 0;
                    padding-bottom: 5px;
                    text-align: center;
                }

                @include respond-to(tablet) {
                }
            }

            .explanation {
                text-align: center;

                @include respond-to(tablet) {
                }


                span.error {
                    color: red;
                }
            }

            .preview {
                width: 50%;
                float: left;
                padding: 2%;

                &:nth-child(2n-1) {
                    padding-left: 0;
                }

                &:nth-child(2n) {
                    padding-right: 0;
                }

                @include respond-to(tablet) {
                    width: 25%;
                    padding: 1.5%;

                    &:nth-child(2n) {
                        padding-left: 0.5%;
                        padding-right: 1%;
                    }

                    &:nth-child(2n-1) {
                        padding-left: 1%;
                        padding-right: 0.5%;
                    }

                    &:nth-child(4n-3) {
                        padding-left: 0;
                        padding-right: 1.5%;
                    }

                    &:nth-child(4n) {
                        padding-left: 1.5%;
                        padding-right: 0;
                    }
                }


                .image {
                    display: block;
                    width: 100%;
                    padding-bottom: 57%;
                    background: #e6e6e6;
                    background-size: contain !important;
                    background-repeat: no-repeat !important;
                    background-position: center center !important;
                }

                .makeprimary, .delete {
                    font-size: 13px;

                    @include respond-to(tablet) {
                        font-size: 100%;
                    }
                }

                .makeprimary {
                    float: left;
                    cursor: pointer;
                }

                .delete {
                    float: right;
                    cursor: pointer;
                }

                &:first-of-type {
                    clear: left;
                }
            }
        }
        //Photos end
        .calendar {
            position: relative;
            .fc-center {
                padding-top: 8px;
            }

            .fc-day-header {
                padding: 15px 0 9px 0;
                background: #1243b2;
                color: $white;

                @include fonts-vw(ProximaNova, 10px, 19px);

                &:last-of-type, &:first-of-type {
                    background: #757575;
                }

                &:nth-of-type(2n-1) {
                    padding: 5px 0 9px 0;

                    @include respond-to(tablet) {
                        padding: 15px 0 9px 0;
                    }
                }
            }
            //Popup
            .addhours, .changehours {
                display: block;
                position: absolute;
                background: #fff;
                z-index: 100;
                width: 128px;
                border: 2px solid #e6e6e6;
                padding: 10px;
                text-align: center;

                input {
                    margin-top: 10px;
                    width: 100%;
                }

                .submit {
                    margin-top: 10px;
                }

                .calendar-btns-wrapper {
                    width: 100%;
                    height: 30px;
                    background-color: #fff;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 6px;

                    .cancel-btn {
                        width: 30px;
                        height: 30px;
                        color: #fff;
                        font-weight: bold;
                        background-image: url(../img/icons/close-icon.png);
                        background-repeat: no-repeat;
                        background-size: 16px;
                        cursor: pointer;
                    }

                    .add-btn {
                        width: 30px;
                        height: 30px;
                        color: #fff;
                        font-weight: bold;
                        background-image: url(../img/icons/submit-icon.png);
                        background-repeat: no-repeat;
                        background-size: 18px;
                        cursor: pointer;
                    }
                }
            }

            .hero-unit {
                position: relative;
                padding: 40px 0;
                color: #fff;
                text-align: center;
                text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.075);
                background: #020031;
                background: -moz-linear-gradient(45deg, #020031 0%, #6d3353 100%);
                background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #020031), color-stop(100%, #6d3353));
                background: -webkit-linear-gradient(45deg, #020031 0%, #6d3353 100%);
                background: -o-linear-gradient(45deg, #020031 0%, #6d3353 100%);
                background: -ms-linear-gradient(45deg, #020031 0%, #6d3353 100%);
                background: linear-gradient(45deg, #020031 0%, #6d3353 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020031', endColorstr='#6d3353',GradientType=1 );
                -webkit-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
                box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.2), inset 0 -3px 7px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                -o-border-radius: 0;
            }

            .hero-unit .btn, .pagination-centered .btn {
                float: none;
                font-weight: normal;
            }

            .hero-unit p {
                margin: 1em 0;
            }

            .bs-docs-social {
                margin-top: 1em;
                padding: 15px 0;
                text-align: center;
                background-color: rgba(245, 245, 245, 0.3);
                border-top: 1px solid rgba(255, 255, 255, 0.3);
                border-bottom: 1px solid rgba(221, 221, 221, 0.3);
            }

            .bs-docs-social-buttons {
                margin-left: 0;
                margin-bottom: 0;
                padding-left: 0;
                list-style: none;
            }

            .bs-docs-social-buttons li {
                display: inline-block;
                padding: 5px 8px;
                line-height: 1;
            }
            /* Allows .modal-backdrop to remain on the page without blocking */
            .modal-backdrop {
                overflow: hidden;
                height: 0;
                -webkit-transition: opacity 0.15s linear, height 0 0.2s linear;
                -moz-transition: opacity 0.15s linear, height 0 0.2s linear;
                -ms-transition: opacity 0.15s linear, height 0 0.2s linear;
                -o-transition: opacity 0.15s linear, height 0 0.2s linear;
                transition: opacity 0.15s linear, height 0 0.2s linear;
            }

            .modal-backdrop.in {
                height: 100%;
                -webkit-transition: opacity 0.15s linear;
                -moz-transition: opacity 0.15s linear;
                -ms-transition: opacity 0.15s linear;
                -o-transition: opacity 0.15s linear;
                transition: opacity 0.15s linear;
            }
            /*** Calendar ***/
            .calAlert {
                width: 595px;
                float: right;
                margin-bottom: 5px;
            }

            .calXBtn {
                float: right;
                margin-top: -5px;
                margin-right: -5px;
            }

            .calWell {
                float: left;
                margin-bottom: 40px;
            }

            .fc-event.openSesame .fc-event-skin {
                background-color: rgb(229, 229, 11);
                color: black;
            }

            .fc-event.customFeed .fc-event-skin {
                background-color: rgb(132, 222, 175);
                color: black;
            }

            .calTools {
                margin-bottom: 10px;
            }
            //My changes
            .fc-event {
                background: none;
                border: none;
                color: #000;
                text-align: center;

                @include fonts-vw(ProximaNova, 16px, 40px);

                .fc-title:after {
                    content: '%';
                    display: inline-block;
                }
            }
        }

        .disabled {
            position: relative;

            &:before {
                content: 'Not availlable in this membership option';
                font-size: 30px;
                padding-top: 20%;
                color: #fff;
                text-align: center;
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                // background: rgba(19, 6, 6, 0.11);
                background: rgba(0, 0, 0, 0.88);
                z-index: 10;
            }
        }
    }

    .explanation {
        span.error {
            color: red;
        }
    }
}

.toextendwithpucture {
    input[type="file"] {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    .pctr {
        margin-left: 0;
        float: left;

        //margin: 28px 0 16px 0;
        clear: left;
    }
}

//US Holidays
td.fc-day-number[data-date="2017-01-02"]:before {
    content: "";
    display: inline-block;
    float: left;
}

@mixin addholiday($day) {
    content: "H";
    display: inline-block;
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    color: #edaa20;

    @include respond-to(tablet) {
        content: $day;
        width: 80%;
    }
}

td.fc-day-number {
    position: relative;

    &[data-date="2018-07-04"]:before {
        @include addholiday("Independence Day");
    }

    &[data-date="2019-07-04"]:before {
        @include addholiday("Independence Day");
    }

    &[data-date="2017-07-04"]:before {
        @include addholiday("Independence Day");
    }

    &[data-date="2017-10-31"]:before {
        @include addholiday("Halloween");
    }

    &[data-date="2018-10-31"]:before {
        @include addholiday("Halloween");
    }

    &[data-date="2019-10-31"]:before {
        @include addholiday("Halloween");
    }

    &[data-date="2017-11-23"]:before {
        @include addholiday("Thanksgiving Day");
    }

    &[data-date="2018-11-22"]:before {
        @include addholiday("Thanksgiving Day");
    }

    &[data-date="2019-11-28"]:before {
        @include addholiday("Thanksgiving Day");
    }

    &[data-date="2016-12-31"]:before {
        @include addholiday("New Year's Eve");
    }

    &[data-date="2017-12-31"]:before {
        @include addholiday("New Year's Eve");
    }

    &[data-date="2018-12-31"]:before {
        @include addholiday("New Year's Eve");
    }

    &[data-date="2019-12-31"]:before {
        @include addholiday("New Year's Eve");
    }

    &[data-date="2017-01-01"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2018-01-01"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2019-01-01"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2017-01-02"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2018-01-02"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2019-01-02"]:before {
        @include addholiday("New Year's Day");
    }

    &[data-date="2017-02-14"]:before {
        @include addholiday("Valentine's Day");
    }

    &[data-date="2018-02-14"]:before {
        @include addholiday("Valentine's Day");
    }

    &[data-date="2019-02-14"]:before {
        @include addholiday("Valentine's Day");
    }

    &[data-date="2017-02-20"]:before {
        @include addholiday("Presidents' Day");
    }

    &[data-date="2018-02-19"]:before {
        @include addholiday("Presidents' Day");
    }

    &[data-date="2019-02-18"]:before {
        @include addholiday("Presidents' Day");
    }

    &[data-date="2017-04-16"]:before {
        @include addholiday("Easter Sunday");
    }

    &[data-date="2018-04-01"]:before {
        @include addholiday("Easter Sunday");
    }

    &[data-date="2019-04-21"]:before {
        @include addholiday("Easter Sunday");
    }

    &[data-date="2017-09-04"]:before {
        @include addholiday("Labor Day");
    }

    &[data-date="2018-09-03"]:before {
        @include addholiday("Labor Day");
    }

    &[data-date="2019-09-02"]:before {
        @include addholiday("Labor Day");
    }

    &[data-date="2017-12-25"]:before {
        @include addholiday("Christmas Day");
    }

    &[data-date="2018-12-25"]:before {
        @include addholiday("Christmas Day");
    }

    &[data-date="2019-12-25"]:before {
        @include addholiday("Christmas Day");
    }

    &[data-date="2017-10-09"]:before {
        @include addholiday("Columbus Day");
    }

    &[data-date="2018-10-08"]:before {
        @include addholiday("Columbus Day");
    }

    &[data-date="2019-10-14"]:before {
        @include addholiday("Columbus Day");
    }

    &[data-date="2017-11-10"]:before {
        @include addholiday("Veterans Day");
    }

    // &[data-date="2017-11-11"]:before {
    //     @include addholiday("Veterans Day");
    // }

    &[data-date="2018-11-11"]:before {
        @include addholiday("Veterans Day");
    }

    // &[data-date="2018-11-12"]:before {
    //     @include addholiday("Veterans Day");
    // }
    &[data-date="2017-01-16"]:before {
        @include addholiday("Martin Luther King Jr. Day");
    }

    &[data-date="2018-01-15"]:before {
        @include addholiday("Martin Luther King Jr. Day");
    }

    &[data-date="2019-01-21"]:before {
        @include addholiday("Martin Luther King Jr. Day");
    }

    &[data-date="2017-04-01"]:before {
        @include addholiday("April Fool's Day");
    }

    &[data-date="2018-04-01"]:before {
        @include addholiday("April Fool's Day");
    }

    &[data-date="2019-04-01"]:before {
        @include addholiday("April Fool's Day");
    }

    &[data-date="2017-05-29"]:before {
        @include addholiday("Memorial Day");
    }

    &[data-date="2018-05-28"]:before {
        @include addholiday("Memorial Day");
    }

    &[data-date="2019-05-27"]:before {
        @include addholiday("Memorial Day");
    }

    &[data-date="2017-01-28"]:before {
        @include addholiday("Chinese New Year");
    }

    &[data-date="2017-02-12"]:before {
        @include addholiday("Lincoln's Birthday");
    }

    &[data-date="2017-03-01"]:before {
        @include addholiday("Ash Wednesday");
    }

    &[data-date="2017-03-12"]:before {
        @include addholiday("Daylight Saving");
    }

    &[data-date="2017-03-17"]:before {
        @include addholiday("St. Patrick's Day");
    }

    &[data-date="2017-03-20"]:before {
        @include addholiday("Vernal equinox");
    }

    &[data-date="2017-04-01"]:before {
        @include addholiday("Easter");
    }

    &[data-date="2017-04-17"]:before {
        @include addholiday("Taxes Due");
    }

    &[data-date="2017-04-26"]:before {
        @include addholiday("Admin Assistants Day");
    }

    &[data-date="2017-05-14"]:before {
        @include addholiday("Mother's Day");
    }

    &[data-date="2017-05-05"]:before {
        @include addholiday("Cinco de Mayo");
    }

    &[data-date="2017-06-04"]:before {
        @include addholiday("Pentecost");
    }

    &[data-date="2017-06-14"]:before {
        @include addholiday("Flag Day");
    }

    &[data-date="2017-06-18"]:before {
        @include addholiday("Father's Day");
    }

    &[data-date="2017-06-21"]:before {
        @include addholiday("June Solstice");
    }

    &[data-date="2017-09-11"]:before {
        @include addholiday("Patriot Day");
    }

    &[data-date="2017-09-22"]:before {
        @include addholiday("Autumnal equinox");
    }

    &[data-date="2017-10-16"]:before {
        @include addholiday("Boss's Day");
    }

    &[data-date="2017-12-21"]:before {
        @include addholiday("December Solice");
    }

    &[data-date="2017-12-26"]:before {
        @include addholiday("Kwanzaa Begins");
    }

    .taxrate {}

    &[data-date="2017-04-11"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2017-04-12"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2018-03-31"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2018-04-01"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2019-04-20"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2019-04-21"]:before {
        @include addholiday("Passover observed");
    }

    &[data-date="2017-09-21"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2017-09-22"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2018-09-10"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2018-09-11"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2019-09-30"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2019-10-01"]:before {
        @include addholiday("Rosh Hashannah");
    }

    &[data-date="2017-09-30"]:before {
        @include addholiday("Yom Kippur");
    }

    &[data-date="2018-09-19"]:before {
        @include addholiday("Yom Kippur");
    }

    &[data-date="2019-10-09"]:before {
        @include addholiday("Yom Kippur");
    }

    &[data-date="2018-12-03"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-04"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-05"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-06"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-07"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-08"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-09"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2018-12-10"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-23"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-24"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-25"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-26"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-27"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-28"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-29"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2019-12-30"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-12"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-13"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-14"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-15"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-16"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-17"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-18"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-19"]:before {
        @include addholiday("Chanukah");
    }

    &[data-date="2017-12-20"]:before {
        @include addholiday("Chanukah");
    }
}