#page-businessgyms-api {
    @extend .all-business;

    .menu-section {
        padding-bottom: 0;

        .wrapper {
            .club-name {
                position: relative;
                top: 35px;
            }
        }

        ul li {
            margin-bottom: 0;
        }
    }

    .main-section {
        .wrapper {
            max-width: 920px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;

            @media (max-width: 1200px) {
                padding-left: 180px !important;
            }

            @media (max-width: 767px) {
                padding-left: 15px !important;
            }
        }

        .heading {
            @include fonts-vw(ProximaNova, 16px, 16px);
            font-weight: 700;
        }

        .inner-thin {
            max-width: 500px;
            margin: 0 auto;
            margin-top: 45px;

            .browsefile {
                input {
                    width: 0;
                    display: none;
                }

                label {
                    &.has-error {
                        margin-bottom: 10px;
                        padding: 9px 10px;

                        &:before {
                            display: none;
                        }

                        &:after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-top: 7px solid #d04b4b;
                            position: absolute;
                            bottom: -7px;
                            left: 20px;
                        }
                    }

                    span:not(.continue) {
                        padding: 12px 27px;
                        display: inline-block;
                        max-width: 74%;
                        height: 34px;
                        overflow: hidden;
                        position: relative;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        .remove {
                            display: block;
                            position: absolute;
                            top: 9px;
                            right: 0;
                            border: 2px solid #b0b0b0;
                            padding: 2px 3px;
                            color: #fff;
                            background: #b0b0b0;
                            z-index: 10000;
                        }
                    }

                    border: 2px solid $color-23;
                    width: 100%;
                    display: inline-block;

                    @include clearfix;
                    padding: 0;
                    margin: 0;

                    .continue {
                        margin: 0;
                    }
                }
            }

            .header {
                @include fonts-vw(ProximaNova, 16px, 16px);
                font-weight: 700;
                margin: 20px 0 20px 0;
            }

            .content {
                @include fonts-vw(ProximaNova, 16px, 16px);
                font-weight: 400;

                .explanation {
                    @include fonts-vw(ProximaNova, 12px, 12px);
                    font-weight: 400;
                    margin-top: -15px;
                    margin-bottom: 20px;
                }

                .browsefile {
                    margin: 20px 0;
                }

                .browsefile, input, textarea {
                    max-width: 433px;
                    width: 100%;
                }

                label.has-error {
                    max-width: 433px;
                }

                textarea {
                    height: 96px;
                    resize: none;
                }

                input[type="text"], input[type="email"], input[type="number"], input[type="phone"],, input[type="url"], textarea {
                    padding: 12px;
                    margin-top: 15px;

                    @include fonts-vw(ProximaNova, 16px, 16px);
                    //margin-bottom: 20px;
                }

                input[type="phone"] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    /* display: none; <- Crashes Chrome on hover */
                    -webkit-appearance: none;
                    margin: 0;
                    /* <-- Apparently some margin are still there even though it's hidden */
                }

                .field {
                    margin-bottom: 20px;
                }
            }
        }

        .continue {
            float: right;
            margin-right: 0;
        }

        .event-wrapper {
            margin-top: 30px;
        }

        .errors {
            font-weight: bold;
        }

        .success {
            font-weight: bold;
            color: #18ae1d;
        }

        .password-warning {
            font-weight: bold;
            color: #18ae1d;
            margin-top: 8px;
        }
    }

}
