﻿// variables
@import 'config/variables';

.business-signup-form-page {
    padding-top: 25px;
    padding-bottom: 75px;

    .form-sections-wrapper {
        background-color: #ececec;
        padding-top: 25px;
        padding-bottom: 35px;
        margin-bottom: 55px;

        @media (max-width: 768px) {
            padding-top: 35px;
        }
    }

    .wrapper {
        position: relative;
    }

    .expander {
        top: 20px;
        position: absolute;
        left: 335px;
        display: inline-block;
        cursor: pointer;
        z-index: 1;
        -webkit-transition: left $trans-duration ease-in;
        -moz-transition: left $trans-duration ease-in;
        -ms-transition: left $trans-duration ease-in;
        -o-transition: left $trans-duration ease-in;
        transition: left $trans-duration ease-in;

        @media (max-width: 768px) {
            left: unset;
            right: 25px;
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;
        }

        .caret-right {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-left: 18px solid #1043b2;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                border-left: 12px solid #ececec;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
            }
        }

        .caret-left {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-right: 18px solid #1043b2;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;

                @media (max-width: 768px) {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-top: 18px solid #1043b2;
                    border-left: 18px solid transparent;
                    border-right: 18px solid transparent;
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: 6px;
                top: 6px;
                border-right: 12px solid #ececec;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;

                @media (max-width: 768px) {
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 0;
                    border-top: 12px solid #ececec;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                }
            }
        }

        &.open {
            position: absolute;
            left: 96%;
            z-index: 1;
            -webkit-transition: left $trans-duration ease-in;
            -moz-transition: left $trans-duration ease-in;
            -ms-transition: left $trans-duration ease-in;
            -o-transition: left $trans-duration ease-in;
            transition: left $trans-duration ease-in;

            @media (max-width: 768px) {
                left: unset;
                right: 40px;
                -webkit-transition: none;
                -moz-transition: none;
                -ms-transition: none;
                -o-transition: none;
                transition: none;
            }
        }
    }

    .instructions-section {
        width: 25%;
        float: left;
        padding-top: 5px;

        @media (max-width: 768px) {
            width: 100%;
            float: unset;
            padding-left: 20px;
            padding-right: 40px;
        }

        header {
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 20px;
        }

        .instructions {
            @media (max-width: 768px) {
                padding-bottom: 5px;
            }

            &:last-child {
                @media (max-width: 768px) {
                    padding-bottom: 15px;
                }
            }
        }

        .below-instructions {
            padding-top: 10px;

            @media (max-width: 768px) {
                padding-top: 5px;
            }
        }
    }

    .fields-section {
        width: 75%;
        float: right;
        padding-left: 35px;
        padding-right: 35px;
        position: relative;
        height: 100%;
        visibility: visible;
        -webkit-transition: all $trans-duration ease-in;
        -moz-transition: all $trans-duration ease-in;
        -ms-transition: all $trans-duration ease-in;
        -o-transition: all $trans-duration ease-in;
        transition: all $trans-duration ease-in;
        padding-top: 40px;

        &.ng-hide-add, &.ng-hide-remove {
            -webkit-transition: all $trans-duration ease-in;
            -moz-transition: all $trans-duration ease-in;
            -ms-transition: all $trans-duration ease-in;
            -o-transition: all $trans-duration ease-in;
            transition: all $trans-duration ease-in;
        }

        &.ng-hide {
            @media (max-width: 768px) {
                height: 0;
            }
        }

        .hide-box {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            z-index: 2;
            background-color: #ececec;
            -webkit-transition: all $trans-duration ease-in;
            -moz-transition: all $trans-duration ease-in;
            -ms-transition: all $trans-duration ease-in;
            -o-transition: all $trans-duration ease-in;
            transition: all $trans-duration ease-in;

            &.ng-hide-add, &.ng-hide-remove {
                -webkit-transition: all $trans-duration ease-in;
                -moz-transition: all $trans-duration ease-in;
                -ms-transition: all $trans-duration ease-in;
                -o-transition: all $trans-duration ease-in;
                transition: all $trans-duration ease-in;
            }

            &.ng-hide {
                left: 100%;

                @media (max-width: 768px) {
                    left: 0;
                    top: 100%;
                }
            }
        }

        @media (max-width: 768px) {
            width: unset;
            float: unset;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 0;
        }
    }

    .wrapper.section-wrapper {
        border-bottom: 2px solid #dadada;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    section {
        @include clearfix;
        overflow: hidden;

        &#Club-Lookup {
            position: relative;
            overflow: unset;
        }
    }


    .next-wrapper {
        padding-bottom: 45px;

        .continue {
            margin-right: 62px;

            @media (max-width: 768px) {
                margin-right: 20px;
            }

            &.back {
                /*margin-left: 15px;*/
                @media (max-width: 768px) {
                    margin-left: 20px;
                }
            }
        }

        .errors {
            clear: both;
            font-weight: bold;
            color: #de0000;
            font-size: 18px;
            float: right;
            margin-right: 15px;
            margin-top: 10px;
        }
    }

    .first-section-button-section {
        .fields-section {
            padding-top: 0;

            .instructions {
                float: left;
                max-width: 72%;
                font-weight: bold;

                @media (max-width: 870px) {
                    max-width: 65%;
                }

                @media (max-width: 500px) {
                    float: unset;
                    max-width: 100%;
                }
            }

            .continue {
                margin-right: 27px;
            }
        }

        .errors {
            clear: both;
            text-align: right;
            padding-right: 62px;
            padding-top: 15px;
            font-weight: bold;
            color: #de0000;
        }
    }

    input {
        border-radius: 0;
    }

    .input-field {
        display: inline-block;
        margin-bottom: 7px;

        @include fonts-vw(ProximaNova, 16px, 16px);
        font-weight: 700;

        textarea, input[type=text], input, input[type=email], input[type=password] {
            width: 100%;

            @include fonts-vw(ProximaNova, 16px, 16px);
            border: 2px solid $color-29;
            background: #fff;
            padding: 10px;
            font-weight: 700;
        }

        select {
            padding: 10px;
            font-weight: 700;
            width: 100%;

            @include fonts-vw(ProximaNova, 16px, 16px);
            border: 2px solid $color-29;
            background-color: #fff;
        }

        label {
            line-height: 25px;

            &.control-label {
                line-height: 1;
                font-size: 16px;
            }
        }

        &.half {
            width: 47%;
            float: left;
            margin-left: 7px;
            margin-right: 7px;

            @media (max-width: 768px) {
                width: 100%;
                float: unset;
                margin-left: 0;
                margin-right: 0;
            }
            /*clear: left;

            & + .half {
                float: right;
                clear: right;
            }*/
        }

        &.quarter {
            width: 23%;
            float: left;
            margin-left: 7px;
            margin-right: 7px;

            @media (max-width: 768px) {
                width: 50%;
                float: left;
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.full {
            width: 96%;
            margin-left: 7px;
            margin-right: 7px;

            @media (max-width: 768px) {
                width: 100%;
                margin-left: unset;
                margin-right: unset;
            }

            @include clearfix;
        }

        &.third {
            width: 30%;
            margin-right: 10px;
        }

        &.hradio {
            clear: left;

            .title {
                font-size: 16px;
            }

            label.control-label {
                font-size: 16px;
                order: 6;
            }

            label:not(.validationMessage) {
                display: inline-block;
                width: 70px;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    //border-radius: 40px;
                    //border: 2px solid $color-23;
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }
            }

            input[type=radio]:checked + label:not(.validationMessage):before,
            input[type=radio]:checked + label.validationMessage + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-checked-business.png');
                background-size: contain;
                background-repeat: no-repeat;
            }

            input[type=radio]:disabled + label:not(.validationMessage):before,
            input[type=radio]:disabled + label.validationMessage + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-unchecked-business-disabled.png');
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.4;
            }
        }

        &.checkbox {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            input[type=checkbox] {
                position: absolute;
            }

            label.control-label {
                font-size: 16px;
                order: 3;
            }

            label:not(.validationMessage) {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    // border: 2px solid $color-23;
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }
            }

            input[type=checkbox]:checked + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-checked-business.png');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        &.hradio, &.checkbox {


            @include clearfix;

            label {
                @include fonts-vw(ProximaNova, 13px, 13px);
                font-weight: 600;
            }

            input {
                width: 0px;
                visibility: hidden;
            }
        }
    }

    .checkbox {
        input[type="checkbox"] {
            width: 0;
            visibility: hidden;
            float: left;
        }

        input[type=checkbox] + label:not(.validationMessage), input[type=checkbox] + label + label {
            position: relative;
            right: 0;
            display: inline-block;
            width: 100%;
            line-height: 1;
            text-align: left;
            padding-left: 36px;

            @include fonts-vw(ProximaNova, 14px, 14px);

            &.clubinfo-checkbox:before {
                top: 10px;
            }

            &:before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                background-size: cover;
                /*margin-right: 10px;*/
                position: absolute;
                left: 2px;
                top: 36%;
                transform: translateY(-50%);
            }
        }

        input[type=checkbox] + label.validationMessage {
            margin-bottom: 15px;

            &:before {
                content: none;
                display: none;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid #d04b4b;
                position: absolute;
                bottom: -6px;
                left: 20px;
            }
        }

        input[type=checkbox]:checked + label:before {
            background: url("../img/icons/icon-checked-business.png") no-repeat;
            background-size: cover;
        }
    }

    .component-footer-decoration {
        position: absolute;
        bottom: 177px;

        @media (max-width: 768px) {
            bottom: 312px;
        }
    }
}


#page-business-signup-form {
    /*.google-error {
        padding-bottom: 10px;
        font-weight: bold;
        color: #de0000;
        font-size: 16px;
        padding-left: 10px;

        &.hours-label {
            padding-left: 0;
            padding-top: 10px;
        }
    }



    .hours-wrapper {
        clear: both;
    }

    header.title {
        @include fonts-vw(ProximaNova, 36px, 36px);
        font-weight: 700;
        text-align: center;
    }*/
    .section-header {
        cursor: pointer;
    }
    /*.subtitle {
        color: $font-color-16;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px;

        .up-arrow, .down-arrow {
            background-repeat: no-repeat;
            background-position: center;
            height: 30px;
            width: 30px;
            padding: 0 20px;
        }

        .down-arrow {
            background-image: url(../img/icons/ico-arrow-down.png);
        }

        .up-arrow {
            background-image: url(../img/icons/ico-arrow-up.png);
        }
    }*/
    .instruction {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 20px;
    }

    input {
        border-radius: 0;
    }

    .input-field {
        display: inline-block;
        margin-bottom: 17px;

        @include fonts-vw(ProximaNova, 16px, 16px);
        font-weight: 700;

        textarea, input[type=text], input {
            width: 100%;

            @include fonts-vw(ProximaNova, 16px, 16px);
            border: 2px solid $color-29;
            background: #fff;
            padding: 10px;
            font-weight: 700;
        }

        select {
            padding: 10px;
            font-weight: 700;
            width: 100%;

            @include fonts-vw(ProximaNova, 16px, 16px);
            border: 2px solid $color-29;
            background-color: #fff;
        }

        label {
            line-height: 25px;

            &.control-label {
                line-height: 1;
                font-size: 16px;
            }
        }

        &.half {
            width: 47%;
            float: left;
            margin-left: 7px;
            margin-right: 7px;

            @media (max-width: 768px) {
                width: 100%;
                float: unset;
                margin-left: 0;
                margin-right: 0;
            }
            /*clear: left;

            & + .half {
                float: right;
                clear: right;
            }*/
        }

        &.full {
            width: 96%;
            margin-left: 7px;
            margin-right: 7px;

            @media (max-width: 768px) {
                width: 100%;
                margin-left: unset;
                margin-right: unset;
            }

            @include clearfix;
        }

        &.third {
            width: 30%;
            margin-right: 10px;
        }

        &.hradio {
            clear: left;

            .title {
                font-size: 16px;
            }

            label.control-label {
                font-size: 16px;
                order: 6;
            }

            label:not(.validationMessage) {
                display: inline-block;
                width: 70px;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    //border-radius: 40px;
                    //border: 2px solid $color-23;
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }
            }

            input[type=radio]:checked + label:not(.validationMessage):before,
            input[type=radio]:checked + label.validationMessage + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-checked-business.png');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        &.checkbox {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            input[type=checkbox] {
                position: absolute;
            }

            label.control-label {
                font-size: 16px;
                order: 3;
            }

            label:not(.validationMessage) {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    // border: 2px solid $color-23;
                    background-image: url('../img/icons/icon-unchecked-business.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }
            }

            input[type=checkbox]:checked + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-checked-business.png');
                background-size: contain;
                background-repeat: no-repeat;
            }

            input[type=checkbox]:disabled + label:before {
                // border-color: $color-23;
                // background: $color-23;
                background-image: url('../img/icons/icon-unchecked-business-disabled.png');
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.4;
            }

            input[type=checkbox]:disabled + label {
                color: #e7e7e7;
            }
        }

        &.hradio, &.checkbox {


            @include clearfix;

            label {
                @include fonts-vw(ProximaNova, 13px, 13px);
                font-weight: 600;
            }

            input {
                width: 0px;
                visibility: hidden;
            }
        }
    }

    .checkbox {
        input[type="checkbox"] {
            width: 0;
            visibility: hidden;
            float: left;
        }

        input[type=checkbox] + label:not(.validationMessage), input[type=checkbox] + label + label {
            position: relative;
            right: 0;
            display: inline-block;
            width: 100%;
            line-height: 1;
            text-align: left;
            padding-left: 36px;

            @include fonts-vw(ProximaNova, 14px, 14px);

            &.clubinfo-checkbox:before {
                top: 10px;
            }

            &:before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url("../img/icons/icon-unchecked-business.png") no-repeat;
                background-size: cover;
                /*margin-right: 10px;*/
                position: absolute;
                left: 2px;
                top: 36%;
                transform: translateY(-50%);
            }
        }

        input[type=checkbox] + label.validationMessage {
            margin-bottom: 15px;

            &:before {
                content: none;
                display: none;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid #d04b4b;
                position: absolute;
                bottom: -6px;
                left: 20px;
            }
        }

        input[type=checkbox]:checked + label:before {
            background: url("../img/icons/icon-checked-business.png") no-repeat;
            background-size: cover;
        }

        input[type=checkbox]:disabled + label:before {
            background: url("../img/icons/icon-unchecked-business-disabled.png") no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        input[type=checkbox]:disabled + label {
            color: #a5a5a5;
        }
    }

    .spinner {
        display: none;
    }

    .spinner.processing {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(58, 56, 56, 0.2);
        display: block;
        margin-top: -24px;
    }

    @keyframes lds-rolling {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @-webkit-keyframes lds-rolling {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    .lds-rolling {
        position: relative;
        margin: 0 auto;
        top: 250px;
    }

    .lds-rolling div,
    .lds-rolling div:after {
        position: absolute;
        width: 164px;
        height: 164px;
        border: 4px solid #1243b2;
        border-top-color: transparent;
        border-radius: 50%;
    }

    .lds-rolling div {
        -webkit-animation: lds-rolling 1s linear infinite;
        animation: lds-rolling 1s linear infinite;
        top: 100px;
        left: 100px;
    }

    .lds-rolling div:after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .lds-rolling {
        width: 200px !important;
        height: 200px !important;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }
}
