﻿#page-reset_password.create-password-page {
    .wrapper {
        text-align: center;
        padding-top: 80px;

        .instructions {
            font-weight:bold;
            color: #fff;
            font-size: 19px;
        }

        .inner-wrapper {
            padding-top: 20px;
        }
    }
}