#favourites {
    position: relative;
    width: 100%;
    display: inline-block;
    background-color: $bg-color-1;
    height: 454px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;

    @include respond-to(tablet) {
        width: 50%;
    }

    .title {
        position: relative;
        display: inline-block;
        margin-left: -2.5%;
        padding: 12px 0 24px 10.5%;
        width: 105%;
        font-size: 24px;
        font-weight: $font-w-bold;
        background-color: $white;

        &:before {
            content: "";
            left: 2.5%;
            top: 32%;
            transform: translateY(-50%);
            position: absolute;
            height: 35px;
            width: 38px;
            background-image: url('../img/icons/ico-favourites-blue.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        @include respond-to(tablet) {
            min-height: 62px;
            padding: 12px 0 unit-vw(40px, tablet) unit-vw(55px,tablet);
            font-size: unit-vw(24px, tablet);

            &:before {
                height: unit-vw(35px, tablet);
                width: unit-vw(38px, tablet);
            }
        }

        @include respond-to(desktop) {
            padding: 12px 0 24px 55px;
            font-size: 24px;

            &:before {
                height: 35px;
                width: 38px;
            }
        }
    }

    .favourites-list {
        padding: 0 2%;
        /*height: 454px;
        overflow-y: auto;
        margin-top: 10px;*/
        @include respond-to(desktop) {
            padding: 0 20px 0 15px;
        }

        > li {
            position: relative;
            // padding-top:20px;
            min-height: 115px;
            transition: all 0.20s ease-in-out;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 5px;

            @include respond-to(tablet) {
                // padding-top: unit-vw(20px, tablet);
                min-height: unit-vw(125px, tablet);
            }

            @include respond-to(desktop) {
                // padding-top: 20px;
                min-height: 106px;
            }

            &:before {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url('../img/shadows-stripes/mobile-section-stripes-gray.png');
                background-repeat: repeat-x;
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

            > a {
                position: relative;
                display: block;
                width: 100%;
                color: $font-color-15;
                cursor: pointer;
                padding: 5px 0;

                &:before {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 5px;
                    width: 8px;
                    background-image: url('../img/icons/ico-arrow-down.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: all 0.25s ease-in-out;
                    transform-origin: top;
                }
            }

            &.expanded {
                > a {
                    &:before {
                        transform: rotate(180deg) translateY(-50%);
                    }
                }
            }

            .favourites-gym-image {
                // float:left;
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
                width: 30%;
                height: 0;
                padding-bottom: 18%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                a {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 60%;
                }
            }

            .favourites-gym-name {
                vertical-align: middle;
                font-weight: $font-w-bold;
                padding-right: 20px;
                width: 70%;
                display: flex;
                flex-direction: column;
                min-height: 75px;

                a {
                    color: $black;
                }
            }

            .favourites-gym-users {
                width: 100%;
                font-weight: normal;
                margin-top: 10px;
            }

            .favourites-remove {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -10px;
                width: 12px;
                height: 16px;
                cursor: pointer;
                background: url('../img/icons/icon-trash.png') no-repeat 0 0;
            }
        }
    }
}
