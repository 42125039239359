
#ui-datepicker-div {
    background-color: $white !important;
    border: 2px solid $color-13 !important;
}


#profile-search {
    position: relative;
    display: none;
   

    @include respond-to(tablet) {
        display: inline-block;
    }

    .title {
        position: relative;
        display: inline-block;
        padding: 12px 0 40px 0px;
        width: 100%;
        font-size: 24px;
        font-weight: $font-w-bold;

        @include respond-to(tablet) {
            min-height: 75px;
            padding: unit-vw(40px, tablet) 0 unit-vw(12px, tablet) 0;
            font-size: unit-vw(24px, tablet);
        }

        @include respond-to(desktop) {
            padding: 44px 0 12px 0px;
            font-size: 24px;
        }
    }

    .input-search, .input-locale, .input-submit {
        display: inline-block;
        margin: 0px 0 5px 0px;
    }

    .input-search {
        width: 24%;
    }

    .input-locale {
        width: 45%;
    }

    .input-submit {
        width: 18%;
    }

    .input-submit {
        button {
            &:active, &:focus {
                border: 2px solid $color-19;
                border-top: 2px solid $color-20;
            }
        }
    }

    .form-group.has-error + .control-label {
        margin-top: -10px;
        margin-bottom: 20px;
        margin-left: 25px;
        width: 70%;
    }

    .has-error-backend {
        .input-search.has-error {
            input {
                box-shadow: inherit;
            }
        }
    }
}

.profile-dashboard-datepicker {
    padding: 1%;
    background-color: $white;
    border: 2px solid $color-13;
    z-index: 100 !important;

    table.ui-datepicker-calendar td.ui-datepicker-today a {
        background: $bg-color-1;
    }

    table.ui-datepicker-calendar a.ui-state-default.ui-state-active {
        background: $color-13;
    }
}