.input-search {
  display:inline-block;
  width: auto;
  height: auto;
  padding: 0;
  position: relative;
  outline: none;


  &:before{
    content: "";
    position: absolute;
    top:5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-image: url('../img/icons/icon-magnifying.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &.calendar {
    &:before {
      left: inherit;
      right: 5px;
      background-image: url('../img/icons/icon-calendar-blue.png');
      background-size: auto 100%;
      z-index: -1;
    }
    input {
      padding: 0 35px 0 10px;
      background: transparent;
    }
  }

  input {
    display: inline-block;
    height: 35px;
    width: 100%;
    line-height: 32px;
    padding: 0 10px 0 30px;
    border: 2px solid $button-color;
    font-family: $font-proxima;
    font-weight: $font-w-semibold;

    &:focus, &:active {
      border: 2px solid $button-color;
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &:-ms-input-placeholder {
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

.input-locale {
  display:inline-block;
  width: auto;
  height: auto;
  padding: 0;
  position: relative;
  outline: none;

  input {
    display: inline-block;
    height: 35px;
    width: 100%;
    line-height: 32px;
    padding: 0 35px 0 10px;
    border: 2px solid $button-color;
    font-family: $font-proxima;
    font-weight: $font-w-semibold;

    &:focus, &:active {
      border: 2px solid $button-color;
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }

    &:-ms-input-placeholder {
      font-family: $font-proxima;
      font-weight: $font-w-semibold;
      text-transform: uppercase;
      font-size: 13px;
    }
  }

  .icon {
    position: absolute;
    top:5px;
    right: 5px;
    height: 25px;
    width: 25px;
    background-image: url('../img/icons/icon-place-blue.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
}

.input-submit {
  display:block;
  width: auto;
  height: auto;
  padding: 0;
  position: relative;
  outline: none;

  input, button {
    display: block;
    height: 35px;
    line-height: 32px;
    padding: 0 30px;
    background-color: $color-13;
    border: 2px solid $color-19;
    border-top: 2px solid $color-20;
    font-family: $font-proxima;
    font-weight: $font-w-semibold;
    text-transform: uppercase;
    color: $white;
    cursor: pointer;
  }
}