.coming-soon-placeholder {
    position: relative;
    &:before {
        content: "";
        display: block !important;
        position: absolute !important;
        z-index: 1 !important;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        background: rgba(0, 0, 0, 0.4) !important;
        width: 100% !important;
    }
    &:after {
        content: "Coming \A soon";
        display: block !important;
        position: absolute;
        z-index: 1;
        top: 50% !important;
        right: 0 !important;
        left: 0 !important;
        transform: translateY(-50%);
        font-family: 'ProximaNova';
        font-size:  30px;
        text-transform: uppercase;
        font-weight: 400;
        color: $white;
        white-space: pre;
        text-align: center;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

        @include respond-to(tablet) {
            font-size: 25px;
        }
        @include respond-to(desktop) {
            font-size: 40px;
        }
    }
}

#page-buy-end.coming-soon {
    .buy-row:first-of-type .buy-column:first-child,
    .buy-row:last-of-type .buy-column:first-child {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
    .buy-column.barcode .subtitle {
        margin-top: 10px;
    }
    .buy-row #workout {
        display: block;
    }
    header {
        text-align: center;
    }
}