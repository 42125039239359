#page-businessgyms-Club {
    .wrapper {
        padding: 0 15px;

        @include respond-to(desktop) {
            padding: 0;
        }
    }

    .coming-soon-wrapper {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        top: -10px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        color: #fff;
        font-weight: bold;
        text-align: center;

        .coming-soon-copy {
            margin-top: 100px;
            font-size: 20px;
        }
    }

    .wrapper.comingSoon {
        position: relative;
    }

    .menu-section {
        .wrapper {
            padding: 2vw 0 10px 0;
        }

        .club-name {
            clear: both;
            font-weight: bold;
            color: #1143b2;
            text-align: center;
            font-size: 31px;
        }

        ul {
            width: 100%;

            li {
                float: left;
                padding: 7px;
                border-right: 1px solid #a8a8a8;
                border-left: 2px solid #a8a8a8;
                border-top: 2px solid #a8a8a8;
                border-bottom: 2px solid #a8a8a8;
                cursor: pointer;
                position: relative;
                margin-right: 5px;
                font-size: 14px;
                margin-bottom: 2vw;

                @include respond-to(tablet) {
                    padding: 12px 13px;
                    margin-right: 0;
                    font-size: inherit;
                }

                &:first-of-type {
                    border-left: 2px solid #a8a8a8;
                }

                &:last-of-type {
                    float: right;
                    border-right: 2px solid #a8a8a8;
                    border-left: 2px solid #a8a8a8;
                }

                &.lastinrow {
                    border-right: 2px solid #a8a8a8;
                }

                &.current {
                    background: #1243b2;
                    border-color: #1243b2;
                    color: $white;
                }

                &.notavailable {
                    background: #dadada;
                    border-color: #dadada;
                    color: $white;

                    &:after {
                        content: 'only for premium';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    #page-businessregistration {
        .main-section .wrapper {
            max-width: 1212px;
        }

        .main-section .fitnesstypes .fitness {
            width: 100%;
        }

        .left-column {
            width: 100%;
            float: left;
        }

        .right-column {
            width: 100%;
            float: right;

            .subtitle {
                text-align: left;
                margin-bottom: 15px;
            }

            button.continue {
                margin-right: 0px;
            }
        }

        @include respond-to(desktop) {
            .left-column {
                width: 45%;
            }

            .right-column {
                width: 45%;
            }
        }


        .bottomnav {
            width: 100%;
            clear: both;
        }

        .taxid-wrapper {
            label {
                width: 150px;
                margin-top: unset;
                float: unset;
                text-align: right;

                @media (max-width: 767px) {
                    text-align: left;
                }
            }

            .two-digit-num {
                float: unset;
            }
        }
    }

    section {
        /*padding-bottom: 30px;*/
        &.calendar {
            padding-top: 30px;

            .description {
                padding-top: 30px;
            }
        }

        @include clearfix;
    }

    button.continue {
        margin-top: 30px;
        margin-right: 80px;
        margin-bottom: 25px;
    }

    button.continue.right {
        margin-top: 15px;
        margin-right: 0;
        float: right;
    }

    .description {
        @include fonts-vw(ProximaNova, 16px, 16px);
    }

    h2.header {
        @include fonts-vw(ProximaNova, 24px, 24px);
        font-weight: 700;
        text-align: left;
        padding-bottom: 21px;

        @include clearfix;

        &.section {
            padding: 33px 0 20px 0;
            text-align: center;
            background-image: url(../img/shadows-stripes/mobile-section-stripes-blue.png);
            background-repeat: repeat-x;
            background-position: left top;

            &.left {
                text-align: left;
            }
        }
    }

    #page-businesspremier .main-section .wrapper, #page-businesspremier .chooseoption .wrapper, #page-businesspremier .presentation .wrapper {
        max-width: 1215px;
    }

    #page-businesspremier .chooseoption .options {
        margin-top: 5px;
    }

    #page-businesspremier .textwithimage .image {
        img {
            max-width: 667px;


            @media (max-width: 1300px) {
                width: 100%;
            }
        }
    }

    #page-standard .main-section .calendar-description {
        margin: 10px 0;
    }

    #page-standard .main-section .barcode {
        margin-top: 35px;

        &.disabled {
            &:before {
                padding-top: 50px;
            }
        }
    }

    #page-businessregistration .main-section .image {
        padding-bottom: 15px;
    }
}
