#ui-datepicker-div {
    display: none;
    padding: 1%;
}

a.ui-datepicker-next.ui-corner-all {
    float: right;


    @include respond-to(tablet) {
        font-size: unit-vw(18px, tablet);
    }
    @include fonts-vw(ProximaNova, 16px, 16px);
}

a.ui-datepicker-prev.ui-corner-all {
    float: left;

    @include respond-to(tablet) {
        font-size: unit-vw(18px, tablet);
    }
    @include fonts-vw(ProximaNova, 16px, 16px);
}
a.ui-datepicker-next, a.ui-datepicker-prev{
    cursor:pointer;
}
.ui-datepicker-title {
    text-align: center;
    // transform: translateY(-100%);
    font-weight: 700;

    @include respond-to(tablet) {
        font-size: unit-vw(18px, tablet);
    }

    @include fonts-vw(ProximaNova, 16px, 16px);

    select {
        padding: 2px;
        margin: 1px;
        border-radius: 6px;
        outline: 0;
    }

    .ui-datepicker-month {
        width: 35%;
    }

    .ui-datepicker-year {
        width: 25%;
    }
}

.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all {
    padding-bottom: 10px;
}



.ui-datepicker th {
    padding: 10px;
    color: $color-13;
    font-weight: 700;
    text-align: center;


    @include respond-to(tablet) {
        font-size: unit-vw(18px, tablet);
    }
    @include fonts-vw(ProximaNova, 16px, 16px);
}

table.ui-datepicker-calendar {
    width: 100%;
    text-align: right;

    tr td {
        padding: 10px;
        position: relative;
        text-align: center;

        a {
            color: #4b4b4b;
            font-weight: 700;

            @include respond-to(tablet) {
                font-size: unit-vw(18px, tablet);
            }
            @include fonts-vw(Roboto, 16px, 16px);
        }
    }

    td.ui-datepicker-today a {
        background: $white;
        border-radius: 50px;
        position: absolute;
        right: 50%;
        margin-right: -15px;
        width: 30px;
        height: 30px;
        top: 3.5px;
        line-height: 30px;
        text-align: center;
    }

    a.ui-state-default.ui-state-active {
        background: $color-13;
        border-radius: 50px;
        color: $white;
        position: absolute;
        right: 50%;
        margin-right: -15px;
        width: 30px;
        height: 30px;
        top: 3.5px;
        line-height: 30px;
        text-align: center;
    }

    td.ui-datepicker-other-month a {

        font-weight: 300;
        color: #4b4b4b;

        @include respond-to(tablet) {
            font-size: unit-vw(18px, tablet);
        }
        @include fonts-vw(Roboto, 16px, 16px);
    }
}