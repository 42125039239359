footer {
    background: $color-3;
    color: $white;
    padding: 19px 0;

    .menu, .privacypolicy {
        margin: 0 auto;
        display: inline-block;
        width: 100%;

        @include respond-to(desktop) {
            width: auto;
            position: absolute;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
        }
    }

    .socialIcons {
        text-align: center;

        .icon {
            display: inline-block;
            width: 43px;
            height: 43px;
            margin-right: 34px;

            @include respond-to(mobile-sm){
                margin-right: unit-vw(34px, mobile);
            }

            &:last-of-type{
                @include respond-to(mobile-sm){
                    margin-right: 0;
                }
            }

            &.facebook {
                @include sprite($layout-fb);
            }

            &.twitter {
                @include sprite($layout-tw);
            }

            &.instagram {
                @include sprite($layout-in);
            }

            &.youtube {
                @include sprite($layout-yt);
            }
        }

        @include respond-to(desktop) {
            text-align: left;

            .icon {
                width: 33px;
                height: 33px;
                margin-right: 4px;
            }
        }
    }

    .forbusiness, .contact {
        @include respond-to(desktop) {
            float: right;
            display: inline-block;
            text-align: right;
        }
    }

    .contact {
        padding-top: 6px;
        color: $white;
        font-family: ProximaNova;
        font-weight: 400; //font-weight: 600;
        font-size: 15.56px;
        cursor: pointer;
    }

    .description {
        float: left;
        width: 30%;
        font-family: ProximaNova;
        font-weight: 400; //font-weight: 600;
        display: none;

        .title {
            font-size: 16px;
        }

        .subtitle {
            font-size: 10px;
        }

        @include respond-to(desktop) {
            display: inline-block;
        }
    }

    .privacypolicy {
        text-align: center;
        font-family: ProximaNova;
        font-weight: 400; //font-weight: 600;
 
        .links {
            font-size: 14px;
            a{
                color:$white;
            }
        }

        .copywright {
            font-size: 10px;
        }
    }

    .forbusiness {
        text-align: center;
    }

    .forbusiness a {
        display:inline-block;
        color: $white;
        font-family: Roboto;// RobotoBlack;
        font-weight: 400;
        padding: 17px 24px;
        font-size: 12px;
        border: 2px solid $white;
        text-decoration: none;
        
    }

    .menu {
        text-align: center;
        font-family: ProximaNova;
        font-weight: 400;
        font-size: 14px;
        padding-top: 9px;

        ul {
            padding: 0;
            margin: 0;

            li {
                display: inline-block;

                a {
                    color: $white;
                    text-decoration: none;
                }

                //  float: left;
                list-style: none;
                padding: 0;

                &:nth-of-type(n-1):after {
                    content: "|";
                    display: inline-block;
                    padding: 0 1vw;
                }

                &:last-of-type:after {
                    content: "";
                }
            }
        }
    }

    .left-side {
        // position: relative;
        float: right;
        width: 100%;

        .menu, .socialIcons {
            //  float: right;

            // width: 100%;
            margin-bottom: 34px;
        }

        @include respond-to(desktop) {
            width: 80%;
            float: left;

            .menu, .socialIcons {
                float: left;
                margin-bottom: 0;
            }
        }

        .bottom-row {
            margin-top: 0;

            @include respond-to(desktop) {
                margin-top: -17px;
            }
        }
    }

    .right-side {
        //position: relative;
        float: right;
        width: 100%;
        margin-bottom: 41px;
        margin-top: 20px;

        .top-row {
            width: 48%;
            float: left;
            text-align: center;
            margin-left:1%;
        }

        .bottom-row {
            float: right;
            width: 48%;
            margin-right:1%;
        }

        .iconemail {
            width: 30px;
            height: 20px;
            background: url("../img/icons/icon-email.png") no-repeat;
            background-size: auto 100%;
            display: inline-block;
        }

        .text {
            margin-top: 4px;
            vertical-align: top;
            display: inline-block;
        }

        @include respond-to(desktop) {
            width: 20%;
            margin-bottom: 0;
            margin-top: 0;

            .top-row {
                width: 100%;
                text-align: right;
                margin-left:0;
            }

            .bottom-row {
                text-align: right;
                width: 100%;
                margin-right:0;
            }
        }
    }

    .top-row {
        @include respond-to(desktop) {
            margin-bottom: 54px;
        }
    }

    .top-row, .bottom-row {
        @include clearfix;
    }
}