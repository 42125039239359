﻿#page-business-forgot_password {
    @extend .all-business;

    &.access-page {
        padding-bottom: 60px;

        .inner-wrapper {
            padding-top: 80px;
            width: 90%;
            margin: 0 auto;
            max-width: 480px;

            @include respond-to(tablet) {
                // width: 32%;
            }
        }

        p, .createaccount {
            a {
                color: $color-32;
            }

            color: $color-32;
        }

        label {
            color: $color-32;
            margin-bottom: 10px;
            @include fonts-vw(ProximaNova, 16px, 16px);
            margin-bottom: 10px;
            font-weight: 700;
            display: inline-block;

            &.control-label.has-error.validationMessage:before {
                display: none;
            }
        }

        .helper a {
            color: $color-32;
            @include fonts-vw(ProximaNova, 14px, 14px);
            font-weight: 400;
        }

        .form-group {
            div  {
                margin-top: 24px;
            }
        }
        input[type="email"] {
            margin-top: 0;
        }
        //End
        .has-error,
        .has-error-backend {
            input[type="email"] {
                margin-bottom: 0;
            }

            .control-label,
            + .control-label {
                color: $white;
            }
        }
    }
}
