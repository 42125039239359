﻿#Pricing-Options {
    .pricing-wrapper {

        .option-description {
            font-weight: normal;
            padding-left: 5px;
        }
    }

    @include clearfix;

    .pricing-options {
        width: 100%;
        max-width: 825px;
        margin: 0 auto;

        .input-field {
            @include clearfix;
            margin-bottom: 15px;

            .subtitle {
                margin: 0;
            }
        }

        .title {
            padding-top: 5px;
        }


        label, .title {
            width: 49%;
            float: left;

            @media (max-width: 768px) {
                width: 47%;
            }
        }

        label {
            padding-right: 25px;
        }

        input, .day-pass-check, .tax-id-inputs-wrapper {
            width: 49%;
            float: right;
            padding: 10px;
        }

        .tax-id-inputs-wrapper {
            padding: 0;

            label {
                width: unset;
            }
        }

        .day-pass-check {
            padding: 0;

            label {
                font-size: 16px;
                margin-right: 20px;
            }
        }
    }

    .pricing-preview {
        width: 80%;
        margin: 0 auto;
        float: none;
        margin-top: 45px;
        border: 1px solid #000;
        display: none;

        @include respond-to(tablet) {
            width: 50%;
            float: right;
            display: block;
        }


        img {
            width: 100%;
        }
    }

    .pricing-description {
        width: 100%;
        float: left;
        clear: both;

        a {
            color: inherit;
            text-decoration: underline;
        }

        @include fonts-vw(ProximaNova, 16px, 16px);
        font-weight: 400;
        color: $black;

        p {
            margin-bottom: 22px;
        }

        margin: 25px 0;
    }

    span.dollar-sign {
        position: relative;
        line-height: 43px;
        height: 43px;
        display: inline-block;
        /*@media (max-width: 768px) {
            line-height: 39px;
            height: 39px;

        }*/
    }

    span.percent {
        position: absolute;
        margin-left: 50px;
        margin-top: 14px;

        @media (max-width: 768px) {
            margin-top: 13px;
        }

        @media (max-width: 479px) {
            margin-top: 14px;
        }
    }

    .taxid-wrapper {
        width: 100%;

        label {
            @media (max-width: 767px) {
                text-align: left !important;
            }
        }

        .two-digit-num {
            width: 100px;
            float: left;

            @include respond-to(mobile-sm) {
                width: 64px;
                margin-top: 0px;
            }

            .text-box {
                width: 100%;
            }
        }

        span {
            width: 20px;
            text-align: center;
            margin: 0px 0px;
            line-height: 40px;
            float: left;
        }

        .seven-digit-num {
            width: 150px;
            float: left;

            @include respond-to(mobile-sm) {
                width: 136px;
                margin-top: 0px;
            }

            .text-box {
                width: 100%;
            }
        }
    }
}
