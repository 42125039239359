﻿.book-popup.small-popup {
    max-width: 575px !important;
    width: 575px !important;
    z-index: 22;

    @media (max-width: 600px) {
        width: 95% !important;
        min-width: 320px !important;
    }

    p {
        display: block;
        margin: 0 auto 10px;
        text-align: center;
        font-size: 14px;
        line-height: 1.2em;
    }

    .main-content {
        padding: 0 20px 20px 20px;

        .terms-html {
            p {
                text-align: left;
            }
        }
    }

    .header {
        border-bottom: none;
        padding-top: 10px;
    }

    .book-header {
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
    }

    .class-content {
        line-height: 1.2;
        border-bottom: 1px solid #115ac0;
        padding-bottom: 10px;
        margin-bottom: 30px;

        .class-name {
            font-weight: bold;
            font-size: 20px;
            /*padding-top: 3px;
            padding-bottom: 3px;*/
        }

        .class-date {
            font-weight: bold;
            font-size: 20px;

            span {
                font-weight: bold;
            }
        }

        .class-time {
            font-weight: bold;
            padding-bottom: 10px;
            font-size: 20px;
        }

        .class-instructor {
            font-weight: bold;
            font-size: 20px;
        }
    }

    .gym-content {
        overflow: auto;
        border-bottom: 1px solid #115ac0;
        padding-bottom: 20px;
        margin-bottom: 30px;

        .logo-content {
            float: left;
            display: inline-block;
            width: 200px;
            margin-right: 15px;
            padding-bottom: 15px;

            @include respond-to(mobile-ex) {
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding-bottom: 0;
            }

            .book-logo {
                vertical-align: middle;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                //100%;
                height: 0;
                width: 120px;
                padding-bottom: 120px;

                @include respond-to(tablet) {
                    margin-bottom: 11px;
                    width: 100%;
                    padding-bottom: 75%;
                }

                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 120px;
                }


                &.empty {
                    background-size: auto 100%;
                    background-color: #008ece;
                    background-image: url("/Content/img/club-logo-placeholder.jpg");
                }
            }
        }

        .location-content {
            padding-top: 37px;
            text-align: left;

            .location-name {
                font-weight: bold;
                font-size: 22px;
            }

            p {
                text-align: left;
            }

            .phone {
                font-size: 14px;

                .phone-label {
                    font-weight: bold;
                }
            }
        }
    }

    .cost-content {
        border-bottom: 1px solid #115ac0;
        padding-bottom: 30px;
        margin-bottom: 30px;
        clear: both;

        .prices {
            text-align: left;
            max-width: 245px;
            margin: 0 auto;
            line-height: 1.2;
            font-size: 16px;

            .price {
                font-weight: bold;
                padding-right: 17px;
                text-align: right;

                .price-sign {
                    float:left;
                }

                .promo-discount-sign {
                    float: left;
                    margin-left: -5px;
                }
            }
        }
    }

    .detail-column.grey {
        // border-top: 1px solid #ececec;
        position: relative;
        background: $bg-color-1;
        border-bottom: 1px solid #ececec;



        header {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 15px;
            padding-left: 25px;

            @include fonts-vw(ProximaNova, 16px, 16px);

            @include respond-to(tablet) {
                padding-left: 0;
            }
        }

        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 20vw;
            left: 100%;
            top: 0;
            background: url("../img/club-about-bg.png") no-repeat;
            background-size: auto 100%;
        }
    }

    .detail-column.promocode {
        padding-top: 27px;
        padding-bottom: 31px;
        max-width: 347px;
        margin-left: -48px;
        border-right: 1px solid #e8e8e8;

        .promo-code-form {
            padding-bottom: 15px;
            padding-left: 43px;
        }

        .promo-header {
            font-weight: bold;
            text-align: left;
            padding-bottom: 7px;
            padding-left: 7px;
        }

        .form-group {
            width: 65%;
            display: inline-block;
        }

        input[type="text"] {
            width: 100%;
            padding: 8px 9px;
            border: 2px solid $color-12;
        }

        a {
            color: inherit;
            text-decoration: underline;

            &:hover {
                color: $color-13;
            }
        }

        button {
            float: right;

            &.in-progress {
                &:after {
                    @include preloader(22px, 22px, 2px, absolute, $color-6, $color-12);
                }

                &:hover {
                    &:after {
                        @include preloader(22px, 22px, 2px, absolute, "", "");
                    }
                }
            }

            &.apply {
                width: 30%;
            }

            &.purchase {
                width: 100%;
            }
        }

        ul.prices {

            li {
                font-family: ProximaNova;
                font-weight: 700;
                font-size: 17px;
                line-height: 24px;
                color: $color-9;

                .price {
                    width: 45%;
                    display: inline-block;
                }
            }

            li.total {
                font-weight: 700;
                font-size: 17px;
                background: url("../img/shadows-stripes/dash-3.png") repeat-x;
                background-size: auto 5px;
                background-position: top;
                padding-top: 10px;
                margin-top: 10px;
                text-transform: uppercase;
            }
        }
    }

    .terms-subtitle {
        font-weight: bold;
    }

    .main-content {
        .terms-html {
            text-align: left;

            &p {
                text-align: left;
            }
        }
    }

    .book-disclaimer {
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: bold;
    }

    .termsandconditions {
        text-align: left;

        .terms {
            position: relative;
            top: 2px;
            margin-right: 6px;
        }
    }

    .book-error {
        color: #de0000;
        font-weight: bold;
        padding-top: 20px;
    }

    .book-button {
        border: none;
        background-color: #ffbd46;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 16px;
        cursor: pointer;
    }

    .book-button:hover {
        background-color: #e9a500;
    }

    #payment-form {
        .payment {
            .text {
                font-weight: bold;
                font-size: 22px;
                color: #115ac0;
                padding-bottom: 20px;
            }

            .method {
                padding-top: 15px;
            }
        }
    }

    .account-form {
        text-align: left;
        border-top: 1px solid #115ac0;
        padding-top: 25px;
        margin-top: 5px;
        border-bottom: 1px solid #115ac0;
        padding-bottom: 25px;
        margin-bottom: 25px;

        .zingfit-instructions {
            font-weight: bold;
            font-size: 18px;
            padding-bottom: 20px;
        }

        .input-field {
            display: inline-block;
            margin-bottom: 17px;
            text-align: left;

            @include fonts-vw(ProximaNova, 16px, 16px);
            font-weight: 700;

            textarea, input[type=text], input {
                width: 100%;

                @include fonts-vw(ProximaNova, 16px, 16px);
                border: 2px solid $color-29;
                background: $color-30;
                padding: 10px;
                font-weight: 700;
            }

            label {
                line-height: 25px;

                &.control-label {
                    line-height: 1;
                    font-size: 16px;
                }
            }


            select {
                appearance: none;
                -moz-appearance: none;
                border-radius: 0;
                /* Firefox */
                -webkit-appearance: none;
                position: relative;
                background: url("../img/icons/ico-arrow-down.png") no-repeat $color-30;
                background-position: 93%;
                width: 100%;
                padding: 10px;
                font-size: 13px;
            }

            select::-ms-expand {
                display: none;
            }
        }



        .editfield {
            &.gender .title {
                width: 16%;
                float: left;
            }

            &.radio.gender {
                label {
                    width: 25% !important;
                }

                .gender-buttons {
                    clear: both;
                }
            }

            &.radio {

                @include clearfix;

                label {
                    width: 40%;
                    float: left;

                    @include respond-to(desktop) {
                        width: auto;
                        min-width: 100px;
                    }


                    &:first-of-type {
                        width: 100%;

                        @include respond-to(desktop) {
                            width: 40%;
                        }
                    }
                }

                input[type=radio] + label:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 40px;
                    border: 2px solid $color-21;
                    margin-bottom: -7px;
                    margin-right: 13px;
                }

                input[type=radio]:checked + label:before {
                    border-color: $color-13;
                    background: $color-13;
                }

                input[type=radio] {
                    visibility: hidden;
                    width: 0;
                    display: none;
                }

                input {
                    float: left;

                    + label {
                        text-align: center;
                    }
                }
            }

            &.birthday {
                width: 100%;
                clear: both;
                display: flex;
                flex-wrap: wrap;

                .control-label {
                    order: 5;
                }

                input {
                    width: 16%;
                    margin-left: 3%;
                    height: 100%;

                    @include respond-to(desktop) {
                        width: 14%;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        width: 24%;
                    }

                    &.ng-invalid {
                        box-shadow: 0 0 4px $error-color;
                    }
                }
                //contact icon removal for safari
                input::-webkit-contacts-auto-fill-button {
                    visibility: hidden;
                    display: none !important;
                    pointer-events: none;
                    position: absolute;
                    opacity: 0;
                    right: 0;
                }
            }
        }


        .method {
            padding-bottom: 20px;
        }
    }

    .confirmation-content {
        padding: 0 40px 0 40px;
        position: relative;

        .thanks-content {
            border-bottom: 1px solid #115ac0;
            padding-bottom: 10px;
            margin-bottom: 30px;
        }

        .thanks-heading {
            font-size: 33px;
            font-weight: bold;
        }

        .thanks-subheading {
            font-size: 19px;
            padding-top: 5px;
            padding-bottom: 20px;
            color: #555;
        }

        .thanks-name {
            font-weight: bold;
            font-size: 20px;
        }

        .thanks-barcode {
            padding-bottom: 20px;

            img {
                width: 100%;
                max-width: 350px;
            }
        }

        .class-content {
            border-bottom: unset;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .thanks-booking-id {
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: bold;
            font-size: 20px;
        }

        .location-content {
            .location-name {
                font-size: 22px;
                font-weight: bold;
            }
        }

        .note-content {
            text-align: left;
            word-break: break-word;
            padding-bottom: 25px;

            .note-header {
                font-weight: bold;
                font-size: 22px;
                padding-bottom: 15px
            }

            .activity-content {
                padding-bottom: 15px;
            }

            .print {
                padding: 4px 15px;
                display: none;
                max-width: 165px;
                margin: 15px auto 0 auto;

                .print-text {
                    display: inline-block;
                    height: 22px;
                    vertical-align: middle;
                    //padding-top: 4px;
                    padding-left: 6px;
                }

                .print-icon {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-top: 3px;

                    @include sprite($buyend-print-blue);
                }

                @include respond-to(desktop) {
                    display: block;
                    cursor: pointer;
                }
            }
        }

        .detail-column.promocode {
            max-width: 421px;
            margin-left: -40px
        }

        .cost-content .prices {
            margin: unset;
            margin-left: 50px;
        }

        .buy-column {
            background-color: #e8e8e8;
            margin-left: -40px;
            margin-right: -40px;
            padding: 0 40px 20px 40px;
            position: relative;
        }

        .share-header {
            font-sizE: 18px;
            font-weight: bold;
            padding-bottom: 21px;
            padding-top: 25px;
        }

        .buy-image {
            width: 100%;
            margin: 0;
            margin-bottom: 30px;

            img {
                display: block;
                margin: auto;
                max-width: 100%;
                max-height: 300px;
            }
        }


        .share, .print, .invite {
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 16px;
            color: $color-13;
            padding: 3px 15px;
            border: 2px solid $color-13;
        }

        .social-tier {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            > * {
                margin: 5px 5px 0;
            }


            .share {
                .share-fb {
                    width: 20px;
                    height: 20px;
                    display: inline-block;

                    @include sprite($buyend-fb-blue);
                }

                .share-text {
                    display: inline-block;
                    height: 26px;
                    line-height: 26px;
                    padding-right: 6px;
                    vertical-align: top;
                }
            }

            .invite {
                .invite-text {
                    display: inline-block;
                    height: 26px;
                    line-height: 26px;
                    padding-left: 6px;
                }

                .invite-icon {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: relative;
                    top: 1px;

                    @include sprite($buyend-email-blue);
                }
            }
        }

        .social-popup {
            top: 0;
            bottom: 0;
            padding: 0;
            width: unset;
            position: absolute;
            left: 0;
            transform: unset;
            -ms-transform: unset;
            display: block;
            /*margin-left: -40px;
            margin-right: -40px;*/
            right: 0;

            .close-icon {
                right: 4px;
                top: 6px;
                padding: 0 8px;
                width: 20px;
                height: 20px;
            }

            .main-content {
                padding: 20px;
                width: 100%;
                margin: 0 auto;
            }



            .invite {
                .invite-text {
                    display: inline-block;
                    height: 26px;
                    line-height: 26px;
                    padding-left: 6px;
                }

                .invite-icon {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: relative;
                    top: 1px;

                    @include sprite($buyend-email-blue);
                }
            }

            &.disable {
                display: none;
            }
        }
    }

    .spinner {
        display: none;
    }

    .spinner.processing {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(58, 56, 56, 0.7);
        display: block;
    }

    @keyframes lds-rolling {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @-webkit-keyframes lds-rolling {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    .lds-rolling {
        position: relative;
        margin: 0 auto;
        top: 250px;
    }

    .lds-rolling div,
    .lds-rolling div:after {
        position: absolute;
        width: 164px;
        height: 164px;
        border: 4px solid #1243b2;
        border-top-color: transparent;
        border-radius: 50%;
    }

    .lds-rolling div {
        -webkit-animation: lds-rolling 1s linear infinite;
        animation: lds-rolling 1s linear infinite;
        top: 100px;
        left: 100px;
    }

    .lds-rolling div:after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .lds-rolling {
        width: 200px !important;
        height: 200px !important;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }
}

@media (max-width: 575px) {
    .book-popup.small-popup {
        .detail-column.promocode {
            margin-left: unset;
            border-right:none;

            .promo-code-form {
                padding-left: 0;
            }
        }

        .detail-column.grey {
            &:after {
                display: none;
            }

            margin-right:-35px;
            margin-left: -35px;
            padding: 20px;
        }

        .gym-content {
            .logo-content {
                .book-logo {
                    margin: 0 auto;
                }
            }
            .location-content {
                text-align: center;

                p {
                    text-align: center;
                }
            }
        }
    }

}

@media print {
    #page-club {
        display: none;
    }

    .book-popup.small-popup {
        width: 100% !important;
        max-width: unset !important;
        top:0;
        
        .book-logo {
            display: none;
        }

        .buy-column {
            display: none;
        }
        .social-popup {
            display: none;
        }
    }
}