.social-popup {
    position: fixed;
    margin: 0 auto;
    padding: 30px 0;
    width: 66%;
    min-width: 320px;
    max-width: 920px;
    z-index: 10;
    top: 3vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    opacity: 1;
    visibility: visible;
    transition: top 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

    &.disable {
        top: -10vw;
        opacity: 0;
        visibility: hidden;
    }

    .close-icon {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 5px;
        top: 5px;
        background-color: $color-16;
        background-image: url("../img/icons/ex_white.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .main-content {
        margin: 20px auto 0;
        padding: 20px;
        width: 90%;
        min-width: 300px;
        display: block;
        background-color: $color-16;
        font-family: ProximaNova;

        h2 {
            font-weight: 700;
            font-size: 18px;
        }

        p {
            margin: 20px auto 10px;
            font-size: 14px;
        }

        ul {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            // justify-content: space-around;
            align-items: center;
            overflow:hidden;
            li {
                width: 48%;
                height: 0;
                padding-bottom: 30%;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: contain;
                float: left;
                border: 5px solid #ccc;

                // &:nth-child(3) {
                //     margin-top: 2%;

                //     + li {
                //         margin-top: 2%;
                //     }
                // }

                &.selected {
                    // outline: 5px solid $color-13;
                    border: 5px solid $color-13;
                }

                @include respond-to(desktop) {
                    &:not(.selected):hover {
                        border: 5px solid $hovered-item;
                    }
                }
            }
        }
    }
}