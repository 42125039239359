.homepage, .body-login-page, .body-register-page {
    position: relative;

    > header {
        position: fixed;
        width: 100%;

        .wrapper {
            width: 90%;
            max-width: 1900px;
        }

        .menu {
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease-in-out;
        }
    }

    footer .wrapper {
        width: 90%;
        max-width: 1900px;
    }

    .body-content {
        padding-top: 143px;

        @include respond-to(tablet) {
            padding-top: 143px;
        }

        @include respond-to(desktop) {
            padding-top: 143px;
        }

        &.body-rally-content {
            padding-top: 193px;
        }
    }

    &[data-overlayactive="true"] {
        > header {
            position: relative;
        }

        .body-content {
            padding-top: 0;
        }
    }
}

#page-home {
    .wrapper {
        width: 90%;
        max-width: 1900px;
    }

    section {
        @include clearfix;
        overflow: hidden;

        header {
            @include attr-px-vw-opt("font-size", 48px, 48px);
            color: $white;
        }
    }

    #search {
        position: relative;
        background: url("../img/homepage-background2.png") no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 7vh 0;

        &:after {
            content: "";
            width: 100%;
            background: url("../img/shadows-stripes/dash.png") no-repeat;
            background-size: 100%;
            display: block;
            position: absolute;
            bottom: 0;
        }

        @include respond-to(tablet) {
            display: flex;
            min-height: calc(100vh - 74px);
            padding: 0;
        }


        @include respond-to(desktop) {
            background: url("../img/homepage-background1.jpg") no-repeat;
            background-size: auto 100%;
            background-position: top right;

            @supports (-ms-ime-align:auto) {
                padding: 72px 5% 0 5%;
            }

            @media all and (-ms-high-contrast:none) {
                padding: 72px 5% 0 5%;
            }

            &:after {
                content: "";
                left: 0;
                width: 100%;
                height: 100px;
                background: url("../img/shadows-stripes/horizontal-stripes.png") top left repeat-x;
                bottom: 0;
            }
        }


        .wrapper {
            align-self: center;

            @include respond-to(desktop) {
                //   @include attr-px-vw-opt("margin-top", -35px, -65px);
                //   @include attr-px-vw-opt("margin-bottom", -65px, -35px);
                padding-top: 3vw;
                padding-bottom: 100px;
            }
        }

        .inner {
            width: 100%;
            position: relative;
        }

        .title {
            font-family: ProximaNova;
            font-weight: 400; //font-weight: 700;
            color: $white;

            @include fonts-vw-opt(ProximaNova,32px, 52px);
        }

        .subtitle {
            font-weight: 400;
            color: $white;

            @include fonts-vw-opt(Roboto, 19px, 24px);
            margin: 0 0 2.7vw 0;
            // margin: 1.4vw 0 1.3vw 0;
        }

        .field {
            @include attr-px-vw-opt("margin-bottom", 18px, 18px);
            width: 100%;
            position: relative;

            @include clearfix;

            &:last-of-type {
                text-align: center;

                @include respond-to(tablet) {
                    margin-left: 0;
                    text-align: left;
                    padding-top: 1.31772vw;
                }
            }

            > label {
                line-height: 33px;
            }

            &.what > label:first-of-type:not(.control-label) {
                float: left;
            }

            label:not(.control-label) {
                font-family: Roboto;
                font-weight: 400; //font-weight: 700;
                font-size: unit-vw(25px, mobile);
                color: $white;
                width: unit-vw(90px, mobile);
                display: inline-block;
                margin-right: unit-vw(-90px, mobile);
                cursor: pointer;

                @include respond-to(mobile-lg) {
                    width: 90px;
                    margin-right: -90px;
                    font-size: 23px;
                }


                @include respond-to(tablet) {
                    width: 100px;
                    margin-right: 0.7vw;
                    font-size: unit-vw(25px, tablet);
                }


                @include respond-to(desktop) {
                    font-size: 1.6vw;
                }
            }

            &:last-of-type {
                label:not(.control-label) {
                    display: none;

                    @include respond-to(tablet) {
                        display: inline-block;
                    }
                }
            }

            .radios {
                // transform: translateX(-6px);
                float: left;
                width: 100%;
                padding-left: unit-vw(100px, mobile);

                @include respond-to(mobile-lg) {
                    padding-left: 100px;
                }


                @include respond-to(tablet) {
                    width: 330px;
                    float: left;
                    padding-left: 0;
                }
            }

            .icon-calendar {
                display: inline-block;
                width: 33px;
                height: 33px;
                background: url(../img/icons/icon-calendar.png) no-repeat;
                background-size: auto 100%;
                background-position: right;
                position: absolute;
                right: 0;
                top: -3%;
                cursor: pointer;

                @include respond-to(tablet) {
                    width: 33px;
                    height: 33px;
                    left: 230px;
                    top: 0;
                }
            }

            input {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                color: $white;
                background: $color-8;
                border: none;
                // border-top: 1px solid $white;
                border-top: 1px solid #96a4b7;
                padding: 5px 10px;
                -webkit-box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);
                box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"] + label:not(.control-label) {
                width: 33.333%;
                font-family: Roboto;
                font-weight: 500;
                font-size: 14px;
                float: left;
                text-align: center;
                color: $white;
                margin-right: 0px;

                @include respond-to(tablet) {
                    width: 100px;
                    margin-right: 10px;
                }

                @include respond-to(desktop) {
                    width: 90px;
                    margin-right: 25px;
                }


                &:first-of-type {
                    text-align: left;
                }

                &:last-of-type {
                    text-align: right;
                    margin-right: 0;
                }

                .description {
                    margin-top: 16px;

                    @include respond-to(tablet) {
                        text-align: center;
                        width: 90px;
                    }

                    @include respond-to(desktop) {
                        width: 85px;
                    }
                }

                &[for="groupFitness"] .icon {
                    background: url("../img/icons/homepage-3.png") $color-8 no-repeat;
                    background-position: center center;

                    @include respond-to(tablet) {
                        ~ .description {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }

                &[for="gymAccess"] .icon {
                    background: url("../img/icons/homepage-1.png") $color-8 no-repeat;
                    background-position: center center;

                    @include respond-to(tablet) {
                        ~ .description {
                            margin-right: 10px;
                        }
                    }

                    @include respond-to(desktop) {
                        ~ .description {
                            margin-right: 5px;
                        }
                    }
                }

                &[for="supriseMe"] .icon {
                    background: url("../img/icons/icon-thumb.png") $color-8 no-repeat;
                    background-position: center center;

                    @include respond-to(tablet) {
                        ~ .description {
                            margin-left: 10px;
                        }
                    }

                    @include respond-to(desktop) {
                        ~ .description {
                            margin-left: 5px;
                        }
                    }
                }

                .icon {
                    width: 72%;
                    margin: 0 auto;
                    display: inline-block;
                    background: $color-8;
                    border-radius: 10px;
                    border-top: 1px solid #96a4b7;
                    -webkit-box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);
                    box-shadow: -2px 3px 11px 0px rgba(0, 0, 0, 0.75);

                    @include respond-to(tablet) {
                        width: 90px;
                    }

                    @include respond-to(desktop) {
                        width: 85px;
                    }


                    &:after {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                }
            }

            input[type="radio"]:checked + label:not(.control-label) {
                &[for="groupFitness"] .icon {
                    background: url("../img/icons/homepage-3b.png") $color-2 no-repeat;
                    background-position: center center;
                }

                &[for="gymAccess"] .icon {
                    background: url("../img/icons/homepage-1b.png") $color-2 no-repeat;
                    background-position: center center;
                }

                &[for="supriseMe"] .icon {
                    background: url("../img/icons/icon-thumb-b.png") $color-2 no-repeat;
                    background-position: center center;
                }
            }

            input[type="radio"] + label:not(.control-label) {
                .icon {
                    &.icon.icon {
                        background-size: 70%;

                        @include respond-to(mobile-lg) {
                            background-size: auto;
                        }


                        @include respond-to(tablet) {
                            background-size: auto;
                        }
                    }
                }
            }

            button {
                padding: 15px 50px;
                color: $color-1;
                background: $color-2;
                border: none;
                text-transform: uppercase;
                border-left: 2px solid #e9a500;
                border-bottom: 2px solid #e9a500;
                border-right: 2px solid #e9a500;
                border-top: 2px solid #f4c44f;
                font-family: Robotoblack;
                font-size: 12px;
                cursor: pointer;
            }

            .home-input-wrapper {
                width: 100%;
                padding-left: unit-vw(100px, mobile);
                position: relative;

                @include respond-to(mobile-lg) {
                    padding-left: 105px;
                }


                @include respond-to(tablet) {
                    padding-left: 0;
                }
            }

            &.when {
                input[type=date]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    display: none;
                }

                input[type="date"]::-webkit-input-placeholder {
                    visibility: hidden !important;
                }

                input::-webkit-calendar-picker-indicator {
                    display: none;
                }

                label:not(.control-label) {
                    float: left;
                }

                input {
                    width: 84%;
                    border-radius: 0;

                    @include respond-to(tablet) {
                        width: 110px;
                    }
                }
            }

            &.where {
                .home-input-wrapper {
                    padding-right: 0;
                    position: relative;
                }

                label:not(.control-label) {
                    float: left;
                }

                input {
                    width: 100%;
                    padding-right: 35px;
                    border-radius: 0;

                    @include respond-to(tablet) {
                        width: 320px;
                    }
                }

                .nearMe {
                    width: 18px;
                    height: 23px;
                    background: url(../img/icons/icon-place.png) center center no-repeat;
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    cursor: pointer;

                    @include respond-to(tablet) {
                        right: inherit;
                        left: 405px;
                    }


                    &.in-progress {
                        background: none;

                        &:before {
                            content: "";

                            @include preloader(18px, 18px, 2px, absolute, "", "");
                            margin: auto;
                            z-index: 5;
                        }
                    }
                }
            }
        }

        .block {
            width: 90%;
            margin: 0 auto;

            @include respond-to(desktop) {
                min-width: 695px;
                max-width: 55%;
                background: url("../img/shadows-stripes/block-background1.png") no-repeat;
                background-size: auto 100%;
                background-position: top right;

                @include attr-px-vw-opt("padding-bottom", 31px, 31px);

                @include attr-px-vw-opt("padding-top", 28px, 28px);
                margin: 0;

                &:before {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 100%;
                    right: 100%;
                    position: absolute;
                    background: $color-6;
                    top: 0;
                }
            }
        }

        .has-error .control-label, .has-error + .control-label {
            @include respond-to(tablet) {
                margin-left: calc(100px + 0.7vw);
                max-width: 320px;
            }
        }

        .form-group + .has-error-backend {
            margin-bottom: 18px;

            &:before {
                left: 50%;
                margin-left: -7px;
            }

            @include respond-to(tablet) {
                margin-left: calc(100px + 0.7vw);
                max-width: 320px;

                &:before {
                    left: inherit;
                    margin-left: 0;
                }
            }
        }
    }

    #features {
        position: relative;
        padding: 0 10%;
        background: $color-3 url("../img/shadows-stripes/mobile-section-stripes.png") top left repeat-x;

        @include respond-to(desktop) {
            padding: 0px 5% 0 5%;
            background: url("../img/homepage-background3a.jpg") no-repeat;
            background-size: cover;
            background-position: center center;
            display: flex;
            min-height: calc(100vh - 74px);

            @supports (-ms-ime-align:auto) {
                padding: 72px 5% 0 5%;
            }

            @media all and (-ms-high-contrast:none) {
                padding: 72px 5% 0 5%;
            }

            .wrapper {
                flex: 1 0 0;
                align-self: center;
                @include clearfix();

                > div {
                    @include clearfix();
                }
            }

            &:before {
                content: "";
                width: 100%;
                padding-top: 65px;
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                background: -moz-linear-gradient(top, rgba(30,87,153,0) 0%, rgba(0,68,159,0.61) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(30,87,153,0) 0%,rgba(0,68,159,0.61) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(0,68,159,0.61) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#9c00449f',GradientType=0 ); /* IE6-9 */
            }
        }

        &:after {
            content: "";
            left: -10%;
            bottom: 0;
            width: 120%;
            height: 8px;
            background: url("../img/shadows-stripes/mobile-section-stripes.png") repeat-x;
            position: absolute;
            display: block;
        }

        @include respond-to(desktop) {
            .wrapper {
                padding-top: 3vw;
                padding-bottom: 100px;
            }

            &:after {
                content: "";
                left: 0;
                width: 100%;
                height: 100px;
                background: url("../img/shadows-stripes/horizontal-stripes.png") top left repeat-x;
                bottom: 0;
            }
        }


        header {
            padding: 8.6% 0 6.4% 0;
            font-family: ProximaNova;
            font-size: 48px;
            font-weight: 400; //font-weight: 700;
            @include respond-to(desktop) {
                padding-top: 0;
            }
        }

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include respond-to(desktop) {
                flex-wrap: nowrap;
            }


            .title, .subtitle {
                padding: 1.5vw 0;
                color: $white;
            }

            li {
                width: 41%;
                text-align: center;

                .image-wrapper {
                    width: 100%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                .title {
                    font-family: ProximaNova;
                    font-size: 20px;
                    font-weight: 400; //font-weight: 600;
                    line-height: 1.1;
                }

                .subtitle {
                    margin-bottom: unit-vw(45px, mobile);
                    font-family: ProximaNova;
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 1.2;
                    margin-left: -10%;
                    margin-right: -10%;
                    color: $white;
                    color: rgba(255,255,255,0.8);

                    @include respond-to(mobile-lg) {
                        margin-bottom: 45px;
                    }


                    @include respond-to(tablet) {
                        margin-bottom: 45px;
                    }


                    @include respond-to(desktop) {
                        margin-bottom: 0;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                img {
                    width: 80%;
                }
            }

            @include respond-to(desktop) {
                li {
                    width: 14.5%;
                    margin-left: 0;
                    padding-right: 0;
                    box-sizing: content-box;
                    clear: none;
                    display: flex;
                    flex-direction: column;
                    float: none;
                    position: relative;
                    padding-left: 4px;
                    padding-right: 4px;

                    + li {
                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            /*left: -1.2vw;*/
                            left: 0px;
                            width: 1px;
                            bottom: 0;
                            background: rgba(255, 255, 255, 0.23);
                        }
                    }

                    .image-wrapper {
                        width: 100%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            border-radius: 20px;
                        }
                    }

                    &:nth-of-type(2n) {
                        clear: none;
                        float: none;
                    }

                    .divider {
                        width: 20%;
                        margin: 0 auto;
                        border-bottom: 5px solid $color-2;
                    }



                    .title {
                        margin: 0 auto;
                        height: 75px;
                        display: flex;
                        align-items: center;
                    }

                    .subtitle {
                        text-align: center;
                    }
                }
            }
        }
    }

    #howitworks {
        color: $color-1;
        padding: 0 10%;
        position: relative;
        background-color: $bg-color-1;

        header {
            color: $color-17;
        }

        @include respond-to(desktop) {
            padding: 0 0 3vw 0;
            background-color: transparent, $color-3;
            background-image: url("../img/shadows-stripes/stripes.png"), url("../img/homepage-background6.jpg");
            background-repeat: no-repeat, no-repeat;
            color: $white;
            background-position: 63%, center;
            background-size: contain, cover;
            // background: rgba(76, 76, 76, 1);
            // background: -moz-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(89, 89, 89, 1) 30%, rgba(44, 44, 44, 1) 56%, rgba(43, 43, 43, 1) 76%, rgba(28, 28, 28, 1) 91%, rgba(17, 17, 17, 1) 94%, rgba(46, 46, 46, 1) 100%);
            // background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(76, 76, 76, 1)), color-stop(0%, rgba(0, 0, 0, 1)), color-stop(0%, rgba(71, 71, 71, 1)), color-stop(0%, rgba(17, 17, 17, 1)), color-stop(0%, rgba(102, 102, 102, 1)), color-stop(30%, rgba(89, 89, 89, 1)), color-stop(56%, rgba(44, 44, 44, 1)), color-stop(76%, rgba(43, 43, 43, 1)), color-stop(91%, rgba(28, 28, 28, 1)), color-stop(94%, rgba(17, 17, 17, 1)), color-stop(100%, rgba(46, 46, 46, 1)));
            // background: -webkit-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(89, 89, 89, 1) 30%, rgba(44, 44, 44, 1) 56%, rgba(43, 43, 43, 1) 76%, rgba(28, 28, 28, 1) 91%, rgba(17, 17, 17, 1) 94%, rgba(46, 46, 46, 1) 100%);
            // background: -o-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(89, 89, 89, 1) 30%, rgba(44, 44, 44, 1) 56%, rgba(43, 43, 43, 1) 76%, rgba(28, 28, 28, 1) 91%, rgba(17, 17, 17, 1) 94%, rgba(46, 46, 46, 1) 100%);
            // background: -ms-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(89, 89, 89, 1) 30%, rgba(44, 44, 44, 1) 56%, rgba(43, 43, 43, 1) 76%, rgba(28, 28, 28, 1) 91%, rgba(17, 17, 17, 1) 94%, rgba(46, 46, 46, 1) 100%);
            // background: linear-gradient(135deg, rgba(76, 76, 76, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(89, 89, 89, 1) 30%, rgba(44, 44, 44, 1) 56%, rgba(43, 43, 43, 1) 76%, rgba(28, 28, 28, 1) 91%, rgba(17, 17, 17, 1) 94%, rgba(46, 46, 46, 1) 100%);
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#2e2e2e', GradientType=1 );
            // &:before {
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            //     background: url(../img/shadows-stripes/stripes.png) no-repeat;
            //     background-position: 65%;
            //     background-size: auto 100%;
            // }
            header {
                color: $white;
            }
        }


        .wrapper > .subtitle {
            @include attr-px-vw-opt("padding-top", 10px, 10px);

            @include attr-px-vw-opt("padding-bottom", 20px, 20px);

            @include attr-px-vw-opt("line-height", 20px, 20px);
            font-family: ProximaNova;
            font-size: 16px;
            font-weight: $font-w-normal;
            color: $color-18;

            @include respond-to(desktop) {
                padding: 0;
                width: 48%;
                color: $white;
            }
        }

        header {
            padding: 6.9% 0 1% 0;
            font-family: ProximaNova;
            font-size: 48px;
            font-weight: 400; //font-weight: 700;
        }

        .elements {
            width: 100%;
            position: relative;

            @include clearfix;

            @include respond-to(desktop) {
                margin-top: 5%;
                float: left;
                width: 48.25%;
            }


            .image {
                float: left;
                width: 25%;

                img {
                    width: 100%;
                }
            }

            .description {
                @include attr-px-vw-opt("padding-left", 16px, 16px);

                @include attr-px-vw-opt("padding-top", 5px, 5px);
                float: left;
                width: 75%;

                .title {
                    font-family: ProximaNova;
                    font-size: 22px;
                    font-weight: 400; //font-weight: 600;
                    margin-bottom: 20px;
                    color: $color-12;

                    @include respond-to(desktop) {
                        color: $white;
                    }
                }

                .subtitle {
                    font-family: ProximaNova;
                    font-size: 16px;
                    font-weight: 300;
                    color: $font-color-16;

                    @include respond-to(desktop) {
                        color: $white;
                        color: rgba(255,255,255,0.8);
                    }
                }

                @include respond-to(desktop) {
                    padding-top: 10px;
                    padding-left: 36px;
                }
            }

            ul {
                li {
                    @include clearfix;
                    margin-bottom: 47px;
                }
            }
        }

        aside {
            //do zmiany
            display: none;
            margin-top: 5.8%;
            width: 40%;
            float: right;

            @include clearfix;

            @include respond-to(desktop) {
                display: block;
                position: relative;
            }


            img {
                width: 90%;
                max-width: 102%;
            }
        }
    }

    #subscribe {
        color: $white;
        position: relative;
        padding-top: 8px;
        margin-bottom: -1px;

        &:after {
            content: "";
            top: 0;
            position: absolute;
            width: 100%;
            height: 8px;
            background-image: url("../img/shadows-stripes/mobile-section-stripes-gray.png");
            background-color: $bg-color-1;
            background-repeat: repeat-x;
        }

        @include respond-to(desktop) {
            padding-top: 0;

            &:after {
                display: none;
            }

            &:before {
                content: "";
                width: 100%;
                padding-top: 65px;
                display: block;
                position: absolute;
                left: 0;
                background: -moz-linear-gradient(top, rgba(23, 99, 204, 1) 0%, rgba(125, 185, 232, 0) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(23, 99, 204, 1) 0%, rgba(125, 185, 232, 0) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(23, 99, 204, 1) 0%, rgba(125, 185, 232, 0) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1763cc', endColorstr='#007db9e8',GradientType=0 );
                /* IE6-9 */
            }
        }

        .widerwrapper {
            // width: $widerwrapper;
            margin: 0 auto;
            position: relative;
        }

        .home-table {
            display: table;
            width: 100%;
            height: 100%;
        }

        .home-table-row {
            display: block;

            @include respond-to(desktop) {
                display: table-row;
            }
        }

        .box-first, .box-second {
            display: block;
            height: 100%;
            overflow: hidden;
            position: relative;

            @include respond-to(desktop) {
                display: table-cell;
            }
        }

        .box-first {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // float: left;
            @include attr-px-vw-opt("padding-top", 100px, 180px);

            @include attr-px-vw-opt("padding-bottom", 30px, 24px);

            @media (max-width: 1200px) {
                width: auto;
            }

            @media (max-width: 992px) {
                width: auto;
            }

            @media (max-width: 768px) {
                width: auto;
                flex-direction: column;
            }

            @media (max-width: 576px) {
                width: auto;
                flex-direction: column;
            }

            @media (max-width: 1100px) {
                display: block;
                padding-top: 5.83333vw;
            }


            .login-block {
                background: url("../img/shadows-stripes/shadow-4.png") no-repeat;
                background-size: 100% 100%;
                position: relative;
                min-width: 385px;
                width: 90%;
                max-width: 687px;
                padding: 31px 0 31px 50px;

                &:before {
                    content: "";
                    display: block;
                    top: 0;
                    height: 100%;
                    width: 50vw;
                    right: 100%;
                    position: absolute;
                    background: $color-7;
                }

                @include respond-to(mobile-sm) {
                    min-width: 500px;
                    width: 100%;
                }


                @include respond-to(mobile-lg) {
                    min-width: 500px;
                    width: 100%;
                }
            }

            .login-block.no-style {
                background: none;

                &:before {
                    background: none;
                }
                &:after {
                    background: none;
                }
            }

            @include respond-to(tablet) {
                .login-block {
                    background-size: 100% 100%;
                    position: relative;
                    min-width: 385px;
                    width: 28%;
                    max-width: 687px;
                    padding: 31px 0 31px 50px;
                    background: $color-7;

                    &:before {
                        content: "";
                        display: block;
                        top: 0;
                        height: 100%;
                        width: 50vw;
                        right: 100%;
                        position: absolute;
                        background: $color-7;
                    }

                    &:after {
                        content: "";
                        width: 300px;
                        background: url("../img/shadows-stripes/shadow-5.png") no-repeat;
                        background-size: auto 100%;
                        /* padding-top: 7%; */
                        display: block;
                        position: absolute;
                        left: 100%;
                        height: 100%;
                        top: 0;
                    }
                }
            }

            @include respond-to(desktop) {
                .login-block {
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 100%;
                        height: 100%;
                        background: url(../img/shadows-stripes/shadow-5.png) no-repeat;
                        background-size: 100% 100%;
                        width: 14vw;
                        top: 0;
                    }
                }
            }

            .cart-block {
                width: 50%;
                margin-right: 0;
                padding: 30px 20px;
                position: relative;
                background: url(../img/shadows-stripes/shadow-6.png) no-repeat;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: inline-block;

                @media (max-width: 1100px) {
                    width: 80%;
                    margin-right: 68px;
                    margin-left: 0%;
                    margin-top: 20px;
                    min-height: 205px;
                    position: relative;
                    background: url(../img/shadows-stripes/shadow-6.png) no-repeat;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    display: block;
                }

                .cart-background {
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    width: 100%;
                    height: 100%;
                }

                .cart-element-wrapper {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    padding-left: 50px;

                    @include respond-to(desktop) {
                        left: -30px;
                    }

                    @include respond-to(tablet) {
                        padding-left: 50px;
                    }


                    .title {
                        margin-top: 30px;

                        @media (max-width: 576px) {
                            margin-top: 30px;
                        }
                    }

                    .subtitle {
                        margin-left: 0px;
                    }

                    a {
                        font-family: ProximaNova;
                        font-size: 18px;
                        font-weight: 400;
                        color: #074073;
                        border: solid 2px #f2b728;
                        background: #f2b728;
                        padding: 15px 40px;
                        margin-top: 16px;
                        display: inline-block;
                        text-transform: uppercase;
                        font-weight: 700;
                        cursor: pointer;

                        @media (max-width: 576px) {
                            padding: 6px 20px;
                        }

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }

            .title {
                //Edit from here
                font-family: ProximaNova;
                font-size: 33px;
                font-weight: 400; //font-weight: 700;
                @include respond-to(mobile-sm) {
                    font-size: unit-vw(33px, mobile);
                }
            }

            .subtitle {
                // max-width: 54%;
                font-family: ProximaNova;
                font-size: 16px;
                font-weight: 400; //font-weight: 600;
                margin-top: 20px;

                @include respond-to(mobile-sm) {
                    font-size: unit-vw(16px, mobile);
                    margin-top: unit-vw(17px, mobile);
                }
            }
        }

        .box-second {
            width: 100%;
            //float: left;
            position: relative;
            /*display: none;*/
            /*@include attr-px-vw-opt("padding-top", 100px, 180px);*/
            @include attr-px-vw-opt("padding-bottom", 30px, 24px);

            @include respond-to(desktop) {
                width: 40%;
                /*display: none;*/
            }


            .subscribe-block {
                background: url("../img/shadows-stripes/shadow-4.png") no-repeat;
                background-size: 100% 100%;
                position: relative;
                min-width: 435px;
                width: 90%;
                max-width: 687px;
                padding: 31px 0 31px 50px;

                &:before {
                    content: "";
                    display: block;
                    top: 0;
                    height: 100%;
                    width: 50vw;
                    right: 100%;
                    position: absolute;
                    background: $color-7;
                }

                @include respond-to(mobile-sm) {
                    min-width: 500px;
                    width: 100%;
                }


                @include respond-to(mobile-lg) {
                    min-width: 500px;
                    width: 100%;
                }

                @media (max-width: 1100px) {
                    min-width: 385px;
                }
            }

            @include respond-to(tablet) {
                .subscribe-block {
                    background-size: 100% 100%;
                    position: relative;
                    /*min-width: 385px;*/
                    width: 28%;
                    max-width: 687px;
                    padding: 31px 0 31px 50px;
                    background: $color-7;

                    &:before {
                        content: "";
                        display: block;
                        top: 0;
                        height: 100%;
                        width: 50vw;
                        right: 100%;
                        position: absolute;
                        background: $color-7;
                    }

                    &:after {
                        content: "";
                        width: 300px;
                        background: url("../img/shadows-stripes/shadow-5.png") no-repeat;
                        background-size: auto 100%;
                        /* padding-top: 7%; */
                        display: block;
                        position: absolute;
                        left: 100%;
                        height: 100%;
                        top: 0;
                    }
                }
            }

            @include respond-to(desktop) {
                .subscribe-block {
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 100%;
                        height: 100%;
                        background: url(../img/shadows-stripes/shadow-5.png) no-repeat;
                        background-size: 100% 100%;
                        width: 14vw;
                        top: 0;
                    }
                }
            }

            .subscribe-block {
                /*background: $color-7;
                position: relative;
                width: 100%;
                padding: 31px 0 31px 50px;

                &:after {
                    content: "";
                    width: 300px;
                    background: url("../img/shadows-stripes/shadow-5.png") no-repeat;
                    background-size: auto 100%;
                    display: block;
                    position: absolute;
                    left: 100%;
                    height: 100%;
                    top: 0;
                }

                @include respond-to(mobile-lg) {
                    width: 91%;

                }


                @include respond-to(tablet) {
                    width: 61%;

                }


                @include respond-to(desktop) {
                    width: 100%;
                    background-color: $color-7;
                    background-image: none;
                    background-size: 100%;

                }*/
                .title {
                    //Edit from here
                    font-family: ProximaNova;
                    font-size: 33px;
                    font-weight: 400; //font-weight: 700;
                    @include respond-to(mobile-sm) {
                        font-size: unit-vw(33px, mobile);
                    }
                }

                .subtitle {
                    // max-width: 54%;
                    font-family: ProximaNova;
                    font-size: 16px;
                    font-weight: 400; //font-weight: 600;
                    margin-top: 20px;

                    @include respond-to(mobile-sm) {
                        font-size: unit-vw(16px, mobile);
                        margin-top: unit-vw(17px, mobile);
                    }
                }
                // button {
                //     font-family: ProximaNova;
                //     font-size: 16px;
                //     font-weight: 400; //font-weight: 600;
                //     color: $color-1;
                //     border: solid 2px $color-2;
                //     background: $color-2;
                //     padding: 16px 29px;
                //     margin-top: 14px;
                // }
                form {
                    display: flex;
                }

                .form-group {
                    display: inline-block;
                    vertical-align: top;
                    width: 30%;
                    margin-right: 5px;

                    @include respond-to(mobile-lg) {
                        width: 50%;
                        margin-right: 10px;
                    }

                    @include respond-to(tablet) {
                        width: 50%;
                        margin-right: 10px;
                    }
                }

                input {
                    font-family: ProximaNova;
                    font-size: 16px;
                    font-weight: 400; //font-weight: 600;
                    color: $color-1;
                    padding: 16px 29px;
                    margin-top: 16px;
                    border: none;
                    border-radius: 0;
                    width: 100%;

                    @include respond-to(mobile-sm) {
                        font-size: unit-vw(16px, mobile);
                        padding: unit-vw(15px, mobile) unit-vw(29px, mobile);
                        margin-top: unit-vw(14px, mobile);
                    }
                }

                button {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .has-error .control-label, .has-error + .control-label, .has-error-backend .control-label, .has-error-backend + .control-label {
                    position: absolute;
                    width: 228px;
                }

                .form-group + .control-label {
                    bottom: -13px;
                }
            }
        }

        button, a {
            font-family: ProximaNova;
            font-size: 16px;
            font-weight: 400; //font-weight: 600;
            color: $color-1;
            border: solid 2px $color-2;
            background: $color-2;
            padding: 16px 40px;
            margin-top: 16px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: $font-w-bold;
            cursor: pointer;
            // min-width: 202px;
            //Edit to here
            @include respond-to(mobile-sm) {
                padding: unit-vw(13px, mobile) unit-vw(37px, mobile);
                margin-top: unit-vw(14px, mobile);
                font-size: unit-vw(15px, mobile);
            }
        }

        button.in-progress {
            &:after {
                margin-right: 2px;

                @include respond-to(tablet) {
                    margin-right: 10px;
                }
            }
        }

        .background1 {
            background: url("../img/homepage-background4.png") no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            width: 100%;
            left: 0;
            height: 100%;
            top: 0;
            z-index: -1;

            @include respond-to(desktop) {
                right: 0;
                width: 100%;
                background-position: center;
            }
        }

        .background2 {
            background: url("../img/homepage-background5.png") no-repeat;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;

            @include respond-to(mobile-sm) {
                background-position: right;
            }


            @include respond-to(tablet) {
                background-position: right center;
            }


            @include respond-to(desktop) {
                width: 45vw;
                background-position: center;
            }
        }
    }

    .component-footer-decoration.footer-decoration--padded {
        margin-top: 0;
    }
}
    //Datepicker styling:
    .home-datepicker {
        background: #fff;
        padding: 1%;

        table.ui-datepicker-calendar td.ui-datepicker-today a {
            background: $bg-color-1;
        }

        table.ui-datepicker-calendar a.ui-state-default.ui-state-active {
            background: $color-13;
        }
    }