﻿#Header-Section {
    .main-header {
        font-weight: bold;
        font-size: 33px;

        @media (max-width: 768px) {
            font-size: 27px;
            text-align: center;
        }
    }

    .sub-header {
        padding-top: 10px;

        @media (max-width: 768px) {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            max-width: unset;
        }
    }

    .header-bar {
        padding-top: 20px;

        @media (max-width: 768px) {
            max-width: 325px;
            margin: 0 auto;
        }

        .header-bar-section {
            padding-right: 22px;
            padding-left: 5px;
            height: 50px;
            display: inline-block;
            margin-left: -4px;
            color: #afafaf;

            @media (max-width: 768px) {
                display: block;
                margin-left: 20px;
                margin-right: 20px;
            }



            &.completed-section {
                .number {
                    background-color: #606060;
                    color: #fff;
                }

                color: #606060;
                cursor: pointer;
            }

            &.current-section {
                color: #1243b2;

                .number {
                    border: 2px solid #1243b2;
                    background-color: #fff;
                    color: #1243b2;
                }


                .label {
                    font-weight: bold;
                }
            }

            .number {
                border-radius: 50%;
                width: 36px;
                height: 36px;
                padding: 8px;
                border: 2px solid #afafaf;
                text-align: center;
                display: inline-block;
                margin-right: 15px;
            }
        }
    }

    .outer-progress-bar {
        max-width: 658px;
        margin-bottom: 20px;
        border: 2px solid #b0b0b0;

        @media (max-width: 768px) {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 15px;
        }

        .progress-bar {
            background-color: #0058c2;
            color: #fff;
            font-weight: bold;
            text-align: right;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            min-width: 30px;
            padding-left: 5px;
        }
    }

    .progress-statement {
        font-weight: bold;
        padding-bottom: 25px;


        @media (max-width: 768px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
